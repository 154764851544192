import { TagType } from "actions/castActions";
import { ValTagDescriptionDetails } from "components/ArchetypeMembers/ShowArchetypeMember/ValTagDescription";
import styles from './styles.module.scss';
import { getTagDisplayName } from "lib/tag.helpers";
import classNames from "classnames";

interface ShowTagProps {
  tag?: TagType;
  className?: string;
}

export const ShowTag = ({ tag, className }: ShowTagProps) => {
  return (<div className={classNames(styles.tagWrapper, className )}>
        <div className={styles.tagTitle}>{getTagDisplayName(tag)}</div>
      <div>
          {tag?.description}{tag?.valDescriptions && tag.valDescriptions.length > 0 ? <ValTagDescriptionDetails tagDescriptions={tag.valDescriptions} /> : undefined}
      </div>
    </div>);
};

export default ShowTag;