import { useState, useMemo, useCallback } from "react";
import classNames from "classnames";

import { getTagKey, tagsToMap } from "actions/helpers/tagHelpers";
import { TagType,  } from "actions/types";
import { UpdateCastMemberType } from "actions/cast.types";
import { Button, ButtonStyle} from 'components/Buttons';
import { ArchetypeDisplayType, ArchetypeSectionType, CastMemberType } from "actions/cast.types";
import { filterTagsBySelectors } from "lib/thresholds";
import { ShowDisplayArchetypeMember, } from "components/ArchetypeMembers/ShowArchetypeMember/BloodOfStarsArchetype";
import { StandingType, getCastStanding } from "lib/archetype.helpers";
import { useForm } from 'react-hook-form';
import { TextField, TextFieldType } from 'components/Forms/TextField';
import { Form } from 'components/Forms/Form';

import styles from "./styles.module.scss";

interface CastMemberEntryProps {
   castMember: CastMemberType;
   archetypeDisplay?: ArchetypeDisplayType;
   editTagOptions?: Array<TagType>;
   saveCastMemberEntry?: (castMember: CastMemberType, updateEntries?: UpdateCastMemberType) => void;
   closeEdit?: (castMember?: CastMemberType) => void;
   onClickDescriptor?: (tag?: TagType) => void;
}

interface CastEntrySectionDisplay {
  section: ArchetypeSectionType;
  castMember: CastMemberType;
  editTagOptions?: Array<TagType>;
  tags?: Array<TagType>;
  tagsMap?: Map<string, TagType>;
  allTagsMap?: Map<string, TagType>;
  setUpdateTags?: (tags: Array<TagType>) => void;
  setUpdateTag?: (tag: TagType, alreadyExists: boolean) => void;
}

// Blood of Stars Section Keys
export const sectionKeys = {
  primary: 'primary',
  points: 'points',
  statline: 'statline',
  profileTraits: 'traits',
  abilityTraits: 'abilityTraits',
  gear: 'gear',
  factionSpecialRules: 'factionSpecialRules',
  factionCampaignUpgrades: 'factionCampaignUpgrades',  
  injuries: 'injuries',
  upgrades: 'upgrades',
  traitConditions: 'traitConditions',
  leaderTraits: 'leaderTraits',
  xp: 'xp',
  tacticOptions: 'tacticOptions',
};

export const BaseCastDisplaySection = ({castMember, section, setUpdateTags, setUpdateTag, editTagOptions, tags, allTagsMap, tagsMap }: CastEntrySectionDisplay) => {
  const tagOptions = new Map<string,TagType>(tagsToMap(editTagOptions));
  const filteredEditTagOptions = filterTagsBySelectors(section.tagKeys, Array.from(tagOptions.values()));

  switch(section.key) {
    case sectionKeys.factionSpecialRules:
    case sectionKeys.gear:
      return (<CastDisplaySectionDefault 
        castMember={castMember}
        section={section}
        tags={tags}
        tagsMap={tagsMap}
        setUpdateTags={setUpdateTags}
        setUpdateTag={setUpdateTag}
        editTagOptions={filteredEditTagOptions}
      />);
    default:
      return null;
  }
};

export const CampaignCastDisplaySection = ({castMember, section, setUpdateTags, setUpdateTag, editTagOptions, tags, allTagsMap, tagsMap }: CastEntrySectionDisplay) => {
  const tagOptions = new Map<string,TagType>(tagsToMap(editTagOptions));
  const filteredEditTagOptions = filterTagsBySelectors(section.tagKeys, Array.from(tagOptions.values()));

  switch(section.key) {
    case sectionKeys.upgrades:
    case sectionKeys.factionCampaignUpgrades:
    case sectionKeys.injuries:
    case sectionKeys.xp:
      return (<CastDisplaySectionDefault 
        castMember={castMember}
        section={section}
        tags={tags}
        tagsMap={tagsMap}
        setUpdateTags={setUpdateTags}
        setUpdateTag={setUpdateTag}
        editTagOptions={filteredEditTagOptions}
      />);
    default:
      return null;
  }
};

export const TacticCastDisplaySection = ({castMember, section, setUpdateTags, setUpdateTag, editTagOptions, tags, allTagsMap, tagsMap }: CastEntrySectionDisplay) => {
  const tagOptions = new Map<string,TagType>(tagsToMap(editTagOptions));
  const filteredEditTagOptions = filterTagsBySelectors(section.tagKeys, Array.from(tagOptions.values()));

  switch(section.key) {
    case sectionKeys.tacticOptions:
      return (<CastDisplaySectionDefault 
        castMember={castMember}
        section={section}
        tags={tags}
        tagsMap={tagsMap}
        setUpdateTags={setUpdateTags}
        setUpdateTag={setUpdateTag}
        editTagOptions={filteredEditTagOptions}
      />);
    default:
      return null;
  }
};

export const CastDisplaySectionStatline = ({castMember, section }: CastEntrySectionDisplay) => {
  const keys = filterTagsBySelectors(section.tagKeys, castMember.tags);

  if (keys) {
    return <div>
      <h4>
        {section.name}:
      </h4>
      <div className={styles.statline}>
        {
          keys.map((k, index) => {
            return <div key={k.key} className={styles.statlineEntry}>
                {k.key}: {Array.isArray(k.val) ? k.val.join(', ') : k.val}{ keys.length > 0 && index !== keys.length - 1 ? `,` : ``}
              </div>
          })
        }
      </div>
    </div>
  } else {
    return null;
  }
}

export const getSubDisplay = (tag: TagType) => {
  return tag.displaySubTags && tag.displaySubTags.map((ds) => { return `${ds.title || ds.baseKey || ds.key}${ds.val ? `(${ds.val})` : ``}`; }).join(`,`); 
}

interface DisplayTagProps {
  tag: TagType;
  setUpdateTag?: (tag: TagType, exists: boolean) => void;
};

export const DisplayTag = ({
  tag
  }: DisplayTagProps) => {
    const title = tag.title || tag.baseKey || tag.key;
    const descriptionDisplay = getSubDisplay(tag);
    const description = `${tag.description}${tag.valDescriptions ? `: ${tag.valDescriptions.map((vd) => { return `${vd.title || vd.key}: ${vd.description}`;}).join(`, `)}`:``}`;
  return <>{title}{tag.val ? `(${Array.isArray(tag.val) ? tag.val.join(', ') : tag.val})` : null}{descriptionDisplay ? `: ${descriptionDisplay}` : ``}{description ? ` - ${description}` : ``}</>
}

export const DisplayTagVal = ({
  tag,
  setUpdateTag
  }: DisplayTagProps) => {
    const updateLoader = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if(event.target?.value) {
        const newTag = {
          ...tag,
          val: event.target?.value
        };

        setUpdateTag && setUpdateTag(newTag, false);
      } else if (event.target?.value === "") {
        const newTag = {
          ...tag,
          val: event.target?.value
        };
        setUpdateTag && setUpdateTag(newTag, true);
      }
    }
    const title = tag.title || tag.baseKey || tag.key;
    const descriptionDisplay = getSubDisplay(tag);
    const description = `${tag.description}${tag.valDescriptions ? `: ${tag.valDescriptions.map((vd) => { return `${vd.title || vd.key}: ${vd.description}`;}).join(`, `)}`:``}`;
  return <><div className={styles.displayTagVal}>
    <TextField 
    name={"tag"} 
    type={TextFieldType.text}
    value = {tag.val}
    onChange={updateLoader}
    />s
   <div className={styles.textDescription}> {title}: {descriptionDisplay ? `${descriptionDisplay}-` : ``}{description ? ` - ${description}` : ``}</div>
  </div></>

}

export const CastDisplaySectionDefault = ({
  section,
  setUpdateTag,
  editTagOptions,
  tagsMap
}: CastEntrySectionDisplay) => {
  return <div>
    <h4>
      {section.name}:
    </h4>
    <div>
      <div>
        {editTagOptions?.map((etg) => {
          const exists = tagsMap && tagsMap.get(getTagKey(etg));
          const finalTag = exists ? { ...etg, ...exists} : etg;
          if(finalTag.valCanBeModified) {
            return (<div key={getTagKey(finalTag)}>
              <div
              className={classNames(styles.tagOptionButton, exists && styles.selectedTagOptionButton)}
              >
                <DisplayTagVal tag={finalTag} setUpdateTag={setUpdateTag}/>
              </div>
            </div>)  
          }
          return (<div key={getTagKey(etg)}>
            <div
            className={classNames(styles.tagOptionButton, exists && styles.selectedTagOptionButton)}
            onClick={() => { 
              etg && setUpdateTag && setUpdateTag(etg, exists ? true : false); 
            }}
            >
              <DisplayTag tag={etg} />
            </div>
          </div>)
        })}
      </div>
    </div>
  </div>
}

export const CastDisplaySectionTrait = ({castMember, section }: CastEntrySectionDisplay) => {
  const keys = filterTagsBySelectors(section.tagKeys, castMember.tags);

  if (keys) {
    return <div>
      <h4>
        {section.name}:
      </h4>
      <div className={styles.statline}>
        {
          keys.map((k, index) => {
            return <div key={k.key} className={styles.statlineEntry}>
              {k.key}{k.val ? ` (${Array.isArray(k.val) ? k.val.join(', ') : k.val})` : null}{ keys.length > 0 && index !== keys.length - 1 ? `,` : ``}
            </div>
          })
        }
      </div>
    </div>
  } else {
    return null;
  }
}

export const CastDisplaySectionCondition = ({castMember, section }: CastEntrySectionDisplay) => {
  const keys = filterTagsBySelectors(section.tagKeys, castMember.tags);

  if (keys) {
    return <div>
      <h4>
        {section.name}:
      </h4>
      <div>
        {
          keys.map((k) => {
            return <div key={k.key}>
              {k.key}{k.val ? `(${Array.isArray(k.val) ? k.val.join(', ') : k.val})` : null}
            </div>
          })
        }
      </div>
    </div>
  } else {
    return null;
  }
}

const useGetPrimaryTraits = (tagsMap: Map<string, TagType>) => {
  return {
    standing: tagsMap.get('Standing'),
    faction: tagsMap.get('Faction') 
  }
};

const translateValues = (vals: any): UpdateCastMemberType => {
  // title?: string;
  // type?: CastMemberClassType;
  // tags?: Array<TagType>;
  // amount?: number;

  return {
    title: vals?.title,
    type: vals?.type,
    amount: vals.amount
  }
}

export const EditCastMemberEntry = ({
    castMember,
    archetypeDisplay,
    editTagOptions,
    saveCastMemberEntry,
    closeEdit,
    onClickDescriptor
}:CastMemberEntryProps) => {
  const [, setSubmitting] = useState(false);
  const [, setSubmitError] = useState('');
  const tagsMap = useMemo(() => {
    return castMember.tagsMap || tagsToMap(castMember?.tags);
  }, [castMember]);
  const primaryTraits =  useGetPrimaryTraits(tagsMap);
  const [updateTagOptions, setUpdateTagOptions] = useState<TagType[]>(castMember?.tagOptions || []);
  const { register, handleSubmit, formState } = useForm({
    reValidateMode: 'onSubmit',
    defaultValues: { 
      title: castMember.title,
      type: castMember.type,
      amount: castMember.amount
    },
  });
  const errors = formState?.errors;
  const standing = useMemo(() => { return getCastStanding(castMember);}, [castMember]);
  const filteredTagOptions = useMemo(() => {
    const newEditTagOptions = editTagOptions?.filter((eto) => {
      const foundTag = tagsMap.get(getTagKey(eto));
      if(foundTag) {
        return false;
      } else {
        return true;
      }
    });

    return newEditTagOptions;

  }, [tagsMap, editTagOptions]);
  // chosen tagOptions
  const allTagsMap = useMemo(() => {
    const startTagsMap = new Map(castMember.tagsMap || tagsToMap(castMember.tags));

    castMember?.tagOptions?.forEach((to) => {
      startTagsMap.set(to.key, to);
    });

    updateTagOptions.forEach((ut) => {
     startTagsMap.set(ut.key, ut);
    });

   return startTagsMap;

  }, [castMember, updateTagOptions]);

  // availableTagOptions.
  const updateTagOptionsMap = useMemo(() => {
    const tagsMap = tagsToMap(updateTagOptions); 

    return tagsMap;
  },[updateTagOptions]);

  const setUpdateTagOption = useCallback((t: TagType, alreadyExists?: boolean) => {
    const newMap = new Map(updateTagOptionsMap);
    if (!alreadyExists) {
      newMap.set(getTagKey(t), t);
    } else {
      newMap.delete(getTagKey(t));
    }

    const nm = Array.from(newMap.values());

    setUpdateTagOptions(nm);
  }, [updateTagOptionsMap, setUpdateTagOptions])

  const saveAndMerge = useCallback(() => {
    handleSubmit(async (vals: UpdateCastMemberType) => { 
      try {
        setSubmitting(true);
        setSubmitError('');
        const translatedValues = translateValues(vals);
        
        if(saveCastMemberEntry) {
          const saveEntry = { ...translatedValues, tagOptions: updateTagOptions };
          saveCastMemberEntry && saveCastMemberEntry(castMember, saveEntry);
        }
        
        setSubmitting(false);

        closeEdit && closeEdit(castMember);

        // forward to next page
      } catch(err) {
        setSubmitting(false);
        setSubmitError('Update to Cast Member Failed.');
      }
    })();

  }, [castMember, updateTagOptions, saveCastMemberEntry, closeEdit, handleSubmit]);

  const primaryTraitStanding = primaryTraits.standing?.title || primaryTraits.standing?.val || '';

  return (
      <div className={styles.archetypeEntryCard}>
          <div className={classNames(styles.archetypeTitle)}>
          <div className={styles.actionButtons}>
              <Button
                buttonStyle={ButtonStyle.primary}
                onClick={() => { saveAndMerge(); }}
                className={styles.viewArchetypeButton}
              >
               Done 
              </Button>
              <Button
                buttonStyle={ButtonStyle.secondary}
                onClick={() => { closeEdit && closeEdit(castMember); }}
                className={styles.viewArchetypeCloseButton}
              >
               x 
              </Button>
            </div>
            <div className={styles.standing}>
              {primaryTraitStanding && `${primaryTraitStanding}`}
            </div>
            <div className={styles.name}>
              {castMember.name}
            </div>

          </div>
          <div className={styles.showDisplayArchetypeMember}>
            <ShowDisplayArchetypeMember
              {...{ 
                displayArchetypeMember: castMember?.calculatedCast || castMember,
                archetypeDisplay,
                onClickDescriptor,
                className: styles.showDisplayArchetypeMemberInnerWrapper
                
              }}
            />
          </div>
          <div>
            <Form
              onSubmit={saveAndMerge}
            >
              <TextField 
                type= {TextFieldType.text}
                placeholder="Name"
                label="Name"
                className={styles.fieldWrapper}
                labelClassName={styles.label}
                fieldClassName={styles.field}
                error={errors?.title}
                {...register("title", {
                  validate:{
                    validTitle: (val?: string) => {
                      if(val) {
                        return true;
                      } else {
                        return true;
                      }
                    }
                  }
                })}
              />
            </Form>
          </div>
          {standing === StandingType.tactic || standing === StandingType.team ?
          (
            <TacticOptions
              {...{ 
                archetypeDisplay,
                 castMember,
                  updateTagOptions,
                   updateTagOptionsMap, 
                   allTagsMap, 
                   setUpdateTagOptions, 
                   setUpdateTagOption, 
                   filteredTagOptions
              }}
            />
          ) : ( 
          <UnitOptions
            {...{ 
              archetypeDisplay,
               castMember,
                updateTagOptions,
                 updateTagOptionsMap, 
                 allTagsMap, 
                 setUpdateTagOptions, 
                 setUpdateTagOption, 
                 filteredTagOptions
            }}
          />
          )}
      </div>
  )
};

interface UnitOptionsProps {
  archetypeDisplay?: ArchetypeDisplayType;
   castMember: CastMemberType;
   updateTagOptions: TagType[];
    updateTagOptionsMap: Map<string, TagType>;
  allTagsMap: Map<string, TagType>;
  setUpdateTagOptions: (value: React.SetStateAction<TagType[]>) => void;
  setUpdateTagOption: (t: TagType, alreadyExists?: boolean) => void;
  filteredTagOptions?: TagType[];
}


const TacticOptions = ({
  archetypeDisplay,
   castMember,
   updateTagOptions, 
   updateTagOptionsMap, 
   allTagsMap, 
   setUpdateTagOptions, 
   setUpdateTagOption, 
   filteredTagOptions
}: UnitOptionsProps) => {
  return <div>
    <div>
      {archetypeDisplay?.data?.sections.map((s) => {
        return <TacticCastDisplaySection
          key={s.key}
          section={s}
          castMember={castMember}
          tags={updateTagOptions}
          tagsMap={updateTagOptionsMap}
          allTagsMap={allTagsMap}
          setUpdateTags={setUpdateTagOptions}
          setUpdateTag={setUpdateTagOption}
          editTagOptions={filteredTagOptions} />;
      })}
    </div>
  </div>;
}


const UnitOptions = ({
  archetypeDisplay,
   castMember,
   updateTagOptions, 
   updateTagOptionsMap, 
   allTagsMap, 
   setUpdateTagOptions, 
   setUpdateTagOption, 
   filteredTagOptions
}: UnitOptionsProps) => {
  return <div>
    <div>
      {archetypeDisplay?.data?.sections.map((s) => {
        return <BaseCastDisplaySection
          key={s.key}
          section={s}
          castMember={castMember}
          tags={updateTagOptions}
          tagsMap={updateTagOptionsMap}
          allTagsMap={allTagsMap}
          setUpdateTags={setUpdateTagOptions}
          setUpdateTag={setUpdateTagOption}
          editTagOptions={filteredTagOptions} />;
      })}
    </div>
    <h3>Campaign Options</h3>
    <div>
      {archetypeDisplay?.data?.sections.map((s) => {
        return <CampaignCastDisplaySection
          key={s.key}
          section={s}
          castMember={castMember}
          tags={updateTagOptions}
          tagsMap={updateTagOptionsMap}
          allTagsMap={allTagsMap}
          setUpdateTags={setUpdateTagOptions}
          setUpdateTag={setUpdateTagOption}
          editTagOptions={filteredTagOptions} />;
      })}
    </div>
  </div>;
}

