import Layout from "Layout";
import { ResetForm } from './ResetForm';

export const ResetPassword = () => {
  return (
    <Layout>
      <div>
          <h2>Reset Password</h2>
          <ResetForm />
      </div>
    </Layout>
  );
}
