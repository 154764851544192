import { useCallback, useMemo, useState, useEffect } from "react";
import Layout from "Layout";
import { useNavigate, useParams } from "react-router-dom";
import { useCreateCast } from "actions/castActions";
import { useGetArchetypes } from "actions/archetypeActions";
import styles from './styles.module.scss';
import { Button } from 'components/Buttons';
import { TextField, TextFieldType } from 'components/Forms/TextField';
import { Select, SelectTypes } from 'components/Forms/Select';
import { useForm } from 'react-hook-form';
import { Form } from 'components/Forms/Form';
import { routeLinks } from "App/routing";
import { useWorld } from "hooks/worldHooks";
import { useGetCurrentUser } from "hooks/userHooks";
import classNames from "classnames";

interface FormValuesType {
  name?: string;
  display?: number;
  description?: string;
  archetypeId?: number;
}

type NewCastPageParams = {
  archetypeId?: string;
}

// name: string;
//description?: string;
export const NewCast = () => {
  const { archetypeId } = useParams<NewCastPageParams>();
  const { id, isAdmin } = useGetCurrentUser(); 
  const { register, handleSubmit, formState, getValues, setValue } = useForm<FormValuesType>({
    defaultValues: { name: '', description: '', display: 1, archetypeId: undefined},
  });

  const navigate = useNavigate();
 
  const { chosenWorldId } = useWorld();
  const createCast = useCreateCast();
  const { data: archetypes } = useGetArchetypes(undefined, undefined, chosenWorldId, id);
  const [isSubmitting, setSubmitting] = useState(false);
  const [, setSubmitError] = useState<any>();

  useEffect(() => {
    const values = getValues();

    if (archetypes  && archetypes.length > 0 && typeof values.archetypeId !== 'undefined') {
      setValue(`archetypeId`, archetypes[0].id)
    }
  }, [archetypes, getValues, setValue]);

  const foundArchetype = useMemo(() => {
  let foundArchetypeId: number;
  let fullFoundArcetype;
  if(archetypeId) {
    try {
      foundArchetypeId = parseInt(archetypeId, 10);
      fullFoundArcetype = archetypes?.find((a) => {
        return a.id === foundArchetypeId;
      })
    } catch(err) {
    }
  }
  return fullFoundArcetype;
},[archetypes, archetypeId]);

  const onSubmit = useCallback(async (cast: FormValuesType) => {
    if (id) {
      const newEntry = {
        userId: id,
        name: cast.name,
        display: cast.display,
        description: cast.description,
        data: {},
        archetypeId: foundArchetype?.id || cast?.archetypeId
      };

      const createdCast = await createCast(newEntry, id, chosenWorldId);
      return createdCast;
    }
  }, [id, foundArchetype?.id, createCast, chosenWorldId]);

  const errors = formState?.errors;

  return (
    <Layout>
      <Form
         onSubmit={handleSubmit(async (vals: FormValuesType) => { 
          setSubmitting(true);
          try {
            const createdCast = await onSubmit(vals); 
          
            setSubmitting(false);
            navigate(`/${routeLinks.teams}/${createdCast?.id ? `${createdCast.id}/${routeLinks.edit}` : `` }`);
          } catch(err) {
            console.log("Submit Error:", err);

            setSubmitError(err);
            setSubmitting(false);
          }
        })} 
      >
      <div >
        <div className={styles.sectionHeader}>
          <h1>
            Teams 
          </h1>
          <div className={styles.updateButtons}>
            <Button
              disabled={isSubmitting} 
              type="submit"
              className={styles.submitButton}
            >
              {`Save`}
            </Button>
          </div>
        </div>
        <div className={styles.entries}>
          {foundArchetype ? (
              <div className={styles.fieldWrapper}><div className={styles.labelHeader}>Archetype:</div> {foundArchetype.name}</div>
            ) : <Select
              placeholder={"Archetype"}
              type={SelectTypes.html}
              label="Archetype"
              className={styles.fieldWrapper} 
              fieldClassName={styles.field}
              labelClassName={styles.labels}
              error={errors?.description}
              options={archetypes?.map((a) => {
                return { value: `${a.id}`, label: a.name};
              })}
              {...register("archetypeId", {
                required: true,
              })}
            />
          }
          <TextField
            type={TextFieldType.text}
            placeholder={"Name"}
            label="Name"
            className={styles.fieldWrapper}
            fieldClassName={styles.field}
            labelClassName={styles.labels}
            error={errors?.name}
            {...register("name", {
              validate:{
                validName: (val?: string) => {
                  return !!val || "Must include Name"
                }
              }
            })}
          />
          {isAdmin && <TextField
            type={TextFieldType.number}
            placeholder={"Display"}
            label="Display"
            className={styles.fieldWrapper}
            fieldClassName={styles.field}
            labelClassName={styles.labels}
            error={errors?.display}
            {...register("display", {
            })}
          />}
          <TextField
            type={TextFieldType.text}
            placeholder={"Description"}
            label="Description"
            className={styles.fieldWrapper}
            fieldClassName={styles.field}
            labelClassName={styles.labels}
            error={errors?.description}
            {...register("description", {
            })}
          />
         
        </div>
      </div>
      </Form>
    </Layout>
  );
}


export default NewCast;