import { ArchetypeDisplayType,
  ArchetypeType, 
  CastMemberClassType,
  CastType, 
  SectionType
 } from 'actions/cast.types';
import { TagThresholdSymbolType, LinkByType, TagThresholdAndOr } from 'actions/tag.types';

export const sectionKeys = {
  primary: 'primary',
  statline: 'statline',
  points: 'points',
  profileTraits: 'traits',
  abilityTraits: 'abilityTraits',
  gear: 'gear',
  factionSpecialRules: 'factionSpecialRules',
  factionCampaignUpgrades: 'factionCampaignUpgrades',
  injuries: 'injuries',
  upgrades: 'upgrades',
  traitConditions: 'traitConditions',
  leaderTraits: 'leaderTraits',
};

export const groupKeys = {
  leaders: 'leaders',
  elites: 'elites',
  rankAndFile: 'rankAndFile',
  tactic: 'tactic',
  bases: 'bases',
  gear: 'gear',
  structures: 'structures',
  monsters: 'monsters',
  factions: 'factions',
  subfactions: 'subfactions'
};

export const exampleArchetypeDisplay: ArchetypeDisplayType = {
  id: 1,
  name: "Faction Archetypes",
  description: "Faction Archetype List",
  createdAt: "2021-07-01T19:42:35+0000",
  updatedAt: "2021-07-01T19:42:35+0000",
  data: { 
    sections: [
      {
        key: sectionKeys.primary,
        name: 'Primary',
        description: 'The main primary info keys of the game',
        createdAt: "2021-07-01T19:42:35+0000",
        updatedAt: "2021-07-01T19:42:35+0000",
        tagKeys: ['Standing', 'Faction'],
      },
      {
        key: sectionKeys.points,
        name: 'Points',
        description: 'The main points keys of the game',
        createdAt: "2021-07-01T19:42:35+0000",
        updatedAt: "2021-07-01T19:42:35+0000",
        tagKeys: [ 'Points'],
      },
      {
        key: sectionKeys.statline,
        name: 'Statline',
        description: 'The main statline keys of the game',
        createdAt: "2021-07-01T19:42:35+0000",
        updatedAt: "2021-07-01T19:42:35+0000",
        tagKeys: ['Move', 'Fight', 'Shoot', 'Evade', 'Life'],
      },
      {
        key: sectionKeys.profileTraits,
        name: 'Profile Traits',
        description: 'The profile Traits of the game',
        createdAt: "2021-07-01T19:42:35+0000",
        updatedAt: "2021-07-01T19:42:35+0000",
        tagKeys: ['Dodge', 'Eagle Eye', 'Fast', 'Fighter', 'Fragile', 'Heavy Arms', 'Skilled', 'Slow', 'Tough', 'Weak'],
      },
      {
        key: sectionKeys.abilityTraits,
        name: 'Ability Traits',
        description: 'The ability traits of the game',
        createdAt: "2021-07-01T19:42:35+0000",
        updatedAt: "2021-07-01T19:42:35+0000",
        tagKeys: ['Berzerk', 'Blaster', 'Boost', 'Charge', 'Counterfire', 'Crit Fire', 'Crit Strike', 'Curse', 'Engulf', 'Explode', 'Blaze', 'Flank', 'Integration', 'Jump', 'Methodical', 'No Ranged', 'Onerous', 'Overpower', 'Pinning', 'Powerful', 'Push', 'Ranged', 'Rapid Fire', 'Regeneration', 'Short Ranged', 'Slug', 'Smash', 'Spotter', 'Target', 'Drag', 'Defender'],
      },
      {
        key: sectionKeys.traitConditions,
        name: 'Trait Conditions',
        description: 'The trait condition modifiers for the game',
        createdAt: "2021-07-01T19:42:35+0000",
        updatedAt: "2021-07-01T19:42:35+0000",
        tagKeys: ['Aim', 'Group',
          {
            "key": "AimTraitConditions",
            "thresholds": [{  
              "thresholdKey":"Aim",  
              "tagThresholdSymbol":TagThresholdSymbolType.Exists
            }]
          }
        ]
      },
      {
        key: sectionKeys.gear,
        name: 'Gear',
        description: 'The gear for the game',
        createdAt: "2021-07-01T19:42:35+0000",
        updatedAt: "2021-07-01T19:42:35+0000",
        type: SectionType.options,
        tagKeys: ['Aux Grenade Launcher', 'Blind Grenades', 'Blinding Flash', 'Charged Bolt', 'Grenades', 'Mutation', 'Pulse Charge'],
      }, 
      {
        key: sectionKeys.factionSpecialRules,
        name: 'Faction Special Rules',
        description: 'The faction special rules of the game',
        createdAt: "2021-07-01T19:42:35+0000",
        updatedAt: "2021-07-01T19:42:35+0000",
        type: SectionType.options,
        tagKeys: ['Indominable', 'Demonic Mark', 'Lightning Reflexes', 'Repair Protocols', 'Combined Arms'],
      },
      {
        key: sectionKeys.injuries,
        name: 'Injuries',
        description: 'The main injury keys of the game',
        createdAt: "2021-07-01T19:42:35+0000",
        updatedAt: "2021-07-01T19:42:35+0000",
        type: SectionType.options,
        tagKeys: ['Injury-Slow', 'Injury-Frail', 'Injury-Weak', 'Injury-No Ranged'],
      },
      {
        key: sectionKeys.upgrades,
        name: 'Upgrades',
        description: 'The upgrade keys for the game',
        createdAt: "2021-07-01T19:42:35+0000",
        updatedAt: "2021-07-01T19:42:35+0000",
        type: SectionType.options,
        tagKeys: ['Faction Training', 'Promotion', 'Promotion 2', 'Training', 'Training 2', "Training 3", "Recovery", "Tactical Training", "Tactical Training 2", "Requisition Troops"],
      },
      {
        key: sectionKeys.factionCampaignUpgrades,
        name: 'Faction Special Rules',
        description: 'The faction special rules of the game',
        createdAt: "2021-07-01T19:42:35+0000",
        updatedAt: "2021-07-01T19:42:35+0000",
        type: SectionType.options,
        tagKeys: [
          {
            "tag": {
              "key": "Trained",
              "val": "Crit Fire",
              "baseKey": "Trained",
              "linkBy": LinkByType.KeyVal
            },
            "thresholds":[{ 
              "tag": {
                "key":"Trained",
                "val":["Crit Fire"],
                "linkBy": LinkByType.KeyVal
              }, 
              "thresholdKey":"Trained", 
              "thresholdVal":"Crit Fire", 
              "tagThresholdSymbol":TagThresholdSymbolType.Exists
            }]
          }, 
            {
              "tag": {
                "key": "Trained",
                "val": "Crit Strike",
                "baseKey": "Trained",
                "linkBy": LinkByType.KeyVal
              },
              "thresholds":[{ 
                "tag": {
                  "key":"Trained",
                  "val":["Crit Strike"],
                  "linkBy": LinkByType.KeyVal
                }, 
                "thresholdKey":"Trained", 
                "thresholdVal":"Crit Strike", 
                "tagThresholdSymbol":TagThresholdSymbolType.Exists
              }]
            },
            {
              "tag": {
                "key": "Trained",
                "val": "Counter Fire",
                "baseKey": "Trained",
                "linkBy": LinkByType.KeyVal
              },
              "thresholds":[{ 
                "tag": {
                  "key":"Trained",
                  "val":["Counter Fire"],
                  "linkBy": LinkByType.KeyVal
                }, 
                "thresholdKey":"Trained", 
                "thresholdVal":"Counter Fire", 
                "tagThresholdSymbol":TagThresholdSymbolType.Exists
              }]
            },
            {
              "tag": {
                "key": "Trained",
                "val": "Fighter",
                "baseKey": "Trained",
                "linkBy": LinkByType.KeyVal
              },
              "thresholds":[{ 
                "tag": {
                  "key":"Trained",
                  "val":["Fighter"],
                  "linkBy": LinkByType.KeyVal
                }, 
                "thresholdKey":"Trained", 
                "thresholdVal":"Fighter", 
                "tagThresholdSymbol":TagThresholdSymbolType.Exists
              }]
            },
            {
              "tag": {
                "key": "Trained",
                "val": "Heavy Arms",
                "baseKey": "Trained",
                "linkBy": LinkByType.KeyVal
              },
              "thresholds":[{ 
                "tag": {
                  "key":"Trained",
                  "val":["Heavy Arms"],
                  "linkBy": LinkByType.KeyVal
                }, 
                "thresholdKey":"Trained", 
                "thresholdVal":"Heavy Arms", 
                "tagThresholdSymbol":TagThresholdSymbolType.Exists
              }]
            },
            {
              "tag": {
                "key": "Trained",
                "val": "Methodical",
                "baseKey": "Trained",
                "linkBy": LinkByType.KeyVal
              },
              "thresholds":[{ 
                "tag": {
                  "key":"Trained",
                  "val":["Methodical"],
                  "linkBy": LinkByType.KeyVal
                }, 
                "thresholdKey":"Trained", 
                "thresholdVal":"Methodical", 
                "tagThresholdSymbol":TagThresholdSymbolType.Exists
              }]
            }
          ],
      },
      {
        key: sectionKeys.leaderTraits,
        name: 'Leader Buffs',
        description: 'The main primary info keys of the game',
        createdAt: "2021-07-01T19:42:35+0000",
        updatedAt: "2021-07-01T19:42:35+0000",
        tagKeys: ['Blaster', 'Curse', 'Integration', 'Overpower', 'Target'],
      },
    ],
    groups: [
      {
        key: groupKeys.leaders,
        name: 'Leaders',
        description: 'The leaders',
        createdAt: "2021-07-01T19:42:35+0000",
        updatedAt: "2021-07-01T19:42:35+0000",
        tagThresholds: [
          {
            thresholdKey: 'Standing',
            threshold: 'leader',
            tagThresholdSymbol: TagThresholdSymbolType.Equal,
            andOrThresholds: TagThresholdAndOr.Or
          },
          {
            thresholdKey: 'Standing',
            threshold: 'leader',
            tag: {
              key: "Standing",
              val: "leader",
              linkBy: LinkByType.KeyVal
            },
            tagThresholdSymbol: TagThresholdSymbolType.Equal,
            andOrThresholds: TagThresholdAndOr.Or

          }]
      },
      {
        key: groupKeys.elites,
        name: 'Elites',
        description: 'The elites',
        createdAt: "2021-07-01T19:42:35+0000",
        updatedAt: "2021-07-01T19:42:35+0000",
        tagThresholds: [
          {
          thresholdKey: 'Standing',
          threshold: 'elite',
          tagThresholdSymbol: TagThresholdSymbolType.Equal,
          andOrThresholds: TagThresholdAndOr.Or

        },
        {
          thresholdKey: 'Standing',
          threshold: 'veteran',
          tagThresholdSymbol: TagThresholdSymbolType.Equal,
          andOrThresholds: TagThresholdAndOr.Or

        }, {
          thresholdKey: 'Standing',
          threshold: 'elite',
          tag: {
            key: "Standing",
            val: "elite",
            linkBy: LinkByType.KeyVal
          },
          tagThresholdSymbol: TagThresholdSymbolType.Equal,
          andOrThresholds: TagThresholdAndOr.Or

        }
      ]
      },          
      {
        key: groupKeys.rankAndFile,
        name: 'Rank and File',
        description: 'The rank and file',
        createdAt: "2021-07-01T19:42:35+0000",
        updatedAt: "2021-07-01T19:42:35+0000",
        tagThresholds: [
          {
          thresholdKey: 'Standing',
          threshold: 'rankAndFile',
          tagThresholdSymbol: TagThresholdSymbolType.Equal,
          andOrThresholds: TagThresholdAndOr.Or
        }, {
          thresholdKey: 'Standing',
          threshold: 'rankAndFile',
          tag: {
            key: "Standing",
            val: "rankAndFile",
            linkBy: LinkByType.KeyVal
          },
          tagThresholdSymbol: TagThresholdSymbolType.Equal,
          andOrThresholds: TagThresholdAndOr.Or
        }]
      },  
      {
        key: groupKeys.tactic,
        name: 'Tactics',
        description: 'The tactics',
        createdAt: "2021-07-01T19:42:35+0000",
        updatedAt: "2021-07-01T19:42:35+0000",
        tagThresholds: [
          {
          thresholdKey: 'Standing',
          threshold: 'tactic',
          tagThresholdSymbol: TagThresholdSymbolType.Equal,
          andOrThresholds: TagThresholdAndOr.Or
        }, {
          thresholdKey: 'Standing',
          threshold: 'tactic',
          tag: {
            key: "Standing",
            val: "tactic",
            linkBy: LinkByType.KeyVal
          },
          tagThresholdSymbol: TagThresholdSymbolType.Equal,
          andOrThresholds: TagThresholdAndOr.Or
        }]
      },       
    ]
  },
};

export const exampleArchetypeUpgrades: ArchetypeDisplayType = {
  id: 1,
  name: "Faction Archetypes",
  description: "Faction Archetype List",
  createdAt: "2021-07-01T19:42:35+0000",
  updatedAt: "2021-07-01T19:42:35+0000",
  data: { 
    sections: [
      {
        key: sectionKeys.gear,
        name: 'Gear',
        description: 'The gear for the game',
        createdAt: "2021-07-01T19:42:35+0000",
        updatedAt: "2021-07-01T19:42:35+0000",
        tagKeys: ['Aux Grenade Launcher', 'Grenades'],
      }, 
      {
        key: sectionKeys.factionSpecialRules,
        name: 'Faction Special Rules',
        description: 'The faction special rules of the game',
        createdAt: "2021-07-01T19:42:35+0000",
        updatedAt: "2021-07-01T19:42:35+0000",
        tagKeys: ['Indominable'],
      },
      {
        key: sectionKeys.factionCampaignUpgrades,
        name: 'Faction Special Rules',
        description: 'The faction special rules of the game',
        createdAt: "2021-07-01T19:42:35+0000",
        updatedAt: "2021-07-01T19:42:35+0000",
        tagKeys: [
          {
            "tag": {
              "key": "Trained",
              "val": "Crit Fire",
              "baseKey": "Trained",
              "linkBy": LinkByType.KeyVal
            },
            "thresholds":[{ 
              "tag": {
                "key":"Trained",
                "val":["Crit Fire"],
                "linkBy": LinkByType.KeyVal
              }, 
              "thresholdKey":"Trained", 
              "thresholdVal":"Crit Fire", 
              "tagThresholdSymbol":TagThresholdSymbolType.Exists
            }]
          }, 
            {
              "tag": {
                "key": "Trained",
                "val": "Crit Strike",
                "baseKey": "Trained",
                "linkBy": LinkByType.KeyVal
              },
              "thresholds":[{ 
                "tag": {
                  "key":"Trained",
                  "val":["Crit Strike"],
                  "linkBy": LinkByType.KeyVal
                }, 
                "thresholdKey":"Trained", 
                "thresholdVal":"Crit Strike", 
                "tagThresholdSymbol":TagThresholdSymbolType.Exists
              }]
            },
            {
              "tag": {
                "key": "Trained",
                "val": "Counter Fire",
                "baseKey": "Trained",
                "linkBy": LinkByType.KeyVal
              },
              "thresholds":[{ 
                "tag": {
                  "key":"Trained",
                  "val":["Counter Fire"],
                  "linkBy": LinkByType.KeyVal
                }, 
                "thresholdKey":"Trained", 
                "thresholdVal":"Counter Fire", 
                "tagThresholdSymbol":TagThresholdSymbolType.Exists
              }]
            },
            {
              "tag": {
                "key": "Trained",
                "val": "Fighter",
                "baseKey": "Trained",
                "linkBy": LinkByType.KeyVal
              },
              "thresholds":[{ 
                "tag": {
                  "key":"Trained",
                  "val":["Fighter"],
                  "linkBy": LinkByType.KeyVal
                }, 
                "thresholdKey":"Trained", 
                "thresholdVal":"Fighter", 
                "tagThresholdSymbol":TagThresholdSymbolType.Exists
              }]
            },
            {
              "tag": {
                "key": "Trained",
                "val": "Heavy Arms",
                "baseKey": "Trained",
                "linkBy": LinkByType.KeyVal
              },
              "thresholds":[{ 
                "tag": {
                  "key":"Trained",
                  "val":["Heavy Arms"],
                  "linkBy": LinkByType.KeyVal
                }, 
                "thresholdKey":"Trained", 
                "thresholdVal":"Heavy Arms", 
                "tagThresholdSymbol":TagThresholdSymbolType.Exists
              }]
            },
            {
              "tag": {
                "key": "Trained",
                "val": "Methodical",
                "baseKey": "Trained",
                "linkBy": LinkByType.KeyVal
              },
              "thresholds":[{ 
                "tag": {
                  "key":"Trained",
                  "val":["Methodical"],
                  "linkBy": LinkByType.KeyVal
                }, 
                "thresholdKey":"Trained", 
                "thresholdVal":"Methodical", 
                "tagThresholdSymbol":TagThresholdSymbolType.Exists
              }]
            },
          ],
      },
      {
        key: sectionKeys.injuries,
        name: 'Injuries',
        description: 'The main injury keys of the game',
        createdAt: "2021-07-01T19:42:35+0000",
        updatedAt: "2021-07-01T19:42:35+0000",
        tagKeys: ['Injury-Slow', 'Injury-Frail', 'Injury-Weak', 'Injury-No Ranged'],
      },
      {
        key: sectionKeys.upgrades,
        name: 'Upgrades',
        description: 'The upgrade keys for the game',
        createdAt: "2021-07-01T19:42:35+0000",
        updatedAt: "2021-07-01T19:42:35+0000",
        tagKeys: ['Faction-Training', 'Promotion', 'Promotion 2', 'Training', 'Training-2', 'Recovery', 'Tactical Training', 'Tactical Training-2', "Requisition Troops"],
      },
    ],
  },
};

export const exampleEditArchetypeDisplay: ArchetypeDisplayType = {
  id: 1,
  name: "Faction Archetypes",
  description: "Faction Archetype List",
  createdAt: "2021-07-01T19:42:35+0000",
  updatedAt: "2021-07-01T19:42:35+0000",
  data: { 
    sections: [
      {
        key: sectionKeys.primary,
        name: 'Primary',
        description: 'The main primary info keys of the game',
        createdAt: "2021-07-01T19:42:35+0000",
        updatedAt: "2021-07-01T19:42:35+0000",
        tagKeys: ['Standing', 'Faction'],
      },
      {
        key: sectionKeys.points,
        name: 'Points',
        description: 'The main points keys of the game',
        createdAt: "2021-07-01T19:42:35+0000",
        updatedAt: "2021-07-01T19:42:35+0000",
        tagKeys: ['Points'],
      },
      {
        key: sectionKeys.statline,
        name: 'Statline',
        description: 'The main statline keys of the game',
        createdAt: "2021-07-01T19:42:35+0000",
        updatedAt: "2021-07-01T19:42:35+0000",
        tagKeys: ['Move', 'Fight', 'Shoot', 'Evade', 'Life'],
      },
      {
        key: sectionKeys.profileTraits,
        name: 'Profile Traits',
        description: 'The profile Traits of the game',
        createdAt: "2021-07-01T19:42:35+0000",
        updatedAt: "2021-07-01T19:42:35+0000",
        tagKeys: ['Dodge', 'Eagle Eye', 'Fast', 'Fighter', 'Fragile', 'Heavy Arms', 'Skilled', 'Slow', 'Tough', 'Weak'],
      },
      {
        key: sectionKeys.abilityTraits,
        name: 'Ability Traits',
        description: 'The ability traits of the game',
        createdAt: "2021-07-01T19:42:35+0000",
        updatedAt: "2021-07-01T19:42:35+0000",
        tagKeys: ['Berzerk', 'Blaster', 'Boost', 'Charge', 'Counterfire', 'Crit Fire', 'Crit Strike', 'Curse', 'Engulf', 'Explode', 'Blaze', 'Flank', 'Integration', 'Jump', 'Methodical', 'No Ranged', 'Onerous', 'Overpower', 'Pinning', 'Powerful', 'Push', 'Ranged', 'Rapid Fire', 'Regeneration', 'Short Ranged', 'Slug', 'Smash', 'Spotter', 'Target', 'Drag', 'Defender'],
      },
      {
        key: sectionKeys.traitConditions,
        name: 'Trait Conditions',
        description: 'The trait condition modifiers for the game',
        createdAt: "2021-07-01T19:42:35+0000",
        updatedAt: "2021-07-01T19:42:35+0000",
        tagKeys: ['Aim', 'Group']
      },
      {
        key: sectionKeys.gear,
        name: 'Gear',
        description: 'The gear for the game',
        createdAt: "2021-07-01T19:42:35+0000",
        updatedAt: "2021-07-01T19:42:35+0000",
        tagKeys: ['Aux Grenade Launcher', 'Blind Grenades', 'Blinding Flash', 'Charged Bolt', 'Grenades', 'Mutation', 'Pulse Charge'],
      }, 

      {
        key: sectionKeys.factionSpecialRules,
        name: 'Faction Special Rules',
        description: 'The faction special rules of the game',
        createdAt: "2021-07-01T19:42:35+0000",
        updatedAt: "2021-07-01T19:42:35+0000",
        tagKeys: ['Indominable', 'Demonic Mark', 'Lightning Reflexes', 'Repair Protocols', 'Combined Arms'],
      },
      {
        key: sectionKeys.injuries,
        name: 'Injuries',
        description: 'The main injury keys of the game',
        createdAt: "2021-07-01T19:42:35+0000",
        updatedAt: "2021-07-01T19:42:35+0000",
        tagKeys: ['Injury-Slow', 'Injury-Frail', 'Injury-Weak', 'Injury-No Ranged'],
      },
      {
        key: sectionKeys.upgrades,
        name: 'Upgrades',
        description: 'The upgrade keys for the game',
        createdAt: "2021-07-01T19:42:35+0000",
        updatedAt: "2021-07-01T19:42:35+0000",
        tagKeys: ['Faction Training', 'Promotion', 'Promotion 2', 'Training', 'Training 2', "Training 3", 'Recovery', 'Tactical Training', 'Tactical Training 2', "Requisition Troops" ],
      },
      {
        key: sectionKeys.leaderTraits,
        name: 'Leader Buffs',
        description: 'The main primary info keys of the game',
        createdAt: "2021-07-01T19:42:35+0000",
        updatedAt: "2021-07-01T19:42:35+0000",
        tagKeys: ['Blaster', 'Curse', 'Integration', 'Overpower', 'Target', 'Defender'],
      },
      {
        key: sectionKeys.factionCampaignUpgrades,
        name: 'Faction Special Rules',
        description: 'The faction special rules of the game',
        createdAt: "2021-07-01T19:42:35+0000",
        updatedAt: "2021-07-01T19:42:35+0000",
        tagKeys: [
        {
          "tag": {
            "key": "Trained",
            "val": "Crit Fire",
            "baseKey": "Trained",
            "linkBy": LinkByType.KeyVal
          },
          "thresholds":[{ 
            "tag": {
              "key":"Trained",
              "val":["Crit Fire"],
              "linkBy": LinkByType.KeyVal
            }, 
            "thresholdKey":"Trained", 
            "thresholdVal":"Crit Fire", 
            "tagThresholdSymbol":TagThresholdSymbolType.Exists
          }]
        }, 
          {
            "tag": {
              "key": "Trained",
              "val": "Crit Strike",
              "baseKey": "Trained",
              "linkBy": LinkByType.KeyVal
            },
            "thresholds":[{ 
              "tag": {
                "key":"Trained",
                "val":["Crit Strike"],
                "linkBy": LinkByType.KeyVal
              }, 
              "thresholdKey":"Trained", 
              "thresholdVal":"Crit Strike", 
              "tagThresholdSymbol":TagThresholdSymbolType.Exists
            }]
          },
          {
            "tag": {
              "key": "Trained",
              "val": "Counter Fire",
              "baseKey": "Trained",
              "linkBy": LinkByType.KeyVal
            },
            "thresholds":[{ 
              "tag": {
                "key":"Trained",
                "val":["Counter Fire"],
                "linkBy": LinkByType.KeyVal
              }, 
              "thresholdKey":"Trained", 
              "thresholdVal":"Counter Fire", 
              "tagThresholdSymbol":TagThresholdSymbolType.Exists
            }]
          },
          {
            "tag": {
              "key": "Trained",
              "val": "Fighter",
              "baseKey": "Trained",
              "linkBy": LinkByType.KeyVal
            },
            "thresholds":[{ 
              "tag": {
                "key":"Trained",
                "val":["Fighter"],
                "linkBy": LinkByType.KeyVal
              }, 
              "thresholdKey":"Trained", 
              "thresholdVal":"Fighter", 
              "tagThresholdSymbol":TagThresholdSymbolType.Exists
            }]
          },
          {
            "tag": {
              "key": "Trained",
              "val": "Heavy Arms",
              "baseKey": "Trained",
              "linkBy": LinkByType.KeyVal
            },
            "thresholds":[{ 
              "tag": {
                "key":"Trained",
                "val":["Heavy Arms"],
                "linkBy": LinkByType.KeyVal
              }, 
              "thresholdKey":"Trained", 
              "thresholdVal":"Heavy Arms", 
              "tagThresholdSymbol":TagThresholdSymbolType.Exists
            }]
          },
          {
            "tag": {
              "key": "Trained",
              "val": "Methodical",
              "baseKey": "Trained",
              "linkBy": LinkByType.KeyVal
            },
            "thresholds":[{ 
              "tag": {
                "key":"Trained",
                "val":["Methodical"],
                "linkBy": LinkByType.KeyVal
              }, 
              "thresholdKey":"Trained", 
              "thresholdVal":"Methodical", 
              "tagThresholdSymbol":TagThresholdSymbolType.Exists
            }]
          },
        ],
      },
    ],
  },
};

// Archetypes.
export const exampleTestArchetypes: ArchetypeType = {
  id: -101,
  key: "-101",
  userId: 1,
  name: "The Hammer Test",
  description: "Elite forces of the Empire.",
  createdAt: "2021-05-18T19:42:35+0000",
  updatedAt: "2021-05-18T19:42:35+0000",
  worldId: 1,
  data: {
    tagModifiersKeys: [],
    tagDescriptionsKeys: ["SkirmishFight"],
    cast: [
      {
        key: "leader:VanguardLieutenant",
        name: "Vanguard Lieutenant",
        type: CastMemberClassType.archetype,
        tags: [
          { 
            key: "Move",
            val: "6"
          },
          { 
            key: "Fight",
            val: "1"
          },
          { 
            key: "Shoot",
            val: "0"
          },
          {
            key: "Evade",
            val: "1"
          },
          { 
            key: "Life",
            val: "3"
          },
          {
            key: "Points",
            val: "10"
          },
          {
            key: "Standing",
            val: "leader",
            valAreTagKeys: true
          },
          { 
            key: "Skilled",
            val: ""
          },
          { 
            key: "Fighter",
            val: ""
          },
          { 
            key: "Tough",
            val: ""
          },
          { 
            key: "Overpower",
            val: ""
          },          
          { 
            key: "Crit Strike",
            val: ""
          },     
          { 
            key: "Aim",
            val: ["Eagle Eye","Crit Fire"],
            valAreTagKeys: true
          }
        ]
      },
      {
        key: "veteran:AssaultVanguard",
        name: "Assault Vanguard",
        type: CastMemberClassType.archetype,
        tags: [
          { 
            key: "Move",
            val: "6"
          },
          { 
            key: "Fight",
            val: "1"
          },
          { 
            key: "Shoot",
            val: "0"
          },
          {
            key: "Evade",
            val: "1"
          },
          { 
            key: "Life",
            val: "3"
          },
          {
            key: "Points",
            val: "10"
          },
          {
            key: "Standing",
            val: "elite",
            valAreTagKeys: true
          },
          { 
            key: "Tough",
          },
          { 
            key: "Skilled",
          },
          { 
            key: "Fighter",
          }
        ]
      },
      {
        key: "veteran:HeavyVanguardMissile",
        name: "Heavy Vanguard (Missile)",
        type: CastMemberClassType.archetype,
        tags: [
          { 
            key: "Move",
            val: "6"
          },
          { 
            key: "Fight",
            val: "1"
          },
          { 
            key: "Shoot",
            val: "0"
          },
          {
            key: "Evade",
            val: "1"
          },
          { 
            key: "Life",
            val: "3"
          },
          {
            key: "Points",
            val: "10"
          },
          {
            key: "Standing",
            val: "elite",
            valAreTagKeys: true
          },
          { 
            key: "Tough",
          },
          { 
            key: "Eagle Eye",
          },
          { 
            key: "Slow",
          },
          { 
            key: "Onerous",
          },
          { 
            key: "Engulf",
          },
          { 
            key: "Slug",
          }
        ]
      },
      {
        key: "veteran:FlamerVanguard",
        name: "Flamer Vanguard",
        type: CastMemberClassType.archetype,
        tags: [
          { 
            key: "Move",
            val: "6"
          },
          { 
            key: "Fight",
            val: "1"
          },
          { 
            key: "Shoot",
            val: "0"
          },
          {
            key: "Evade",
            val: "1"
          },
          { 
            key: "Life",
            val: "3"
          },
          {
            key: "Points",
            val: "10"
          },
          {
            key: "Standing",
            val: "elite",
            valAreTagKeys: true
          },
          { 
            key: "Tough",
          },
          { 
            key: "Skilled",
          },
          { 
            key: "Short Ranged",
          },
          { 
            key: "Engulf",
          },
          { 
            key: "Blaze",
          }
        ]
      },
    ],
    tagOptions: [
      {
        key: "Aux Grenade Launcher"
      },
      {
        key: "Grenades"
      },
      {
        key: "Indominable"
      },
      {
        key: "Injury-Slow"
      },
      {
        key: "Injury-Frail"
      },
      {
        key: "Injury-Weak"
      },
      {
        key: "Injury-No Ranged"
      },
      {
        key: "Faction Training"
      },
      {
        key: "Promotion"
      },
      {
        key: "Promotion 2"
      },
      {
        key: "Training"
      },
      {
        key: "Training 2"
      },
      {
        key: "Training 3"
      },
      {
        key: "Recovery"
      },
      {
        key: "Tactical Training"
      },
      {
        key: "Tactical Training 2"
      },
      {
        key: "Requisition Troops"
      },
      {
        key: "Fighter"
      },

      {
        key: "Heavy Arms"
      },
      {
        key: "Crit Fire"
      },
      {
        key: "Crit Strike"
      },
      {
        key: "Methodical"
      },
      {
        key: "Counter Fire"
      },
    ],
    tags: [
      { 
        key: "archetype",
      },
      { 
        key: "freeborne",
      },
      { 
        key: "rangers",
      },
      { 
        key: "freedom",
      },
    ],
  } 
};


// Archetypes.
export const exampleHammerArchetype: ArchetypeType = {
  id: -1,
  key: "-1",
  userId: 1,
  name: "The Hammer",
  description: "Elite forces of the Empire.",
  createdAt: "2021-05-18T19:42:35+0000",
  updatedAt: "2021-05-18T19:42:35+0000",
  worldId: 1,
  data: {
    tagModifiersKeys: [],
    tagDescriptionsKeys: ["SkirmishFight"],
    cast: [
      {
        key: "leader:VanguardLieutenant",
        name: "Vanguard Lieutenant",
        type: CastMemberClassType.archetype,
        tags: [
          { 
            key: "Move",
            val: "6"
          },
          { 
            key: "Fight",
            val: "1"
          },
          { 
            key: "Shoot",
            val: "0"
          },
          {
            key: "Evade",
            val: "1"
          },
          { 
            key: "Life",
            val: "3"
          },
          {
            key: "Points",
            val: "10"
          },
          {
            key: "Standing",
            val: "leader",
            valAreTagKeys: true,
          },
          { 
            key: "Skilled",
            val: ""
          },
          { 
            key: "Fighter",
            val: ""
          },
          { 
            key: "Tough",
            val: ""
          },
          { 
            key: "Overpower",
            val: ""
          },          
          { 
            key: "Crit Strike",
            val: ""
          },     
          { 
            key: "Aim",
            val: ["Eagle Eye","Crit Fire"],
            valAreTagKeys: true,
          }
        ]
      },
      {
        key: "veteran:AssaultVanguard",
        name: "Assault Vanguard",
        type: CastMemberClassType.archetype,
        tags: [
          { 
            key: "Move",
            val: "6"
          },
          { 
            key: "Fight",
            val: "1"
          },
          { 
            key: "Shoot",
            val: "0"
          },
          {
            key: "Evade",
            val: "1"
          },
          { 
            key: "Life",
            val: "3"
          },
          {
            key: "Points",
            val: "10"
          },
          {
            key: "Standing",
            val: "elite",
            valAreTagKeys: true,
          },
          { 
            key: "Tough",
          },
          { 
            key: "Skilled",
          },
          { 
            key: "Fighter",
          }
        ]
      },
      {
        key: "veteran:HeavyVanguardMissile",
        name: "Heavy Vanguard (Missile)",
        type: CastMemberClassType.archetype,
        tags: [
          { 
            key: "Move",
            val: "6"
          },
          { 
            key: "Fight",
            val: "1"
          },
          { 
            key: "Shoot",
            val: "0"
          },
          {
            key: "Evade",
            val: "1"
          },
          { 
            key: "Life",
            val: "3"
          },
          {
            key: "Points",
            val: "10"
          },
          {
            key: "Standing",
            val: "elite",
            valAreTagKeys: true,
          },
          { 
            key: "Tough",
          },
          { 
            key: "Eagle Eye",
          },
          { 
            key: "Slow",
          },
          { 
            key: "Onerous",
          },
          { 
            key: "Engulf",
          },
          { 
            key: "Slug",
          }
        ]
      },
      {
        key: "veteran:HeavyVanguardGatling",
        name: "Heavy Vanguard (Gatling)",
        type: CastMemberClassType.archetype,
        tags: [
          { 
            key: "Move",
            val: "6"
          },
          { 
            key: "Fight",
            val: "1"
          },
          { 
            key: "Shoot",
            val: "0"
          },
          {
            key: "Evade",
            val: "1"
          },
          { 
            key: "Life",
            val: "3"
          },
          {
            key: "Points",
            val: "10"
          },
          {
            key: "Standing",
            val: "elite",
            valAreTagKeys: true,
          },
          { 
            key: "Tough",
          },
          { 
            key: "Eagle Eye",
          },
          { 
            key: "Slow",
          },
          { 
            key: "Onerous",
          },
          { 
            key: "Engulf",
          },
          { 
            key: "Aim",
            val: "Rapid Fire",
            valAreTagKeys: true,
          }
        ]
      },
      {
        key: "veteran:FlamerVanguard",
        name: "Flamer Vanguard",
        type: CastMemberClassType.archetype,
        tags: [
          { 
            key: "Move",
            val: "6"
          },
          { 
            key: "Fight",
            val: "1"
          },
          { 
            key: "Shoot",
            val: "0"
          },
          {
            key: "Evade",
            val: "1"
          },
          { 
            key: "Life",
            val: "3"
          },
          {
            key: "Points",
            val: "10"
          },
          {
            key: "Standing",
            val: "elite",
            valAreTagKeys: true,
          },
          { 
            key: "Tough",
          },
          { 
            key: "Skilled",
          },
          { 
            key: "Short Ranged",
          },
          { 
            key: "Engulf",
          },
          { 
            key: "Blaze",
          }
        ]
      },
      {
        key: "rankAndFile:Novitiate",
        name: "Novitiate",
        type: CastMemberClassType.archetype,
        tags: [
          { 
            key: "Move",
            val: "6"
          },
          { 
            key: "Fight",
            val: "1"
          },
          {
            key: "Shoot",
            val: "0"
          },
          {
            key: "Evade",
            val: "1"
          },
          { 
            key: "Life",
            val: "3"
          },
          {
            key: "Points",
            val: "10"
          },
          {
            key: "Standing",
            val: "rankAndFile",
            valAreTagKeys: true,
          },
          { 
            key: "Tough",
          },
        ]
      },
      {
        key: "veteran:Vanguard",
        name: "Vanguard",
        type: CastMemberClassType.archetype,
        tags: [
          { 
            key: "Move",
            val: "6"
          },
          { 
            key: "Fight",
            val: "1"
          },
          { 
            key: "Shoot",
            val: "0"
          },
          {
            key: "Evade",
            val: "1"
          },
          { 
            key: "Life",
            val: "3"
          },
          { 
            key: "Points",
            val: "10"
          },
          {
            key: "Standing",
            valAreTagKeys: true,
            val: "elite"
          },
          { 
            key: "Skilled",
          },
          { 
            key: "Eagle Eye",
          },
          { 
            key: "Tough",
          }
        ]
      },
      {
        key: "rankAndFile:Vanguard",
        name: "Vanguard",
        type: CastMemberClassType.archetype,
        tags: [
          { 
            key: "Move",
            val: "6"
          },
          { 
            key: "Fight",
            val: "1"
          },
          { 
            key: "Shoot",
            val: "0"
          },
          {
            key: "Evade",
            val: "1"
          },
          { 
            key: "Life",
            val: "3"
          },
          { 
            key: "Points",
            val: "10"
          },
          {
            key: "Standing",
            valAreTagKeys: true,
            val: "rankAndFile"
          },
          { 
            key: "Skilled",
          },
          { 
            key: "Eagle Eye",
          },
          { 
            key: "Tough",
          },
          { 
            key: "Notes",
            valAreTagKeys: true,
            val: "Note:AlterativeStandingVeteran"
          },
        ]
      },
      {
        key: "Tactics:Redouble Fire",
        name: "Redouble Fire",
        type: CastMemberClassType.tactic,
        tags: [
          { 
            key: "Points",
            val: "0"
          },
          {
            key: "Standing",
            valAreTagKeys: true,
            val: "tactic"
          },
          {
            key: "Order",
            val: "10"
          },
          {
            key: "Faction",
            valAreTagKeys: true,
            val: "Hammer"
          },
          {
            key: "Tactic:Redouble Fire",
            val: "Redouble Fire"
          }
        ]
      },
      {
        key: "Tactics:Double Attack",
        name: "Double Attack",
        type: CastMemberClassType.tactic,
        tags: [
          { 
            key: "Points",
            val: "0"
          },
          {
            key: "Standing",
            valAreTagKeys: true,
            val: "tactic"
          },
          {
            key: "Order",
            val: "11"
          },
          {
            key: "Faction",
            val: "Hammer"
          },
          {
            key: "Tactic:Double Attack",
            val: "Double Attack"
          }
        ]
      },
      {
        key: "Tactics:Beyond Death",
        name: "Beyond Death",
        type: CastMemberClassType.tactic,
        tags: [
          { 
            key: "Points",
            val: "0"
          },
          {
            key: "Standing",
            valAreTagKeys: true,
            val: "tactic"
          },
          {
            key: "Order",
            val: "12"
          },
          {
            key: "Faction",
            val: "Hammer"
          },
          {
            key: "Tactic:Beyond Death",
            val: "Beyond Death"
          }
        ]
      },
      {
        key: "Tactics:Stalker",
        name: "Stalker",
        type: CastMemberClassType.tactic,
        tags: [
          { 
            key: "Points",
            val: "0"
          },
          {
            key: "Standing",
            valAreTagKeys: true,
            val: "tactic"
          },
          {
            key: "Order",
            val: "13"
          },
          {
            key: "Faction",
            val: "Hammer"
          },
          {
            key: "Tactic:Stalker",
            val: "Stalker"
          }
        ]
      },
    ],
    tagOptions: [
      {
        key: "Aux Grenade Launcher"
      },
      {
        key: "Grenades"
      },
      {
        key: "Indominable"
      },
      {
        key: "Injury-Slow"
      },
      {
        key: "Injury-Frail"
      },
      {
        key: "Injury-Weak"
      },
      {
        key: "Injury-No Ranged"
      },
      {
        key: "Faction-Training"
      },
      {
        key: "Promotion"
      },
      {
        key: "Promotion-2"
      },
      {
        key: "Training"
      },
      {
        key: "Training-2"
      },
      {
        key: "Recovery"
      },
      {
        key: "Tactical-Training"
      },
      {
        key: "Tactical-Training-2"
      },
      {
        key: "Fighter"
      },

      {
        key: "Heavy Arms"
      },
      {
        key: "Crit Fire"
      },
      {
        key: "Crit Strike"
      },
      {
        key: "Methodical"
      },
      {
        key: "Counter Fire"
      },
    ],
    tags: [
      { 
        key: "archetype",
      },
      { 
        key: "freeborne",
      },
      { 
        key: "rangers",
      },
      { 
        key: "freedom",
      },
    ],
  } 
};

export const exampleCultsArchetype: ArchetypeType = {
  id: -2,
  key: "-2",
  userId: 1,
  name: "Cults",
  description: "Cults twisted by the Demon Plague.",
  createdAt: "2021-05-18T19:42:35+0000",
  updatedAt: "2021-05-18T19:42:35+0000",
  worldId: 1,
  data: {
    tagModifiersKeys: [],
    tagDescriptionsKeys: ["SkirmishFight"],
    cast: [
      {
        key: "leader:Sorcerer",
        name: "Sorcerer",
        type: CastMemberClassType.archetype,
        tags: [
          { 
            key: "Move",
            val: "6"
          },
          { 
            key: "Fight",
            val: "1"
          },
          { 
            key: "Shoot",
            val: "0"
          },
          {
            key: "Evade",
            val: "1"
          },
          { 
            key: "Life",
            val: "3"
          },
          {
            key: "Points",
            val: "10"
          },
          {
            key: "Standing",
            valAreTagKeys: true,
            val: "leader"
          },
          { 
            key: "Skilled",
            val: ""
          },
          { 
            key: "Short Ranged",
            val: ""
          },
          { 
            key: "Blaze",
            val: ""
          },
          { 
            key: "Overpower",
            val: ""
          },          
          { 
            key: "Crit Strike",
            val: ""
          }
        ]
      },
      {
        key: "leader:DemonLord",
        name: "Demon Lord",
        type: CastMemberClassType.archetype,
        tags: [
          { 
            key: "Move",
            val: "6"
          },
          { 
            key: "Fight",
            val: "1"
          },
          { 
            key: "Shoot",
            val: "0"
          },
          {
            key: "Evade",
            val: "1"
          },
          { 
            key: "Life",
            val: "3"
          },
          {
            key: "Points",
            val: "10"
          },
          {
            key: "Standing",
            valAreTagKeys: true,
            val: "leader"
          },
          { 
            key: "Skilled",
          },
          { 
            key: "Fighter",
          },
          { 
            key: "Dodge",
          },
          { 
            key: "No Ranged",
          },
          { 
            key: "Overpower",
          },
          { 
            key: "Powerful",
          },
          { 
            key: "Smash",
          }
        ]
      },
      {
        key: "veteran:Demon",
        name: "Demon",
        type: CastMemberClassType.archetype,
        tags: [
          { 
            key: "Move",
            val: "6"
          },
          { 
            key: "Fight",
            val: "1"
          },
          { 
            key: "Shoot",
            val: "0"
          },
          {
            key: "Evade",
            val: "1"
          },
          { 
            key: "Life",
            val: "3"
          },
          {
            key: "Points",
            val: "10"
          },
          {
            key: "Standing",
            valAreTagKeys: true,
            val: "elite"
          },
          { 
            key: "Skilled",
          },
          { 
            key: "Dodge",
          },
          { 
            key: "No Ranged",
          }
        ]
      },
      {
        key: "veteran:Possessed",
        name: "Possessed",
        type: CastMemberClassType.archetype,
        tags: [
          { 
            key: "Move",
            val: "6"
          },
          { 
            key: "Fight",
            val: "1"
          },
          { 
            key: "Shoot",
            val: "0"
          },
          {
            key: "Evade",
            val: "1"
          },
          { 
            key: "Life",
            val: "3"
          },
          {
            key: "Points",
            val: "10"
          },
          {
            key: "Standing",
            valAreTagKeys: true,
            val: "elite"
          },
          { 
            key: "Skilled",
          },
          { 
            key: "Fighter",
          },
          { 
            key: "Fast",
          },
          { 
            key: "No Ranged",
          },
          { 
            key: "Powerful",
          },
          { 
            key: "Smash",
          }
        ]
      },
      {
        key: "veteran:Twisted",
        name: "Twisted",
        type: CastMemberClassType.archetype,
        tags: [
          { 
            key: "Move",
            val: "6"
          },
          { 
            key: "Fight",
            val: "1"
          },
          { 
            key: "Shoot",
            val: "0"
          },
          {
            key: "Evade",
            val: "1"
          },
          { 
            key: "Life",
            val: "3"
          },
          {
            key: "Points",
            val: "10"
          },
          {
            key: "Standing",
            valAreTagKeys: true,
            val: "elite"
          },
          { 
            key: "Tough",
          },
          { 
            key: "Skilled",
          },
          { 
            key: "Slow",
          },
          { 
            key: "Short Ranged",
          },
          { 
            key: "Smash",
          }
        ]
      },
      {
        key: "rankAndFile:Cultists",
        name: "Cultists",
        type: CastMemberClassType.archetype,
        tags: [
          { 
            key: "Move",
            val: "6"
          },
          { 
            key: "Fight",
            val: "1"
          },
          {
            key: "Shoot",
            val: "0"
          },
          {
            key: "Evade",
            val: "1"
          },
          { 
            key: "Life",
            val: "3"
          },
          {
            key: "Points",
            val: "10"
          },
          {
            key: "Standing",
            valAreTagKeys: true,
            val: "rankAndFile"
          },
          { 
            key: "Fragile",
          },
          { 
            key: "Group",
            valAreTagKeys: true,
            val: ["Skilled"]
          },
        ]
      },
      {
        key: "rankAndFile:InfectedFanatic",
        name: "Infected Fanatic",
        type: CastMemberClassType.archetype,
        tags: [
          { 
            key: "Move",
            val: "6"
          },
          { 
            key: "Fight",
            val: "1"
          },
          { 
            key: "Shoot",
            val: "0"
          },
          {
            key: "Evade",
            val: "1"
          },
          { 
            key: "Life",
            val: "3"
          },
          { 
            key: "Points",
            val: "10"
          },
          {
            key: "Standing",
            valAreTagKeys: true,
            val: "rankAndFile"
          },
          { 
            key: "Weak",
          },
          { 
            key: "Fragile",
          },
          { 
            key: "Short Ranged",
          },
          { 
            key: "Explode",
          },
          { 
            key: "Drone",
          },
          { 
            key: "Group",
            valAreTagKeys: true,
            val: ["Skilled"]
          },
        ]
      },
      {
        key: "Tactics:Blazing Eye",
        name: "Blazing Eye",
        type: CastMemberClassType.tactic,
        tags: [
          { 
            key: "Points",
            val: "0"
          },
          {
            key: "Standing",
            val: "tactic"
          },
          {
            key: "Order",
            val: "10"
          },
          {
            key: "Faction",
            valAreTagKeys: true,
            val: "Cults"
          },
          {
            key: "Tactic:Blazing Eye",
            valAreTagKeys: true,
            val: "Blazing Eye"
          }
        ]
      },
      {
        key: "Tactics:Double Melee",
        name: "Double Melee",
        type: CastMemberClassType.tactic,
        tags: [
          { 
            key: "Points",
            val: "0"
          },
          {
            key: "Standing",
            valAreTagKeys: true,
            val: "tactic"
          },
          {
            key: "Order",
            val: "11"
          },
          {
            key: "Faction",
            val: "Cults"
          },
          {
            key: "Tactic:Double Melee",
            val: "Double Melee"
          }
        ]
      },
      {
        key: "Tactics:Corrupting Miasma",
        name: "Corrupting Miasma",
        type: CastMemberClassType.tactic,
        tags: [
          { 
            key: "Points",
            val: "0"
          },
          {
            key: "Standing",
            valAreTagKeys: true,
            val: "tactic"
          },
          {
            key: "Order",
            val: "12"
          },
          {
            key: "Faction",
            val: "Cults"
          },
          {
            key: "Tactic:Corrupting Miasma",
            val: "Corrupting Miasma"
          }
        ]
      },
      {
        key: "Tactics:Shadow Numbers",
        name: "Shadow Numbers",
        type: CastMemberClassType.tactic,
        tags: [
          { 
            key: "Points",
            val: "0"
          },
          {
            key: "Standing",
            valAreTagKeys: true,
            val: "tactic"
          },
          {
            key: "Order",
            val: "13"
          },
          {
            key: "Faction",
            val: "Cults"
          },
          {
            key: "Tactic:Shadow Numbers",
            val: "Shadow Numbers"
          }
        ]
      },
    ],
    tagOptions: [
      {
        key: "Mutation"
      },
      {
        key: "Demonic Surge"
      },
      {
        key: "Injury-Slow"
      },
      {
        key: "Injury-Frail"
      },
      {
        key: "Injury-Weak"
      },
      {
        key: "Injury-No Ranged"
      },
      {
        key: "Faction-Training"
      },
      {
        key: "Promotion"
      },
      {
        key: "Promotion-2"
      },
      {
        key: "Training"
      },
      {
        key: "Training-2"
      },
      {
        key: "Recovery"
      },
      {
        key: "Tactical-Training"
      },
      {
        key: "Tactical-Training-2"
      },
      {
        key: "Fast"
      },
      {
        key: "Dodge"
      },
      {
        key: "Charge"
      },
      {
        key: "Crit Strike"
      },
      {
        key: "Fighter"
      },
      {
        key: "Relentless"
      },
    ],
    tags: [
      { 
        key: "archetype",
      },
      { 
        key: "Demon Plague",
      },
      { 
        key: "Cults",
      },
      { 
        key: "Blood of Stars",
      },
    ],
  } 
};


export const exampleAdvancedGuardArchetype: ArchetypeType = {
  id: -3,
  key: "-3",
  userId: 1,
  name: "Advanced Guard",
  description: "The Advanced Guard of the Gifted.",
  createdAt: "2021-05-18T19:42:35+0000",
  updatedAt: "2021-05-18T19:42:35+0000",
  worldId: 1,
  data: {
    tagModifiersKeys: [],
    tagDescriptionsKeys: ["SkirmishFight"],
    cast: [
      {
        key: "leader:ExaltedGuard",
        name: "Exalted Guard",
        type: CastMemberClassType.archetype,
        tags: [
          { 
            key: "Move",
            val: "6"
          },
          { 
            key: "Fight",
            val: "1"
          },
          { 
            key: "Shoot",
            val: "0"
          },
          {
            key: "Evade",
            val: "1"
          },
          { 
            key: "Life",
            val: "3"
          },
          {
            key: "Points",
            val: "10"
          },
          {
            key: "Standing",
            val: "leader"
          },
          { 
            key: "Fast",
            val: ""
          },
          { 
            key: "Dodge",
            val: ""
          },
          { 
            key: "Defender",
            val: ""
          },
          { 
            key: "Short Ranged",
            val: ""
          },          
          { 
            key: "Drag",
            val: ""
          },     
          { 
            key: "Crit Strike",
            val: ""
          }
        ]
      },
      {
        key: "veteran:HeavyWeaponGuard",
        name: "Heavy Weapon Guard",
        type: CastMemberClassType.archetype,
        tags: [
          { 
            key: "Move",
            val: "6"
          },
          { 
            key: "Fight",
            val: "1"
          },
          { 
            key: "Shoot",
            val: "0"
          },
          {
            key: "Evade",
            val: "1"
          },
          { 
            key: "Life",
            val: "3"
          },
          {
            key: "Points",
            val: "10"
          },
          {
            key: "Standing",
            val: "elite"
          },
          { 
            key: "Fast",
          },
          { 
            key: "Slug",
          },
          { 
            key: "Engulf",
          },
        ]
      },
      {
        key: "veteran:BladeDancer",
        name: "Blade Dancer",
        type: CastMemberClassType.archetype,
        tags: [
          { 
            key: "Move",
            val: "6"
          },
          { 
            key: "Fight",
            val: "1"
          },
          { 
            key: "Shoot",
            val: "0"
          },
          {
            key: "Evade",
            val: "1"
          },
          { 
            key: "Life",
            val: "3"
          },
          {
            key: "Points",
            val: "10"
          },
          {
            key: "Standing",
            val: "elite"
          },
          { 
            key: "Skilled",
          },
          { 
            key: "Fast",
          },
          { 
            key: "Powerful",
          },
          { 
            key: "Charge",
          },
          { 
            key: "Short Ranged",
          },
        ]
      },
      {
        key: "veteran:ShadowFire",
        name: "Shadow Fire",
        type: CastMemberClassType.archetype,
        tags: [
          { 
            key: "Move",
            val: "6"
          },
          { 
            key: "Fight",
            val: "1"
          },
          { 
            key: "Shoot",
            val: "0"
          },
          {
            key: "Evade",
            val: "1"
          },
          { 
            key: "Life",
            val: "3"
          },
          {
            key: "Points",
            val: "10"
          },
          {
            key: "Standing",
            val: "elite"
          },
          { 
            key: "Fast",
          },
          { 
            key: "Short Ranged",
          },
          { 
            key: "Jump",
          },
          { 
            key: "Crit Fire",
          },
          { 
            key: "Engulf",
          },
        ]
      },
      {
        key: "veteran:Lancer",
        name: "Lancer",
        type: CastMemberClassType.archetype,
        tags: [
          { 
            key: "Move",
            val: "6"
          },
          { 
            key: "Fight",
            val: "1"
          },
          { 
            key: "Shoot",
            val: "0"
          },
          {
            key: "Evade",
            val: "1"
          },
          { 
            key: "Life",
            val: "3"
          },
          {
            key: "Points",
            val: "10"
          },
          {
            key: "Standing",
            val: "elite"
          },
          { 
            key: "Fast",
          },
          { 
            key: "Short Ranged",
          },
          { 
            key: "Blaze",
          },
          { 
            key: "Crit Fire",
          },
          { 
            key: "Slug",
          },
        ]
      },
      {
        key: "rankAndFile:Guard",
        name: "Guard",
        type: CastMemberClassType.archetype,
        tags: [
          { 
            key: "Move",
            val: "6"
          },
          { 
            key: "Fight",
            val: "1"
          },
          {
            key: "Shoot",
            val: "0"
          },
          {
            key: "Evade",
            val: "1"
          },
          { 
            key: "Life",
            val: "3"
          },
          {
            key: "Points",
            val: "10"
          },
          {
            key: "Standing",
            val: "rankAndFile"
          },
          { 
            key: "Fast",
          },
          { 
            key: "Short Ranged",
          },
        ]
      },
      {
        key: "rankAndFile:HighGuard",
        name: "High Guard",
        type: CastMemberClassType.archetype,
        tags: [
          { 
            key: "Move",
            val: "6"
          },
          { 
            key: "Fight",
            val: "1"
          },
          { 
            key: "Shoot",
            val: "0"
          },
          {
            key: "Evade",
            val: "1"
          },
          { 
            key: "Life",
            val: "3"
          },
          { 
            key: "Points",
            val: "10"
          },
          {
            key: "Standing",
            val: "rankAndFile"
          },
          { 
            key: "Fast",
          },
          { 
            key: "Crit Fire",
          },
        ]
      },
      {
        key: "Tactics:Psycho Wind",
        name: "Psycho Wind",
        type: CastMemberClassType.tactic,
        tags: [
          { 
            key: "Points",
            val: "0"
          },
          {
            key: "Standing",
            val: "tactic"
          },
          {
            key: "Order",
            val: "10"
          },
          {
            key: "Faction",
            val: "AdvancedGuard"
          },
          {
            key: "Tactic:Psycho Wind",
            val: "Psycho Wind"
          }
        ]
      },
      {
        key: "Tactics:Double Attack",
        name: "Double Attack",
        type: CastMemberClassType.tactic,
        tags: [
          { 
            key: "Points",
            val: "0"
          },
          {
            key: "Standing",
            val: "tactic"
          },
          {
            key: "Order",
            val: "11"
          },
          {
            key: "Faction",
            val: "AdvancedGuard"
          },
          {
            key: "Tactic:Double Attack",
            val: "Double Attack"
          }
        ]
      },
      {
        key: "Tactics:Jump Step",
        name: "Jump Step",
        type: CastMemberClassType.tactic,
        tags: [
          { 
            key: "Points",
            val: "0"
          },
          {
            key: "Standing",
            val: "tactic"
          },
          {
            key: "Order",
            val: "12"
          },
          {
            key: "Faction",
            val: "AdvancedGuard"
          },
          {
            key: "Tactic:Jump Step",
            val: "Jump Step"
          }
        ]
      },
      {
        key: "Tactics:Hidden",
        name: "Hidden",
        type: CastMemberClassType.tactic,
        tags: [
          { 
            key: "Points",
            val: "0"
          },
          {
            key: "Standing",
            val: "tactic"
          },
          {
            key: "Order",
            val: "13"
          },
          {
            key: "Faction",
            val: "AdvancedGuard"
          },
          {
            key: "Tactic:Hidden",
            val: "Hidden"
          }
        ]
      },
    ],
    tagOptions: [
      {
        key: "Blinding Flash"
      },
      {
        key: "Charged Bolt"
      },
      {
        key: "Lightning Reflexes"
      },
      {
        key: "Injury-Slow"
      },
      {
        key: "Injury-Frail"
      },
      {
        key: "Injury-Weak"
      },
      {
        key: "Injury-No Ranged"
      },
      {
        key: "Faction-Training"
      },
      {
        key: "Promotion"
      },
      {
        key: "Promotion-2"
      },
      {
        key: "Training"
      },
      {
        key: "Training-2"
      },
      {
        key: "Recovery"
      },
      {
        key: "Tactical-Training"
      },
      {
        key: "Tactical-Training-2"
      },
      {
        key: "Crit Fire"
      },
      {
        key: "Dodge"
      },
      {
        key: "Jump"
      },
      {
        key: "Crit Strike"
      },
      {
        key: "Fighter"
      },
      {
        key: "Tough"
      },
    ],
    tags: [
      { 
        key: "archetype",
      },
      { 
        key: "Advanced Guard",
      },
      { 
        key: "Gifted",
      },
      { 
        key: "Blood of Stars",
      },
    ],
  } 
};



export const exampleTechnocratStrikeTeamsArchetype: ArchetypeType = {
  id: -4,
  key: "-4",
  userId: 1,
  name: "Technocrat Strike Teams",
  description: "The Strike Teams of the Technocrats.",
  createdAt: "2021-05-18T19:42:35+0000",
  updatedAt: "2021-05-18T19:42:35+0000",
  worldId: 1,
  data: {
    tagModifiersKeys: [],
    tagDescriptionsKeys: ["SkirmishFight"],
    cast: [
      {
        key: "leader:Engineer",
        name: "Engineer",
        type: CastMemberClassType.archetype,
        tags: [
          { 
            key: "Move",
            val: "6"
          },
          { 
            key: "Fight",
            val: "1"
          },
          { 
            key: "Shoot",
            val: "0"
          },
          {
            key: "Evade",
            val: "1"
          },
          { 
            key: "Life",
            val: "3"
          },
          {
            key: "Points",
            val: "10"
          },
          {
            key: "Standing",
            val: "leader"
          },
          { 
            key: "Slow",
            val: ""
          },
          { 
            key: "Regeneration",
            val: ""
          },
          { 
            key: "Blaster",
            val: ""
          },
          { 
            key: "Short Ranged",
            val: ""
          },          
          { 
            key: "Crit Fire",
            val: ""
          },     
        ]
      },
      {
        key: "veteran:Librarian",
        name: "Librarian",
        type: CastMemberClassType.archetype,
        tags: [
          { 
            key: "Move",
            val: "6"
          },
          { 
            key: "Fight",
            val: "1"
          },
          { 
            key: "Shoot",
            val: "0"
          },
          {
            key: "Evade",
            val: "1"
          },
          { 
            key: "Life",
            val: "3"
          },
          {
            key: "Points",
            val: "10"
          },
          {
            key: "Standing",
            val: "elite"
          },
          { 
            key: "Slow",
          },
          { 
            key: "Regeneration",
          },
          { 
            key: "Integration",
          },
          { 
            key: "Engulf",
          },
        ]
      },
      {
        key: "veteran:HeavyWarrior",
        name: "Heavy Warrior",
        type: CastMemberClassType.archetype,
        tags: [
          { 
            key: "Move",
            val: "6"
          },
          { 
            key: "Fight",
            val: "1"
          },
          { 
            key: "Shoot",
            val: "0"
          },
          {
            key: "Evade",
            val: "1"
          },
          { 
            key: "Life",
            val: "3"
          },
          {
            key: "Points",
            val: "10"
          },
          {
            key: "Standing",
            val: "elite"
          },
          { 
            key: "Heavy Arms",
          },
          { 
            key: "Tough",
          },
          { 
            key: "Slow",
          },
          { 
            key: "Slug",
          },
          { 
            key: "Regeneration",
          },
        ]
      },
      {
        key: "veteran:Melee Warrior",
        name: "Melee Warrior",
        type: CastMemberClassType.archetype,
        tags: [
          { 
            key: "Move",
            val: "6"
          },
          { 
            key: "Fight",
            val: "1"
          },
          { 
            key: "Shoot",
            val: "0"
          },
          {
            key: "Evade",
            val: "1"
          },
          { 
            key: "Life",
            val: "3"
          },
          {
            key: "Points",
            val: "10"
          },
          {
            key: "Standing",
            val: "elite"
          },
          { 
            key: "Slow",
          },
          { 
            key: "Regeneration",
          },
          { 
            key: "Relentless",
          },
          { 
            key: "Short Ranged",
          },
        ]
      },
      {
        key: "veteran:Warrior Blaster",
        name: "Warrior Blaster",
        type: CastMemberClassType.archetype,
        tags: [
          { 
            key: "Move",
            val: "6"
          },
          { 
            key: "Fight",
            val: "1"
          },
          { 
            key: "Shoot",
            val: "0"
          },
          {
            key: "Evade",
            val: "1"
          },
          { 
            key: "Life",
            val: "3"
          },
          {
            key: "Points",
            val: "10"
          },
          {
            key: "Standing",
            val: "elite"
          },
          { 
            key: "Slow",
          },
          { 
            key: "Regeneration",
          },
          { 
            key: "Blaze",
          },
          { 
            key: "Crit Fire",
          },
        ]
      },
      {
        key: "rankAndFile:Warrior",
        name: "Warrior",
        type: CastMemberClassType.archetype,
        tags: [
          { 
            key: "Move",
            val: "6"
          },
          { 
            key: "Fight",
            val: "1"
          },
          {
            key: "Shoot",
            val: "0"
          },
          {
            key: "Evade",
            val: "1"
          },
          { 
            key: "Life",
            val: "3"
          },
          {
            key: "Points",
            val: "10"
          },
          {
            key: "Standing",
            val: "rankAndFile"
          },
          { 
            key: "Slow",
          },
          { 
            key: "Heavy Arms",
          },
          { 
            key: "Regeneration",
          },
        ]
      },
      {
        key: "rankAndFile:Drones",
        name: "Drones",
        type: CastMemberClassType.archetype,
        tags: [
          { 
            key: "Move",
            val: "6"
          },
          { 
            key: "Fight",
            val: "1"
          },
          { 
            key: "Shoot",
            val: "0"
          },
          {
            key: "Evade",
            val: "1"
          },
          { 
            key: "Life",
            val: "3"
          },
          { 
            key: "Points",
            val: "10"
          },
          {
            key: "Standing",
            val: "rankAndFile"
          },
          { 
            key: "Weak",
          },
          { 
            key: "No Ranged",
          },
          { 
            key: "Drone",
          },
          { 
            key: "Group",
            val: ["Relentless"]
          },
        ]
      },
      {
        key: "Tactics:Triangulate Fire",
        name: "Triangulate Fire",
        type: CastMemberClassType.tactic,
        tags: [
          { 
            key: "Points",
            val: "0"
          },
          {
            key: "Standing",
            val: "tactic"
          },
          {
            key: "Order",
            val: "10"
          },
          {
            key: "Faction",
            val: "TechnocratStrikeTeams"
          },
          {
            key: "Tactic:Triangulate Fire",
            val: "Triangulate Fire"
          }
        ]
      },
      {
        key: "Tactics:Double Fire",
        name: "Double Fire",
        type: CastMemberClassType.tactic,
        tags: [
          { 
            key: "Points",
            val: "0"
          },
          {
            key: "Standing",
            val: "tactic"
          },
          {
            key: "Order",
            val: "11"
          },
          {
            key: "Faction",
            val: "TechnocratStrikeTeams"
          },
          {
            key: "Tactic:Double Fire",
            val: "Double Fire"
          }
        ]
      },
      {
        key: "Tactics:Finality",
        name: "Finality",
        type: CastMemberClassType.tactic,
        tags: [
          { 
            key: "Points",
            val: "0"
          },
          {
            key: "Standing",
            val: "tactic"
          },
          {
            key: "Order",
            val: "12"
          },
          {
            key: "Faction",
            val: "TechnocratStrikeTeams"
          },
          {
            key: "Tactic:Finality",
            val: "Finality"
          }
        ]
      },
      {
        key: "Tactics:Disguise",
        name: "Disguise",
        type: CastMemberClassType.tactic,
        tags: [
          { 
            key: "Points",
            val: "0"
          },
          {
            key: "Standing",
            val: "tactic"
          },
          {
            key: "Order",
            val: "13"
          },
          {
            key: "Faction",
            val: "TechnocratStrikeTeams"
          },
          {
            key: "Tactic:Disguise",
            val: "Disguise"
          }
        ]
      },
    ],
    tagOptions: [
      {
        key: "Pulse Charge"
      },
      {
        key: "Repair Protocols"
      },
      {
        key: "Injury-Slow"
      },
      {
        key: "Injury-Frail"
      },
      {
        key: "Injury-Weak"
      },
      {
        key: "Injury-No Ranged"
      },
      {
        key: "Faction-Training"
      },
      {
        key: "Promotion"
      },
      {
        key: "Promotion-2"
      },
      {
        key: "Training"
      },
      {
        key: "Training-2"
      },
      {
        key: "Recovery"
      },
      {
        key: "Tactical-Training"
      },
      {
        key: "Tactical-Training-2"
      },
      {
        key: "Relentless"
      },
      {
        key: "Heavy Arms"
      },
      {
        key: "Push"
      },
      {
        key: "Methodical"
      },
      {
        key: "Fighter"
      },
      {
        key: "Tough"
      },
    ],
    tags: [
      { 
        key: "archetype",
      },
      { 
        key: "Technocrat Strike Teams",
      },
      { 
        key: "Technocrats",
      },
      { 
        key: "Blood of Stars",
      },
    ],
  } 
};


export const exampleRangersOfIshtarArchetype: ArchetypeType = {
  id: -5,
  key: "-5",
  userId: 1,
  name: "Rangers of Ishtar",
  description: "Rangers of Ishtar.",
  createdAt: "2021-05-18T19:42:35+0000",
  updatedAt: "2021-05-18T19:42:35+0000",
  worldId: 1,
  data: {
    tagModifiersKeys: [],
    tagDescriptionsKeys: ["SkirmishFight"],
    cast: [
      {
        key: "leader:RangerHeavyBlaster",
        name: "RangerHeavyBlaster",
        type: CastMemberClassType.archetype,
        tags: [
          { 
            key: "Move",
            val: "6"
          },
          { 
            key: "Fight",
            val: "1"
          },
          { 
            key: "Shoot",
            val: "0"
          },
          {
            key: "Evade",
            val: "1"
          },
          { 
            key: "Life",
            val: "3"
          },
          {
            key: "Points",
            val: "10"
          },
          {
            key: "Standing",
            val: "leader"
          },
          { 
            key: "Eagle Eye",
            val: ""
          },
          { 
            key: "Blaster",
            val: ""
          },
          { 
            key: "Slug",
            val: ""
          },   
        ]
      },
      {
        key: "veteran:RangerCaptain",
        name: "RangerCaptain",
        type: CastMemberClassType.archetype,
        tags: [
          { 
            key: "Move",
            val: "6"
          },
          { 
            key: "Fight",
            val: "1"
          },
          { 
            key: "Shoot",
            val: "0"
          },
          {
            key: "Evade",
            val: "1"
          },
          { 
            key: "Life",
            val: "3"
          },
          {
            key: "Points",
            val: "10"
          },
          {
            key: "Standing",
            val: "leader"
          }, 
          { 
            key: "Eagle Eye",
          },
          { 
            key: "Aim",
            val: ["Blaster", "Pinning"]
          },
        ]
      },
      {
        key: "veteran:RangerSniper",
        name: "Ranger Sniper",
        type: CastMemberClassType.archetype,
        tags: [
          { 
            key: "Move",
            val: "6"
          },
          { 
            key: "Fight",
            val: "1"
          },
          { 
            key: "Shoot",
            val: "0"
          },
          {
            key: "Evade",
            val: "1"
          },
          { 
            key: "Life",
            val: "3"
          },
          {
            key: "Points",
            val: "10"
          },
          {
            key: "Standing",
            val: "elite"
          },
          { 
            key: "Eagle Eye",
          },
          { 
            key: "Fragile",
          },
          { 
            key: "Slow",
          },
          { 
            key: "Onerous",
          },
          { 
            key: "Crit Fire",
          },
          { 
            key: "Slug",
          },
          { 
            key: "Ranged",
          },
        ]
      },
      {
        key: "veteran:Hunter",
        name: "Hunter",
        type: CastMemberClassType.archetype,
        tags: [
          { 
            key: "Move",
            val: "6"
          },
          { 
            key: "Fight",
            val: "1"
          },
          { 
            key: "Shoot",
            val: "0"
          },
          {
            key: "Evade",
            val: "1"
          },
          { 
            key: "Life",
            val: "3"
          },
          {
            key: "Points",
            val: "10"
          },
          {
            key: "Standing",
            val: "elite"
          },
          { 
            key: "Eagle Eye",
          },
          { 
            key: "Slow",
          },
          { 
            key: "Tough",
          },
          { 
            key: "Pinning",
          },
          { 
            key: "Booster",
          },
          { 
            key: "Aim",
            val: ["Engulf"]
          },
        ]
      },
      {
        key: "veteran:RangeRider",
        name: "Range Rider",
        type: CastMemberClassType.archetype,
        tags: [
          { 
            key: "Move",
            val: "6"
          },
          { 
            key: "Fight",
            val: "1"
          },
          { 
            key: "Shoot",
            val: "0"
          },
          {
            key: "Evade",
            val: "1"
          },
          { 
            key: "Life",
            val: "3"
          },
          {
            key: "Points",
            val: "10"
          },
          {
            key: "Standing",
            val: "elite"
          },
          { 
            key: "Fast",
          },
          { 
            key: "Charge",
          },
          { 
            key: "Aim",
            val: ["Eagle Eye"]
          },
        ]
      },
      {
        key: "rankAndFile:Ranger",
        name: "Ranger",
        type: CastMemberClassType.archetype,
        tags: [
          { 
            key: "Move",
            val: "6"
          },
          { 
            key: "Fight",
            val: "1"
          },
          {
            key: "Shoot",
            val: "0"
          },
          {
            key: "Evade",
            val: "1"
          },
          { 
            key: "Life",
            val: "3"
          },
          {
            key: "Points",
            val: "10"
          },
          {
            key: "Standing",
            val: "rankAndFile"
          },
          { 
            key: "Weak",
          },
          { 
            key: "Eagle Eye",
          },
          { 
            key: "Onerous",
          },
          { 
            key: "Group",
            val: ["Ranged"]
          },
          { 
            key: "Aim",
            val: ["Skilled"]
          },
        ]
      },
      {
        key: "rankAndFile:Guardian",
        name: "Guardian",
        type: CastMemberClassType.archetype,
        tags: [
          { 
            key: "Move",
            val: "6"
          },
          { 
            key: "Fight",
            val: "1"
          },
          { 
            key: "Shoot",
            val: "0"
          },
          {
            key: "Evade",
            val: "1"
          },
          { 
            key: "Life",
            val: "3"
          },
          { 
            key: "Points",
            val: "10"
          },
          {
            key: "Standing",
            val: "rankAndFile"
          },
          { 
            key: "Fragile",
          },
          { 
            key: "Weak",
          },
          { 
            key: "Dodge",
          },
          { 
            key: "Slow",
          },
          { 
            key: "Push",
          },
          { 
            key: "Aim",
            val: ["Eagle Eye"]
          },
        ]
      },
      {
        key: "Tactics:Called Strike",
        name: "Called Strike",
        type: CastMemberClassType.tactic,
        tags: [
          { 
            key: "Points",
            val: "0"
          },
          {
            key: "Standing",
            val: "tactic"
          },
          {
            key: "Order",
            val: "10"
          },
          {
            key: "Faction",
            val: "RangersOfIshtar"
          },
          {
            key: "Tactic:Called Strike",
            val: "Called Strike"
          }
        ]
      },
      {
        key: "Tactics:Double Fire",
        name: "Double Fire",
        type: CastMemberClassType.tactic,
        tags: [
          { 
            key: "Points",
            val: "0"
          },
          {
            key: "Standing",
            val: "tactic"
          },
          {
            key: "Order",
            val: "11"
          },
          {
            key: "Faction",
            val: "RangersOfIshtar"
          },
          {
            key: "Tactic:Double Fire",
            val: "Double Fire"
          }
        ]
      },
      {
        key: "Tactics:Combined Arms",
        name: "Combined Arms",
        type: CastMemberClassType.tactic,
        tags: [
          { 
            key: "Points",
            val: "0"
          },
          {
            key: "Standing",
            val: "tactic"
          },
          {
            key: "Order",
            val: "12"
          },
          {
            key: "Faction",
            val: "RangersOfIshtar"
          },
          {
            key: "Tactic:Combined Arms",
            val: "Combined Arms"
          }
        ]
      },
      {
        key: "Tactics:Trap",
        name: "Trap",
        type: CastMemberClassType.tactic,
        tags: [
          { 
            key: "Points",
            val: "0"
          },
          {
            key: "Standing",
            val: "tactic"
          },
          {
            key: "Order",
            val: "13"
          },
          {
            key: "Faction",
            val: "RangersOfIshtar"
          },
          {
            key: "Tactic:Trap",
            val: "Trap"
          }
        ]
      },
    ],
    tagOptions: [
      {
        key: "Blind Grenades"
      },
      {
        key: "Marked Shot"
      },
      {
        key: "Injury-Slow"
      },
      {
        key: "Injury-Frail"
      },
      {
        key: "Injury-Weak"
      },
      {
        key: "Injury-No Ranged"
      },
      {
        key: "Faction-Training"
      },
      {
        key: "Promotion"
      },
      {
        key: "Promotion-2"
      },
      {
        key: "Training"
      },
      {
        key: "Training-2"
      },
      {
        key: "Recovery"
      },
      {
        key: "Tactical-Training"
      },
      {
        key: "Tactical-Training-2"
      },
      {
        key: "Pinning"
      },
      {
        key: "Heavy Arms"
      },
      {
        key: "Ranged"
      },
      {
        key: "Counter Fire"
      },
      {
        key: "Push"
      },
      {
        key: "Tough"
      },
    ],
    tags: [
      { 
        key: "archetype",
      },
      { 
        key: "Rangers of Ishtar",
      },
      { 
        key: "RangersOfIshtar",
      },
      { 
        key: "Ishtar",
      },
      { 
        key: "Blood of Stars",
      },
    ],
  } 
};


export const exampleArchetype = exampleHammerArchetype; 
export const exampleArchetypes = [ exampleTestArchetypes, exampleHammerArchetype, exampleCultsArchetype, exampleAdvancedGuardArchetype, exampleTechnocratStrikeTeamsArchetype, exampleRangersOfIshtarArchetype]; 

export const exampleCastMock : CastType = {
  id: 1,
  userId: 1,
  name: "Alpha Pack",
  description: "A Famous Spec Ops Team.",
  createdAt: "2021-05-18T19:42:35+0000",
  updatedAt: "2021-05-18T19:42:35+0000",
  data: {
    cast: [
      {
        key: "hero:1",
        name: "T Falcon",
        type: CastMemberClassType.hero,
        tags: [
          { 
            key: "hero",
            val: "1"
          },
          {
            key: "leader",
          }
        ],
        archetypeKey: "leader:VanguardLieutenant",
      }
    ],
    tags: [
      { 
        key: "Hammer",
      }
    ]
  }
};
