import Layout from "Layout";
import { useParams } from "react-router-dom";

import { CastPageParams } from "pages/Casts/id";
import { useGetCastProps } from "pages/Casts/id/cast.hook";

import { EditCastPresentation } from "./EditCast.Presentation";
import routeLinks from "App/routing/routeLinks";
import { sendToastal } from "components/Toastal";
import { useEffect } from "react";
import { useGetCurrentUser } from "hooks/userHooks";

export const EditCast = () => {
  const { id: castId } = useParams<CastPageParams>();
  const { isAdmin, isGuest, id } = useGetCurrentUser(); 
  
  const {
    displayCast,
    displayArchetypeMembers,
    archetypeDisplay,
    editArchetypeDisplay,
    tags,
    name,
    displayTagOptions,
    totalUnits,
    totalPoints,
    workingCast,
    updateNumberOfMembersInWorkingCast,
    addArchetypeToWorkingCast,
    updateOrderInWorkingCast,
    updateCastMemberInWorkingCast,
    updateWorkingCast,
    saveWorkingCast,
    groupedTags,
  } = useGetCastProps(id, castId);

  const showWorkingCast = displayCast || [];
  useEffect(() => {
    sendToastal("Swipe right to add, swipe left to remove.");
  }, []);

  return (
    <Layout>
      {castId ? <EditCastPresentation {...{
        displayCast: showWorkingCast,
        workingCast,
        editTagOptions: displayTagOptions,
        updateNumberOfMembersInWorkingCast,
        updateCastMemberInWorkingCast,
        addArchetypeToWorkingCast,
        updateOrderInWorkingCast,
        updateWorkingCast,
        displayArchetypeMembers,
        archetypeDisplay,
        editArchetypeDisplay,
        tags,
        name,
        totalPoints,
        totalUnits,
        castId,
        saveWorkingCast,
        viewLinkTo: `/${routeLinks.teams}/${castId}`,
        groupedTags,
        isAdmin,
        isGuest
        }} 
      /> : <div>No Cast Id</div>}
    </Layout>
  );
}