import React, { ReactNode } from 'react';
import LayoutPresentation from './PrintableLayout.Presentation';

interface BaseProps {
  children?: ReactNode;
}

const Layout = (props: BaseProps) => {
  return (
      <LayoutPresentation {...props} ></LayoutPresentation> 
  );
};

export default Layout;

