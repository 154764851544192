import { useCallback } from "react";
import Layout from "Layout";
import { ArchetypeType } from "actions/cast.types";
import { Button } from "components/Buttons/Button";
import { TextField, TextFieldType } from "components/Forms/TextField";
import styles from "./styles.module.scss";
import { useNavigate } from "react-router-dom";
import { Form } from 'components/Forms/Form';
import JSONEditor from "components/JsonEditor"
import { routeLinks } from "App/routing";
import { FormType, useArchetypeForm } from "../useArchetypeForm";

export const NewArchetype = () => {
  const navigate = useNavigate();

  const submitCallback = useCallback((archetype?: ArchetypeType) => {
    navigate(`/${routeLinks.factions}/${archetype?.id}`);
  }, [navigate]);

  const {
    formName,
    errors,
    onSubmit,
    register,
    setValue,
    formData
  } = useArchetypeForm({ formType: FormType.Create, callback: submitCallback });

  return (
    <Layout>
      <div >
        <h1>
          { `Create${formName && ':'} ${formName}`}

        </h1>
        <div>
          <Form
            onSubmit={onSubmit}
          >
            <Button type="submit">Save</Button> 
            <TextField 
              type={TextFieldType.text}
              placeholder="Name"
              label="Name"
              labelClassName={styles.label}
              className={styles.entry}
              error={errors?.name}
              {...register("name",{
                validate:{
                  validLength: (val?: string) => {
                    return (val && val?.length > 0) || "Cannot be blank";
                  }
                }
              })}
            />
            <TextField 
              type={TextFieldType.text}
              labelClassName={styles.label}
              className={styles.entry}
              placeholder="Description"
              label="Description"
              error={errors?.description}
              {...register("description",{
                validate:{
                  validLength: (val?: string) => {
                    return (val && val?.length > 0) || "Cannot be blank";
                  }
                }
              })}
            />
            <TextField 
              type={TextFieldType.number}
              labelClassName={styles.label}
              className={styles.entry}
              placeholder="Display"
              label="Display"
              error={errors?.display}
              {...register("display",{
              })}
            />
            <TextField
              type={TextFieldType.textArea}
              label="Data" 
              fieldClassName={styles.dataText}
              labelClassName={styles.label}
              className={styles.hidden}
              error={errors?.data}
              {...register("data",{
                validate:{
                  validLength: (val?: string) => {
                    return val && val?.length > 0;
                  }
                }
              })}
            />
            <JSONEditor
              value={formData}
              className={styles.dataBlock}
              label="Data"
              labelClassName={styles.label}
              onChange={(changed, oldChanged) => {
                if(changed !== oldChanged && (typeof changed === "string" || typeof changed === 'undefined')) {
                  setValue('data', changed || '');
                }
              }}
            />
          </Form>
        </div>
      </div>
    </Layout>
  );
}
