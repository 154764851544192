import styles from "./styles.module.scss";
import { Link } from 'react-router-dom';
import { routeLinks } from 'App/routing';
import classNames from "classnames";
import logo from "assets/images/icons/BloodOfStarsLogo.png";
import { useWorld } from "hooks/worldHooks";

// Header for all pages
// Dumb component, so use stateless Function declaration
// no props
const Header = () => {
  const { chosenWorld, isMultiWorld} = useWorld();
  return (
    <header>
      <div className={classNames(styles.header)}>
        <div className={styles.headerTitle}>
          <Link to={`/${routeLinks.home}`}>
            <img src={logo}  className={styles.headerLogo} alt="logo"></img> 
          </Link>
          Blood of Stars
          {chosenWorld && isMultiWorld ? ` - ${chosenWorld.name}(${chosenWorld.id})` : null}
        </div>
      </div>
    </header>
  );
};

export default Header;
