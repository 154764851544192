import { ReactNode } from "react";
import classNames from "classnames";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

import styles from "./styles.module.scss";
import { ToastProps } from "react-toastify/dist/types";

export enum BackgroundTypes {
  None = "none",
  Default = "Default"
}

interface ToastalProps {
  children: ReactNode;
  wrapperClass?: string;
  toastalBackgroundType?: BackgroundTypes;
}

export const Toastal = ({
  children,
  wrapperClass,
  toastalBackgroundType,
}: ToastalProps) => {

  const backgroundModal = toastalBackgroundType === BackgroundTypes.None ? undefined : styles.modalBackground;

  return (
    <>
      <div className={classNames(styles.modalWrapper, wrapperClass)}>
        <div className={classNames(backgroundModal)}>
        </div>
        <div className={styles.modal}>
          {children}
        </div>
      </div>
    </>
  );
}

export const ToastalContainer = () => {
  return <ToastContainer 
    position = "bottom-right"
    draggable={false}
    autoClose={1500}
    closeOnClick
    theme="light"
    hideProgressBar
  />
}

export const sendToastal = (val: ReactNode, toastProps?: ToastProps) => {
  toast(val, toastProps);
}

export default Toastal;
