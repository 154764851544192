import {
    atom,
    useRecoilState,
  } from 'recoil';
import { userIsAuthorized } from 'actions/userActions';
import { atomKeys } from 'atoms/keys';

const initialUserAuth = userIsAuthorized();

export interface AuthStateAtomType {
  Authenticated?: boolean; 
  Authenticating?: boolean;
};

const initialState = {
    Authenticated: initialUserAuth,
    Authenticating: false,
};

export const authState = atom<AuthStateAtomType>({
  key: atomKeys.authState, // unique ID (with respect to other atoms/selectors)
  default: initialState, // default value (aka initial value)
});

export const useAuthState = () => {
  const [auth, setAuth] = useRecoilState(authState);

  // can add selectors here for Auth, as 3rd param object
  return {
    state: auth,
    setState: setAuth
  };
};
