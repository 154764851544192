import styles from './styles.module.scss';
import { TagType } from 'actions/types';
import { TagModifierType, TagThresholdType } from 'actions/tag.types';

interface ShowTagModifierProps {
  tagModifier: TagModifierType;
  tagDescriptionsMap?:  Map<string, TagType>;
}


export const ShowTagModifier = ({ tagModifier, tagDescriptionsMap }: ShowTagModifierProps ) => {

  return (<div>
    <h4 className={styles.name}>{tagModifier.id}</h4>
      {tagModifier.priority && (<div className={styles.entry}>
        <div className={styles.label}>priority:</div> {tagModifier.priority}
      </div>)}{tagModifier.primaryTagKey && (<div className={styles.entry}>
      <div  className={styles.label}>primary tag key:</div> {tagModifier.primaryTagKey}
        </div>)}{tagModifier.relatedTagKey && (<div className={styles.entry}>
        <div  className={styles.label}>related tag key:</div> {tagModifier.relatedTagKey}
        </div>)}{tagModifier.symbol && (<div className={styles.entry}>
        <div  className={styles.label}>symbol:</div> {tagModifier.symbol}
        </div>)}{typeof tagModifier.val !== 'undefined' && (<div className={styles.entry}>
        <div  className={styles.label}>val:</div> {tagModifier?.val}
        </div>)}{typeof tagModifier.val2 !== 'undefined'  && (<div className={styles.entry}>
        <div  className={styles.label}>val 2:</div> {tagModifier?.val2}
        </div>)}{tagModifier.primaryIsBaseKey && (<div className={styles.entry}>
        <div  className={styles.label}>primaryIsBaseKey:</div> {tagModifier?.primaryIsBaseKey}
        </div>)}{tagModifier.description && (<div className={styles.entry}>
        <div className={styles.label}>description:</div> {tagModifier?.description}
        </div>)}
        {tagModifier.thresholds && (<div className={styles.entryLong}><div className={styles.label}>thresholds:</div> <div className={styles.thresholdSection}>{
        tagModifier?.thresholds?.map((threshold,index) => {
          return <><ShowThreshold threshold={threshold} />---------</>
        })}</div>
      </div>)}<div className={styles.entry}>
        <div  className={styles.label}>created at:</div> {tagModifier?.createdAt}
        </div><div className={styles.entry}>
        <div  className={styles.label}>updated at:</div> {tagModifier.updatedAt}
      </div>
  </div>);
};

interface ShowThresholdProps {
  threshold: TagThresholdType; 
}
const ShowThreshold = ({ threshold }: ShowThresholdProps) => {
  return <div key={threshold.thresholdKey}>
    <div  className={styles.entry}>
      <div  className={styles.label}>threshold key:</div> {threshold.thresholdKey}
    </div>
    {threshold.threshold && (<div  className={styles.entry}>
      <div  className={styles.label}>threshold:</div> {threshold.threshold}
    </div>)}
    {threshold.thresholdTriggerBelow && (<div  className={styles.entry}>
      <div  className={styles.label}>threshold trigger below:</div> {threshold.thresholdTriggerBelow}
    </div>)}
     {threshold.andOrThresholds && (<div  className={styles.entry}>
      <div  className={styles.label}>and or thresholds:</div> {threshold.andOrThresholds}
    </div>)}
    {threshold.tagThresholdSymbol && (<div  className={styles.entry}>
      <div  className={styles.label}>tag threshold symbol:</div> {threshold.tagThresholdSymbol}
    </div>)}
    {threshold.exactMatch && (<div  className={styles.entry}>
      <div  className={styles.label}>exactMatch:</div> {threshold.exactMatch}
    </div>)}
    {threshold.thresholds && (<div  className={styles.entryLong}>
      <div  className={styles.label}>thresholds:</div>{threshold?.thresholds?.map((m) => {
        return <>
        <ShowThreshold threshold={m} />
        ::: 
        </>
      })}
    </div>)} 
  </div>
}

export default ShowTagModifier;