import { Cast } from "pages/Casts/id";

export type CastPageParams = {
  id: string;
};

export interface CastParams {
  readOnly?: boolean;
}

export const CastOfRenown = (props: CastParams) => {
  // const { id: castId } = useParams<CastPageParams>();
  // const { state: { id } } = useUserState();
  return (<Cast {...props} readOnly={true} />);
}
