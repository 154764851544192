import React, { useState } from "react";
import classNames from "classnames";

import { Modal, ModalTypes } from "components/Modal";

import { ValTagDescriptionDetails } from "components/ArchetypeMembers/ShowArchetypeMember/ValTagDescription";

import styles from "./styles.module.scss";
import { TagType } from "actions/types";
import { getTagDisplayName } from "lib/tag.helpers";

interface EditCastDetailsProps {
  tag: TagType;
  close?: () => void;
}

//showingTagDescriptor
export const useTagDescriptorModal = () => {
  const [showTagDescriptor, setShowTagDescriptor] = useState<TagType | undefined>();
  
  const onClickDescriptor = (tagType?: TagType) => {
    (tagType && showTagDescriptor?.key !== tagType?.key) ? setShowTagDescriptor(tagType) : setShowTagDescriptor(undefined);
  }
  return {
    showTagDescriptor,
    setShowTagDescriptor,
    onClickDescriptor
  }
}

export const TagDescriptorModal = ({
  tag,
  close,
}: EditCastDetailsProps) => {
  return  (
    <Modal
      modalType={ModalTypes.Toast}
    >
      <div className={styles.archetypeEntryCard}  onClick={() => { close && close(); }}>
        <div className={classNames(styles.archetypeTitle)}>
          <div className={styles.tagTitle}>
            {getTagDisplayName(tag)}
          </div>
        </div>
        <div>
          {tag?.description}{tag?.valDescriptions && tag.valDescriptions.length > 0 ? (<> <ValTagDescriptionDetails tagDescriptions={tag.valDescriptions} /></>) : undefined}
        </div>
      </div>
    </Modal>
  );
  
}