import { useMemo, useEffect, useState } from 'react';
import { CastMemberType, ArchetypeDisplayType, ArchetypeType } from 'actions/cast.types';
import { TagType } from 'actions/types';
import { TagModifierType, TagGroupingType, LinkByType } from 'actions/tag.types';
import { useGetArchetype, useCreateArchetype, useUpdateArchetype, CreateArchetypeFunctionType, UpdateArchetypeFunctionType } from 'actions/archetypeActions';
import { useGetBaseTagsGroup } from 'actions/tagActions';
import { useGetTagModifiersGroups, useGetAllTagModifierGroups } from 'actions/tagModifiersActions';
import {  useGetArchetypeDisplay } from 'actions/archetypeDisplayActions';
import { tagsToMap } from 'actions/helpers/tagHelpers';
import { groupTags } from "lib/tagGrouping.helpers";
import { addTagDescriptions } from "actions/helpers/tagHelpers";
import { defaultKey } from 'lib/config';
// extras
import { TagModifiersGroupType } from 'actions/tag.types';
import { 
  modifyTagsMap,
  calculateDisplayArchetypeMemberTags,
  createUngroupedGroup,
  groupCasts 
} from "lib/thresholds";
import { useWorld } from './worldHooks';
import { useGetCurrentUser } from './userHooks';

// Blood of Stars Section Keys
export const SectionKeys = {
  primary: 'primary',
  statline: 'statline',
  traits: 'traits',
  abilityTraits: 'abilityTraits',
  gear: 'gear',
  factionSpecialRules: 'factionSpecialRules',
  injuries: 'injuries',
  upgrades: 'upgrades',
  traitConditions: 'traitConditions',
  leaderTraits: 'leaderTraits',
  tagOptions: 'tagOptions',
  factionCampaignUpgrades: 'factionCampaignUpgrades',
  xp: 'xp',
  tacticOptions: 'tacticOptions',
};

interface useDisplayArchetypeMembersProps {
  archetypeMembers?: Array<CastMemberType>;
  tagModifiers?: Array<TagModifierType>;
  tagDescriptionsMap?: Map<string, TagType>;
};

export const updateTags = (memberTagsMap: Map<string, TagType>, tagModifiers?: TagModifierType[], tagDescriptionsMap?: Map<string, TagType>) => {
  const newTagModifiers = tagModifiers ? modifyTagsMap(tagModifiers, memberTagsMap, false) : memberTagsMap;

  return tagsToMap(Array.from(newTagModifiers.values()), tagDescriptionsMap);
};

export const useDisplayArchetypeMembers = ({
  archetypeMembers, tagModifiers, tagDescriptionsMap
}: useDisplayArchetypeMembersProps) => {

  const {
    displayArchetypeMembers,
    displayArchetypeMembersMap,
  } = useMemo(() => {
    const at = archetypeMembers?.map<CastMemberType>((atm) => {
      const allTags = [...(atm?.tags || []), ...(atm?.tagOptions || [])];
      // all tags
      const memberTagsMap = tagsToMap(allTags, tagDescriptionsMap);
      // tags gained by options
      const memberTagOptionsMap = tagsToMap((atm?.tagOptions || []), tagDescriptionsMap);

      // sort by priority, and perform double pass by priority.
      let modifiedTagMaps = updateTags(memberTagsMap, tagModifiers, tagDescriptionsMap);
      let newArchetypeCast = atm.archetypeCast;

      if (newArchetypeCast) {
        const castAllTags = [...(newArchetypeCast?.tags || []), ...(newArchetypeCast?.tagOptions || [])];
        const castMemberTagOptionsMap = tagsToMap((newArchetypeCast?.tagOptions || []), tagDescriptionsMap);
        const castMemberTagsMap = tagsToMap(castAllTags, tagDescriptionsMap);
        let castModifiedTagMaps = updateTags(castMemberTagsMap, tagModifiers, tagDescriptionsMap);
        
        newArchetypeCast = {
          ...newArchetypeCast,
          tags: Array.from(castModifiedTagMaps.values()),
          tagsMap: castModifiedTagMaps,
          tagOptionsMap: castMemberTagOptionsMap, 
        }
      }

      return {
        ...atm,
        archetypeCast: newArchetypeCast,
        tags: Array.from(modifiedTagMaps.values()),
        tagsMap: modifiedTagMaps,
        tagOptionsMap: memberTagOptionsMap,
      };
    });

    const atMap = new Map(at?.map((at) => { return [at.key, at]; })) 

    return { 
      displayArchetypeMembers: at,
      displayArchetypeMembersMap: atMap,
    };
  }, [archetypeMembers, tagModifiers, tagDescriptionsMap]);

  return {
    displayArchetypeMembers,
    displayArchetypeMembersMap,
  };
}

interface useDisplayArchetypeMemberProps {
  archetypeMember: CastMemberType;
  tagModifiers?: Array<TagModifierType>;
  tagDescriptionsMap?: Map<string, TagType>;
};

// Display and calculate tags for member.
export const useDisplayArchetypeMember = ({
  archetypeMember, tagModifiers, tagDescriptionsMap
}: useDisplayArchetypeMemberProps) => {
  const fullArchetypeMember = useMemo(() => {
    const newTagMember = calculateDisplayArchetypeMemberTags({archetypeMember, tagModifiers, tagDescriptionsMap }); 
    
    return newTagMember;
  }, [tagModifiers, archetypeMember, tagDescriptionsMap]);

  return {
    displayArchetypeMember: fullArchetypeMember,
    displayMemberTagMaps: fullArchetypeMember.tagsMap,
  };
}

export interface UseArchetypeProps {
  userId?: number;
  archetypeKey?: string | number;
  name?: string;
  description?: string;
  archetype?: ArchetypeType;
  tagModifiers?: Array<TagModifierType>;
  archetypeDisplay?: ArchetypeDisplayType;
  baseTags?: Array<TagType>;
  displayTagOptions?: Array<TagType>;
  groupedTags?: Array<TagGroupingType>;
  createArchetype?: CreateArchetypeFunctionType;
  updateArchetype?: UpdateArchetypeFunctionType;
  loaded?: boolean;
  // Base Info loaded
  tagModifiersGroup?: TagModifiersGroupType;
}

const DefaultData = {
  id: 0,
  userId: 0,
  name: 'Cast',
  description: 'description...',
  data: {
    cast: [],
    tags: []
  },
  createdAt: undefined,
  updatedAt: undefined,
};

export const useArchetype = (archetypeKey?: string | number): UseArchetypeProps => {
  const { chosenWorldId } = useWorld()
  const { id: userId } = useGetCurrentUser(); 
  const { data } = useGetArchetype(archetypeKey, chosenWorldId, userId);
  const foundData = data || DefaultData;
  const [loaded, setLoaded]= useState(false);

  useEffect(() => {
    if(data) {
      setLoaded(true);
    }
  }, [data]);
  
  const { data: archetypeDisplay } = useGetArchetypeDisplay(defaultKey, chosenWorldId, userId);
  // get Tag Modifiers for World
  const { data: baseTagModifiersGroup } = useGetAllTagModifierGroups(data?.worldId, userId);
  // get Tag Modifiers for Tag Modifiers
  const { data: tagModifiersGroup } = useGetTagModifiersGroups(data?.data?.tagModifiersKeys, chosenWorldId, userId);
  const { data: baseTagsGroup } = useGetBaseTagsGroup(data?.data?.tagDescriptionsKeys?.join(),chosenWorldId, userId);
  const createArchetype = useCreateArchetype();
  const updateArchetype = useUpdateArchetype();
  const tagModifiers = useMemo(() => {
    let tagsModifiers: TagModifierType[] = [];
    if(baseTagModifiersGroup) {
      const baseTagsModifiers = baseTagModifiersGroup.reduce((r: TagModifierType[], g) => {
        return [...r, ...(g.tagModifiers || [])];
      },[]);
      tagsModifiers = [ ...baseTagsModifiers]; 
    }

    if(tagModifiersGroup) {
    }
    return tagsModifiers;
  },[tagModifiersGroup, baseTagModifiersGroup]);

  const baseTags = useMemo(() => {
    return baseTagsGroup?.tags ? baseTagsGroup.tags : [];
  },[baseTagsGroup?.tags]);

  const archetype: ArchetypeType = foundData;
  const baseTagsMap = tagsToMap(baseTags);
  const groupedTags = useMemo(() => {
    const cast: Array<CastMemberType> = archetype?.data?.cast || [];
    const baseTagsMap = tagsToMap(baseTags);
    const archetypeTags = cast?.reduce(
      (tags: Map<string, TagType>, cast:CastMemberType) => {
        const deconstructedTags = cast?.tags.reduce((dTags: TagType[], tag) => {
          if(tag.valAreTagKeys && tag.linkBy !== LinkByType.KeyVal) {
            const values = Array.isArray(tag.val) ? tag.val : [tag.val];
            const valuesConvertedToTags = values.reduce((res: TagType[],v) => {
              const foundTag = baseTagsMap.get(`${v}`);
              if(foundTag) {
                res.push({...foundTag});
              }
              return res;
            },[]);
            // turn base Tag into simple tag
            const baseTag = { ...tag, val: undefined, valAreTagKeys: false};
            return [...dTags, baseTag, ...valuesConvertedToTags]; 
          } else {
            return [...dTags, tag];
          }
        },[]);
        const tagsMap = tagsToMap(deconstructedTags);
       return new Map([...tags, ...tagsMap]);
      }, new Map<string,TagType>()
    );
    const additionalOptionTags = tagsToMap(archetype?.data?.tagOptions || []);

    const allArchetypeTags =  new Map([...archetypeTags, ...additionalOptionTags]);

    const allArchetypeTagsWithDescription = addTagDescriptions(Array.from(allArchetypeTags.values()), tagsToMap(baseTags)); 

    if(baseTagsGroup?.groupings) {
      return groupTags(baseTagsGroup?.groupings, allArchetypeTagsWithDescription);
    } else {
      return [];
    }
  },[baseTagsGroup, archetype, baseTags]);
  const displayTagOptions = addTagDescriptions(archetype?.data?.tagOptions, baseTagsMap) || []; 
  return {
    archetypeKey,
    name: foundData?.name,
    archetype: foundData,
    description: foundData?.description,
    archetypeDisplay,
    displayTagOptions,
    tagModifiers,
    tagModifiersGroup,
    baseTags,
    groupedTags,
    createArchetype: (cast: ArchetypeType) => { return createArchetype(cast, chosenWorldId)},
    updateArchetype: (archetypeId: string | number, cast: ArchetypeType) => { return updateArchetype(archetypeId, cast, chosenWorldId)},
    loaded
  };
}

export const useGroupCasts = (cast?: CastMemberType[], archetypeDisplay?: ArchetypeDisplayType) => {

  if( !cast || !archetypeDisplay) {
    return {
      displayGroups: []
    }
  }
  
  if (!archetypeDisplay.data?.groups ) {
    return {
      displayGroups: [createUngroupedGroup(cast || [])]
    }
  }
  const groupings = archetypeDisplay.data?.groups;
  const finalDisplayCasts = groupCasts(groupings, cast);

  return {
    displayGroups: finalDisplayCasts
  };
}
