import {
    atom,
    useRecoilState,
  } from 'recoil';
import { atomKeys } from 'atoms/keys';
import { UserRoles } from "lib/config";
export interface UserStateAtomType {
  email?: string;
  userName?: string;
  firstName?: string;
  lastName?: string;
  lastUserActivity?: string;
  role?: UserRoles,
  id?: number;
  worldId?: number;
};

const initialState = {
  email: undefined,
  userName: '',
  id: undefined,
};

export const authState = atom<UserStateAtomType>({
  key: atomKeys.userState, // unique ID (with respect to other atoms/selectors)
  default: initialState, // default value (aka initial value)
});

export const useUserState = () => {
  const [auth, setAuth] = useRecoilState(authState);

  // can add selectors here for Auth, as 3rd param object
  return {
    state: auth,
    setState: setAuth,
    userId: auth?.id,
    userName: auth?.userName,
    role: auth?.role || UserRoles.anon,
    worldId: auth?.worldId || undefined 
  };
};
