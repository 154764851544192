import { Modal } from "components/Modal";
import { ShowTagsGroups } from "components/Tags/ShowTagsGroups";
import styles from "./styles.module.scss";
import { TagGroupingType } from 'actions/tag.types';

interface GroupedTagsModalProps {
  closeModal: () => void;
  groupedTags?: Array<TagGroupingType>;
}

export const GroupedTagsModal = ({
  groupedTags,
  closeModal
}: GroupedTagsModalProps) => {
  return (<Modal
    onBackgroundClick={() => { closeModal(); }}
    onClick={() => { closeModal(); }}
    includeCloseButton={true}
    closeModal={() => { closeModal(); }}
  >
  <div className={styles.traitsList}>
    <h2>Traits</h2>
    <ShowTagsGroups groupedTags={groupedTags} />
  </div>
</Modal>)
};

export default GroupedTagsModal;