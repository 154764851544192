
import { TagModifiersType, TagModifierType, TagModifierSymbol, TagType, TagModifiersGroupType, BaseTagsGroupType,TagThresholdSymbolType  } from "actions/tag.types";

export const exampleTagModifiersMock2: Array<TagModifierType> =  [
        {
          id:"75442486-0878-440c-9db1-a7006c25a39f-001",
          primaryTagKey: "Eagle Eye",
          relatedTagKey: "Shoot",
          symbol: TagModifierSymbol.Add,
          val: 1,
          description: "",
          priority: 1000,
          createdAt: "2021-02-17T19:59:00.251Z",
          updatedAt: "2021-02-17T19:59:00.251Z",
        },
        {
          id:"75442486-0878-440c-9db1-a7006c25a39f-002",
          primaryTagKey: "Heavy Arms",
          relatedTagKey: "Shoot",
          symbol: TagModifierSymbol.Add,
          val: 1,
          description: "",
          priority: 1000,
          createdAt: "2021-02-17T19:59:00.251Z",
          updatedAt: "2021-02-17T19:59:00.251Z",
        },
        {
          id:"75442486-0878-440c-9db1-a7006c25a39f-003",
          primaryTagKey: "Skilled",
          relatedTagKey: "Fight",
          symbol: TagModifierSymbol.Add,
          val: 1,
          description: "",
          priority: 1000,
          createdAt: "2021-02-17T19:59:00.251Z",
          updatedAt: "2021-02-17T19:59:00.251Z",
        },
        {
          id:"75442486-0878-440c-9db1-a7006c25a39f-004",
          primaryTagKey: "Fighter",
          relatedTagKey: "Fight",
          symbol: TagModifierSymbol.Add,
          val: 1,
          description: "",
          priority: 1000,
          createdAt: "2021-02-17T19:59:00.251Z",
          updatedAt: "2021-02-17T19:59:00.251Z",
        },
        {
          id:"75442486-0878-440c-9db1-a7006c25a39f-005",
          primaryTagKey: "Dodge",
          relatedTagKey: "Evade",
          symbol: TagModifierSymbol.Add,
          val: 2,
          description: "",
          priority: 1000,
          createdAt: "2021-02-17T19:59:00.251Z",
          updatedAt: "2021-02-17T19:59:00.251Z",
        },
        {
          id:"75442486-0878-440c-9db1-a7006c25a39f-006",
            primaryTagKey: "Weak",
            relatedTagKey: "Fight",
            symbol: TagModifierSymbol.Add,
            val: -1,
            description: "",
            priority: 1000,
            createdAt: "2021-02-17T19:59:00.251Z",
            updatedAt: "2021-02-17T19:59:00.251Z",
        },
        {
          id:"75442486-0878-440c-9db1-a7006c25a39f-007",
          primaryTagKey: "Weak",
          relatedTagKey: "Evade",
          symbol: TagModifierSymbol.Add,
          val: -1,
          description: "",
          priority: 1000,
          createdAt: "2021-02-17T19:59:00.251Z",
          updatedAt: "2021-02-17T19:59:00.251Z",
      },
        {
          id:"75442486-0878-440c-9db1-a7006c25a39f-008",
            primaryTagKey: "Tough",
            relatedTagKey: "Life",
            symbol: TagModifierSymbol.Add,
            val: 1,
            description: "",
            priority: 1000,
            createdAt: "2021-02-17T19:59:00.251Z",
            updatedAt: "2021-02-17T19:59:00.251Z",
        },
        {
          id:"75442486-0878-440c-9db1-a7006c25a39f-009",
            primaryTagKey: "Fragile",
            relatedTagKey: "Life",
            symbol: TagModifierSymbol.Add,
            val: -1,
            description: "",
            priority: 1000,
            createdAt: "2021-02-17T19:59:00.251Z",
            updatedAt: "2021-02-17T19:59:00.251Z",
        },
        {
          id:"75442486-0878-440c-9db1-a7006c25a39f-010",
            primaryTagKey: "Fast",
            relatedTagKey: "Move",
            symbol: TagModifierSymbol.Add,
            val: 2,
            description: "",
            priority: 1000,
            createdAt: "2021-02-17T19:59:00.251Z",
            updatedAt: "2021-02-17T19:59:00.251Z",
        },
        {
          id:"75442486-0878-440c-9db1-a7006c25a39f-011",

            primaryTagKey: "Slow",
            relatedTagKey: "Move",
            symbol: TagModifierSymbol.Add,
            val: -2,
            description: "",
            priority: 1000,
            createdAt: "2021-02-17T19:59:00.251Z",
            updatedAt: "2021-02-17T19:59:00.251Z",
        },
      ]

export const exampleTagModifiersMock: Array<TagModifiersType> = [
  {
    id: 121,
    name: "Base Stats",
    description: "Base Stats",
    data: {
      tagModifiers: [
        {
          id:"75442486-0878-440c-9db1-a7006c25a39f-001",
          primaryTagKey: "Eagle Eye",
          relatedTagKey: "Shoot",
          symbol: TagModifierSymbol.Add,
          val: 1,
          description: "",
          priority: 1000,
          createdAt: "2021-02-17T19:59:00.251Z",
          updatedAt: "2021-02-17T19:59:00.251Z",
        },
        {
          id:"75442486-0878-440c-9db1-a7006c25a39f-002",
          primaryTagKey: "Heavy Arms",
          relatedTagKey: "Shoot",
          symbol: TagModifierSymbol.Add,
          val: 1,
          description: "",
          priority: 1000,
          createdAt: "2021-02-17T19:59:00.251Z",
          updatedAt: "2021-02-17T19:59:00.251Z",
        },
        {
          id:"75442486-0878-440c-9db1-a7006c25a39f-003",
          primaryTagKey: "Skilled",
          relatedTagKey: "Fight",
          symbol: TagModifierSymbol.Add,
          val: 1,
          description: "",
          priority: 1000,
          createdAt: "2021-02-17T19:59:00.251Z",
          updatedAt: "2021-02-17T19:59:00.251Z",
        },
        {
          id:"75442486-0878-440c-9db1-a7006c25a39f-004",
          primaryTagKey: "Fighter",
          relatedTagKey: "Fight",
          symbol: TagModifierSymbol.Add,
          val: 1,
          description: "",
          priority: 1000,
          createdAt: "2021-02-17T19:59:00.251Z",
          updatedAt: "2021-02-17T19:59:00.251Z",
        },
        {
          id:"75442486-0878-440c-9db1-a7006c25a39f-005",
          primaryTagKey: "Dodge",
          relatedTagKey: "Evade",
          symbol: TagModifierSymbol.Add,
          val: 2,
          description: "",
          priority: 1000,
          createdAt: "2021-02-17T19:59:00.251Z",
          updatedAt: "2021-02-17T19:59:00.251Z",
        },
        {
          id:"75442486-0878-440c-9db1-a7006c25a39f-006",
            primaryTagKey: "Weak",
            relatedTagKey: "Fight",
            symbol: TagModifierSymbol.Add,
            val: -1,
            description: "",
            priority: 1000,
            createdAt: "2021-02-17T19:59:00.251Z",
            updatedAt: "2021-02-17T19:59:00.251Z",
        },
        {
          id:"75442486-0878-440c-9db1-a7006c25a39f-007",
          primaryTagKey: "Weak",
          relatedTagKey: "Evade",
          symbol: TagModifierSymbol.Add,
          val: -1,
          description: "",
          priority: 1000,
          createdAt: "2021-02-17T19:59:00.251Z",
          updatedAt: "2021-02-17T19:59:00.251Z",
      },
        {
          id:"75442486-0878-440c-9db1-a7006c25a39f-008",
            primaryTagKey: "Tough",
            relatedTagKey: "Life",
            symbol: TagModifierSymbol.Add,
            val: 1,
            description: "",
            priority: 1000,
            createdAt: "2021-02-17T19:59:00.251Z",
            updatedAt: "2021-02-17T19:59:00.251Z",
        },
        {
          id:"75442486-0878-440c-9db1-a7006c25a39f-009",
            primaryTagKey: "Fragile",
            relatedTagKey: "Life",
            symbol: TagModifierSymbol.Add,
            val: -1,
            description: "",
            priority: 1000,
            createdAt: "2021-02-17T19:59:00.251Z",
            updatedAt: "2021-02-17T19:59:00.251Z",
        },
        {
          id:"75442486-0878-440c-9db1-a7006c25a39f-010",
            primaryTagKey: "Fast",
            relatedTagKey: "Move",
            symbol: TagModifierSymbol.Add,
            val: 2,
            description: "",
            priority: 1000,
            createdAt: "2021-02-17T19:59:00.251Z",
            updatedAt: "2021-02-17T19:59:00.251Z",
        },
        {
          id:"75442486-0878-440c-9db1-a7006c25a39f-011",

            primaryTagKey: "Slow",
            relatedTagKey: "Move",
            symbol: TagModifierSymbol.Add,
            val: -2,
            description: "",
            priority: 1000,
            createdAt: "2021-02-17T19:59:00.251Z",
            updatedAt: "2021-02-17T19:59:00.251Z",
        },
      ]
    },

    createdAt: "2021-02-17T19:59:00.251Z",
    updatedAt: "2021-02-17T19:59:00.251Z",
  },
  {
    id: 122,
    name: "Injury",
    description: "Injury Modifiers",
    data: {
      tagModifiers: [
        {
          id:"75442486-0878-440c-9db1-a7006c25a39f-012",
            primaryTagKey: "Injury-Slow",
            relatedTagKey: "Slow",
            symbol: TagModifierSymbol.Add,
            val: 1,
            description: "",
            priority: 900,
            createdAt: "2021-02-17T19:59:00.251Z",
            updatedAt: "2021-02-17T19:59:00.251Z",
        },
        {
          id:"75442486-0878-440c-9db1-a7006c25a39f-013",
            primaryTagKey: "Injury-Fragile",
            relatedTagKey: "Fragile",
            symbol: TagModifierSymbol.Add,
            val: 1,
            description: "",
            priority: 900,
            createdAt: "2021-02-17T19:59:00.251Z",
            updatedAt: "2021-02-17T19:59:00.251Z",
        }, 
        {
          id:"75442486-0878-440c-9db1-a7006c25a39f-014",
            primaryTagKey: "Injury-Weak",
            relatedTagKey: "Weak",
            symbol: TagModifierSymbol.Add,
            val: 1,
            description: "",
            priority: 900,
            createdAt: "2021-02-17T19:59:00.251Z",
            updatedAt: "2021-02-17T19:59:00.251Z",
        }, 
        {
          id:"75442486-0878-440c-9db1-a7006c25a39f-015",
            primaryTagKey: "Injury-No Ranged",
            relatedTagKey: "No Ranged",
            symbol: TagModifierSymbol.Add,
            val: 1,
            description: "",
            priority: 900,
            createdAt: "2021-02-17T19:59:00.251Z",
            updatedAt: "2021-02-17T19:59:00.251Z",
        },  
      ]
    },

    createdAt: "2021-02-17T19:59:00.251Z",
    updatedAt: "2021-02-17T19:59:00.251Z",
  },
  {
    id: 123,
    name: "Points",
    description: "Story Points",
    data: {
      tagModifiers: [
        {
          id:"75442486-0878-440c-9db1-a7006c25a39f-016",
          primaryTagKey: "Dodge",
          relatedTagKey: "Points",
          symbol: TagModifierSymbol.Add,
          val: 2,
          description: "",
          priority: 1000,
          createdAt: "2021-02-17T19:59:00.251Z",
          updatedAt: "2021-02-17T19:59:00.251Z",
        },
        {
          id:"75442486-0878-440c-9db1-a7006c25a39f-017",
            primaryTagKey: "Eagle Eye",
            relatedTagKey: "Points",
            symbol: TagModifierSymbol.Add,
            val: 2,
            description: "",
            priority: 1000,
            createdAt: "2021-02-17T19:59:00.251Z",
            updatedAt: "2021-02-17T19:59:00.251Z",
        }, 
         {
          id:"75442486-0878-440c-9db1-a7006c25a39f-018",
            primaryTagKey: "Fast",
            relatedTagKey: "Points",
            symbol: TagModifierSymbol.Add,
            val: 2,
            description: "",
            priority: 1000,
            createdAt: "2021-02-17T19:59:00.251Z",
            updatedAt: "2021-02-17T19:59:00.251Z",
        }, 
        {
          id:"75442486-0878-440c-9db1-a7006c25a39f-019",
            primaryTagKey: "Fighter",
            relatedTagKey: "Points",
            symbol: TagModifierSymbol.Add,
            val: 2,
            description: "",
            priority: 1000,
            createdAt: "2021-02-17T19:59:00.251Z",
            updatedAt: "2021-02-17T19:59:00.251Z",
        }, 
        {
          id:"75442486-0878-440c-9db1-a7006c25a39f-020",
            primaryTagKey: "Fragile",
            relatedTagKey: "Points",
            symbol: TagModifierSymbol.Add,
            val: -2,
            description: "",
            priority: 1000,
            createdAt: "2021-02-17T19:59:00.251Z",
            updatedAt: "2021-02-17T19:59:00.251Z",
        }, 
        {
          id:"75442486-0878-440c-9db1-a7006c25a39f-021",
            primaryTagKey: "Heavy Arms",
            relatedTagKey: "Points",
            symbol: TagModifierSymbol.Add,
            val: 2,
            description: "",
            priority: 1000,
            createdAt: "2021-02-17T19:59:00.251Z",
            updatedAt: "2021-02-17T19:59:00.251Z",
        }, 
        {
          id:"75442486-0878-440c-9db1-a7006c25a39f-022",
            primaryTagKey: "Skilled",
            relatedTagKey: "Points",
            symbol: TagModifierSymbol.Add,
            val: 2,
            description: "",
            priority: 1000,
            createdAt: "2021-02-17T19:59:00.251Z",
            updatedAt: "2021-02-17T19:59:00.251Z",
        }, 
        {
          id:"75442486-0878-440c-9db1-a7006c25a39f-023",
            primaryTagKey: "Slow",
            relatedTagKey: "Points",
            symbol: TagModifierSymbol.Add,
            val: -2,
            description: "",
            priority: 1000,
            createdAt: "2021-02-17T19:59:00.251Z",
            updatedAt: "2021-02-17T19:59:00.251Z",
        }, 
        {
          id:"75442486-0878-440c-9db1-a7006c25a39f-024",
            primaryTagKey: "Tough",
            relatedTagKey: "Points",
            symbol: TagModifierSymbol.Add,
            val: 2,
            description: "",
            priority: 1000,
            createdAt: "2021-02-17T19:59:00.251Z",
            updatedAt: "2021-02-17T19:59:00.251Z",
        }, 
        {
          id:"75442486-0878-440c-9db1-a7006c25a39f-025",
            primaryTagKey: "Weak",
            relatedTagKey: "Points",
            symbol: TagModifierSymbol.Add,
            val: -2,
            description: "",
            priority: 1000,
            createdAt: "2021-02-17T19:59:00.251Z",
            updatedAt: "2021-02-17T19:59:00.251Z",
        }, 
        {
          id:"75442486-0878-440c-9db1-a7006c25a39f-026",
            primaryTagKey: "Relentless",
            relatedTagKey: "Points",
            symbol: TagModifierSymbol.Add,
            val: 2,
            description: "",
            priority: 1000,
            createdAt: "2021-02-17T19:59:00.251Z",
            updatedAt: "2021-02-17T19:59:00.251Z",
        }, 
        {
            id:"75442486-0878-440c-9db1-a7006c25a39f-027",

            primaryTagKey: "Blaster",
            relatedTagKey: "Points",
            symbol: TagModifierSymbol.Add,
            val: 2,
            description: "",
            priority: 1000,
            createdAt: "2021-02-17T19:59:00.251Z",
            updatedAt: "2021-02-17T19:59:00.251Z",
        }, 
        {
            id:"75442486-0878-440c-9db1-a7006c25a39f-028",
            primaryTagKey: "Blaze",
            relatedTagKey: "Points",
            symbol: TagModifierSymbol.Add,
            val: 2,
            description: "",
            priority: 1000,
            createdAt: "2021-02-17T19:59:00.251Z",
            updatedAt: "2021-02-17T19:59:00.251Z",
        },
        {
            id:"75442486-0878-440c-9db1-a7006c25a39f-029",
            primaryTagKey: "Booster",
            relatedTagKey: "Points",
            symbol: TagModifierSymbol.Add,
            val: 1,
            description: "",
            priority: 1000,
            createdAt: "2021-02-17T19:59:00.251Z",
            updatedAt: "2021-02-17T19:59:00.251Z",
        }, 
        {
            id:"75442486-0878-440c-9db1-a7006c25a39f-030",
            primaryTagKey: "Charge",
            relatedTagKey: "Points",
            symbol: TagModifierSymbol.Add,
            val: 2,
            description: "",
            priority: 1000,
            createdAt: "2021-02-17T19:59:00.251Z",
            updatedAt: "2021-02-17T19:59:00.251Z",
        }, 
        {
            id:"75442486-0878-440c-9db1-a7006c25a39f-031",

            primaryTagKey: "Counter Fire",
            relatedTagKey: "Points",
            symbol: TagModifierSymbol.Add,
            val: 2,
            description: "",
            priority: 1000,
            createdAt: "2021-02-17T19:59:00.251Z",
            updatedAt: "2021-02-17T19:59:00.251Z",
        }, 
        {
            id:"75442486-0878-440c-9db1-a7006c25a39f-032",
            primaryTagKey: "Crit Fire",
            relatedTagKey: "Points",
            symbol: TagModifierSymbol.Add,
            val: 2,
            description: "",
            priority: 1000,
            createdAt: "2021-02-17T19:59:00.251Z",
            updatedAt: "2021-02-17T19:59:00.251Z",
        }, 
        {
            id:"75442486-0878-440c-9db1-a7006c25a39f-033",
            primaryTagKey: "Crit Strike",
            relatedTagKey: "Points",
            symbol: TagModifierSymbol.Add,
            val: 1,
            description: "",
            priority: 1000,
            createdAt: "2021-02-17T19:59:00.251Z",
            updatedAt: "2021-02-17T19:59:00.251Z",
        }, 
        {
            id:"75442486-0878-440c-9db1-a7006c25a39f-034",
            primaryTagKey: "Defender",
            relatedTagKey: "Points",
            symbol: TagModifierSymbol.Add,
            val: 2,
            description: "",
            priority: 1000,
            createdAt: "2021-02-17T19:59:00.251Z",
            updatedAt: "2021-02-17T19:59:00.251Z",
        }, 
        {
            id:"75442486-0878-440c-9db1-a7006c25a39f-035",
            primaryTagKey: "Drag",
            relatedTagKey: "Points",
            symbol: TagModifierSymbol.Add,
            val: 2,
            description: "",
            priority: 1000,
            createdAt: "2021-02-17T19:59:00.251Z",
            updatedAt: "2021-02-17T19:59:00.251Z",
        }, 
        {
            id:"75442486-0878-440c-9db1-a7006c25a39f-036",
            primaryTagKey: "Engulf",
            relatedTagKey: "Points",
            symbol: TagModifierSymbol.Add,
            val: 2,
            description: "",
            priority: 1000,
            createdAt: "2021-02-17T19:59:00.251Z",
            updatedAt: "2021-02-17T19:59:00.251Z",
        }, 
        {
            id:"75442486-0878-440c-9db1-a7006c25a39f-037",
            primaryTagKey: "Explode",
            relatedTagKey: "Points",
            symbol: TagModifierSymbol.Add,
            val: 1,
            description: "",
            priority: 1000,
            createdAt: "2021-02-17T19:59:00.251Z",
            updatedAt: "2021-02-17T19:59:00.251Z",
        },

         {
            id:"75442486-0878-440c-9db1-a7006c25a39f-038",
            primaryTagKey: "Integration",
            relatedTagKey: "Points",
            symbol: TagModifierSymbol.Add,
            val: 2,
            description: "",
            priority: 1000,
            createdAt: "2021-02-17T19:59:00.251Z",
            updatedAt: "2021-02-17T19:59:00.251Z",
        }, 
        {
            id:"75442486-0878-440c-9db1-a7006c25a39f-039",
            primaryTagKey: "Jump",
            relatedTagKey: "Points",
            symbol: TagModifierSymbol.Add,
            val: 2,
            description: "",
            priority: 1000,
            createdAt: "2021-02-17T19:59:00.251Z",
            updatedAt: "2021-02-17T19:59:00.251Z",
        },         {
            id:"75442486-0878-440c-9db1-a7006c25a39f-040",
            primaryTagKey: "Methodical",
            relatedTagKey: "Points",
            symbol: TagModifierSymbol.Add,
            val: 2,
            description: "",
            priority: 1000,
            createdAt: "2021-02-17T19:59:00.251Z",
            updatedAt: "2021-02-17T19:59:00.251Z",
        }, 
        {
            id:"75442486-0878-440c-9db1-a7006c25a39f-041",
            primaryTagKey: "No Ranged",
            relatedTagKey: "Points",
            symbol: TagModifierSymbol.Add,
            val: -2,
            description: "",
            priority: 1000,
            createdAt: "2021-02-17T19:59:00.251Z",
            updatedAt: "2021-02-17T19:59:00.251Z",
        },         
        {
            id:"75442486-0878-440c-9db1-a7006c25a39f-042",
            primaryTagKey: "Onerous",
            relatedTagKey: "Points",
            symbol: TagModifierSymbol.Add,
            val: -1,
            description: "",
            priority: 1000,
            createdAt: "2021-02-17T19:59:00.251Z",
            updatedAt: "2021-02-17T19:59:00.251Z",
        }, 
        {
            id:"75442486-0878-440c-9db1-a7006c25a39f-043",
            primaryTagKey: "Overpower",
            relatedTagKey: "Points",
            symbol: TagModifierSymbol.Add,
            val: 2,
            description: "",
            priority: 1000,
            createdAt: "2021-02-17T19:59:00.251Z",
            updatedAt: "2021-02-17T19:59:00.251Z",
        },         {
            id:"75442486-0878-440c-9db1-a7006c25a39f-044",
            primaryTagKey: "Pinning",
            relatedTagKey: "Points",
            symbol: TagModifierSymbol.Add,
            val: 2,
            description: "",
            priority: 1000,
            createdAt: "2021-02-17T19:59:00.251Z",
            updatedAt: "2021-02-17T19:59:00.251Z",
        }, 
        {
            id:"75442486-0878-440c-9db1-a7006c25a39f-045",
            primaryTagKey: "Powerful",
            relatedTagKey: "Points",
            symbol: TagModifierSymbol.Add,
            val: 2,
            description: "",
            priority: 1000,
            createdAt: "2021-02-17T19:59:00.251Z",
            updatedAt: "2021-02-17T19:59:00.251Z",
        },         {
            id:"75442486-0878-440c-9db1-a7006c25a39f-046",
            primaryTagKey: "Push",
            relatedTagKey: "Points",
            symbol: TagModifierSymbol.Add,
            val: 2,
            description: "",
            priority: 1000,
            createdAt: "2021-02-17T19:59:00.251Z",
            updatedAt: "2021-02-17T19:59:00.251Z",
        },
        {
            id:"75442486-0878-440c-9db1-a7006c25a39f-047",
            primaryTagKey: "Ranged",
            relatedTagKey: "Points",
            symbol: TagModifierSymbol.Add,
            val: 2,
            description: "",
            priority: 1000,
            createdAt: "2021-02-17T19:59:00.251Z",
            updatedAt: "2021-02-17T19:59:00.251Z",
        },         
        {
            id:"75442486-0878-440c-9db1-a7006c25a39f-048",

            primaryTagKey: "Rapid Fire",
            relatedTagKey: "Points",
            symbol: TagModifierSymbol.Add,
            val: 6,
            description: "",
            priority: 1000,
            createdAt: "2021-02-17T19:59:00.251Z",
            updatedAt: "2021-02-17T19:59:00.251Z",
        }, 
        {
            id:"75442486-0878-440c-9db1-a7006c25a39f-049",
            primaryTagKey: "Regeneration",
            relatedTagKey: "Points",
            symbol: TagModifierSymbol.Add,
            val: 3,
            description: "",
            priority: 1000,
            createdAt: "2021-02-17T19:59:00.251Z",
            updatedAt: "2021-02-17T19:59:00.251Z",
        },         
        {
            id:"75442486-0878-440c-9db1-a7006c25a39f-050",
            primaryTagKey: "Short Ranged",
            relatedTagKey: "Points",
            symbol: TagModifierSymbol.Add,
            val: -1,
            description: "",
            priority: 1000,
            createdAt: "2021-02-17T19:59:00.251Z",
            updatedAt: "2021-02-17T19:59:00.251Z",
        }, 
        {
            id:"75442486-0878-440c-9db1-a7006c25a39f-051",
            primaryTagKey: "Slug",
            relatedTagKey: "Points",
            symbol: TagModifierSymbol.Add,
            val: 4,
            description: "",
            priority: 1000,
            createdAt: "2021-02-17T19:59:00.251Z",
            updatedAt: "2021-02-17T19:59:00.251Z",
        },         {
            id:"75442486-0878-440c-9db1-a7006c25a39f-052",
            primaryTagKey: "Smash",
            relatedTagKey: "Points",
            symbol: TagModifierSymbol.Add,
            val: 1,
            description: "",
            priority: 1000,
            createdAt: "2021-02-17T19:59:00.251Z",
            updatedAt: "2021-02-17T19:59:00.251Z",
        }, 
     ]
    },

    createdAt: "2021-02-17T19:59:00.251Z",
    updatedAt: "2021-02-17T19:59:00.251Z",
  },
  {
    id: 124,
    name: "Trait Condition Points",
    description: "Base Stats",
    createdAt: "2021-02-17T19:59:00.251Z",
    updatedAt: "2021-02-17T19:59:00.251Z",
    data: {
      tagModifiers: [
        {
            id:"75442486-0878-440c-9db1-a7006c25a39f-053",
          primaryTagKey: "Aim",
          relatedTagKey: "Points",
          symbol: TagModifierSymbol.AddArray,
          val: -1,
          description: "",
          priority: 1000,
          createdAt: "2021-02-17T19:59:00.251Z",
          updatedAt: "2021-02-17T19:59:00.251Z",
        },
        {
            id:"75442486-0878-440c-9db1-a7006c25a39f-054",
          primaryTagKey: "Group",
          relatedTagKey: "Points",
          symbol: TagModifierSymbol.AddArray,
          val: -1,
          description: "",
          priority: 1000,
          createdAt: "2021-02-17T19:59:00.251Z",
          updatedAt: "2021-02-17T19:59:00.251Z",
        },
        {
            id:"75442486-0878-440c-9db1-a7006c25a39f-055",
          primaryTagKey: "Aim",
          relatedTagKey: "Points",
          symbol: TagModifierSymbol.ValueTag,
          val: 1,
          description: "",
          priority: 1000,
          createdAt: "2021-02-17T19:59:00.251Z",
          updatedAt: "2021-02-17T19:59:00.251Z",
        },
        {
            id:"75442486-0878-440c-9db1-a7006c25a39f-056",
          primaryTagKey: "Group",
          relatedTagKey: "Points",
          symbol: TagModifierSymbol.ValueTag,
          val: 1,
          description: "",
          priority: 1000,
          createdAt: "2021-02-17T19:59:00.251Z",
          updatedAt: "2021-02-17T19:59:00.251Z",
        }

      ]
    },
  },
  {
    id: 125,
    name: "Gear Points",
    description: "Base Stats",
    createdAt: "2021-02-17T19:59:00.251Z",
    updatedAt: "2021-02-17T19:59:00.251Z",
    data: {
      tagModifiers: [
        {
            id:"75442486-0878-440c-9db1-a7006c25a39f-057",
          primaryTagKey: "Blind Grenades",
          relatedTagKey: "Points",
          symbol: TagModifierSymbol.Add,
          val: 1,
          description: "",
          priority: 1000,
          createdAt: "2021-02-17T19:59:00.251Z",
          updatedAt: "2021-02-17T19:59:00.251Z",
        },
        {
            id:"75442486-0878-440c-9db1-a7006c25a39f-058",
          primaryTagKey: "Blinding Flash",
          relatedTagKey: "Points",
          symbol: TagModifierSymbol.Add,
          val: 1,
          description: "",
          priority: 1000,
          createdAt: "2021-02-17T19:59:00.251Z",
          updatedAt: "2021-02-17T19:59:00.251Z",
        },
        {
            id:"75442486-0878-440c-9db1-a7006c25a39f-059",
          primaryTagKey: "Charged Bolt",
          relatedTagKey: "Points",
          symbol: TagModifierSymbol.Add,
          val: 1,
          description: "",
          priority: 1000,
          createdAt: "2021-02-17T19:59:00.251Z",
          updatedAt: "2021-02-17T19:59:00.251Z",
        },
        {
            id:"75442486-0878-440c-9db1-a7006c25a39f-060",
          primaryTagKey: "Grenades",
          relatedTagKey: "Points",
          symbol: TagModifierSymbol.Add,
          val: 1,
          description: "",
          priority: 1000,
          createdAt: "2021-02-17T19:59:00.251Z",
          updatedAt: "2021-02-17T19:59:00.251Z",
        },
        {
            id:"75442486-0878-440c-9db1-a7006c25a39f-061",
          primaryTagKey: "Mutation",
          relatedTagKey: "Points",
          symbol: TagModifierSymbol.Add,
          val: 1,
          description: "",
          priority: 1000,
          createdAt: "2021-02-17T19:59:00.251Z",
          updatedAt: "2021-02-17T19:59:00.251Z",
        },
        {
            id:"75442486-0878-440c-9db1-a7006c25a39f-062",
          primaryTagKey: "Pulse Charge",
          relatedTagKey: "Points",
          symbol: TagModifierSymbol.Add,
          val: 1,
          description: "",
          priority: 1000,
          createdAt: "2021-02-17T19:59:00.251Z",
          updatedAt: "2021-02-17T19:59:00.251Z",
        },
        {
            id:"75442486-0878-440c-9db1-a7006c25a39f-063",
          primaryTagKey: "Secondary Grenade Launcher",
          relatedTagKey: "Points",
          symbol: TagModifierSymbol.Add,
          val: 2,
          description: "",
          priority: 1000,
          createdAt: "2021-02-17T19:59:00.251Z",
          updatedAt: "2021-02-17T19:59:00.251Z",
        },
      ]
    },
  },
  {
    id: 126,
    name: "Traits",
    description: "Base Stats",
    createdAt: "2021-02-17T19:59:00.251Z",
    updatedAt: "2021-02-17T19:59:00.251Z",
    data: {
      tagModifiers: [
        {
            id:"75442486-0878-440c-9db1-a7006c25a39f-064",
          primaryTagKey: "Trained",
          relatedTagKey: "Points",
          symbol: TagModifierSymbol.Add,
          val: 2,
          description: "",
          priority: 1000,
          createdAt: "2021-02-17T19:59:00.251Z",
          updatedAt: "2021-02-17T19:59:00.251Z",
        },
      ]
    }
  },
  {
    id: 126,
    name: "Threshold Points",
    description: "Threshold Base Stats",
    createdAt: "2021-02-17T19:59:00.251Z",
    updatedAt: "2021-02-17T19:59:00.251Z",
    data: {
      tagModifiers: [
        {
          id:"75442486-0878-440c-9db1-a7006c25a39f-065",
          primaryTagKey: "Tough",
          relatedTagKey: "Points",
          symbol: TagModifierSymbol.Add,
          val: 1,
          description: "",
          priority: 1000,
          thresholds: [
            {
              thresholdKey: "Points",
              threshold: 17,
              tagThresholdSymbol: TagThresholdSymbolType.GreaterOrEqual
            }
          ],
          createdAt: "2021-02-17T19:59:00.251Z",
          updatedAt: "2021-02-17T19:59:00.251Z",
        },
        {
          id:"75442486-0878-440c-9db1-a7006c25a39f-066",
          primaryTagKey: "Dodge",
          relatedTagKey: "Points",
          symbol: TagModifierSymbol.Add,
          val: 1,
          description: "",
          priority: 1000,
          thresholds: [
            {
              thresholdKey: "Points",
              threshold: 17,
              tagThresholdSymbol: TagThresholdSymbolType.GreaterOrEqual
            }
          ],
          createdAt: "2021-02-17T19:59:00.251Z",
          updatedAt: "2021-02-17T19:59:00.251Z",
        },
        {
          id:"75442486-0878-440c-9db1-a7006c25a39f-067",
          primaryTagKey: "Blaze",
          relatedTagKey: "Points",
          symbol: TagModifierSymbol.Add,
          val: 1,
          description: "If Blaze does not have short range, it's worth more",
          priority: 1000,
          thresholds: [
            {
              thresholdKey: "Short Ranged",
              thresholdTriggerBelow: true,
              tagThresholdSymbol: TagThresholdSymbolType.DoesNotExist
            }
          ],
          createdAt: "2021-02-17T19:59:00.251Z",
          updatedAt: "2021-02-17T19:59:00.251Z",
        },
        {
          id:"75442486-0878-440c-9db1-a7006c25a39f-068",
          primaryTagKey: "Points",
          relatedTagKey: "Points",
          symbol: TagModifierSymbol.Add,
          val: 1,
          description: "When a model is low points, with significant life, increase points",
          priority: 1000,
          thresholds: [
            {
              thresholdKey: "Points",
              threshold: 9,
              thresholdTriggerBelow: true,
              tagThresholdSymbol: TagThresholdSymbolType.LessOrEqual
            },
            {
              thresholdKey: "Life",
              threshold: 3,
              tagThresholdSymbol: TagThresholdSymbolType.GreaterOrEqual
            }
          ],
          createdAt: "2021-02-17T19:59:00.251Z",
          updatedAt: "2021-02-17T19:59:00.251Z",
        },
        {
          id:"75442486-0878-440c-9db1-a7006c25a39f-069",
          primaryTagKey: "Points",
          relatedTagKey: "Points",
          symbol: TagModifierSymbol.Add,
          val: 1,
          description: "When a model has a ton of buffs for Shooting",
          priority: 1000,
          thresholds: [
            {
              thresholdKey: "Slug",
              tagThresholdSymbol: TagThresholdSymbolType.Exists
            },
            {
              thresholdKey: "Crit Fire",
              tagThresholdSymbol: TagThresholdSymbolType.Exists
            },
            {
              thresholdKey: "Shoot",
              threshold: 1,
              tagThresholdSymbol: TagThresholdSymbolType.GreaterOrEqual
            },
          ],
          createdAt: "2021-02-17T19:59:00.251Z",
          updatedAt: "2021-02-17T19:59:00.251Z",
        },
      ]
    }
  },
  {
    id: 126,
    name: "Negative Points",
    description: "Threshold Base Stats",
    createdAt: "2021-02-17T19:59:00.251Z",
    updatedAt: "2021-02-17T19:59:00.251Z",
    data: {
      tagModifiers: [
        {
          id:"75442486-0878-440c-9db1-a7006c25a39f-070",
          primaryTagKey: "Fragile",
          relatedTagKey: "Negative",
          symbol: TagModifierSymbol.Add,
          val: 2,
          description: "",
          priority: 1000,
          createdAt: "2021-02-17T19:59:00.251Z",
          updatedAt: "2021-02-17T19:59:00.251Z",
        },
        {
          id:"75442486-0878-440c-9db1-a7006c25a39f",
          primaryTagKey: "Slow",
          relatedTagKey: "Negative",
          symbol: TagModifierSymbol.Add,
          val: 2,
          description: "",
          priority: 1000,
          createdAt: "2021-02-17T19:59:00.251Z",
          updatedAt: "2021-02-17T19:59:00.251Z",
        },
        {
          id:"75442486-0878-440c-9db1-a7006c25a39f-071",
          primaryTagKey: "Weak",
          relatedTagKey: "Negative",
          symbol: TagModifierSymbol.Add,
          val: 2,
          description: "",
          priority: 1000,
          createdAt: "2021-02-17T19:59:00.251Z",
          updatedAt: "2021-02-17T19:59:00.251Z",
        },
        {
          id:"75442486-0878-440c-9db1-a7006c25a39f-072",
          primaryTagKey: "No Ranged",
          relatedTagKey: "Negative",
          symbol: TagModifierSymbol.Add,
          val: 2,
          description: "",
          priority: 1000,
          createdAt: "2021-02-17T19:59:00.251Z",
          updatedAt: "2021-02-17T19:59:00.251Z",
        }, 
        {
          id:"75442486-0878-440c-9db1-a7006c25a39f-073",
          primaryTagKey: "Onerous",
          relatedTagKey: "Negative",
          symbol: TagModifierSymbol.Add,
          val: 1,
          description: "",
          priority: 1000,
          createdAt: "2021-02-17T19:59:00.251Z",
          updatedAt: "2021-02-17T19:59:00.251Z",
        },
        {
          id:"75442486-0878-440c-9db1-a7006c25a39f-074",
          primaryTagKey: "Short Ranged",
          relatedTagKey: "Negative",
          symbol: TagModifierSymbol.Add,
          val: 1,
          description: "",
          priority: 1000,
          createdAt: "2021-02-17T19:59:00.251Z",
          updatedAt: "2021-02-17T19:59:00.251Z",
        },
        {
          id:"75442486-0878-440c-9db1-a7006c25a39f-075",
          primaryTagKey: "Aim",
          relatedTagKey: "Negative",
          symbol: TagModifierSymbol.AddArray,
          val: 1,
          description: "",
          priority: 1000,
          createdAt: "2021-02-17T19:59:00.251Z",
          updatedAt: "2021-02-17T19:59:00.251Z",
        },
        {
          id:"75442486-0878-440c-9db1-a7006c25a39f-076",
          primaryTagKey: "Group",
          relatedTagKey: "Negative",
          symbol: TagModifierSymbol.AddArray,
          val: 1,
          description: "",
          priority: 1000,
          createdAt: "2021-02-17T19:59:00.251Z",
          updatedAt: "2021-02-17T19:59:00.251Z",
        },
        {
          id:"75442486-0878-440c-9db1-a7006c25a39f-077",
          primaryTagKey: "Negative",
          relatedTagKey: "Points",
          symbol: TagModifierSymbol.Add,
          val: 1,
          val2: 0,
          thresholds: [
            {
              thresholdKey: "Negative",
              threshold: 7,
              tagThresholdSymbol: TagThresholdSymbolType.GreaterOrEqual
            }
          ],
          description: "When there are too many negatives, increase points",
          priority: 1000,
          createdAt: "2021-02-17T19:59:00.251Z",
          updatedAt: "2021-02-17T19:59:00.251Z",
        },
      ]
    }
  }
];

export const exampleTagModifiersGroupMock: TagModifiersGroupType = {
  id: -1,
  name:"Tag Modifiers Mock",
  description: "Base Stats",
  createdAt: "2021-02-17T19:59:00.251Z",
  updatedAt: "2021-02-17T19:59:00.251Z",
  data: {
    tagModifiers: exampleTagModifiersMock2
  }
}

export const exampleBaseTagsMock: Array<TagType> = [

    {   
      key: "Blaze",
      description: " If you lose when making a Shoot attack, the target loses 1 life if you rolled a 4+.", 
      createdAt: "2021-02-17T19:59:00.251Z",
      updatedAt: "2021-02-17T19:59:00.251Z",
    },
    {   
      key: "Relentless",
      title: "Relentless",
      description: "When losing a fight attack, the enemy loses 1 life if you rolled a 4+.", 
      createdAt: "2021-02-17T19:59:00.251Z",
      updatedAt: "2021-02-17T19:59:00.251Z",
    },
    {   
      key: "Booster",
      description: "Roll a d6 at start of activation. On a 3+, move up to 1”.", 
      createdAt: "2021-02-17T19:59:00.251Z",
      updatedAt: "2021-02-17T19:59:00.251Z",
    },
    {   
      key: "Charge",
      description: "If you started your activation not in base contact, get +2 fight on combats this activation.", 
      createdAt: "2021-02-17T19:59:00.251Z",
      updatedAt: "2021-02-17T19:59:00.251Z",
    },
    {   
      key: "Counterfire",
      description: "If a unit moves into base contact, make a shoot attack at +0 at that unit.", 
      createdAt: "2021-02-17T19:59:00.251Z",
      updatedAt: "2021-02-17T19:59:00.251Z",
    },

    {   
      key: "Dodge",
      description: "Get +2 fight when suffering a shoot attack.", 
      createdAt: "2021-02-17T19:59:00.251Z",
      updatedAt: "2021-02-17T19:59:00.251Z",
    },
    {   
      key: "Eagle Eye",
      title: "Eagle Eye",
      description: "Get +1 shoot.", 
      createdAt: "2021-02-17T19:59:00.251Z",
      updatedAt: "2021-02-17T19:59:00.251Z",
    },
    {   
      key: "Fast",
      description: "Get +2 Move.", 
      createdAt: "2021-02-17T19:59:00.251Z",
      updatedAt: "2021-02-17T19:59:00.251Z",
    },
    {   
      key: "Fighter",
      description: "Get +1 fight in combat attacks.", 
      createdAt: "2021-02-17T19:59:00.251Z",
      updatedAt: "2021-02-17T19:59:00.251Z",
    },
    {   
      key: "Flank",
      description: "If this unit and a friendly unit are in base contact with the same unit, get +1 fight in combat. Does not stack with other units with flank.",
      createdAt: "2021-02-17T19:59:00.251Z",
      updatedAt: "2021-02-17T19:59:00.251Z",
    },

    {   
      key: "Jumper",
      description: "When activating, roll a d6. On a 3+, ignore terrain up to 4” high and also units when moving. (e.g. You can move away from enemies as if they weren’t there).",
      createdAt: "2021-02-17T19:59:00.251Z",
      updatedAt: "2021-02-17T19:59:00.251Z",
    },
    {   
      key: "Methodical",
      description: "Add 1 to your result if you roll a 1 during your activation. It still counts as rolling a 1.", 
      createdAt: "2021-02-17T19:59:00.251Z",
      updatedAt: "2021-02-17T19:59:00.251Z",
    },
    {   
      key: "Protected",
      description: "You can choose to have the first attack against you each turn to instead target a friendly unit in base contact by swapping places with it.", 
      createdAt: "2021-02-17T19:59:00.251Z",
      updatedAt: "2021-02-17T19:59:00.251Z",
    },
    {   
      key: "Push",
      description: "May choose to get +2 to fight during combat, but do no damage if you win.", 
      createdAt: "2021-02-17T19:59:00.251Z",
      updatedAt: "2021-02-17T19:59:00.251Z",
    },
    {   
      key: "Ranged",
      description: "Shoot unlimited distance.", 
      createdAt: "2021-02-17T19:59:00.251Z",
      updatedAt: "2021-02-17T19:59:00.251Z",
    },

    {   
      key: "Shielding",
      description: "You can forfeit your attack to shield a unit. If that unit is attacked this turn while within 1”, switch places with that unit and this unit is attacked instead.", 
      createdAt: "2021-02-17T19:59:00.251Z",
      updatedAt: "2021-02-17T19:59:00.251Z",
    },
    {   
      key: "Skilled",
      title: "Skilled",
      description: "+1 fight in combat", 
      createdAt: "2021-02-17T19:59:00.251Z",
      updatedAt: "2021-02-17T19:59:00.251Z",
    },
    {   
      key: "Powerful",
      description: "On winning a combat, enemies lose 1 more life if you rolled a 4+.", 
      createdAt: "2021-02-17T19:59:00.251Z",
      updatedAt: "2021-02-17T19:59:00.251Z",
    },
    {   
      key: "Sunder",
      description: "Forfeit move and attack to make a combat attack with +1 fight. If you succeed and rolled a 4+, the enemy loses 1 life.", 
      createdAt: "2021-02-17T19:59:00.251Z",
      updatedAt: "2021-02-17T19:59:00.251Z",
    },
    {   
      key: "Tough",
      description: "+1 life", 
      createdAt: "2021-02-17T19:59:00.251Z",
      updatedAt: "2021-02-17T19:59:00.251Z",
    },
    {   
      key: "Warn",
      description: "Forfeit attack to choose an enemy within 6” that you can see. On a 3+, your units get +1 fight vs their next attack", 
      createdAt: "2021-02-17T19:59:00.251Z",
      updatedAt: "2021-02-17T19:59:00.251Z",
    },

    {   
      key: "Shadow",
      description: "At the start of the game, place a unit within 3” with Very-Fragile and No-Ranged.", 
      createdAt: "2021-02-17T19:59:00.251Z",
      updatedAt: "2021-02-17T19:59:00.251Z",
    },
    {
      key: "Overpower",
      description: "This unit wins all ties, unless the opponent would also win the tie.", 
      createdAt: "2021-02-17T19:59:00.251Z",
      updatedAt: "2021-02-17T19:59:00.251Z",
    },
    {
      key: "Blaster",
      description: "Forfeit attack and move, to make a ranged attack. All units within 1” of the target take a ranged attack as well at shoot 0.", 
      createdAt: "2021-02-17T19:59:00.251Z",
      updatedAt: "2021-02-17T19:59:00.251Z",
    },
    {
      key: "Crit Fire",
      title: "Crit Fire",
      description: "If rolled a 6 for a shoot attack, the enemy loses 1 life.", 
      createdAt: "2021-02-17T19:59:00.251Z",
      updatedAt: "2021-02-17T19:59:00.251Z",
    },
    {
      key: "Move",
      description: "The distance a unit can move, in inches.", 
      createdAt: "2021-02-17T19:59:00.251Z",
      updatedAt: "2021-02-17T19:59:00.251Z",
    },
    {
      key: "Fight",
      description: "The skill a unit has in defending or attacking in base contact", 
      createdAt: "2021-02-17T19:59:00.251Z",
      updatedAt: "2021-02-17T19:59:00.251Z",
    },
    {
      key: "Shoot",
      description: "The skill a unit has in attacking a unit from range", 
      createdAt: "2021-02-17T19:59:00.251Z",
      updatedAt: "2021-02-17T19:59:00.251Z",
    },
    {
      key: "Life",
      description: "The toughness a unit is. If they lose all their life, they are defeated.", 
      createdAt: "2021-02-17T19:59:00.251Z",
      updatedAt: "2021-02-17T19:59:00.251Z",
    },
    {
      key: "Points",
      description: "The Value of the unit.", 
      createdAt: "2021-02-17T19:59:00.251Z",
      updatedAt: "2021-02-17T19:59:00.251Z",
    },
    {
      key: "Fragile",
      description: "Start with -1 life", 
      createdAt: "2021-02-17T19:59:00.251Z",
      updatedAt: "2021-02-17T19:59:00.251Z",
    },
    {
      key: "No Ranged",
      description: "Can’t Shoot", 
      createdAt: "2021-02-17T19:59:00.251Z",
      updatedAt: "2021-02-17T19:59:00.251Z",
    },
    {
      key: "Slow",
      description: "-2” Move. Can’t move more than 2” and shoot in the same activation", 
      createdAt: "2021-02-17T19:59:00.251Z",
      updatedAt: "2021-02-17T19:59:00.251Z",
    },
    {
      key: "Weak",
      description: "-1 fight", 
      createdAt: "2021-02-17T19:59:00.251Z",
      updatedAt: "2021-02-17T19:59:00.251Z",
    },
    {
      key: "Very-fragile",
      description: "-2 life", 
      createdAt: "2021-02-17T19:59:00.251Z",
      updatedAt: "2021-02-17T19:59:00.251Z",
    },
    {
      key: "Mook",
      description: "Get -2 life, -1 fight, -2” move, and if they move more than 2” they can’t shoot in the same activation.", 
      createdAt: "2021-02-17T19:59:00.251Z",
      updatedAt: "2021-02-17T19:59:00.251Z",
    },
    {
      key: "Tenacious",
      description: " If an attack caused the unit to lose its last life, roll a d6. On a 5 or 6, gain 1 life back, and gain Weak for the rest of the scenario.",
      createdAt: "2021-02-17T19:59:00.251Z",
      updatedAt: "2021-02-17T19:59:00.251Z",
    },
    {
      key: "Stoic",
      description: "When this unit rolls an even number (2, 4 or 6) in combat, you can choose to cancel all movement resulting from the combat to push an enemy unit in base contact 1”.",
      createdAt: "2021-02-17T19:59:00.251Z",
      updatedAt: "2021-02-17T19:59:00.251Z",
    },
    {
      key: "Cloaked",
      description: "This unit can not be seen during the 1st round. If this unit rolled a 1 when attacked by a shoot attack, it can add +1 fight.",
      createdAt: "2021-02-17T19:59:00.251Z",
      updatedAt: "2021-02-17T19:59:00.251Z",
    },
    {
      key: "Overcharge",
      description: "This unit can spend its command token before a combat to gain +1 fight for the roll, and it can reroll the dice if it would lose the roll.",
      createdAt: "2021-02-17T19:59:00.251Z",
      updatedAt: "2021-02-17T19:59:00.251Z",
    },
    {
      key: "Fleet",
      description: "After deployment before the start of the first round, move this unit 4”.  If an attack causes this unit to move, it may move an additional 1”.",
      createdAt: "2021-02-17T19:59:00.251Z",
      updatedAt: "2021-02-17T19:59:00.251Z",
    },
    {
      key: "Prepared",
      description: "At the Start of the game get 1 prepared token. If a prepared unit fails a roll during its activation and you have prepared tokens, discard a prepared token reroll that roll.",
      createdAt: "2021-02-17T19:59:00.251Z",
      updatedAt: "2021-02-17T19:59:00.251Z",
    },
    {
      key: "Standing",
      description: "The Standing this unit has in its cast.",
      createdAt: "2021-02-17T19:59:00.251Z",
      updatedAt: "2021-02-17T19:59:00.251Z",
      valAreTagKeys: true,
    },
    {
      key: "Aim",
      description: "Forfeit move to gain trait ‘X’ this round.",
      createdAt: "2021-02-17T19:59:00.251Z",
      updatedAt: "2021-02-17T19:59:00.251Z",
      valAreTagKeys: true,
    },
    {
      key: "Group",
      description: "If this unit has over ½ max life, it is made up of 2 models and gains the trait ‘X’. It loses this trait if under ½ max life.",
      createdAt: "2021-02-17T19:59:00.251Z",
      updatedAt: "2021-02-17T19:59:00.251Z",
      valAreTagKeys: true,
    },
    {
      key: "Tactic:RedoubleFire",
      description: "If you haven’t moved your Focal Point this round and you did not win an attack against a target within 4” of your Focal Point, the attacker can make a free attack against that target.",
      createdAt: "2021-02-17T19:59:00.251Z",
      updatedAt: "2021-02-17T19:59:00.251Z",
    },
    {
      key: "Tactic:DoubleAttack",
      description: "If one of your units succeeds on an attack, roll a d6. On a 3+, they may make a free attack.",
      createdAt: "2021-02-17T19:59:00.251Z",
      updatedAt: "2021-02-17T19:59:00.251Z",
    },
    {
      key: "Tactic:BeyondDeath",
      description: "If your unit was defeated, roll a d6. On a 3+, make 1 attack before being defeated.",
      createdAt: "2021-02-17T19:59:00.251Z",
      updatedAt: "2021-02-17T19:59:00.251Z",
    },
    {
      key: "Tactic:Stalker",
      description: "Secretly Deployed. During deployment, secretly choose a unit. If it is attacked during the first round, get +2 Evade. The first time it attacks, it gets +2 Shoot and +2 Fight for that activation.",
      createdAt: "2021-02-17T19:59:00.251Z",
      updatedAt: "2021-02-17T19:59:00.251Z",
    },
    {
      key: "Rapid Fire",
      title: "Rapid Fire",
      description: "After making a shoot attack, make a free shoot attack.",
      createdAt: "2021-02-17T19:59:00.251Z",
      updatedAt: "2021-02-17T19:59:00.251Z",
    },
    {
      key: "Evade",
      description: "The stat to use when evading shoot attacks",
      createdAt: "2021-02-17T19:59:00.251Z",
      updatedAt: "2021-02-17T19:59:00.251Z",
    },
    {
      key: "leader",
      title: "Leader",
      description: "The Leader of your Band",
      createdAt: "2021-02-17T19:59:00.251Z",
      updatedAt: "2021-02-17T19:59:00.251Z",
    },
    {
      key: "veteran",
      title: "Veteran",
      description: "The Veterans of your Band",
      createdAt: "2021-02-17T19:59:00.251Z",
      updatedAt: "2021-02-17T19:59:00.251Z",
    }, 
    {
      key: "rankAndFile",
      title: "Rank & File",
      description: "The Rank & File members of your Band",
      createdAt: "2021-02-17T19:59:00.251Z",
      updatedAt: "2021-02-17T19:59:00.251Z",
    },  
    {
      key: "Note",
      title: "Note",
      description: "Extra Notes.",
      createdAt: "2021-02-17T19:59:00.251Z",
      updatedAt: "2021-02-17T19:59:00.251Z",
    },
    {
      key: "Note:AlterativeStandingVeteran",
      description: "You may also take this unit as a Veteran.",
      createdAt: "2021-02-17T19:59:00.251Z",
      updatedAt: "2021-02-17T19:59:00.251Z",
    },
]

export const exampleBaseTagsGroupMock: BaseTagsGroupType = {
  id: '-1',
  description: "Base Stats",
  createdAt: "2021-02-17T19:59:00.251Z",
  updatedAt: "2021-02-17T19:59:00.251Z",
  data: {
    tags: exampleBaseTagsMock
  },
  tags: exampleBaseTagsMock
}