import { useCallback } from "react";
import Layout from "Layout";
import { Button } from "components/Buttons/Button";
import { TextField, TextFieldType } from "components/Forms/TextField";
import styles from "./styles.module.scss";
import { FormType } from "components/Forms/Form";
import { useArchetypeDisplayForm } from "pages/ArchetypeDisplays/useArchetypeDisplayForm";
import { Form } from 'components/Forms/Form';
import JSONEditor from "components/JsonEditor";
import { useNavigate } from "react-router-dom";
import {  ArchetypeDisplayType } from 'actions/cast.types';
import { routeLinks } from "App/routing";

export const NewArchetypeDisplay = () => {
  const navigate = useNavigate();

  const submitCallback = useCallback((archetypeDisplay?: ArchetypeDisplayType) => {
    navigate(`/${routeLinks.archetypeDisplays}/${archetypeDisplay?.id}`);
  }, [navigate]);

  const {
    formSections,
    formGroups,
    errors,
    onSubmit,
    register,
    submitting,
    submitError,
    setValue
  } = useArchetypeDisplayForm({ formType: FormType.Create, callback: submitCallback });

  return (
    <Layout>
      <div >
        <h1>
          New Archetype Displays
        </h1>
        <div>
          <Form
            onSubmit={onSubmit}
          >
            <Button type="submit">Save</Button>
            {!submitting && submitError && (<div>{submitError}</div>)} 
            <TextField 
              type={TextFieldType.text}
              placeholder="key"
              label="Name"
              labelClassName={styles.label}
              className={styles.entry}
              error={errors?.name}
              {...register("name",{
                validate:{
                  validLength: (val?: string) => {
                    return (val && val?.length > 0) || "Field Must not be empty.";
                  }
                }
              })}
            />
            <TextField 
              type={TextFieldType.text}
              placeholder="Description"
              label="Description"
              labelClassName={styles.label}
              className={styles.entry}
              error={errors?.description}
              {...register("description",{
                validate:{
                  validLength: (val?: string) => {
                    return (val && val?.length > 0) || "Field Must not be empty.";
                  }
                }
              })}
            />
            <TextField 
              type={TextFieldType.number}
              labelClassName={styles.label}
              className={styles.entry}
              placeholder="Display"
              label="Display"
              error={errors?.display}
              {...register("display",{
              })}
            />
            <TextField
              type={TextFieldType.textArea}
              label="Sections" 
              fieldClassName={styles.dataText}
              labelClassName={styles.label}
              className={styles.hidden}
              error={errors?.sections}
              {...register("sections",{
                validate:{
                  validLength: (val?: string) => {
                    return val && val?.length > 0;
                  }
                }
              })}
            />
            <JSONEditor
              value={formSections}
              className={styles.dataBlock}
              label="Sections"
              labelClassName={styles.label}
              onChange={(changed, oldChanged) => {
                if(changed !== oldChanged && (typeof changed === "string" || typeof changed === 'undefined')) {
                  setValue('sections', changed || '');
                }
              }}
            />
            <TextField
              type={TextFieldType.textArea}
              label="Groups" 
              fieldClassName={styles.dataText}
              labelClassName={styles.label}
              className={styles.hidden}
              error={errors?.groups}
              {...register("groups",{
                validate:{
                  validLength: (val?: string) => {
                    return val && val?.length > 0;
                  }
                }
              })}
            />
            <JSONEditor
              value={formGroups}
              className={styles.dataBlock}
              label="Groups"
              labelClassName={styles.label}
              onChange={(changed, oldChanged) => {
                if(changed !== oldChanged && (typeof changed === "string" || typeof changed === 'undefined')) {
                  setValue('groups', changed || '');
                }
              }}
            />
          </Form>
        </div>
      </div>
    </Layout>
  );
}
