import * as base from "./lib/baseActions";
import { apiEndpoints } from "lib/config";
import useSWR from 'swr';
import { mutate } from 'swr';
import { WorldId } from 'lib/config';
import { CastType } from 'actions/cast.types';
import axios from "axios";
import { cleanCast } from "./helpers/castHelpers";
export type { TagType } from "actions/types";

const localStorageKeys = {
  cast: "cast",
}

export const getLocalCasts = async (worldId?: number | string, archetypeId?: number) : Promise<Array<CastType>> => {
  try { 
    const casts = await Promise.all([...Array(3)].map(async (_, idx) => {
      try {
        const c = await getLocalCast(idx+1, worldId);
        return cleanCast(c);
      } catch(err) {
        return { id: idx+1, name: "None"};
      }
    })); 
    
    return casts?.filter((c) => { 
      return c.id && (!archetypeId || c.archetypeId === archetypeId); 
    }).map((c:CastType) => {
      return cleanCast(c);
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      err.message = "Failed to get a casts.";
    }
    throw err;
  }
};

export const getLocalCast = async (castId :  number | string, worldId?: number | string) : Promise<CastType> => {
  try {  
    const castString = localStorage.getItem(`${worldId ? `${apiEndpoints.worlds}/${worldId}/` : ``}${localStorageKeys.cast}/${castId}`) || "";
    if(!castString) {
      throw new Error("Could Not find Local Cast");
    }

    let cast = JSON.parse(castString);
    return cleanCast(cast);
  } catch (err) {
    if (axios.isAxiosError(err)) {
      err.message = "Failed to get a casts.";
    }
    throw err;
  }
};

export const createLocalCast = async (cast: CastType, castId?:  number | string, worldId?: number | string) : Promise<CastType> => {
  try {
    const saveCast = { ...cast, id: castId || 1 };
    localStorage.setItem(`${worldId ? `${apiEndpoints.worlds}/${worldId}/` : ``}${localStorageKeys.cast}/${castId}`, JSON.stringify(saveCast));

    return cleanCast(cast);
  } catch (err) {
    if (axios.isAxiosError(err)) {
      err.message = "Failed to get a tag.";
    }
    throw err;
  }
};

export const updateLocalCast = async (cast: CastType, castId?:  number | string, worldId?: number | string) : Promise<CastType> => {
  return createLocalCast(cast, castId, worldId);
};

export const deleteCast = async (userId: number | string, castId : number) : Promise<CastType> => {
  try {
    const result = await base.del(`${apiEndpoints.users}/${userId}/${apiEndpoints.casts}/${castId}`);
    return cleanCast(result.data);
  } catch (err) {
    if (axios.isAxiosError(err)) {
      err.message = "Failed to delete a tag.";
    }
    throw err;
  }
};

export const deleteLocalCast = async (castId:  number | string, worldId?: number | string) : Promise<CastType> => {
  try {
    const localCast = await getLocalCast(castId, worldId);
    localStorage.removeItem(`${worldId ? `${apiEndpoints.worlds}/${worldId}/` : ``}${localStorageKeys.cast}/${castId}`);
    return localCast;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      err.message = "Failed to delete a tag.";
    }
    throw err;
  }
};

interface useCreateCastResultType {
  (cast: CastType,  worldId?: number | string, castId?: number | string):  Promise<CastType> 
} 

export const useCreateLocalCast = () : useCreateCastResultType => {
  return async (cast: CastType, worldId?: number | string, castId?: number | string) => {
    worldId = worldId || WorldId;
    let result;
    
    result = await createLocalCast(cleanCast(cast), castId, worldId);

    const newCastId = result.id;
    mutate(`${worldId ? `${apiEndpoints.worlds}/${worldId}/` : ``}/users/guest/casts/${newCastId}`, result); 
    return result;
  }
}

interface useUpdateCastResultType {
  (castId: number, cast: CastType, worldId?: number | string):  Promise<CastType> 
} 

export const useUpdateLocalCast = () : useUpdateCastResultType => {
  return async (castId: number, cast: CastType, worldId?: number | string) => {
    worldId = worldId || WorldId;
    let result;

    result = await updateLocalCast(cleanCast(cast), castId, worldId);
    mutate(`${worldId ? `${apiEndpoints.worlds}/${worldId}/` : ``}/users/guest/casts/${castId}`, result);
    return result;
  }
}


interface useDeleteCastResultType {
  ( castId: number):  Promise<CastType> 
} 

export const useDeleteLocalCast = () : useDeleteCastResultType => {
  return async (castId: number,  worldId?: number | string) => {
    worldId = worldId || WorldId;
    let result;
    // no userId, use guest
    result = await deleteLocalCast(castId, worldId);
    mutate(`${worldId ? `${apiEndpoints.worlds}/${worldId}/` : ``}/users/guest/casts/${castId}`, result);
    return result;
  }
}

type useGetLocalCastsSWRType = [
  string, number | string | undefined, number | undefined
] 

export const useGetLocalCasts = (worldId?: number | string, archetypeId?: number) => {
  worldId = worldId || WorldId;
  return useSWR<CastType[]>(
    [
      `${worldId ? `${apiEndpoints.worlds}/${worldId}/` : ``}users/guest/casts`,
      worldId,
      archetypeId
    ],
    ([key, worldId]: useGetLocalCastsSWRType) => { 
      return getLocalCasts(worldId, archetypeId);
    }
  );
};

type useGetLocalCastSWRType = [
  string, string | number, number | undefined
] 


export const useGetLocalCast = ( castId?: string | number, config?: any, worldId?: number | string) => {
  worldId = worldId || WorldId;
  return useSWR<CastType>(
    [
      `${worldId ? `${apiEndpoints.worlds}/${worldId}/` : ``}users/guest/casts/${castId}`,
      castId,
      worldId
    ],
    ([key, castId, worldId]: useGetLocalCastSWRType) => {
      return getLocalCast(castId, worldId);
    },
    config
  );
};
