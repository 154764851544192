// The base API url for taxplan.api
export const baseAPIUrl = process.env.REACT_APP_API_URL;
export const googleOAuthClientId = process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID;

export const worldId = 1;
export const WorldId = 1;

export const defaultKey = 'default';

// Jwt specific data 
export const jwtData = { 
  refreshUrl: 'refreshAuth',
  authToken: 'authToken',
  refreshToken: 'refreshToken',
  authTokenExpiry: 'authTokenExpiry',
  refreshTokenExpiry: 'refreshTokenExpiry',
  expiryOffset: 60*1000, // 60 seconds
};

export enum UserRoles {
  admin = 'admin',
  user = 'user',
  anon = 'anon',
  guest = 'guest',
  tester = "tester"
};

export const DisplayKeys = {
  public: 0,
  private: 1, // Only creator has access,
  admin: 2, // Only admins can view,
  unlisted: 3, // anyone can view directly, but only admins can list.
  playtest: 4, // playtesters can view, but only admins can edit.
  renown: 5, // public types editable only by admins
};

export const helpEmail = process.env.REACT_APP_HELP_EMAIL;
export const contactEmail = process.env.REACT_APP_CONTACT_EMAIL;

export const apiEndpoints = {
  login: 'login',
  users: 'users',
  usersMe: 'users/me',
  tags: 'tags',
  tagsGroups: 'tagsGroups',
  tagModifiersGroups: 'tagModifiersGroups',
  casts: 'casts',
  stories: 'stories',
  archetypes: 'archetypes',
  archetypeDisplays: 'archetypeDisplays',
  worlds: 'worlds',
  chosenWorld: 'chosenWorld',
  guest: 'guest',
  docs: 'docs',
  googleLogin: 'oauth/google/login',
  localGameData: 'localGameData',
  chosenTactics:'chosenTactics'
};

export const errorCodes = {
  tooManyRequests: 'tooManyRequestsError',
  general: "generalError",
  default: "generalError"
}