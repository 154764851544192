import { ReactComponent as PyramidSvg } from "assets/svgs/pyramid3.svg";
import { ReactComponent as CultistSvg } from "assets/svgs/Cultist3-Black.svg";
import { ReactComponent as GiftedSvg } from "assets/svgs/Gifted2-Black.svg";
import { ReactComponent as HammerSvg } from "assets/svgs/Hammer3-Black.svg";
import { ReactComponent as IshtarSvg } from "assets/svgs/Ishtar2-Black.svg";
import { ReactComponent as TechnocratsSvg } from "assets/svgs/Technocrats2-Black.svg";
import { ReactComponent as HamburgerSvg } from "assets/svgs/Hamburger.svg";
import { ReactComponent as LogoSvg } from "assets/svgs/Logo.svg";

import classNames from "classnames"; 
import styles from "./styles.module.scss";

interface IconProps {
  className?: string;
  icon?: string;
  restProps?: any;
}

enum iconKeys {
  Pyramid = "Pyramid",
  Cultists = "Cultists",
  Cults = "Cults",
  Gifted = "Gifted",
  AdvancedGuard = "AdvancedGuard",
  Hammer = "Hammer",
  TheHammer = "TheHammer",
  Ishtar = "Ishtar",
  RangersOfIshtar = "RangersOfIshtar",
  Technocrats = "Technocrats",
  TechnocratStrikeTeam = "TechnocratStrikeTeam",
  Hamburger = "Hamburger",
  Logo = "Logo"
};

export const PyramidIcon = ({className, ...restProps}: IconProps) => {
  return <PyramidSvg className={classNames(styles.icon, className)} {...restProps} />
}

export const CultistIcon = ({className, ...restProps}: IconProps) => {
  return <CultistSvg className={classNames(styles.icon, className)} {...restProps} />
}

export const GiftedIcon = ({className, ...restProps}: IconProps) => {
  return <GiftedSvg className={classNames(styles.icon, className)} {...restProps} />
}

export const HammerIcon = ({className, ...restProps}: IconProps) => {
  return <HammerSvg className={classNames(styles.icon, className)} {...restProps} />
}

export const IshtarIcon = ({className, ...restProps}: IconProps) => {
  return <IshtarSvg className={classNames(styles.icon, className)} {...restProps} />
}

export const TechnocratsIcon = ({className, ...restProps}: IconProps) => {
  return <TechnocratsSvg className={classNames(styles.icon, className)} {...restProps} />
}

export const HamburgerIcon = ({className, ...restProps}: IconProps) => {
  return <HamburgerSvg className={classNames(styles.icon, className)} {...restProps} />
}

export const LogoIcon = ({className, ...restProps}: IconProps) => {
  return <LogoSvg className={classNames(styles.icon, className)} {...restProps} />
}

export const Icon = ({className, icon, ...restProps}: IconProps) => {
  const restIconProps = {
    className,
    ...restProps
  };

  switch(icon) {

    case(iconKeys.Cultists):
    case(iconKeys.Cults):
      return <CultistIcon {...restIconProps} />
    case(iconKeys.Hammer):
      return <HammerIcon {...restIconProps} />
    case(iconKeys.Gifted):
    case(iconKeys.AdvancedGuard):
      return <GiftedIcon {...restIconProps} />
    case(iconKeys.Ishtar):
    case(iconKeys.RangersOfIshtar):
      return <IshtarIcon {...restIconProps} />
    case(iconKeys.Technocrats):
    case(iconKeys.TechnocratStrikeTeam):
      return <TechnocratsIcon {...restIconProps} />
    case(iconKeys.Logo):
      return <LogoIcon {...restIconProps} />
    case(iconKeys.Hamburger):
      return <HamburgerIcon {...restIconProps} />
    case(iconKeys.Pyramid):
    default:
      return <PyramidIcon {...restIconProps} />
  }
}