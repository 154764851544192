import { useState } from 'react'
import { loginUser, userIsAuthorized } from 'actions/userActions';
import { useNavigate } from "react-router-dom";
import { Button } from 'components/Buttons';
import { useForm } from 'react-hook-form';
import { TextField, TextFieldType } from 'components/Forms/TextField';
import { Form } from 'components/Forms/Form';
import styles from './styles.module.scss';
import { useAuthState, AuthStateAtomType } from 'atoms/auth.atom';
import { useUserState } from 'atoms/users.atom';

interface LoginFormValProps {
  email: string;
  password: string;
};

const useOnLogin = () => {
  const { setState: setUserState } = useUserState();
  const { state: appState, setState: setAppState } = useAuthState();
  const mergeAppState = (newState: AuthStateAtomType) => { return { ...appState, ...newState} };

  const onSubmit = async ({ email, password } : { email: string; password: string; }) => {
    mergeAppState({ Authenticating: true });

    try {
      const results = await loginUser({email, password});

      if(results && results.data) {
        setUserState(results.data); // { email: results.data.email, userName: results.data.first_name });
      }
  
      setAppState({ Authenticated: userIsAuthorized(), Authenticating: false });
        
      return results;
    } catch(err) {
      setAppState({ Authenticated: false, Authenticating: false });
      throw err;
    }
  };

  return {
    onSubmit
  };
};

export const LoginFormComponent = () => {

  const navigate = useNavigate();
  const { onSubmit: onSubmitLogin } = useOnLogin();
  const [isSubmitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState('');
  const { register, handleSubmit, formState } = useForm({
    reValidateMode: 'onSubmit',
    defaultValues: { email: ``, password: ``},
  });
  const errors = formState?.errors;


  return (
    <Form
      onSubmit={handleSubmit(async (vals: LoginFormValProps) => { 
        try {
          setSubmitting(true);
          setSubmitError('');

          await onSubmitLogin(vals); 

          setSubmitting(false);
          
          navigate('/');
        // forward to next page
        } catch(err) {
          setSubmitting(false);
          setSubmitError('Login failed. Please try again, or reach out to us.');
        }
      })}
    >
      <TextField 
        type={TextFieldType.email}
        placeholder="Email"
        label="email"
        className={styles.field}
        labelClassName={styles.labels}
        error={errors?.email}
        {...register("email", {
          validate:{
            validEmail: (val?: string) => {
              return (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(val || ``)) || "Must be a valid email";
            }
          }
        })}
      />
      <TextField
        type={TextFieldType.password}
        placeholder="Password"
        label="password"
        className={styles.field}
        labelClassName={styles.labels}
        error={errors?.password}
        {...register("password", {
          validate:{
            validLength: (val?: string) => {
              return   (val || ``).length >= 5 || "Length must be greater than 5";
            }
          }
        })}
      />
      { submitError && (<div className={styles.submitError}>{submitError}</div>)}
      <Button disabled={isSubmitting} className={styles.submitButton} type="submit">
        Submit
      </Button>
    </Form>
  );
};

export const LoginForm = LoginFormComponent;
