import React, { ReactNode }  from 'react'

export interface FormProps {
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  className?: string;
  children?: ReactNode;
}

export enum FormType {
  Update,
  All,
  Create,
  Get
}

export const Form = ({ onSubmit, ...restProps}: FormProps) => {

  return <form onSubmit={onSubmit} {...restProps} />
}

export default Form;
