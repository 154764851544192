import React, { useMemo, useState, useCallback, useEffect } from "react";
import { 
  useGetDoc,
  useCreateDoc,
  useUpdateDoc,
  CreateDocFunctionType,
  UpdateDocFunctionType
} from 'actions/docActions';
import { DocType } from "actions/doc.types";
import { useForm } from 'react-hook-form';
import { isArrayValidator, createValidateJsonString } from "components/JsonEditor"
import { useGetCurrentUser } from "hooks/userHooks";
import { useWorld } from "hooks/worldHooks";

interface UseDocProps {
  userId?: number;
  docKey?: string | number;
  name?: string;
  description?: string;
  doc?: DocType;
  markdown: string;
  createDoc?: CreateDocFunctionType;
  updateDoc?: UpdateDocFunctionType;
  loaded?: boolean;
}

const useDoc = (docKey?: string | number): UseDocProps => {
  const [loaded, setLoaded]= useState(false);
  const { id } = useGetCurrentUser();
  const { chosenWorldId } = useWorld(); 
  const { data } = useGetDoc(docKey, chosenWorldId, id);

  useEffect(() => {
    if(data) {
      setLoaded(true);
    }
  }, [data])

  const createDoc = useCreateDoc();
  const updateDoc = useUpdateDoc();

  return {
    docKey: docKey || undefined,
    name: data?.name,
    description: data?.description,
    doc: data,
    markdown: data?.markdown || "",
    createDoc: (tagModifiersGroup: DocType) => { return createDoc(tagModifiersGroup, chosenWorldId); },
    updateDoc: (tagModifiersGroupId: string | number, tagModifiersGroup: DocType) => { return updateDoc(tagModifiersGroupId, tagModifiersGroup, chosenWorldId); },
    loaded
  };
}

interface SubmitDocTypes {
  name?: string;
  display?: number;
  description?: string;
  markdown?: string;
}

const translateValues = (vals: SubmitDocTypes): DocType => {
  // title?: string;
  // type?: CastMemberClassType;
  // tags?: Array<TagType>;
  // amount?: number;

  return {
    name: vals?.name,
    display: vals?.display,
    description: vals?.description,
    data: { 
      markdown: vals.markdown || ""
    },
    markdown: vals.markdown
  }
}

export enum FormType {
  Update,
  All,
  Create,
  Get
}

interface useDocFormProps {
  docId?: string;
  formType?: FormType;
  callback?: (doc?: DocType) => void ;
}

export const useDocForm = ({
  docId, 
  formType = FormType.Update,
  callback = () => {} 
}: useDocFormProps = {}) => {
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState('');

  const {
    name,
    description,
    doc,
    markdown,
    loaded,
    createDoc,
    updateDoc
  } = useDoc(docId);

  const [archetypeLoaded, setArchetypeLoaded ] = useState(false);  

  const loadFormValues = useCallback((doc?: DocType) => {
    return { 
      name: doc?.name ,
      display: doc?.display,
      description: doc?.description,
      markdown: doc?.markdown
    };
  },[]);

  const { register, handleSubmit, formState, setValue, reset, setError, clearErrors } = useForm({
    defaultValues: loadFormValues(doc)
  });

  useEffect(() => {
    if(loaded && !archetypeLoaded) {
      reset(loadFormValues(doc));
      setArchetypeLoaded(true);
    }
  }, [setArchetypeLoaded, reset, loaded, doc, loadFormValues, archetypeLoaded]);

  const saveAndMerge = useCallback(async (vals: SubmitDocTypes) => {
      try {
        setSubmitting(true);
        setSubmitError('');
        const translatedValues = translateValues({ ...vals });
        let doc: DocType | undefined;
        if(formType === FormType.Create) {
          doc = await (createDoc && createDoc(translatedValues));
          setSubmitting(false);
        } else {
          doc = await (updateDoc && docId ? updateDoc(docId, translatedValues) : undefined);
          setSubmitting(false);
        }
        callback(doc);
        return doc
        // forward to next page
      } catch(err) {
        setSubmitting(false);
        setSubmitError('Update to Cast Member Failed.');
      }
  }, [formType, callback, createDoc, updateDoc, docId]);
  const errors = formState?.errors;

  const jsonValidator = useMemo(() => {
   return isArrayValidator();
  },
  []);

  const validateJsonString = useMemo(() => {
    return createValidateJsonString(jsonValidator);
  },
  [jsonValidator]);

  const onSubmit = async (e:  React.FormEvent<HTMLFormElement>) => {
    await handleSubmit(saveAndMerge)(e);
  }

  return {
    name,
    description,
    markdown,
    doc,
    loaded,
    submitting,
    submitError,
    saveAndMerge,
    validateJsonString,
    jsonValidator,
    register,
    setValue,
    onSubmit,
    errors,
    setError,
    handleSubmit,
    clearErrors
  };
}
