import React, { ReactNode } from "react";
import classNames from "classnames";

import styles from "./styles.module.scss";
import { Button, ButtonStyle } from "components/Buttons";

export enum BackgroundTypes {
  None = "none",
  Default = "Default"
}

export enum ModalTypes {
  Default = "Default",
  Toast = "Toast",
  Modal = "Modal"
}

interface ModalProps {
  children: ReactNode;
  wrapperClass?: string;
  modalClass?: string;
  modalBackgroundType?: BackgroundTypes;
  modalType?: ModalTypes;
  onClick?: () => void;
  onBackgroundClick?: () => void;
  closeModal?: () => void;
  includeCloseButton?: boolean;
}

export const Modal = ({
  children,
  wrapperClass,
  modalClass,
  modalBackgroundType,
  modalType, 
  onClick,
  onBackgroundClick,
  closeModal,
  includeCloseButton = false
}: ModalProps) => {

  let backgroundModal = modalBackgroundType === BackgroundTypes.None ? undefined : styles.modalBackground;
  let modalWrapperClass = styles.modalWrapper;
  let modalInnerClass = styles.modal;
  switch(modalType) {
    case(ModalTypes.Toast):
      modalWrapperClass = styles.modalToastWrapper;
      backgroundModal = undefined;
      modalInnerClass = styles.modalToastClass;
      break;
    default:
      backgroundModal = modalBackgroundType === BackgroundTypes.None ? undefined : styles.modalBackground;
      modalWrapperClass = styles.modalWrapper;
  } 

  return (
    <>
      <div className={classNames(modalWrapperClass, wrapperClass)} onClick={onClick}>
        <div className={classNames(backgroundModal)} onClick={onBackgroundClick}>
        </div>
        <div className={modalInnerClass}>
        {includeCloseButton && (<div className={styles.closeModal}>
          <Button buttonStyle={ButtonStyle.primary} onClick={() => { closeModal && closeModal();}}>X</Button>
        </div>)}
          {children}
        </div>
      </div>
    </>
  );
}

export default Modal;