import { TagType, LinkByType, DisplayTagType } from 'actions/tag.types';
export type { TagType } from "actions/types";

interface resultType {
  valDescriptions?: Array<TagType>;
}

const getValsAsKey = (tag: TagType) => {
  if(!tag.val) {
    return undefined;
  }

  if( Array.isArray(tag.val)) {
    return tag.val.join(`:`);
  } else {
    return `${tag.val}`;
  }
};

export const getTagKey = (tag: TagType | DisplayTagType | string) => {
  if (typeof tag === "string" || typeof tag === "undefined") {
    return tag;
  } else {
    return getTagKeyTag(tag);
  }
}

export const getTagKeyTag = (tag: TagType | DisplayTagType) => {
  let key: undefined | string = tag.key;
  switch(tag.linkBy) {
    case (LinkByType.Id):
      key = tag.id ? `${tag.id}` : ``;
      break;
    case (LinkByType.KeyVal):
      let baseKey = tag.baseKey || tag.key;
      // we need to add baseKey if it didn't exist, so there is no loop
      let val = getValsAsKey(tag);
      key = tag.val ? `${baseKey}::${val}` : `${baseKey}`;
      break;
    case (LinkByType.Key):
    default:
      key = tag.key;
  }

  if (!key) {
    key = '';
  }
 
  return key;
}

export const addTagDescription = (tag?: TagType, tagDescriptionsMap?: Map<string, TagType>): TagType | undefined => {
  if (!tag) {
    return undefined;
  }
  const t = { ...tag};

    const td = tagDescriptionsMap?.get(t.key);
    let valDescriptions: resultType = {};
    
    if (td?.valAreTagKeys && t.val) {
      let vals;
      if(!Array.isArray(t.val)) {
        vals = [t.val];
      } else {
        vals = t.val
      }

      valDescriptions = vals.reduce((result: resultType, v) => {
        const foundDescription = (typeof v === `string` && tagDescriptionsMap?.get(v)) || ``;
        
        if(foundDescription) {
          if(!result.valDescriptions) {
            result.valDescriptions = [];
          }

          result.valDescriptions.push(foundDescription);
        }

        return result;
      }, { } );
    }

    if(!td) {
      return  { ...t, ...valDescriptions};
    } else {
      return { ...td, ...t, groups:  [ ...(td?.groups || []), ...(t?.groups || [])], ...valDescriptions, description: td.description};
    }
}

export const addTagDescriptions = (
  tags?: Array<TagType>,
  tagDescriptionsMap?: Map<string, TagType>
): TagType[] => {
  if (!tags) {
    return new Array<TagType>();
  }
  return tags.map((t) => {
    const foundTag = addTagDescription(t, tagDescriptionsMap); 
    return foundTag || t;
  });
}

export const tagsToMap = (tags?: Array<TagType>, tagDescriptionsMap?: Map<string, TagType>): Map<string,TagType> => {
  return new Map(
    tags?.map((tag) => {
      let key: undefined | string = getTagKey(tag);
      let curTag: TagType = { 
        ...tag,
        baseKey: tag.baseKey || tag.key
      };

      if (!key) {
        key = '';
      }

      if(tagDescriptionsMap) {
        const foundTag = addTagDescription(curTag, tagDescriptionsMap); 
        curTag = foundTag || curTag;
      }

      return [key, curTag];
    })
  );
};

