import Layout from "Layout";
import { useParams } from "react-router-dom";
import { LinkButton } from "components/Buttons/Button";
import { ShowTag } from "components/Tags/ShowTag";
import {  useGetCurrentUser } from "hooks/userHooks";
import { useGetTags } from "pages/TagsGroups/useTagsGroupForm";
import styles from "./styles.module.scss";
import { routeLinks } from "App/routing";
import { getTagKey } from "actions/helpers/tagHelpers";

type TagParams = {
  tagsGroupId: string;
};

export const TagsGroup = () => {
  const { tagsGroupId } = useParams<TagParams>();
  const { isAdmin, id } = useGetCurrentUser();
  const { baseTagsGroup, groupedTags } = useGetTags(tagsGroupId, undefined, id);

  const tagsGroup = baseTagsGroup;

  // Get tags for this modifier.
  return (
    <Layout>
      <div >
        <h1>
          { `${!tagsGroup?.name ? `${tagsGroupId}:` : ``}${tagsGroup?.name}`}
        </h1>
        { isAdmin && <div className={styles.editButtons}>
          <LinkButton to={`/${routeLinks.traits}/${tagsGroupId}/${routeLinks.edit}`} >Edit</LinkButton> 
          <LinkButton to={`/${routeLinks.traits}/${tagsGroupId}/${routeLinks.copy}`} >Copy</LinkButton> 
        </div>}
        <div>{`${tagsGroup?.description}`}</div>
        <div>
          {groupedTags?.map((group) => {
            return <div key={group.id || group.key}>
              <h3>{group.title}</h3>
              <div>
                {group.tags?.map((tag) => {
                  return tag && <div className={styles.tagWrapper} key={tag.id || getTagKey(tag)}><ShowTag key={tag.id}
                    {...{ tag }}
                  /></div>;
                })}
              </div>
            </div>;
          })}
          
        </div>
      </div>
    </Layout>
  );
}
