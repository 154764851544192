import React, { useState, useRef, useEffect, useCallback, ReactNode, JSXElementConstructor } from "react";
import classNames from "classnames";

import {
  useWindowSize,
} from '@react-hook/window-size';

import styles from "./styles.module.scss";
import { Default } from "react-toastify/dist/utils";
import { ReactElement } from "react-markdown/lib/react-markdown";

type DraggableEvent = React.MouseEvent<HTMLElement | SVGElement, MouseEvent> | React.TouchEvent<HTMLElement | SVGElement> | MouseEvent | TouchEvent;

interface DraggableData {
  x: number;
  y: number;
  deltaX: number;
  deltaY: number;
}

export interface XSwipeableAndYCounterProps extends Omit<SwipeableProps, "onDragged" | "onSwiped" | "onDragging"> {
  counterStyle?: string;
  onDragged?: (dragXStep: number, dragYStep: number, count: number) => void; 
  onDragging?: (dragXStep: number, dragYStep: number, count: number) => void; 
  onSwiped?: (swipeDirection: swipedDirectionType, count: number) => void;
  CounterComponent?: JSXElementConstructor<CounterProps>; // Takes count: number and countStyle: string
  maxCounter?: number;
  allowSelect?: boolean;
}

export interface XSwipeableAndYDragProps extends Omit<SwipeableProps, "onDragged" | "onSwiped" | "onDragging"> {
  counterStyle?: string;
  onDragged?: (dragXStep: number, dragYStep: number, count: number) => void; 
  onDragging?: (dragXStep: number, dragYStep: number, count: number) => void; 
  onSwiped?: (swipeDirection: swipedDirectionType, count: number) => void;
  onDragEndY?: (swipeDirection: swipedDirectionType, count: number) => void;
  CounterComponent?: JSXElementConstructor<CounterProps>; // Takes count: number and countStyle: string
  maxCounter?: number;
}

export interface SwipeableProps {
    onSwiped?: (swipedDirection: swipedDirectionType) => void;
    onDragged?: (dragXStep: number, dragYStep: number) => void;
    onDragging?: (dragXStep: number, dragYStep: number) => void;
    onStopped?: (dragXStep: number, dragYStep: number) => void;
    pixelXStep?: number;
    pixelXStepInPercent?: boolean; // in percent of window height, or using pixels
    pixelYStep?: number;
    pixelYStepInPercent?: boolean; // in percent of window height, or using pixels
    axis?: axisType;
    children: ReactNode;
    rotateAmount?: number;
    rotateMax?: number;
    transitionTimeStyle?: transitionTimeStyleType;
    transitionTimeDefault?: number;
    msToPixel?: number;
    maxTransitionTime?: number;
    velocityThreshold?: number;
    distanceThreshold?: number;
    classNameDragging?: string;
    classNameJustDragged?: string;
    cancelSelector?: string;
    swipeNumberTrigger?: number;
    allowSelect?: boolean;
 }

export enum axisType {
   x = "x",
   y = "y",
   xAndY = "both",
   xAndYCount = "xAndYCount",
   yCount = "yCount",
   none = "none",
   xCountAndY = "xCountAndY",
   xCount = "xCount",
 };

 export interface ceProp {
     velocityX: number;
     velocityY: number;
     time: number;
     // intervals: Array<NodeJS.Timeout>;
     startTime: number;
     curX: number;
     curY: number;
     rotation: number;
     justSwiped: boolean;
     postSwipeStarted: boolean;
     transitionTime: number;
     transitionTimeDragged: number;
     timeout?: NodeJS.Timeout;
     swipedDirection?: swipedDirectionType;
     startX?: number;
     startY?: number;
     started?: boolean;
     pastDistanceThreshold?: boolean;
 };
 
export interface additionalStyles {
    transition?: string;
    transform?: string;
    left?: string;
    top?: string;
}

// how the transition Timing is calculated
enum transitionTimeStyleType {
  maxTime = "maxTime",
  default = "default",
  specificTime = "specificTime",
  velocity = "velocity"
};

export enum swipedDirectionType {
  left = "swipeLeft",
  right = "swipeRight",
  up = "swipeUp",
  down = "swipeDown",
  leftUp = "swipeLeftUp",
  rightUp = "swipeRightUp",
  leftDown = "swipeLeftDown",
  rightDown = "swipeRightDown",
};

// Calculate the length.
const calcLength = (x: number, y: number, axis: axisType) => {
  if( axis === axisType.x || axis === axisType.xAndYCount) {
    return Math.abs(x);
  } else if (axis === axisType.y || axis === axisType.xCountAndY) {
    return Math.abs(y);
  } else if (axis === axisType.xAndY) {
    return Math.sqrt(x*x + y*y);
  } else {
    return 0;
  }
}

interface PixelPercentBreakpoint {
  pixelPercent: number;
  upperBound: number;
  lowerBound: number
}

interface UseCalcStepsByHeightOptions {
  pixelPercent: number; // defaultPixel Percent
  pixelPercentBreakpoints?: Array<PixelPercentBreakpoint>;
  forWindowWidth?: boolean;
}
/// Calculate the steps using pixelPercent
const useCalcStepsByHeight = (options?: UseCalcStepsByHeightOptions) => {
  const [windowWidth, windowHeight] = useWindowSize();

  const {
    pixelPercent,
    pixelPercentBreakpoints,
    forWindowWidth
  } = (options || {});

  const calcStepsByHeight = useCallback((pixels: number) => {
    let calcPixelPercent = pixelPercent || 0.1;
    const windowSize = forWindowWidth ? windowWidth : windowHeight;
    if (pixelPercentBreakpoints && pixelPercentBreakpoints?.length > 0) {
      const breakpoints = pixelPercentBreakpoints;
      const breakpoint = breakpoints.find((b) => {
        return b.upperBound <= windowSize && b.lowerBound >= windowSize;
      });

      calcPixelPercent = breakpoint?.pixelPercent || calcPixelPercent;
    }
      return pixels/(calcPixelPercent * windowSize);
  }, [pixelPercent, windowHeight, windowWidth, forWindowWidth, pixelPercentBreakpoints]);

  return calcStepsByHeight;
}

interface Draggable2Props {
  children?: ReactNode;
  onDragStart?: (e: DraggableEvent, data:any) => void;
  onDragEnd?: (e: DraggableEvent, data:any) => void;
  onDrag?: (e: DraggableEvent, data:any) => void;
  nodeRef: React.RefObject<HTMLElement>;
  allowSelect?: boolean;
  swipeNumberTrigger?: number;
}

export const Draggable2 = ({
  onDragStart,
  onDragEnd,
  onDrag,
  nodeRef,
  swipeNumberTrigger = 1,
  allowSelect = false,
  children
}: Draggable2Props) => {
  const [isDragging, setIsDragging] = useState(false);
  const ce = useRef<ceProp>({
    velocityX:0,
    velocityY:0,
    time:0,
    // intervals: [],
    startTime:0,
    curX: 0,
    curY: 0,
    rotation: 0,
    justSwiped: false,
    postSwipeStarted: false,
    transitionTime: 0,
    transitionTimeDragged: 250,
    startX: undefined,
    startY: undefined,
    started: false,
    pastDistanceThreshold: false
  });

  const onMouseDownListener = (e: any) => {
    if (ce.current) {
      ce.current.started = true;
      ce.current.startX = e.clientX;
      ce.current.curX = e.clientX;
      ce.current.startY = e.clientY;
      ce.current.curY = e.clientY;
      setIsDragging(true);
      onDragStart && onDragStart(e, {
        x:e.clientX, 
        y: e.clientY,
        deltaX: e.clientX - (ce.current.startX || 0),
        deltaY: e.clientY - (ce.current.startY || 0),
      }); 
      
    }
  };

  const onMouseMoveListener = (e: any) => {
    if (ce.current?.started) {
      ce.current.started = true;
      ce.current.curX = e.clientX;
      ce.current.curY = e.clientY;
      onDrag && onDrag(e, {
        x:e.clientX, 
        y: e.clientY,
        deltaX: e.clientX - (ce.current.startX || 0),
        deltaY: e.clientY - (ce.current.startY || 0),
      }); 
    }
  };

  const onMouseUpListener = (e: any) => {
    if (ce.current) {

      setIsDragging(false);

      const deltaX =e.clientX - (ce.current.startX || 0);
      const deltaY  =  e.clientY - (ce.current.startY || 0);
      ce.current.started = false;
      ce.current.startX = 0;
      ce.current.curX = e.clientX;
      ce.current.startY = 0;
      ce.current.curY = e.clientY; 
      onDragEnd && onDragEnd(e, {
        x:e.clientX, 
        y: e.clientY,
        deltaX: deltaX,
        deltaY: deltaY,
      });

    }
  };

  const onTouchDownListener = (e: React.TouchEvent<HTMLElement | SVGElement>) => {
    if (ce.current) {
      if(e.touches.length === swipeNumberTrigger) {
        const firstTouch = e.touches.item(0);
        ce.current.started = true;
        ce.current.startX = firstTouch.clientX;
        ce.current.curX = firstTouch.clientX;
        ce.current.startY = firstTouch.clientY;
        ce.current.curY = firstTouch.clientY;
        onDragStart && onDragStart(e, {
          x: firstTouch.clientX, 
          y: firstTouch.clientY,
          deltaX: firstTouch.clientX - (ce.current.startX || 0),
          deltaY: firstTouch.clientY - (ce.current.startY || 0),
        }); 
      } else {
        ce.current.started = false;
        ce.current.startX = 0;// firstTouch.clientX;
        ce.current.curX = 0; //firstTouch.clientX;
        ce.current.startY = 0; //firstTouch.clientY;
        ce.current.curY = 0; // firstTouch.clientY; 
      }
    }
  };

  const onTouchMoveListener = (e: any) => {
    if (ce.current?.started) {
      if(e.changedTouches.length === swipeNumberTrigger) {
        const firstTouch = e.changedTouches.item(0);
        // ce.current.started = true;
        ce.current.curX = firstTouch.clientX;
        ce.current.curY = firstTouch.clientY;
        onDrag && onDrag(e, {
          x: firstTouch.clientX, 
          y: firstTouch.clientY,
          deltaX: firstTouch.clientX - (ce.current.startX || 0),
          deltaY: firstTouch.clientY - (ce.current.startY || 0),
        });
      } else if(e.changedTouches.length > swipeNumberTrigger) {
        ce.current.started = false;
        ce.current.startX = 0;
        ce.current.curX = 0; //firstTouch.clientX;
        ce.current.startY = 0;
        ce.current.curY = 0; // firstTouch.clientY;
        onDragEnd && onDragEnd(e, {
          x: 0,
          y: 0,
          deltaX: 0,
          deltaY: 0
        });
      } 
    }
  };

  const onTouchUpListener = (e: any) => {
    if (ce.current) {
      if(ce.current.started && e.changedTouches.length) {
        const firstTouch = e.changedTouches.item(0);
        ce.current.started = false;
        ce.current.startX = 0;
        ce.current.curX = firstTouch.clientX;
        ce.current.startY = 0;
        ce.current.curY = firstTouch.clientY;
        onDragEnd && onDragEnd(e, {
          x: firstTouch.clientX, 
          y: firstTouch.clientY,
          deltaX: firstTouch.clientX - (ce.current.startX || 0),
          deltaY: firstTouch.clientY - (ce.current.startY || 0),
        });
      } else {
        ce.current.started = false;
        ce.current.startX = 0;
        ce.current.curX = 0; 
        ce.current.startY = 0;
        ce.current.curY = 0; 
      } 
    }
  };

  return <div
    className={classNames(styles.swipeableHandlerWrapper, !allowSelect ? styles.swipeableDontAllowSelect: undefined )}
    onMouseDown={(e: any) => {
      onMouseDownListener(e);
    }}
    onMouseMove={(e: any) => {
      onMouseMoveListener(e);

    }}
    onMouseUp={(e: any) => {
      onMouseUpListener(e);

    }}
    onTouchStart={(e: any) => {
      onTouchDownListener(e);
    }}
    onTouchMove={(e: any) => {
      onTouchMoveListener(e);

    }}
    onTouchEnd={(e: any) => {
      onTouchUpListener(e);

    }}
    >
    <div style={{ 
      display: isDragging ? 'block' :'none', 
      right:0, top:0, 
      width: '100%',
       height: '100%', 
       position: 'fixed', 
       background: 'transparent'
    }}
    onMouseMove={(e: any) => {
      onMouseMoveListener(e);

    }}
    onMouseUp={(e: any) => {
      onMouseUpListener(e);

    }}
    onTouchMove={(e: any) => {
      onTouchMoveListener(e);

    }}
    onTouchEnd={(e: any) => {
      onTouchUpListener(e);

    }}
    ></div>
    {children}
  </div>
}

export const Swipeable = ({
  children,
  onSwiped,
  onDragged,
  onDragging,
  onStopped,
  pixelXStep = 50,
  pixelXStepInPercent = false,
  pixelYStep = 50,
  pixelYStepInPercent = false,
  cancelSelector,
  swipeNumberTrigger,
  allowSelect = false,
  ...restProps
}: SwipeableProps) => {
  const [, setXPosition] = useState(0);
  const [, setYPosition] = useState(0);
  const [rotation, setRotation] = useState(0);
  const [, setRedraw] = useState({});

  const forceRedraw = useCallback(() => {
    setRedraw({});
  }, [setRedraw]);

  const ce = useRef<ceProp>({
    velocityX:0,
    velocityY:0,
    time:0,
    // intervals: [],
    startTime:0,
    curX: 0,
    curY: 0,
    rotation: 0,
    justSwiped: false,
    postSwipeStarted: false,
    transitionTime: 0,
    transitionTimeDragged: 250,
    startX: undefined,
    startY: undefined,
    pastDistanceThreshold: false
  });
  const [justSwiped, setJustSwiped] = useState(false);

  const calcStepsByHeight = useCalcStepsByHeight({ pixelPercent: pixelYStep });
  const calcStepsByWidth = useCalcStepsByHeight({ pixelPercent: pixelXStep, forWindowWidth: true });
  const nodeRef = useRef(null);

  const {
    rotateAmount,
    rotateMax,
    transitionTimeStyle,
    transitionTimeDefault,
    msToPixel,
    maxTransitionTime,
    velocityThreshold,
    axis,
    distanceThreshold,
    classNameJustDragged,
  } = { ...{
    rotateAmount: 2000,
    rotateMax: 30,
    transitionTimeStyle:  transitionTimeStyleType.default,
    transitionTimeDefault: 100,
    msToPixel: 300,
    maxTransitionTime: 2000,
    // velocityThreshold: 150/1000, // px/ms
    velocityThreshold: 150/30, // px/ms
    axis: axisType.x,
    distanceThreshold: 50,
  }, ...restProps };

  const onActuallySwiped = useCallback((swipedDirection: swipedDirectionType) => {
    
    if  (onSwiped) {
      onSwiped(swipedDirection);
    } else {
    }
  }, [onSwiped]);


  const onActuallyDragged = useCallback((dragXStep: number, dragYStep: number) => {
    if  (onDragged) {
      onDragged(dragXStep, dragYStep);
    }
  }, [onDragged]);

  const onStart  = useCallback((e: DraggableEvent, data: DraggableData) => {
    if(ce.current) {
      ce.current.startX = data.x;
      ce.current.startY = data.y;
      ce.current.pastDistanceThreshold = false;
    }

  },[]);

  const onDrag = useCallback((e: DraggableEvent, data: DraggableData) => {
    const  currentDate = Date.now();
    const timeElapsed = currentDate - ce.current.time;

    if(ce) {
      ce.current.velocityX = data.deltaX/ timeElapsed;
      ce.current.time = currentDate;
      ce.current.velocityY = data.deltaY / timeElapsed;
      if(!ce?.current?.startTime) {
        ce.current.startTime = currentDate;
      }
    }


    let prevCurX =  ce.current.curX;
    let prevCurY = ce.current.curY;

    if(axis === axisType.x || axis === axisType.xAndY || axis === axisType.xAndYCount || axis === axisType.xCountAndY || axis === axisType.xCount) {
      ce.current.curX = data.x - (ce.current.startX || 0);
    }

    if(axis === axisType.y || axis === axisType.xAndY || axis === axisType.xAndYCount || axis === axisType.xCountAndY || axis === axisType.yCount) {
      ce.current.curY = data.y - (ce.current.startY || 0);
    }

    if(axis === axisType.xAndY) {
      const deltaY = data.y  - (ce.current.startY || 0);
      const deltaX = data.x  - (ce.current.startX || 0);
      const rotate = deltaY * 360 / rotateAmount < rotateMax ? deltaY * 360 / rotateAmount : rotateMax;
      let direction = deltaX < 0 ? -1 : 1;
      ce.current.rotation = direction * rotate;           
    }

    ce.current.pastDistanceThreshold = ce.current.pastDistanceThreshold || calcLength(ce.current.curX, ce.current.curY, axis) > distanceThreshold;
    // setX or setY
    setXPosition(ce.current.curX);
    setYPosition(ce.current.curY);


    if(onDragging) {
      const prevCurXStep = Math.round(pixelXStepInPercent ? calcStepsByWidth(prevCurX) : prevCurX/pixelXStep);
      const prevCurYStep = Math.round(pixelYStepInPercent ? calcStepsByHeight(prevCurY) : prevCurY/pixelYStep);
      const curXStep  = Math.round(pixelXStepInPercent ? calcStepsByWidth(ce.current.curX) : ce.current.curX/pixelXStep);
      const curYStep  = Math.round(pixelYStepInPercent ? calcStepsByHeight(ce.current.curY) : ce.current.curY/pixelYStep);

      if (prevCurXStep !== curXStep || prevCurYStep !== curYStep) {
        onDragging(curXStep, curYStep);
      }

    }
  }
  ,[axis, distanceThreshold, onDragging, rotateAmount, rotateMax, pixelXStepInPercent, calcStepsByWidth, pixelXStep, pixelYStepInPercent, calcStepsByHeight, pixelYStep]);

  const stopLogger =  useCallback((e: DraggableEvent, data: DraggableData) => {
    const curTime = Date.now();
    ce.current.startTime = curTime + curTime - ce.current.startTime;
    ce.current.startTime = ce.current.startTime > curTime + 3000 ? curTime + 3000 : ce.current.startTime;
    if(axis === axisType.x || axis === axisType.xAndY || axis === axisType.xAndYCount || axis === axisType.xCountAndY || axis === axisType.xCount) {
      ce.current.curX = data.x - (ce.current.startX || 0); 
    }
    if(axis === axisType.y || axis === axisType.xAndY || axis === axisType.xAndYCount || axis === axisType.xCountAndY || axis === axisType.yCount) {
      ce.current.curY = data.y - (ce.current.startY || 0); 
    }
   
    switch(transitionTimeStyle) {
      case transitionTimeStyleType.maxTime:
        const curTime2 = Date.now();
        const elapsedTime = ce.current.startTime > 0 ? curTime2 - ce.current.startTime : 0;
        ce.current.transitionTime =  elapsedTime < maxTransitionTime ? elapsedTime : maxTransitionTime;
        break;
      case transitionTimeStyleType.velocity:
        // ms
        ce.current.transitionTime = msToPixel * calcLength(ce.current.velocityX, ce.current.velocityY, axisType.xAndY);
        break;
      case transitionTimeStyleType.specificTime:
      case transitionTimeStyleType.default:
        ce.current.transitionTime = transitionTimeDefault;
        break;
    }
    
    ce.current.justSwiped = true;

    if (
      (axis === axisType.x ||
        axis === axisType.xAndYCount
      )
      && velocityThreshold < Math.abs(ce?.current?.velocityX) 
      && distanceThreshold < Math.abs(ce?.current.curX)
    ) {
      if(ce?.current.velocityX > 0) {
        ce.current.swipedDirection = swipedDirectionType.right;
        onActuallySwiped(ce.current.swipedDirection);
      } else if (ce?.current.velocityX < 0) {
        ce.current.swipedDirection = swipedDirectionType.left;
        onActuallySwiped(ce.current.swipedDirection);
      }
      
      setJustSwiped(true);
      setXPosition(ce.current.curX + ce.current.transitionTime * ce?.current?.velocityX);
    } else if (
      (
        axis === axisType.y ||
        axis === axisType.xCountAndY
      )
      && velocityThreshold < Math.abs(ce?.current?.velocityY) 
      && distanceThreshold < Math.abs(ce?.current.curY)
    ) {
      if(ce?.current.velocityY > 0) {
        ce.current.swipedDirection = swipedDirectionType.right;
        onActuallySwiped(ce.current.swipedDirection);
      } else if (ce?.current.velocityY < 0) {
        ce.current.swipedDirection = swipedDirectionType.left;
        onActuallySwiped(ce.current.swipedDirection);
      }

      setJustSwiped(true);

      setYPosition(ce.current.curY + ce.current.transitionTime * ce?.current?.velocityY);
    } else {
      let newXPosition = 0;
      let newYPosition = 0;
      if(axis === axisType.xAndY) {
        const velocity = calcLength(ce?.current?.velocityX, ce?.current?.velocityY, axis);
        const velocityXAbs = Math.abs(ce?.current?.velocityX);
        const velocityYAbs = Math.abs(ce?.current?.velocityY);
 
        const length = calcLength(ce?.current?.curX, ce?.current?.curY, axis);
        if(velocityThreshold < velocity && distanceThreshold < length) {

          if(ce?.current.velocityX > 0 && velocityXAbs >= velocityYAbs) {
            ce.current.swipedDirection = swipedDirectionType.right;
            onActuallySwiped(ce.current.swipedDirection);
          } else if (ce?.current.velocityX < 0 && velocityXAbs >= velocityYAbs) {
            ce.current.swipedDirection = swipedDirectionType.left;
            onActuallySwiped(ce.current.swipedDirection);
          } else if(ce?.current.velocityY > 0) {
            ce.current.swipedDirection = swipedDirectionType.down;
            onActuallySwiped(ce.current.swipedDirection);
          } else if (ce?.current.velocityY < 0) {
            ce.current.swipedDirection = swipedDirectionType.up;
            onActuallySwiped(ce.current.swipedDirection);
          } else {
            onActuallyDragged(ce?.current?.curX, ce?.current?.curY);
          }

          newXPosition = ce.current.curX + ce.current.transitionTime * ce?.current?.velocityX;
          newYPosition = ce.current.curY + ce.current.transitionTime * ce?.current?.velocityY;
        } else {

          ce.current.curX = ce.current.curX + ce.current.transitionTime * ce?.current?.velocityX;
          ce.current.curY = ce.current.curY + ce.current.transitionTime * ce?.current?.velocityY;
          onActuallyDragged(ce?.current?.curX, ce?.current?.curY);
        }
      } else {
        onActuallyDragged(ce?.current?.curX, ce?.current?.curY);
      }

      setXPosition(newXPosition);
      setYPosition(newYPosition);
      setJustSwiped(true);
    }
  }
  ,[axis, distanceThreshold, maxTransitionTime, msToPixel, onActuallyDragged, onActuallySwiped, transitionTimeDefault, transitionTimeStyle, velocityThreshold]);

  // Whenever swiped, handle transition after being swiped.
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    const ceCurrent = ce.current;
    if(justSwiped) {
      ce.current.postSwipeStarted = true;
      if(axis === axisType.x || axis === axisType.xAndY || axis === axisType.xAndYCount || axis === axisType.xCountAndY || axis === axisType.xCount) {
        ce.current.curX = ce.current.curX + ce.current.velocityX*ce.current.transitionTimeDragged;
      }
      if(axis === axisType.y || axis === axisType.xAndY || axis === axisType.xAndYCount || axis === axisType.xCountAndY || axis === axisType.yCount) {
        ce.current.curY = ce.current.curY + ce.current.velocityY*ce.current.transitionTimeDragged;
      }
      if(ce.current.timeout) {
        clearTimeout(ce.current.timeout);
      }
      ce.current.timeout = setTimeout(() => {   
        if(ce?.current?.justSwiped) {
          ce.current.justSwiped = false;
        } 
        if(ce?.current?.postSwipeStarted) {
          ce.current.postSwipeStarted = false;
        } 
        if(justSwiped) {        
          setJustSwiped(false);
        }
        if(ce.current) {
          ce.current.curX = 0;
          ce.current.curY = 0;
          
          setXPosition(0);
          setYPosition(0);
          setRotation(0);
          onStopped && onStopped(0,0);
          forceRedraw();
        }
      }, ce?.current?.transitionTimeDragged);
    } else {
    }

    return () => {
      if(ceCurrent?.timeout) {
        clearTimeout(ceCurrent.timeout);
      }
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [justSwiped, onStopped, forceRedraw, axis]);

  const pastDistanceThreshold  = ce?.current?.pastDistanceThreshold;

  // Handle Special Styles
  const specialStyle: additionalStyles = pastDistanceThreshold ? {
    transform: `translate(${(ce?.current?.curX).toString()}px,${(ce?.current?.curY).toString()}px)`,
  } : {};

  const rotationStyle: any = {
    transform:`rotate(${rotation.toString()}deg)`,
    transition: `all ${(100).toString()}ms`,
  };

  let draggedAnimation = undefined;

  if(justSwiped || ce.current.justSwiped ) {
    specialStyle.transition=`all ${(ce?.current?.transitionTimeDragged).toString()}ms`;
    specialStyle.transition=`all ${(ce.current?.transitionTimeDragged).toString()}ms`;

    if(classNameJustDragged) {
      draggedAnimation = classNameJustDragged;
    }
  } else {
  } 

   return (
    <Draggable2 
    onDragStart={onStart}
    onDragEnd={stopLogger}
    onDrag={onDrag} 
    nodeRef={nodeRef}
    allowSelect={allowSelect}
    swipeNumberTrigger={swipeNumberTrigger}
    >
      <div 
       className={styles.swipeableWrapper}
      >
        <div
         ref={nodeRef} 
         className={classNames(styles.transition, draggedAnimation)} style={{position: "relative", ...specialStyle}} 
         >
          <div style={rotationStyle}>
            {children}
            </div>
        </div>
      </div>
    </Draggable2>
   );
};

export const XSwipeableAndYCounter = (props: XSwipeableAndYCounterProps) => {
  const {
    children,
    onDragging,
    onDragged,
    onSwiped,
    onStopped,
    maxCounter,
    counterStyle,
    allowSelect=false,
    axis,
    CounterComponent,
    ...restProps
  } = props;

  const [countY, setYCount] = useState(0);
  const [countX, setXCount] = useState(0);

  const onDraggingHandler = (x: number, y: number) => {
    if ( y !== countY) {
      const newCount = (maxCounter && maxCounter > 0) ? (y > 0 ? -1 :  1) * Math.min(maxCounter, Math.abs(y)) : y;
      setYCount(newCount);
    }

    if ( x !== countX) {
      const newCount = (maxCounter && maxCounter > 0) ? (x > 0 ? -1 :  1) * Math.min(maxCounter, Math.abs(x)) : x;
      setXCount(newCount);
    }

    onDragging && onDragging(x, y, (axis === axisType.xCountAndY || axis === axisType.xCount) ? countX : countY);
  }

  const onDraggedHandler = (x: number, y: number) => {
    if ( y !== countY) {
      const newCount = (maxCounter && maxCounter > 0) ? (y > 0 ? -1 :  1) * Math.min(maxCounter, Math.abs(y)) : y;
      setYCount(newCount);
    }

    if ( x !== countX) {
      const newCount = (maxCounter && maxCounter > 0) ? (x > 0 ? -1 :  1) * Math.min(maxCounter, Math.abs(x)) : x;
      setXCount(newCount);
    }

    onDragged && onDragged(x, y, (axis === axisType.xCountAndY || axis === axisType.xCount) ? countX : countY);
  }


  const onSwipedHandler = (direction: swipedDirectionType) => {
    onSwiped && onSwiped(direction, countY);
  }

  const onStoppedHandler = (x:number, y:number) => {
    setYCount(0);
    setXCount(0);
    onStopped && onStopped(x, y);
  }

  const count = axis === axisType.xCount || axis === axisType.xCountAndY ? countX : countY;
  
  return <><Swipeable 
    {...{...restProps}}
    axis = {axis}
    onDragging = {onDraggingHandler}
    onDragged = {onDraggedHandler}
    onSwiped = {onSwipedHandler}
    onStopped = {onStoppedHandler}
    allowSelect = {allowSelect}
  >
    <div>
    {children}
    {
      CounterComponent ? 
        <CounterComponent count={count} counterStyle={counterStyle} /> 
        : <DefaultCounter count={count} counterStyle={counterStyle} />
    }
    </div>
  </Swipeable></>
}

export const XSwipeableAndYDrag = (props: XSwipeableAndYDragProps) => {
  const {
    children,
    onDragged,
    onSwiped,
    onDragging,
    onStopped,
    maxCounter,
    counterStyle,
    CounterComponent,
    axis,
    ...restProps
  } = props;

  const [countY, setYCount] = useState(0);
  const [countX, setXCount] = useState(0);

  const onDraggingHandler = (x: number, y: number) => {
    if ( y !== countY) {
      const newCount = (maxCounter && maxCounter > 0) ? (y > 0 ? -1 :  1) * Math.min(maxCounter, Math.abs(y)) : y;
      setYCount(newCount);
    }

    if ( x !== countX) {
      const newCount = (maxCounter && maxCounter > 0) ? (x > 0 ? -1 :  1) * Math.min(maxCounter, Math.abs(x)) : x;
      setXCount(newCount);
    }

    onDragging && onDragging(x, y, (axis === axisType.xCountAndY || axis === axisType.xCount) ? countX : countY);
  }

  const onDraggedHandler = (x: number, y: number) => {
    if ( y !== countY) {
      const newCount = (maxCounter && maxCounter > 0) ? (y > 0 ? -1 :  1) * Math.min(maxCounter, Math.abs(y)) : y;
      setYCount(newCount);
    }

    if ( x !== countX) {
      const newCount = (maxCounter && maxCounter > 0) ? (x > 0 ? -1 :  1) * Math.min(maxCounter, Math.abs(x)) : x;
      setXCount(newCount);
    }
    
    onDragged && onDragged(x, y, (axis === axisType.xCountAndY || axis === axisType.xCount) ? countX : countY);
  }


  const onSwipedHandler = (direction: swipedDirectionType) => {
    onSwiped && onSwiped(direction, countY);
  }

  const onStoppedHandler = (x:number, y:number) => {
    setYCount(0);
    setXCount(0);
    onStopped && onStopped(x, y);
  }
  const count = axis === axisType.xCount || axis === axisType.xCountAndY ? countX : countY;

  return <><Swipeable 
    {...{...restProps}}
    axis={axis}
    onDragging = {onDraggingHandler}
    onDragged = {onDraggedHandler}
    onSwiped = {onSwipedHandler}
    onStopped = {onStoppedHandler}
  >
    <div>
    {children}
    {
      CounterComponent ? 
        <CounterComponent count={count} counterStyle={counterStyle} /> 
        : <DefaultCounter count={count} counterStyle={counterStyle} />
    } 
    </div>
  </Swipeable></>
}

export interface CounterProps {
  count: number;
  counterStyle?: string
}
const DefaultCounter = ({count, counterStyle}: CounterProps) => {
  return !count ? null : (<div className={classNames(styles.counter, counterStyle)}>
    {Math.abs(count)}
    </div>);
}
