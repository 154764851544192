import Layout from "Layout";
import { Link } from "react-router-dom";
import { routeLinks } from "App/routing";
import { useGetAllBaseTagsGroup } from "actions/tagActions";
import { LinkButton } from "components/Buttons";
import {  useGetCurrentUser } from "hooks/userHooks";

import styles from "./styles.module.scss";
import { useWorld } from "hooks/worldHooks";

export const TagsGroups = () => {
  const { isAdmin, id, isGuest } = useGetCurrentUser();
  const { chosenWorldId } = useWorld()
  const { data } = useGetAllBaseTagsGroup(chosenWorldId, id);

  return (
    <Layout>
      <div >
        <h1>
          Traits:
        </h1>
        <div>
          {isAdmin && <div className={styles.actions}>
            <LinkButton to={`/${routeLinks.traits}/${routeLinks.new}`} >New</LinkButton> 
          </div>}
          {
            data?.map((tagsGroup) => {
              return (<div key={tagsGroup.id}><Link to={`${isGuest ? `/${routeLinks.guest}` : ``}/${routeLinks.traits}/${tagsGroup.id}`} >{!tagsGroup.name ? `${tagsGroup.id}: ` : ``}{tagsGroup.name}</Link></div>)
            })
          }
        </div>
      </div>
    </Layout>
  );
}
