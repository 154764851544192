import classNames from "classnames";

import { getTagKey, tagsToMap } from "actions/helpers/tagHelpers";
import { TagType,  } from "actions/types";
import { ArchetypeType, UpdateCastMemberType } from "actions/cast.types";
import { ArchetypeDisplayType, ArchetypeSectionType, CastMemberType } from "actions/cast.types";
import { filterTagsBySelectors } from "lib/thresholds";

import styles from "./styles.module.scss";
import { SectionKeys } from "hooks/archetypeMemberHooks";

interface CastMemberEntryProps {
   archetype: ArchetypeType;
   archetypeDisplay?: ArchetypeDisplayType;
   editTagOptions?: Array<TagType>;
   saveCastMemberEntry?: (castMember: CastMemberType, updateEntries?: UpdateCastMemberType) => void;
   closeEdit?: (castMember?: CastMemberType) => void;
   onClickDescriptor?: (tag?: TagType) => void;
}

interface CastEntrySectionDisplay {
  section: ArchetypeSectionType;
  editTagOptions?: Array<TagType>;
  allTagsMap?: Map<string, TagType>;
}

export const BaseCastDisplaySection = ({ section, editTagOptions }: CastEntrySectionDisplay) => {
  const tagOptions = new Map<string,TagType>(tagsToMap(editTagOptions));
  const filteredEditTagOptions = filterTagsBySelectors(section.tagKeys, Array.from(tagOptions.values()));

  switch(section.key) {
    case SectionKeys.factionSpecialRules:
    case SectionKeys.gear:
      return (<CastDisplaySectionDefault 
        section={section}
        editTagOptions={filteredEditTagOptions}
      />);
    default:
      return null;
  }
};

export const CampaignCastDisplaySection = ({ section, editTagOptions }: CastEntrySectionDisplay) => {
  const tagOptions = new Map<string,TagType>(tagsToMap(editTagOptions));
  const filteredEditTagOptions = filterTagsBySelectors(section.tagKeys, Array.from(tagOptions.values()));

  switch(section.key) {
    case SectionKeys.factionCampaignUpgrades:
      return (<CastDisplaySectionDefault 
        section={section}
        editTagOptions={filteredEditTagOptions}
      />);
    default:
      return null;
  }
};

export const TacticCastDisplaySection = ({ section, editTagOptions }: CastEntrySectionDisplay) => {
  const tagOptions = new Map<string,TagType>(tagsToMap(editTagOptions));
  const filteredEditTagOptions = filterTagsBySelectors(section.tagKeys, Array.from(tagOptions.values()));

  switch(section.key) {
    case SectionKeys.tacticOptions:
      return (<CastDisplaySectionDefault 
        section={section}
        editTagOptions={filteredEditTagOptions}
      />);
    default:
      return null;
  }
};

export const getSubDisplay = (tag: TagType) => {
  return tag.displaySubTags && tag.displaySubTags.map((ds) => { return `${ds.title || ds.baseKey || ds.key}${ds.val ? `(${ds.val})` : ``}`; }).join(`,`); 
}

interface DisplayTagProps {
  tag: TagType;
};

export const DisplayTag = ({
  tag
  }: DisplayTagProps) => {
    const title = tag.title || tag.baseKey || tag.key;
    const descriptionDisplay = getSubDisplay(tag);
    const description = `${tag.description}${tag.valDescriptions ? `: ${tag.valDescriptions.map((vd) => { return `${vd.title || vd.key}: ${vd.description}`;}).join(`, `)}`:``}`;
  return <>{title}{tag.val ? `(${Array.isArray(tag.val) ? tag.val.join(', ') : tag.val})` : null}{descriptionDisplay ? `: ${descriptionDisplay}` : ``}{description ? ` - ${description}` : ``}</>

}

export const DisplayTagVal = ({
  tag,
  }: DisplayTagProps) => {
    const title = tag.title || tag.baseKey || tag.key;
    const descriptionDisplay = getSubDisplay(tag);
    const description = `${tag.description}${tag.valDescriptions ? `: ${tag.valDescriptions.map((vd) => { return `${vd.title || vd.key}: ${vd.description}`;}).join(`, `)}`:``}`;
  return <><div className={styles.displayTagVal}>
   <div className={styles.textDescription}> {title}: {descriptionDisplay ? `${descriptionDisplay}-` : ``}{tag.val || 0} {description ? ` - ${description}` : ``}</div>
  </div></>
}

export const CastDisplaySectionDefault = ({
  section,
  editTagOptions,
}: CastEntrySectionDisplay) => {
  return <div>
    <h4>
      {section.name}:
    </h4>
    <div>
      <div>
        {editTagOptions?.map((etg) => {
          const finalTag = etg; 
          if(finalTag.valCanBeModified) {
            return (<div key={getTagKey(finalTag)}>
              <div
                className={classNames(styles.tagOptionButton)}
              >
                <DisplayTagVal tag={finalTag} />
              </div>
            </div>)  
          }
          return (<div key={getTagKey(etg)}>
            <div
            className={classNames(styles.tagOptionButton)}
            >
              <DisplayTag tag={etg} />
            </div>
          </div>)
        })}
      </div>
    </div>
  </div>
}

export const ViewArchetypeDisplayOptions = ({
    archetypeDisplay,
    editTagOptions}:CastMemberEntryProps) => { 

  return (
      <div className={styles.archetypeEntryCard}>
          <div className={classNames(styles.archetypeTitle)}>
          </div>
          <UnitOptions
            {...{ 
              archetypeDisplay,
                 filteredTagOptions: editTagOptions
            }}
          />
      </div>
  )
};

interface UnitOptionsProps {
  archetypeDisplay?: ArchetypeDisplayType;
  filteredTagOptions?: TagType[];
}

const UnitOptions = ({
  archetypeDisplay,
   filteredTagOptions
}: UnitOptionsProps) => {
  const filterSection = archetypeDisplay?.data?.sections || []; 

  return <div>
    <div>
      {filterSection.map((s) => {
        return <BaseCastDisplaySection
          key={s.key}
          section={s}
          editTagOptions={filteredTagOptions} />;
      })}
    </div>
    <h3>Campaign Options</h3>
    <div>
      {filterSection.map((s) => {
        return <CampaignCastDisplaySection
          key={s.key}
          section={s}
          editTagOptions={filteredTagOptions} />;
      })}
    </div>
  </div>;
}

