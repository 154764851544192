import Layout from "Layout";

export const TermsAndConditions = () => {

  return (
    <Layout>
      <div >
        <h1>
          Terms and Conditions:
        </h1>
        <div>
          Blood of Stars Team Builder requires your login only to track your Blood of Stars teams and other Builder information, as well as your email for contact. We will not use your information for anything but contacting you with regards to Blood of Stars or Support, given your permission.
        </div>
      </div>
    </Layout>
  );
}
