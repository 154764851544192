import {useCallback} from "react";
import Layout from "Layout";
import classNames from "classnames";
import { useParams } from "react-router-dom";

import { Button } from "components/Buttons/Button";
import { TextField, TextFieldType } from "components/Forms/TextField";
import styles from "./styles.module.scss";

import { Form } from 'components/Forms/Form';
import { useWorldForm } from "pages/Worlds/useWorldForm";
import { routeLinks } from "App/routing";
import { useNavigate } from "react-router-dom";
import { WorldType } from "actions/world.types";

type DocParams = {
  worldId: string;
};

export const EditWorld = () => {
  const { worldId } = useParams<DocParams>();
  const navigate = useNavigate();

  const submitCallback = useCallback((doc?: WorldType) => {
    navigate(`/${routeLinks.worlds}/${doc?.id || worldId}`);
  }, [navigate, worldId]);

  const {
    name,
    errors,
    onSubmit,
    register,
    submitting,
    submitError,
  } = useWorldForm({worldId, callback: submitCallback});

  return (
    <Layout>
      <div >
        <h1>
          { `${worldId} : ${name}`}
        </h1>
        <div>
          <Form
            onSubmit={onSubmit}
          >
            <Button type="submit">Save</Button>
            {!submitting && submitError && (<div>{submitError}</div>)}
            <TextField 
              type={TextFieldType.text}
              placeholder="key"
              label="Name"
              labelClassName={styles.label}
              className={styles.entry}
              error={errors?.name && { ...errors.name, ...{message:"Name must not be empty"}}}
              {...register("name",{
                validate:{
                  validLength: (val?: string) => {
                    return val && val?.length > 0;
                  }
                }
              })}
            />
            <TextField 
              type={TextFieldType.text}
              placeholder="Description"
              label="Description"
              labelClassName={styles.label}
              className={classNames(styles.entry, errors?.description && styles.textError)}
              error={errors?.description && { ...errors.description, ...{message:"Description must not be empty"}}}
              {...register("description",{
                validate:{
                  validLength: (val?: string) => {
                    return val && val?.length > 0;
                  }
                }
              })}
            />
            <TextField 
              type={TextFieldType.number}
              labelClassName={styles.label}
              className={styles.entry}
              placeholder="Display"
              label="Display"
              error={errors?.display}
              {...register("display",{
              })}
            />
            <TextField
              type={TextFieldType.text}
              placeholder="Version"
              label="Version" 
              labelClassName={styles.label}
              className={styles.entry}
              error={errors?.version}
              {...register("version",{
                validate:{
                  validLength: (val?: string) => {
                    return val && val?.length > 0;
                  }
                }
              })}
            /> 
          </Form>
        </div>
        <div>
        </div>
      </div>
    </Layout>
  );
}
