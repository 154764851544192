
import { TagType } from "actions/types";
import { ArchetypeDisplayType, ArchetypeSectionType, CastMemberType } from "actions/cast.types";

import { ShowDisplayArchetypeMember } from "components/ArchetypeMembers/ShowArchetypeMember/BloodOfStarsArchetype";
import { tagsToMap } from "actions/helpers/tagHelpers";
import styles from "./styles.module.scss";
import { getTags } from "components/ArchetypeMembers/ShowArchetypeMember";

interface ArchetypeEntryProps {
   castMember: CastMemberType;
   archetypeDisplay?: ArchetypeDisplayType;
   dragging?: boolean;
   cancelSelector?: string;
   setIsOpen?: (isOpen: boolean) => void;
   isOpen?: boolean;
   onClickDescriptor?: (tag?: TagType) => void;
   isShrunk?: boolean;
   toggleShrink?: () => void;
}

interface ArchetypeEntrySectionDisplay {
  section: ArchetypeSectionType;
  castMember: CastMemberType;
}

// Blood of Stars Section Keys
export const sectionKeys = {
  primary: 'primary',
  statline: 'statline',
  profileTraits: 'traits',
  abilityTraits: 'abilityTraits',
  gear: 'gear',
  factionSpecialRules: 'factionSpecialRules',
  injuries: 'injuries',
  upgrades: 'upgrades',
  traitConditions: 'traitConditions',
  leaderTraits: 'leaderTraits',
};


export const ArchetypeDisplaySection = ({castMember, section }: ArchetypeEntrySectionDisplay) => {

  switch(section.key) {
    case sectionKeys.primary:
    case sectionKeys.leaderTraits:
    case sectionKeys.upgrades:
      return null;
    case sectionKeys.statline:
      return (<ArchetypeDisplaySectionDefault castMember={castMember} section={section} />);
    case sectionKeys.traitConditions:
      return (<ArchetypeDisplaySectionCondition castMember={castMember} section={section} />);

    default:
     return (<ArchetypeDisplaySectionDefault castMember={castMember} section={section} />);
  }
};

export const ArchetypeDisplaySectionDefault = ({castMember, section }: ArchetypeEntrySectionDisplay) => {
  const tagsMap = castMember.tagsMap || tagsToMap(castMember.tags);
  const sectionTagsMap = getTags(section.tagKeys, tagsMap); 
  const keys = sectionTagsMap.map((t) => { return t.key; }).join(`, `);

  if (keys) {
    return <div>
      {section.name}:{keys}
    </div>
  } else {
    return null;
  }
}

export const ArchetypeDisplaySectionCondition = ({castMember, section }: ArchetypeEntrySectionDisplay) => {
  const tagsMap = castMember.tagsMap  || tagsToMap(castMember.tags);  
  const sectionTagsMap = getTags(section.tagKeys, tagsMap); 
  const keys = sectionTagsMap.map((t) => { return t.key; }).join(`, `);
  if (keys) {
    return <div>
      {section.name}: {keys}
    </div>
  } else {
    return null;
  }
}

export const ArchetypeEntry = ({
    castMember,
    archetypeDisplay,
    onClickDescriptor,
    isShrunk,
    toggleShrink
}:ArchetypeEntryProps) => {
  return (<div className={styles.archetypeEntryCard}>
    <ShowDisplayArchetypeMember
                    {...{ 
                  displayArchetypeMember: castMember,
                  archetypeDisplay,
                  onClickDescriptor,
                  isShrunk,
                  toggleShrink
                }}
    />
  </div>);
};
