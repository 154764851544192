import { useCallback } from "react";
import axios from "axios";
import * as base from "./lib/baseActions";
import { apiEndpoints } from "lib/config";
import {
   WorldType,
  } from "actions/world.types";
import useSWR from 'swr';
import { mutate } from 'swr';

export interface DocsResult {
  data: Array<WorldType>;
};

export interface DocResult {
  data: WorldType;
};

export const cleanDoc = (doc: WorldType) => {
  return {
    ...doc,
    version: doc?.data?.version,
  }
}

export const getWorld = async(worldId?: string | number, userId?: string | number) : Promise<WorldType> => {
  let endpoint = ``;

    endpoint = `${userId ? `${apiEndpoints.users}/${userId}/` : `${apiEndpoints.users}/guest/`}${apiEndpoints.worlds}/${worldId}`;
  try {
    const doc = await base.get(endpoint);

    return cleanDoc(doc.data);
  } catch (err: any) {
    err.message = "Failed to get a tag.";
    throw err;
  }
}

export const getAllWorlds = async(userId?: string | number) : Promise<Array<WorldType>> => {
  try {
    const docs = await base.get(`${userId ? `${apiEndpoints.users}/${userId}/` : `${apiEndpoints.users}/guest/`}${apiEndpoints.worlds}`);
    const docsData = docs.data ? docs.data : [];
    return [...[], ...docsData]
      .map((doc) => {
        return cleanDoc(doc);
      });
  } catch (err: any) {
    err.message = "Failed to get a tags.";
    throw err;
  }
}

type useGetDocSWRType = [
  string, string | number | undefined, string | number | undefined
];

export const useGetWorld = ( worldId?: string | number, userId?: string | number ) => {
  return useSWR<WorldType>(worldId ?
    [
      `${userId ? `${apiEndpoints.users}/${userId}/` : `${apiEndpoints.users}/guest/`}${apiEndpoints.worlds}/${worldId}`,
      worldId,
      userId
    ] : null,
    ([key, worldId, userId]: useGetDocSWRType) => { 
      return getWorld(worldId, userId);
    }
  );
};

type  useGetAllDocsSWRType = [
  string, string | number | undefined
] 

export const useGetAllWorlds = (userId?: string | number) => {
  return useSWR<Array<WorldType>>(
    [
      `${userId ? `${apiEndpoints.users}/${userId}/` : `${apiEndpoints.users}/guest/`}${apiEndpoints.worlds}`,
      userId
    ],
    ([key, userId]: useGetAllDocsSWRType) => { 
      return getAllWorlds(userId);
    }
  );
};

// Create Tag Modifiers Group
export const createWorld = async (doc: WorldType) : Promise<WorldType> => {
  try {
    const endpoint = `${apiEndpoints.worlds}`;
    
    const result = await base.post(endpoint, doc);
    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      err.message = "Failed to get a tag.";
    }
    throw err;
  }
};

export const updateWorld = async ( worldId: number | string, doc: WorldType) : Promise<WorldType> => {
    try {
      const result = await base.put(`${apiEndpoints.worlds}/${worldId}`, doc);
      return result.data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        err.message = "Failed to get a tag.";
      }
      throw err;
    }
};

export const deleteWorld = async (worldId?: number | string) : Promise<WorldType> => {
  try {
    const result = await base.del(`${apiEndpoints.worlds}/${worldId}`);
    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      err.message = "Failed to delete a tag modifiers group.";
    }
    throw err;
  }
};

export interface CreateWorldFunctionType {
  (world: WorldType):  Promise<WorldType> 
}

export const useCreateWorld = () : CreateWorldFunctionType => {
  return useCallback(async (world: WorldType) => {
    const result = await createWorld(world);
    const tagModifierGroupId = result.id;
    mutate(`/${apiEndpoints.worlds}/${tagModifierGroupId}`, result); 
    return result;
  }, []);
};

export interface UpdateWorldFunctionType {
  (tagModifierGroupId: number | string, cast: WorldType, worldId?: number | string):  Promise<WorldType> 
} 

export const useUpdateWorld = () : UpdateWorldFunctionType => {
  return useCallback(async (worldId: number | string, world: WorldType) => {
    const result = await updateWorld(worldId, world);
    mutate([`/${apiEndpoints.worlds}/${worldId}`, worldId], result);
    return result;
  },[]);
}

export interface DeleteWorldFunctionType {
  (tagModifierGroupId: number | string, worldId?: number | string):  Promise<WorldType> 
} 

export const useDeleteWorld = () : DeleteWorldFunctionType => {
  return useCallback(async ( worldId: number | string) => {
    const result = await deleteWorld(worldId);
    mutate([`/${apiEndpoints.worlds}/${worldId}`, worldId], result);
    return result;
  },[]);
}

export const setChosenWorld = (worldId: string | number) => {
    localStorage.setItem(`/${apiEndpoints.worlds}/${apiEndpoints.chosenWorld}`, worldId.toString());
    return worldId.toString();
}

export const getChosenWorld = () => {
  const result = localStorage.getItem(`/${apiEndpoints.worlds}/${apiEndpoints.chosenWorld}`) || "";
  return result.toString();
}

interface useSetChosenWorldResultType {
  ( worldId: number | string) :  Promise<string> 
} 

export const useSetChosenWorld = () : useSetChosenWorldResultType => {
  return async (worldId: number | string) => {
    let result;

    result = await setChosenWorld(worldId);
    mutate([`/${apiEndpoints.worlds}/${apiEndpoints.chosenWorld}`], result);
    return result;
  }
}

type useGetLocalCastSWRType = [
  string
] 

export const useGetChosenWorld = () => {
  return useSWR<string>(
    [
      `/${apiEndpoints.worlds}/${apiEndpoints.chosenWorld}`,
    ],
    ([key]: useGetLocalCastSWRType) => {
      return getChosenWorld();
    },
  );
};
