import React, { useMemo, useState, useCallback, useEffect } from "react";
import { 
  useGetBaseTagsGroup,
} from 'actions/tagActions';
import { 
  useGetTagModifiersGroup,
  useCreateTagModifiersGroup,
  useUpdateTagModifiersGroup,
  CreateTagModifiersGroupFunctionType,
  UpdateTagModifiersGroupFunctionType
} from 'actions/tagModifiersActions';
import { TagType, TagModifiersGroupType, TagModifierType } from "actions/tag.types";
import { tagsToMap } from "actions/helpers/tagHelpers";
import { useForm } from 'react-hook-form';
import { isArrayValidator, createValidateJsonString } from "components/JsonEditor"
import { useWorld } from "hooks/worldHooks";
import { useGetCurrentUser } from "hooks/userHooks";
import { defaultKey } from "lib/config";

interface UseArchetypeProps {
  userId?: number;
  tagModifiersGroupKey?: string | number;
  name?: string;
  description?: string;
  tagModifiersGroup?: TagModifiersGroupType;
  tagModifiers?: Array<TagModifierType>;
  tagDescriptions?: Array<TagType>;
  createTagModifiersGroup?: CreateTagModifiersGroupFunctionType;
  updateTagModifiersGroup?: UpdateTagModifiersGroupFunctionType;
  loaded?: boolean;
}

const useTagModifier = (tagModifiersGroupKey?: string | number): UseArchetypeProps => {
  const [loaded, setLoaded]= useState(false);
  const { id } = useGetCurrentUser();
  const { chosenWorldId } = useWorld();
  const { data } = useGetTagModifiersGroup(tagModifiersGroupKey, chosenWorldId, id);
  const { data: tagsGroup } = useGetBaseTagsGroup(defaultKey, chosenWorldId, id);

  useEffect(() => {
    if(data) {
      setLoaded(true);
    }
  }, [data])

  const createTagModifiersGroup = useCreateTagModifiersGroup();
  const updateTagModifiersGroup = useUpdateTagModifiersGroup();

  return {
    tagModifiersGroupKey: tagModifiersGroupKey || undefined,
    name: data?.name,
    description: data?.description,
    tagModifiersGroup: data,
    tagModifiers: data?.tagModifiers,
    tagDescriptions: tagsGroup?.tags,
    createTagModifiersGroup: (tagModifiersGroup: TagModifiersGroupType) => { return createTagModifiersGroup(tagModifiersGroup, chosenWorldId)},
    updateTagModifiersGroup: (tagModifierGroupId: string | number, tagModifiersGroup: TagModifiersGroupType) => { return updateTagModifiersGroup(tagModifierGroupId, tagModifiersGroup, chosenWorldId)},
    loaded
  };
}

interface SubmitTagModifiersGroupTypes {
  name?: string;
  description?: string;
  data: string;
}

const translateValues = (vals: any): TagModifiersGroupType => {
  // title?: string;
  // type?: CastMemberClassType;
  // tags?: Array<TagType>;
  // amount?: number;

  return {
    name: vals?.name,
    display: vals?.display,
    description: vals?.description,
    data: { 
      tagModifiers: vals.data
    },
    tagModifiers: vals.data
  }
}

export enum FormType {
  Update,
  All,
  Create,
  Get
}

interface useTagModifierFormProps {
  tagModifiersGroupId?: string;
  formType?: FormType;
  callback?: (tagModifirsGroup?: TagModifiersGroupType) => void ;
}

export const useTagModifierForm = ({
  tagModifiersGroupId, 
  formType = FormType.Update,
  callback = () => {} 
}: useTagModifierFormProps = {}) => {
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState('');

  const {
    name,
    description,
    tagModifiersGroup,
    tagDescriptions,
    tagModifiers,
    loaded,
    createTagModifiersGroup,
    updateTagModifiersGroup
  } = useTagModifier(tagModifiersGroupId);


  const [archetypeLoaded, setArchetypeLoaded ] = useState(false);  

  const loadFormValues = useCallback((tagModifiersGroup?: TagModifiersGroupType) => {
    return { 
      name: tagModifiersGroup?.name ,
      display: tagModifiersGroup?.display || 0,
      description: tagModifiersGroup?.description,
      data: JSON.stringify(tagModifiersGroup?.tagModifiers) 
    };
  },[]);

  const { register, handleSubmit, formState, getValues, setValue, reset, setError, clearErrors } = useForm({
    defaultValues: loadFormValues(tagModifiersGroup)
  });

  const formData = getValues('data');

  useEffect(() => {
    if(loaded && !archetypeLoaded) {
      reset(loadFormValues(tagModifiersGroup));
      setArchetypeLoaded(true);
    }
  }, [setArchetypeLoaded, reset, loaded, tagModifiersGroup, loadFormValues, archetypeLoaded]);

  const { data: baseTagsGroup } = useGetBaseTagsGroup(defaultKey);

  const tagDescriptionsMap = useMemo(() => {
    return tagsToMap(baseTagsGroup?.tags);
  },[baseTagsGroup])

  const saveAndMerge = useCallback(async (vals: SubmitTagModifiersGroupTypes) => {
      try {
        setSubmitting(true);
        setSubmitError('');
        let attemptCreate = true;
        let parsedData = undefined;
        try {
          parsedData = JSON.parse(vals?.data);

        } catch {
          console.log("Failed to parse Data")
          attemptCreate = false;
        }
        if (attemptCreate) {
          const translatedValues = translateValues({ ...vals, data: parsedData });
          let tagModifiersGroup: TagModifiersGroupType | undefined;
          if(formType === FormType.Create) {
            tagModifiersGroup = await (createTagModifiersGroup && createTagModifiersGroup(translatedValues));
            setSubmitting(false);
          } else {
            tagModifiersGroup = await (updateTagModifiersGroup && tagModifiersGroupId ? updateTagModifiersGroup(tagModifiersGroupId, translatedValues) : undefined);
            setSubmitting(false);
          }
          callback(tagModifiersGroup);
          return tagModifiersGroup
        } else {
            //did not attempt to create
        }
        // forward to next page
      } catch(err) {
        setSubmitting(false);
        setSubmitError('Update to Cast Member Failed.');
      }
  }, [formType, callback, createTagModifiersGroup, updateTagModifiersGroup, tagModifiersGroupId]);
  const errors = formState?.errors;

  const jsonValidator = useMemo(() => {
   return isArrayValidator();
  },
  []);

  const validateJsonString = useMemo(() => {
    return createValidateJsonString(jsonValidator);
  },
  [jsonValidator]);

  const onSubmit = async (e:  React.FormEvent<HTMLFormElement>) => {
    await handleSubmit(saveAndMerge)(e);
  }

  return {
    name,
    description,
    tagModifiers,
    tagModifiersGroup,
    tagDescriptions,
    loaded,
    submitting,
    submitError,
    formData,
    tagDescriptionsMap,
    saveAndMerge,
    validateJsonString,
    jsonValidator,
    register,
    setValue,
    onSubmit,
    errors,
    setError,
    handleSubmit,
    clearErrors
  };
}
