import * as base from "./lib/baseActions";
import { jwtData, apiEndpoints } from "lib/config";
import {  logoutUser } from "actions/userActions";

/// Fetch data for logged in user
const fetchUser = async () => {
  return base.get(apiEndpoints.usersMe);
};

export interface authResult {
  data: {
    token: string;
  }
}

export interface UserResult {
  data: {
    firstName?: string;
    lastName?: string;
    username?: string;
    email?: string;
  };
};

export interface googleLogin {
  user: {
    firstName?: string;
    lastName?: string;
    username?: string;
    email?: string;
  };
  new: boolean;
}

interface googleOAuthLogin {
  token: string;
}

/// Login
/// data = { email:"login email", password: "login pass" }
export const googleLoginUser = async (data : googleOAuthLogin) : Promise<googleLogin> => {
  const token = data.token;

  try {
    // logout if you try to login again.
    await logoutUser();

    const loginResult = await base.post(apiEndpoints.googleLogin, {
      token
    });

    localStorage.setItem(jwtData.authToken, loginResult.data.authToken);
    localStorage.setItem(jwtData.authTokenExpiry, loginResult.data.expiresIn);
    localStorage.setItem(jwtData.refreshToken, loginResult.data.refreshToken);
    localStorage.setItem(jwtData.refreshTokenExpiry, loginResult.data.refreshExpiresIn);

    const user = await fetchUser();

    return {
      user: user?.data,
      new: loginResult.data.new
    }
  } catch (err: any) {

    if(err.status === 400) {
      err.message = "Incorrect Email or Password.";
    }

    throw err;
  }
};
