import { useMemo } from "react";
import Layout from "Layout";
import { useParams } from "react-router-dom";
import { useGetBaseTagsGroup } from 'actions/tagActions';
import { useGetTagModifiersGroup } from 'actions/tagModifiersActions';
import { LinkButton } from "components/Buttons/Button";
import { ShowTagModifier } from "components/TagModifiers/ShowTagModifier";
import { tagsToMap } from "actions/helpers/tagHelpers";
import styles from "./styles.module.scss";
import { routeLinks } from "App/routing/routeLinks";
import {  useGetCurrentUser } from "hooks/userHooks";
import { useWorld } from "hooks/worldHooks";
import { defaultKey } from "lib/config";

type TagModifierParams = {
  tagModifiersGroupId: string;
};

export const TagModifiersGroup = () => {
  const { id } = useGetCurrentUser(); 
  const { tagModifiersGroupId } = useParams<TagModifierParams>();
  const { chosenWorldId } = useWorld(); 
  const { data } = useGetTagModifiersGroup(tagModifiersGroupId, chosenWorldId, id);
  const tagModifiersGroup = data;

  const tagModifiersArray = tagModifiersGroup?.tagModifiers || [];
  
  // Get tags for this modifier.
  const { data: baseTagsGroup } = useGetBaseTagsGroup(defaultKey);

  const tagDescriptionsMap = useMemo(() => {
    return tagsToMap(baseTagsGroup?.tags);
  },[baseTagsGroup])
  return (
    <Layout>
      <div >
        <h1>
          { `${tagModifiersGroupId}:${tagModifiersGroup?.name}`}
        </h1>
        <div className={styles.editButtons}>
          <LinkButton to={`/${routeLinks.tagModifiersGroups}/${tagModifiersGroupId}/${routeLinks.edit}`} >Edit</LinkButton> 
          <LinkButton to={`/${routeLinks.tagModifiersGroups}/${tagModifiersGroupId}/${routeLinks.copy}`} >Copy</LinkButton> 
        </div>
        <div>description: {`${tagModifiersGroup?.description}`}</div>
        <div>display: {`${tagModifiersGroup?.display}`}</div>
        <div>
          {tagModifiersArray && tagModifiersArray?.map((tagModifier) => {
            return <><ShowTagModifier key={tagModifier.id}
              {...{ tagModifier, tagDescriptionsMap }}
            /><div>---------------------</div></>;
          })}
        </div>
      </div>
    </Layout>
  );
}
