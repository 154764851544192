import { Button } from 'components/Buttons';
import { Modal } from "components/Modal";
import styles from "./styles.module.scss";
import TextField, { TextFieldType } from 'components/Forms/TextField';
import { useState } from "react";
import lz from "lz-string";
import { CastType } from 'actions/cast.types';

interface UpdateLinkCastModalProps {
  workingCast?: CastType;
  updateWorkingCast?: (updateCast: CastType, excludeCast?: boolean) => void;
  closeModal: () => void;
  getOnly?: boolean
}

export const UpdateLinkCastModal = ({
  workingCast,
  updateWorkingCast,
  closeModal,
  getOnly
}: UpdateLinkCastModalProps) => {
  const isGetOnly = getOnly || false;
  const [loadedText, setLoadedText] = useState('');
  const [loadedTextError, setLoadedTextError] = useState('');
  const onUpdateLink = async () => {
    const lzn = lz.compressToEncodedURIComponent(JSON.stringify(workingCast));
    setLoadedText(lzn); 
  }

  const updateLoader = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if(event.target?.value || event.target?.value === "") {
      setLoadedText(event.target?.value);
    } 
  }

  const onLoadFromLink = async () => {
    if(!isGetOnly && loadedText && updateWorkingCast) {
      try {
        const text = lz.decompressFromEncodedURIComponent(loadedText)
        const parsedWorkingCast = JSON.parse(text);
        
        if (!parsedWorkingCast) {
          throw new Error("Failed to parse");
        }

        updateWorkingCast(parsedWorkingCast);
        setLoadedTextError("");
      } catch(err) {
        setLoadedTextError("Failed To Load Text");
      }
    }
  }

  return (<Modal
    includeCloseButton={true}
    closeModal={() => { closeModal(); }}
    onBackgroundClick={() => { closeModal(); }}
  >
    <div className={styles.modal}>
      <div>
        {isGetOnly ?
        <h3>Get Code</h3>
        : <h3>Get or Load Code</h3>
        } 
      </div>
      <div>You can use a Code to load a team.</div>
      <div>
        <TextField 
          name="loader" 
          fieldClassName={styles.loaderField} 
          type={TextFieldType.textArea} 
          value={loadedText}
          onChange={updateLoader}
        ></TextField>
        {loadedTextError ? 
          (<div className={styles.error}>
            {loadedTextError}
          </div>) : null}
        <div className={styles.loaderButtons}>
          {!isGetOnly ? <Button
                      className={styles.editButton}
                      onClick={onLoadFromLink}
          >Load Team From Code</Button> : null}
          <Button
                      className={styles.editButton}
                      onClick={onUpdateLink}
          >Get Code From This Team</Button>
        </div>
      </div>
    </div>
  </Modal>);
};

export default UpdateLinkCastModal;