import { useMemo, useState, useCallback } from "react";
import Layout from "Layout";
import { useParams } from "react-router-dom";
import { ArchetypeType } from "actions/cast.types";
import { Button } from "components/Buttons/Button";
import { TextField, TextFieldType } from "components/Forms/TextField";
import { ShowArchetypeMember } from "components/ArchetypeMembers/ShowArchetypeMember";
import styles from "./styles.module.scss";
import { useNavigate } from "react-router-dom";
import { useArchetype } from 'hooks/archetypeMemberHooks';
import { useForm } from 'react-hook-form';
import { Form } from 'components/Forms/Form';
import JSONEditor from "components/JsonEditor"
import { tagsToMap } from "actions/helpers/tagHelpers";

type CastPageParams = {
  archetypeId: string;
};

interface SubmitArchetypeTypes {
  name?: string;
  description?: string;
  data: string;
}

const translateValues = (vals: any): ArchetypeType => {
  // title?: string;
  // type?: CastMemberClassType;
  // tags?: Array<TagType>;
  // amount?: number;

  return {
    name: vals?.name,
    display: vals?.display,
    description: vals?.description,
    data: vals.data
  }
}

export const CopyArchetype = () => {
  const { archetypeId } = useParams<CastPageParams>();
  const [, setSubmitting] = useState(false);
  const [, setSubmitError] = useState('');
  const navigate = useNavigate();

  const {
    name,
    archetype,
    createArchetype,
    baseTags,
    tagModifiers,
    archetypeDisplay
  } = useArchetype(archetypeId);

  const { register, handleSubmit, formState, setValue } = useForm({
    defaultValues: { 
      name: name,
      display: 0,
      description: archetype?.description,
      data: JSON.stringify(archetype?.data) 
    },
  });

  const tagDescriptionsMap = useMemo(() => {
    return tagsToMap(baseTags); 
  },[baseTags])

  const saveAndMerge = useCallback(async (vals: SubmitArchetypeTypes) => {
      try {
        setSubmitting(true);
        setSubmitError('');
        let attemptCreate = true;
        let parsedData = undefined;
        try {
          parsedData = JSON.parse(vals?.data);
        
        } catch {
          console.log("Failed to parse Data")
          attemptCreate = false;
        }
        
        if(attemptCreate) {
          const translatedValues = translateValues({ ...vals, data: parsedData });
          await (createArchetype && createArchetype(translatedValues));
          navigate(`/archetypes`);
          setSubmitting(false);
        }
        // forward to next page
      } catch(err) {
        setSubmitting(false);
        setSubmitError('Update to Cast Member Failed.');
      }
  }, [createArchetype, navigate]);
  const errors = formState?.errors;

  return (
    <Layout>
      <div >
        <h1>
          { `${archetypeId} : ${name}`}
        </h1>
        <div>
          <Form
            onSubmit={handleSubmit(saveAndMerge)}
          >
            <Button type="submit">Save</Button> 
            <TextField 
              type={TextFieldType.text} 
              placeholder="key"
              label="Name"
              labelClassName={styles.label}
              className={styles.entry}
              error={errors?.name}
              {...register("name",{
                validate:{
                  validLength: (val?: string) => {
                    return val && val?.length > 0;
                  }
                }
              })}
            />
            <TextField 
              type={TextFieldType.text} 
              placeholder="Description"
              label="Description"
              labelClassName={styles.label}
              className={styles.entry}
              error={errors?.description}
              {...register("description",{
                validate:{
                  validLength: (val?: string) => {
                    return val && val?.length > 0;
                  }
                }
              })}
            />
            <TextField 
              type={TextFieldType.number} 
              labelClassName={styles.label}
              className={styles.entry}
              placeholder="Display"
              label="Display"
              error={errors?.display}
              {...register("display",{
              })}
            />
            <TextField
              type={TextFieldType.textArea} 
              label="Data" 
              fieldClassName={styles.dataText}
              labelClassName={styles.label}
              className={styles.hidden}
              error={errors?.data}
              {...register("data",{
                validate:{
                  validLength: (val?: string) => {
                    return val && val?.length > 0;
                  }
                }
              })}
            />
            <JSONEditor
              value={JSON.stringify(archetype?.data)}
              className={styles.dataBlock}
              label="Data"
              labelClassName={styles.label}
              onChange={(changed, oldChanged) => {
                if(changed !== oldChanged && (typeof changed === "string" || typeof changed === 'undefined')) {
                  setValue('data', changed || '');
                }
              }}
            />
          </Form>
        </div>
        <div>
          {archetype?.data?.cast?.map((archetypeMember) => {
            return (<ShowArchetypeMember
              key={archetypeMember.key}
            {...{ archetypeMember, archetypeDisplay, tagModifiers, tagDescriptionsMap }}
            />);
          })}
        </div>
      </div>
    </Layout>
  );
}
