import { useSetChosenWorld, useGetChosenWorld, useGetWorld} from 'actions/worldActions';
import { useGetCurrentUser } from "hooks/userHooks";
import { defaultKey } from "lib/config";
export const useWorld = () => {
  const { data } = useGetChosenWorld();
  const setChosenWorld = useSetChosenWorld();
  const { isAdmin, isTester, id: userId } = useGetCurrentUser();
  const chosenWorldId = ((isAdmin || isTester) && data) ? data : defaultKey;
  const { data: chosenWorld } = useGetWorld(chosenWorldId, userId);
  
  return {
    setChosenWorld,
    chosenWorldId: chosenWorld ? chosenWorld.id : chosenWorldId, 
    chosenWorld: chosenWorld,
    isMultiWorld: isAdmin || isTester
  }
}