import Layout from "Layout";
import { useState } from "react";
import { useParams } from "react-router-dom";
import classNames from "classnames";

import { ArchetypeDisplayType, CastMemberType } from "actions/cast.types";
import { useGroupCasts } from 'hooks/archetypeMemberHooks';
import { useNavigate } from "react-router-dom";
import { ShowDisplayArchetypeMember } from "components/ArchetypeMembers/ShowArchetypeMember/BloodOfStarsArchetype";
import { LinkButton, Button, ButtonStyle } from 'components/Buttons';
import { Modal } from "components/Modal";
import styles from "./styles.module.scss";
import { useGetCastProps } from "./cast.hook";
import { TagType } from "actions/types";
import { TagDescriptorModal, useTagDescriptorModal } from "components/ArchetypeMembers/TagDescriptorModal";
import { GroupedTagsModal } from "components/Modals/GroupedTagsModal";
import { routeLinks } from "App/routing";
import { ViewCastMemberDisplayOptions } from "components/ViewCastMemberDisplayOptions";
import TacticsModal from "components/Modals/TacticsModal";
import UpdateLinkCastModal from "components/Modals/UpdateLinkCastModal";
import { ShrinkButton, useShrinkList } from "components/ShrinkList";
import { DivCutCornerBorder } from "components/DivCutCornerBorder";
import { useGetCurrentUser } from "hooks/userHooks";
import { TargetType } from "components/Buttons/Button";

interface ShowCastProps {
  displayCast?: Array<CastMemberType>;
  archetypeDisplay?: ArchetypeDisplayType; 
  onClickDescriptor?: (tag?: TagType) => void;
  openDetails?: (castMember: CastMemberType) => void;
  checkIsShrunk?: (key: string) => void;
  toggleShrink?: (key: string) => void;
}

interface CastEntryProps {
  displayArchetypeMember: CastMemberType;
  archetypeDisplay?: ArchetypeDisplayType;
  dragging?: boolean;
  cancelSelector?: string;
  setIsOpen?: (isOpen: boolean) => void;
  isOpen?: boolean;
  openDetails?: (castMember?: CastMemberType) => void;
  onClickDescriptor?: (tagType?: TagType) => void;
  className?: string;
  isShrunk?: boolean;
  toggleShrink?: () => void;
}

export const CastEntry = ({
  displayArchetypeMember,
  archetypeDisplay,
  openDetails,
  onClickDescriptor,
  className,
  isShrunk,
  toggleShrink 
}:CastEntryProps) => {
  return (
  <div className={styles.archetypeEntryCard}>
    <ShowDisplayArchetypeMember
                  {...{ 
                displayArchetypeMember,
                archetypeDisplay,
                onClickDescriptor,
                className,
                isShrunk,
                toggleShrink
              }}
    />
    {isShrunk ? null : (<div className={styles.modButtons}>
      <Button
        buttonStyle={ButtonStyle.secondary}
        onClick={() => { openDetails && openDetails(); }}
        className={styles.viewArchetypeButton}
      >
       {`+`}
      </Button>
    </div>)}
  </div>
);
};

export const ShowCast = ({
  displayCast,
  archetypeDisplay,
  onClickDescriptor,
  openDetails,
  checkIsShrunk: isShrunk,
  toggleShrink,
}: ShowCastProps) => {
  const { displayGroups } = useGroupCasts(displayCast, archetypeDisplay);
  return (
    <div>
      {displayGroups?.map((dg)=> {
        return (
          <div className={styles.archetypeGroupWrapper} key={dg.key}>
            <h4 className={styles.groupName}>{dg.name}</h4>
            <div className={styles.archetypeMembersWrapper}>
              {dg.casts?.map((castMember) => {
      
                const calculatedCastMember = castMember.calculatedCast;
                return <div key={calculatedCastMember?.key || castMember.key}>
                  <div>
                  {calculatedCastMember?.archetypeCast && (
                    <CastEntry
                      className={styles.showDisplayArchetypeMember}
                      {...{ 
                        displayArchetypeMember: calculatedCastMember,
                        archetypeDisplay,
                        onClickDescriptor,
                        openDetails: () => { openDetails && openDetails(castMember); },
                        isShrunk: !!(isShrunk && isShrunk(castMember.key)),
                        toggleShrink: () => { toggleShrink && toggleShrink(castMember.key); }
                      }}
                    />
                  )}
                  </div>
                </div>
              })}
            </div>
          </div>
        )
      })}
    </div>
  );
}

export type CastPageParams = {
  id: string;
};

export interface CastParams {
  readOnly?: boolean;
}

export const Cast = ({ readOnly }: CastParams) => {
  const { id: castId } = useParams<CastPageParams>();
  const navigate = useNavigate();
  const { id, isAdmin } = useGetCurrentUser(); 
  const {
    name,
    displayCast,
    totalPoints,
    totalUnits,
    archetypeDisplay,
    displayTagOptions,
    deleteCast,
    groupedTags,
    workingCast,
    displayTactics
  } = useGetCastProps(id, castId);
  const { showTagDescriptor, setShowTagDescriptor, onClickDescriptor } = useTagDescriptorModal();
  const [openDetailsCastMember, setOpenDetailsCastMember ] = useState<CastMemberType | undefined>(undefined);
  const [showCastMemberOptions, setShowCastMemberOptions ] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showTraitsModal, setShowTraitsModal] = useState(false);
  const [showTacticsModal, setShowTacticsModal] = useState(false);
  const [showLinkModal, setShowLinkModal] = useState(false);
  
  const onClickDelete = async () => {
    if(id && castId && parseInt(castId)) {
      let castIdInt;
      try {
        castIdInt = parseInt(castId);
      } catch(erropenDetails) {
      }
      
      if(castIdInt) {
        await deleteCast(castIdInt, id);
        navigate(`/${routeLinks.teams}`);
      }
    }
  }

  const openDetails = (openMember: CastMemberType) => {
    setOpenDetailsCastMember(openMember);
    setShowCastMemberOptions(true);
  }

  const { toggleShrink, toggleShrinkAll, allShrunk, isShrunk } = useShrinkList(displayCast);

  const showingAModal = showTraitsModal || showTacticsModal || showLinkModal || showCastMemberOptions;

  const showEditDel = !readOnly && (isAdmin || id === workingCast?.userId); 

  return (
    <Layout>
      <div>
        <div className={styles.sectionHeader}>
          <div className={styles.nameSection}>
            <h1>
              { `${name}`} 
            </h1>
            <div className={styles.sectionHeaderEditButtons}>
              {!showEditDel ? null : <LinkButton 
                to={`/${routeLinks.teams}/${castId}/${routeLinks.edit}`} className={styles.editButton}
                >
                  Edit
                </LinkButton>
              }
              {!showEditDel ? null : <Button 
                  className={styles.editButton}
                  onClick={() => { 
                    setShowDeleteModal(!showDeleteModal);
                  }}
                >
                  Delete
                </Button>
              }
            </div>
          </div>
          <div className={styles.editCast}>
            <Button 
              className={styles.editButton}
              onClick={() => { 
                setShowTraitsModal(!showTraitsModal);
              }}
            >Traits</Button>
            <Button 
              className={styles.editButton}
              onClick={() => { 
                setShowTacticsModal(!showTacticsModal);
              }}
            >Tactics</Button>
            <Button 
              className={styles.editButton}
              onClick={() => { 
                setShowLinkModal(!showLinkModal);
              }}
            >Code</Button>
            <LinkButton 
              className={styles.editButton}
              to={`/${routeLinks.teams}/${castId}/${routeLinks.printable}`}
              target={TargetType.blank}
            >Print</LinkButton>
            {!showEditDel ? null : <LinkButton 
              to={`/${routeLinks.teams}/${castId}/${routeLinks.edit}`} 
              className={classNames(styles.editButton, styles.editCastEditButtons)}
              >
                Edit
              </LinkButton>
            }
            {!showEditDel ? null : <Button 
                className={classNames(styles.editButton, styles.editCastEditButtons)}
                onClick={() => { 
                  setShowDeleteModal(!showDeleteModal);
                }}
              >
                Delete
              </Button>
            }
            <ShrinkButton {...{toggleShrinkAll, allShrunk, className: classNames(styles.editButton, styles.mobileHide) }} />
          </div>
        </div>
        <div className={classNames(styles.mobileSelectFooter,(showTagDescriptor || showingAModal) && styles.mobileHide)}>
          <DivCutCornerBorder
              className={classNames(styles.mobileSelect)}
              backgroundClassName={styles.mobilePointsBackground}
            ><div className={styles.mobilePoints}>Pts: {totalPoints} ({totalUnits})</div></DivCutCornerBorder>
            <Button
              onClick={() => {
                toggleShrinkAll();
              }}
              className={classNames(styles.mobileSelectButton)}
            >{allShrunk ? `expand` : `shrink`}</Button>
        </div>
        <div className={styles.detailsHeader}>
          <div className={styles.unitCount}>
            Units: {totalUnits}
          </div>
          <div>
            Points: {totalPoints}
          </div>
        </div>
        <div className={classNames(styles.chooseCasts)}>
          <div>
            <ShowCast
              {...{
                displayCast,
                totalPoints,
                archetypeDisplay,
                onClickDescriptor,
                openDetails,
                checkIsShrunk: isShrunk,
                toggleShrink 
              }}
            />
          </div>
        </div>
      </div>
      {!showDeleteModal ? null : <Modal>
        <div className={styles.deleteModal}>
          <div>
            Are you Sure you want to delete?
          </div>
          <div>

          <Button
                      className={styles.editButton}
                      onClick={onClickDelete}
          >
            Yes, Delete</Button>
          <Button
                      className={styles.editButton}
                      onClick={() => {
                        setShowDeleteModal(false);
                      }}
          > No, go Back
          </Button>
          </div>
        </div>
      </Modal>}
      {!showTraitsModal ? null : <GroupedTagsModal
          closeModal={() => {
            setShowTraitsModal(false);
          }}
          groupedTags={groupedTags}
        />
      }
      {!showTacticsModal ? null : <TacticsModal
          closeModal={() => {
            setShowTacticsModal(false);
          }}
          tacticCasts={displayTactics}
          {...{onClickDescriptor,
            archetypeDisplay,
          openDetails
          }}
        />
      }
      { showCastMemberOptions && openDetailsCastMember && <Modal
          onBackgroundClick={() => { setShowCastMemberOptions(false)}}
          onClick={() => { setShowCastMemberOptions(false) }}
          closeModal={() => { setShowCastMemberOptions(false) }}
          includeCloseButton={true}
        >
          <ViewCastMemberDisplayOptions 
            castMember={ openDetailsCastMember}
            archetypeDisplay={archetypeDisplay}
            editTagOptions={displayTagOptions}
          ></ViewCastMemberDisplayOptions>
        </Modal>
        }
        {!showLinkModal ? null : <UpdateLinkCastModal
        closeModal={() => {
          setShowLinkModal(false);
        }}
          workingCast={workingCast}
          getOnly={true}
      />}
            { showTagDescriptor && <TagDescriptorModal
          tag={showTagDescriptor}
          close={() => {
            setShowTagDescriptor(undefined)
          }} 
        />
      }
    </Layout>
  );
}
