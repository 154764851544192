import MonacoEditor from '@monaco-editor/react';

interface MonacoEditorProps {
  onChange?: (value?: string, event?: any) => void;
  value?: string;
  height?: string;
}

export const Editor = ({
  onChange,
  value,
  height
}: MonacoEditorProps) => {
return (
    <MonacoEditor
      height={height}
      defaultLanguage="markdown"
      defaultValue="// some comment"
      value={value}
      onChange={(value, event) => {
        onChange && onChange(value, event);
      }}
    />
  );
}