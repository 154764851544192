import { Icon } from "../index";
import {  ArchetypeType } from 'actions/cast.types';
import classNames from "classnames";
import styles from "./styles.module.scss";

interface ArchetypeIconProps {
  className?: string;
  archetype: ArchetypeType;
}


export const ArchetypeIcon = ({
  className,
  archetype
}: ArchetypeIconProps

) => {
return <Icon className={classNames(styles.archetypeSize, className)} icon={archetype?.data?.icon || archetype?.icon}/> 
}

interface TagIconProps {
  className?: string;
  tagKey: string;
}

enum TagIconKey {
  Standing = "Standing",
  Move = "Move",
  Fight = "Fight",
  Shoot = "Shoot",
  Evade = "Evade",
  Life = "Life",
  Points = "Points",
};

export const TagIcon = ({
  className,
  tagKey
}: TagIconProps) => {
  switch(tagKey) {
    case(TagIconKey.Standing):
      return "";
    case(TagIconKey.Move):
    case(TagIconKey.Fight):
    case(TagIconKey.Shoot):
    case(TagIconKey.Evade):
    case(TagIconKey.Life):
    case(TagIconKey.Points):
    default:
      return <>tagKey</>;
  }
}