import { useCallback } from "react";
import Layout from "Layout";
import { Button } from "components/Buttons/Button";
import { TextField, TextFieldType } from "components/Forms/TextField";
import styles from "./styles.module.scss";
import { FormType } from "components/Forms/Form";
import { useDocForm } from "pages/Docs/useDocForm";
import { Form } from 'components/Forms/Form';
import { useNavigate } from "react-router-dom";
import { DocType } from "actions/doc.types";
import { routeLinks } from "App/routing";
import classNames from "classnames";

export const NewDoc = () => {
  const navigate = useNavigate();

  const submitCallback = useCallback((docs?: DocType) => {
    navigate(`/${routeLinks.docs}/${docs?.id}`);
  }, [navigate]);

  const {
    errors,
    onSubmit,
    register,
    submitting,
    submitError  } = useDocForm({ formType: FormType.Create, callback: submitCallback });

  return (
    <Layout>
      <div >
        <h1>
          New Doc
        </h1>
        <div>
          <Form
            onSubmit={onSubmit}
          >
            <Button type="submit">Save</Button>
            {!submitting && submitError && (<div>{submitError}</div>)} 
            <TextField 
              type={TextFieldType.text}
              placeholder="key"
              label="Name"
              labelClassName={styles.label}
              className={styles.entry}
              error={errors?.name}
              {...register("name",{
                validate:{
                  validLength: (val?: string) => {
                    return (val && val?.length > 0) || "Cannot be blank";
                  }
                }
              })}
            />
            <TextField 
              type={TextFieldType.text}
              placeholder="Description"
              label="Description"
              labelClassName={styles.label}
              className={styles.entry}
              error={errors?.description}
              {...register("description",{
                validate:{
                  validLength: (val?: string) => {
                    return (val && val?.length > 0) || "Field Must not be empty.";
                  }
                }
              })}
            />
            <TextField 
              type={TextFieldType.number}
              labelClassName={styles.label}
              className={styles.entry}
              placeholder="Display"
              label="Display"
              error={errors?.display}
              {...register("display",{
              })}
            />
            <TextField
              type={TextFieldType.textArea}
              label="Data" 
              fieldClassName={styles.dataText}
              labelClassName={styles.label}
              className={classNames(false && styles.hidden)}
              error={errors?.markdown}
              {...register("markdown",{
                validate:{
                  validLength: (val?: string) => {
                    return (val && val?.length > 0) || "Cannot be blank";
                  }
                }
              })}
            />
          </Form>
        </div>
      </div>
    </Layout>
  );
}
