import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { TagType } from 'actions/types';
import { TagDescriptionType } from 'actions/tag.types';

interface ValTagDescriptionDetailsProps {
  tagDescriptions?: Array<TagDescriptionType>;
}

export const ValTagDescriptionDetails = ({ tagDescriptions}: ValTagDescriptionDetailsProps) => {
  return <>
    {(tagDescriptions && tagDescriptions.length > 0) ? `(` : ``}
    { tagDescriptions?.map((t) => {
      return <div key={`${t.id}-${t.key}`}>
        {t.title || t.key}: {t.description}
      </div>
    })}
    {tagDescriptions && tagDescriptions.length > 0 ? `)` : ``}
  </>
}

interface ValTagDescriptionProps {
  tag: TagType;
}

export const ValTagDescription = ({tag}: ValTagDescriptionProps) => {
  return <div className={classNames(styles.tagDescription)}>
         {tag.title || tag.key}: {tag.description}{tag.valDescriptions && tag.valDescriptions.length > 0 ? <ValTagDescriptionDetails tagDescriptions={tag.valDescriptions} /> : undefined}
      </div>
}
