
import { TagType } from "actions/types";
import { SectionType } from "actions/cast.types";
import { displayVal } from "lib/display.helpers";
import { Button, ButtonStyle} from 'components/Buttons';

import { ArchetypeDisplayType, ArchetypeSectionType, CastMemberType } from "actions/cast.types";
import { ShowDisplayArchetypeMember } from "components/ArchetypeMembers/ShowArchetypeMember/BloodOfStarsArchetype";
import { tagsToMap } from "actions/helpers/tagHelpers";
import styles from "./styles.module.scss";
import { SectionKeys } from "hooks/archetypeMemberHooks";
import { StandingType, getCastStanding, getStandingTag } from "lib/archetype.helpers";

interface CastEntryProps {
   castMember: CastMemberType;
   archetypeDisplay?: ArchetypeDisplayType;
   dragging?: boolean;
   cancelSelector?: string;
   setIsOpen?: (isOpen: boolean) => void;
   isOpen?: boolean;
   openEdit?: (castMember?: CastMemberType) => void;
   openUpOrDown?: (castMember?: CastMemberType) => void;
   onClickDescriptor?: (tagType?: TagType) => void;
   isShrunk?: boolean;
   toggleShrink?: () => void;
   numberOfEntries?: number
}

interface CastEntrySectionDisplay {
  section: ArchetypeSectionType;
  castMember: CastMemberType;
}

export const CastDisplaySection = ({castMember, section }: CastEntrySectionDisplay) => {
  switch(section.key) {
    case SectionKeys.primary:
    case SectionKeys.leaderTraits:
      return null;
    case SectionKeys.traitConditions:
       return (<CastDisplaySectionCondition castMember={castMember} section={section} />);
    case SectionKeys.statline:
      return (<CastDisplaySectionDefault castMember={castMember} section={section} />);
    case SectionKeys.injuries:
    case SectionKeys.tagOptions:
    case SectionKeys.gear:
    case SectionKeys.upgrades:
      return (<CastDisplaySectionOptions castMember={castMember} section={section} />);
    default:
     return (<CastDisplaySectionDefault castMember={castMember} section={section} />);
  }
};

export const CastDisplaySectionDefault = ({castMember, section }: CastEntrySectionDisplay) => {
  const mainTagsMap = castMember.tagsMap || tagsToMap(castMember.tags); 
  const tagOptionsMap = castMember.tagOptionsMap || tagsToMap(castMember.tagOptions); 
  const tagsMap = section?.type === SectionType.options ? tagOptionsMap : mainTagsMap;
  const keys = section.tagKeys.map((tk) => {
      const currentVal = (typeof tk === "string" && tagsMap.get(tk)) || undefined; 
      if (currentVal) {
        return `${currentVal.key}${ currentVal.val === 0 || currentVal.val ? `: ${currentVal.val}` : ``}`
      } else {
        return '';
      }
    }).filter((c) => { return c !== '';}).join(', ');
    if (keys) {
      return <div>
        {section.name}: {keys}
      </div>
    } else {
      return null;
    }
}

export const CastDisplaySectionOptions = ({castMember, section }: CastEntrySectionDisplay) => {
  const tagsMap = castMember?.tagOptionsMap || 
    (castMember.tagOptions && tagsToMap(castMember.tagOptions)) 
    || new Map<string, TagType>();
  const keys = section.tagKeys.map((tk) => {
      const tagKey = typeof tk === "string" ? tk : tk.tagKey || "";
      const currentVal = tagsMap.get(tagKey); 
      if (currentVal) {
        return `${currentVal.key}${ currentVal.val === 0 || currentVal.val ? `: ${currentVal.val}` : ``}`
      } else {
        return '';
      }
    }).filter((c) => { return c !== '';}).join(', ');
    if (keys) {
      return <div>
        {section.name}: {keys}
      </div>
    } else {
      return null;
    }
}

export const CastDisplaySectionCondition = ({castMember, section }: CastEntrySectionDisplay) => {
  const tagsMap = castMember.tagsMap || tagsToMap(castMember.tags);
  const keys = section.tagKeys.map((tk) => {
    const tagKey = typeof tk === "string" ? tk : tk.tagKey || "";
      const currentVal = tagsMap.get(tagKey); 
      if (currentVal) {
        return `${currentVal.key}${ currentVal.val === 0 || currentVal.val ? `: (${displayVal(currentVal.val)})` : ``}`
      } else {
        return '';
      }
    }).filter((c) => { return c !== '';}).join(', ');
    if (keys) {
      return <div>
        {section.name}: {keys}
      </div>
    } else {
      return null;
    }
}

export const CastEntry = ({
    castMember,
    archetypeDisplay,
    openEdit,
    openUpOrDown,
    onClickDescriptor,
    isShrunk,
    toggleShrink,
    numberOfEntries
}:CastEntryProps) => {
  const castStanding = getCastStanding(castMember);
  return (
    <div className={styles.archetypeEntryCard}>
      <ShowDisplayArchetypeMember
                    {...{ 
                  displayArchetypeMember: castMember,
                  archetypeDisplay,
                  onClickDescriptor,
                  isShrunk,
                  toggleShrink
                }}
      />
      {!isShrunk && <div className={styles.modButtons}>
        {numberOfEntries && numberOfEntries > 1 && <Button
            buttonStyle={ButtonStyle.secondary}
            onClick={() => { openUpOrDown && openUpOrDown(); }}
            className={styles.viewUpDownButton}
          >
           ↑ ↓ 
          </Button>
        }
        {!isShrunk && !(castStanding === StandingType.team || castStanding === StandingType.tactic) && 
          <Button
            buttonStyle={ButtonStyle.secondary}
            onClick={() => { openEdit && openEdit(); }}
            className={styles.viewArchetypeButton}
          >
           + 
          </Button>
        } 
      </div>}
    </div>
  );
};
