// Generic types

export interface TagSelector {
  key?: string;
  tagKey?: string;
  tag?: TagType | Array<TagType>;
  thresholds?: Array<TagThresholdType>;
  andOrThresholds?: TagThresholdAndOr; // whether the threshold children are and or or types
}

export interface TagType {
    id?: string | number;
    key: string;
    baseKey?: string; // The base key val, if the key is different.
    title?: string;
    val?: string | number | string[] | number[] | (string | number)[];
    val2?: string | number | string[] | number[] | (string | number)[];
    tagGroupKey?: string;
    linkBy?: LinkByType; // Assume Key is the default
    valCanBeModified?: boolean;
    // "numberAllowed": "unlimited",
    // "valCanBeModified": true,
    displaySubTags?: Array<TagType> ;
    groups?: Array<string>;
    description?: string;
    valDescriptions?: Array<TagDescriptionType>;
    createdAt?: string;
    updatedAt?: string;
    valAreTagKeys?: boolean; // If the  tag value are keys to search for
    computed?: boolean; // if this did not have a base state, but was instead computed.
    modified?: boolean; // if this did have a base state, but was modified by a computation.
}

export interface DisplayTagType extends TagType {
  label?: string;
};

export interface BaseTagsGroupType {
  id?: string | number;
  key?: string;
  name?: string;
  display?: number;
  description?: string;
  createdAt?: string;
  updatedAt?: string;
  data: {
    tags: Array<TagType>;
    groupings?: Array<TagGroupingType>;
  };
  tags?: Array<TagType>;
  groupings?: Array<TagGroupingType>;
}

export interface TagGroupingType {
  id?: string | number;
  key: string;
  title?: string;
  description?: string;
  createdAt?: string;
  updatedAt?: string;
  thresholds: Array<TagSelector | string> ;
  tags?: Array<TagType>; // the tags that might have been grouped by the taggroup.
}

export interface TagDescriptionType {
  id?: string | number;
  key: string;
  title?: string;
  description?: string;
  createdAt?: string;
  updatedAt?: string;
}

export enum LinkByType {
  Key = "key", // link by the tag key. This is the default
  Id = "id", // link by the id
  KeyVal = "keyVal", // link by the Key-val combo.
}

export enum TagModifierSymbol {
    Add = 'add', // add to value to related tag, adding tag when not exists.
    AddValue = 'addValue', // add the value of the tag to the related tag, adding tag when not exists.
    Multiply = 'multiply', // multiply value to related tag
    Cancel = 'cancel', // when value is greater than 0, then zero out related tag.
    ValueTag = 'valueTag', // Calculate the modifiers of the tags in the values 
    AddArray = 'addArray', // Add Modifier for each entry in array 
    MultiplyAdd = 'multiplyAdd', // Add Modifier for each entry in array 
    ValueTagFilterResults = 'valueTagFilterResults', // Calculate the modifiers of the tags in the values, but only use a selection of the results.
    ValueTagFilterOutResults = 'valueTagFilterOutResults', // Calculate the modifiers of the tags in the values, but filter out a selection of the results.
    AddValueAsTags = 'addValueAsTags', // add the values as tags. (i.e. the value in the tag is added as a tag)
}


export enum TagThresholdSymbolType {
  GreaterOrEqual = "greaterOrEqual", // tag val is greater or equal.
  LessOrEqual = "lessOrEqual", // tag val is equal or less
  Greater = "greater", // tag val is greater than
  Less = "less", // tag val is less than
  Equal = "equal", // val is equal 
  NotEqual = "notEqual",
  Exists = "exists", // tag exists, threshold & key matches. If the exactMatch is not true, you can get baseKey = thresholdKey.
  ExistsInVal = "existsInVal", // tag exists, threshold & key matches. 
  DoesNotExist = "doesNotExist", // tag does not eixst
  LessOrNotExists = "lessOrNotExists", // tag values are less, or tag does not exist
  LessOrEqualOrNotExists = "lessOrEqualOrNotExists", // tags are equal or less or does not exist
  InGroup = "inGroup" // tag is in a certain group
}

export enum TagThresholdAndOr {
  And = "and",
  Or = "or"
}

export interface TagThresholdType {
    andOrThresholds?: TagThresholdAndOr; // whether the threshold children are and or or types
    thresholds?: Array<TagThresholdType>; //child thresholds 'OR'.
    tag?: TagType;
    thresholdKey?: string;
    threshold?: number | string;
    thresholdVal?: string;
    thresholdTriggerBelow?: boolean; // depreciate
    tagThresholdSymbol?: TagThresholdSymbolType;
    exactMatch?: boolean // if true, only check key, not baseKey
}

export interface TagModifiersGroupType {
  id?: string | number;
  key?: string;
  name?: string;
  display?: boolean;
  description?: string;
  createdAt?: string;
  updatedAt?: string;
  data?: {
    tagModifiers: Array<TagModifierType>
  };
  tagModifiers?: Array<TagModifierType>;
}

export interface TagModifierType {
    id?: string | number;
    primaryTagKey: string;
    relatedTagKey: string;
    symbol: TagModifierSymbol;
    primaryIsBaseKey?: boolean; 
    val?: number | string;
    val2?: number | string;
    priority: number;
    thresholds?: Array<TagThresholdType>;
    description?: string;
    createdAt?: string;
    updatedAt?: string;
}

export interface TagModifiersType {
  id: number;
  name: string;
  description: string;

  data: {
    tagModifiers: Array<TagModifierType>;
  };
  tagModifiers?: Array<TagModifierType>;
  createdAt: string;
  updatedAt: string;
};


