import PrintableLayout from "Layout/PrintableLayout";
import { Fragment } from "react";
import { useParams } from "react-router-dom";
import classNames from "classnames";

import { TagGroupingType } from "actions/tag.types";

import { ArchetypeDisplayType, CastMemberType } from "actions/cast.types";
import { useGroupCasts } from 'hooks/archetypeMemberHooks';
import { ShowDisplayArchetypeMember } from "components/ArchetypeMembers/ShowArchetypeMember/BloodOfStarsArchetype";
import styles from "./styles.module.scss";
import { useGetCastProps } from "./cast.hook";
import { TagType } from "actions/types";
import { useGetCurrentUser } from "hooks/userHooks";
import { getTagKey } from "actions/helpers/tagHelpers";
import ShowTag from "components/Tags/ShowTag";
import { StandingType } from "lib/archetype.helpers";

interface ShowCastProps {
  displayCast?: Array<CastMemberType>;
  archetypeDisplay?: ArchetypeDisplayType; 
  onClickDescriptor?: (tag?: TagType) => void;
  openDetails?: (castMember: CastMemberType) => void;
  checkIsShrunk?: (key: string) => void;
  toggleShrink?: (key: string) => void;
}

interface CastEntryProps {
  displayArchetypeMember: CastMemberType;
  archetypeDisplay?: ArchetypeDisplayType;
  dragging?: boolean;
  cancelSelector?: string;
  setIsOpen?: (isOpen: boolean) => void;
  isOpen?: boolean;
  openDetails?: (castMember?: CastMemberType) => void;
  onClickDescriptor?: (tagType?: TagType) => void;
  className?: string;
  isShrunk?: boolean;
  toggleShrink?: () => void;
}

export const PrintableCastEntry = ({
  displayArchetypeMember,
  archetypeDisplay,
  onClickDescriptor,
  className,
  isShrunk,
  toggleShrink 
}:CastEntryProps) => {
  return (
  <div className={styles.archetypeEntryCard}>
    <ShowDisplayArchetypeMember
                  {...{ 
                displayArchetypeMember,
                archetypeDisplay,
                onClickDescriptor,
                className,
                isShrunk,
                toggleShrink,
                printMode: true
              }}
    />
  </div>
);
};

export const ShowPrintableCast = ({
  displayCast,
  archetypeDisplay,
  onClickDescriptor,
}: ShowCastProps) => {
  const { displayGroups } = useGroupCasts(displayCast, archetypeDisplay);
  return (
    <div className={styles.archetypeMembersWrapper}>
      {displayGroups?.map((dg)=> {
        if(dg?.key === StandingType.tactic) {
          return null;
        }

        return (
          <Fragment  key={dg.key}>
              {dg.casts?.map((castMember) => {
      
                const calculatedCastMember = castMember.calculatedCast;
                return <div key={calculatedCastMember?.key || castMember.key} className={styles.castMember}>
                  <div className={styles.memberWrapper}>
                    <div className={styles.groupName}>{dg.name}</div>
                  {calculatedCastMember?.archetypeCast && (
                    <PrintableCastEntry
                      className={styles.showDisplayArchetypeMember}
                      {...{ 
                        displayArchetypeMember: calculatedCastMember,
                        archetypeDisplay,
                        onClickDescriptor,
                        openDetails: () => { },
                        isShrunk: false,
                        toggleShrink: () => {  }
                      }}
                    />
                  )}
                  </div>
                </div>
              })}
          </Fragment>
        )
      })}
    </div>
  );
}

export type CastPageParams = {
  id: string;
};

export interface CastParams {
  readOnly?: boolean;
}

export const PrintableCast = () => {
  const { id: castId } = useParams<CastPageParams>();
  const { id } = useGetCurrentUser(); 
  const {
    name,
    description,
    displayCast,
    totalPoints,
    totalUnits,
    archetypeDisplay,
    groupedTags,
    displayTactics
  } = useGetCastProps(id, castId);

  return (
    <PrintableLayout>
      <div className={styles.pageBlock}>
        <div className={styles.sectionHeader}>
            <h1>
            { `${name}`} 
          </h1>
         <div className={styles.detailsHeader}>
           <div className={styles.unitCount}>
             Units: {totalUnits}
           </div>
           <div>
             Points: {totalPoints}
           </div>
         </div>
        </div>
        {description && <div>{description}</div>}
        <div className={classNames(styles.chooseCasts)}>
          <div>
            <ShowPrintableCast
              {...{
                displayCast,
                totalPoints,
                archetypeDisplay,
                onClickDescriptor: () => {},
                openDetails: () => {},
                checkIsShrunk: () => { return false; },
                toggleShrink: () => {}
              }}
            />
          </div>
        </div>
      </div>
      <div className={styles.pageBlock}>

        <div className={classNames(styles.tactics)}>
          <h2>Tactics</h2>
          <div className={styles.tacticsBlock}>

            {displayTactics?.map((tc, key) => {
              return <div className={classNames(styles.spacing, styles.tacticBlock)} key={tc.key}>
                <div 
                className={classNames(styles.tacticHeader)}

                >{key+1}: </div> <ShowDisplayArchetypeMember
                    {...{ 
                      displayArchetypeMember: tc,
                      archetypeDisplay,
                      onClickDescriptor: () => {}
                    }}
                  />
              </div>
            })}
          </div>
        </div>
        <h2>Traits</h2>
        <div className={classNames(styles.traitsList)}>
          <ShowTagsGroups groupedTags={groupedTags} tagClassName={styles.tagBlock} />
        </div>
      </div>
    </PrintableLayout>
  );
}


interface ShowTagsGroupsProps {
  groupedTags?: Array<TagGroupingType>;
  tagClassName?: string;
}
interface ShowTagsProps {
  tagsTitle?: string;
  tags?: Array<TagType>;
  tagClassName?: string;
}

export const ShowTagsGroups = ({ groupedTags, tagClassName }: ShowTagsGroupsProps) => {
  return (        <div>
    {groupedTags?.map((group) => {
      return <div key={group.id || group.key}>

          <ShowTags tagsTitle={group.title} tags={group.tags} tagClassName={tagClassName} />
      </div>;
    })}
    
  </div>);
};

const ShowTags = ({tagsTitle, tags, tagClassName}: ShowTagsProps) => {

  const [first, ...rest] = tags?.length && tags.length > 0 ? tags : [undefined]; 

  return (<>
        <div className={styles.firstTagWrapper}>

        <h3 className={styles.tagTitle}>{tagsTitle}</h3>
        {first && (<div className={styles.tagWrapper}>
          <ShowTag className={tagClassName}
              {...{ tag: first }}
            />
        </div>)}
        </div>
        <div>
            {rest?.map((tag) => {
            return tag && <div key={tag.id || getTagKey(tag)} className={styles.tagWrapper}><ShowTag key={tag.id} className={tagClassName}
              {...{ tag }}
            /></div>;
          })}
        </div>
  </>
  )
}