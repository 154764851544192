import { useState, useMemo, useCallback, useEffect } from "react";

import { TagType } from "actions/types";
import { useGetCast, useUpdateCast, useCreateCast, useDeleteCast } from "actions/castActions";
import { CastMemberClassType, CastMemberType, CastType, UpdateCastMemberType } from "actions/cast.types";
import { useDisplayArchetypeMembers } from "hooks/archetypeMemberHooks";
import { addTagDescriptions } from "actions/helpers/tagHelpers";
import { cloneDeep, clone, times } from "lodash";
import { v4 as uuidv4 } from 'uuid';
import { useArchetype } from 'hooks/archetypeMemberHooks';
import { tagsToMap } from "actions/helpers/tagHelpers";
import { flatten } from "lodash";
import { sendToastal } from "components/Toastal";

const PointsTagKey = "Points";

const DefaultData: CastType = {
  id: 0,
  userId: 0,
  name: 'Cast',
  description: 'description...',
  data: {
    cast: [],
    tags: []
  },
  createdAt: undefined,
  updatedAt: undefined,
};

const sumCast = (casts: Array<CastMemberType>) => {
  return casts.reduce((total, c) => {
    const sumCast = c?.calculatedCast || c?.archetypeCast || c;
    const tagCost = parseInt((sumCast.tagsMap ? (sumCast.tagsMap.get(PointsTagKey)?.val || 0) : (sumCast.tags.find(() => { return sumCast.key === PointsTagKey; })?.val || 0)).toString(), 10);
    return total + (sumCast?.amount && sumCast?.amount > 0 ? sumCast?.amount : 1) * tagCost;
  }, 0);
}

const sumCastSize = (casts: Array<CastMemberType>) => {
  return casts.reduce((total, c) => {
    return total + (c?.amount && c?.amount > 0 ? c?.amount : 1);
  }, 0);
}

const useGetTactics = (displayCast: Array<CastMemberType>, defaultFactions?: Array<number | string>) => {
  const {
    archetype  } = useArchetype(defaultFactions && defaultFactions.length > 0 ? defaultFactions[0] : undefined)

  const filteredDefaultTactics = useMemo(() => {
    const tactics = archetype?.data.cast?.filter((f) => {
      return f.type = CastMemberClassType.tactic;
    })
    return tactics || [];

  }, [archetype]);

  const filteredSelectedTactics = useMemo(() => {

  const tactics = displayCast.filter((f) => {
    return f.type === CastMemberClassType.tactic;
  })
  return tactics || [];
},
[displayCast]);

  return {
    tactics: [...filteredDefaultTactics, ...filteredSelectedTactics]
};
};

export const useGetCastProps = (userId?: number, castId?: number | string, worldId?: number) => {

  const [workingCast, setWorkingCastBase] = useState(DefaultData);
  const [isDirty, setIsDirty] = useState(false);
  const [initialLoad, setInitialLoad] = useState(false);

  const setWorkingCast = useCallback((newWorkingCast: CastType, isClean?: boolean) => {
    setWorkingCastBase(newWorkingCast);
    setIsDirty(isClean ? true : false);
  }, [setWorkingCastBase, setIsDirty]);


  const { data } = useGetCast(userId, castId, {
    onSuccess: (newData: CastType) => {
      if(!initialLoad) {
        setInitialLoad(true);
        setWorkingCast(newData, true);
      }
    }
  });

  useEffect(() => {
    setWorkingCast(data || DefaultData, true);
  }, [data, setWorkingCast]);

  const updateCast = useUpdateCast();
  const createCast = useCreateCast();
  const deleteCast = useDeleteCast();

  const foundData = { ...DefaultData, ...data};

  const { name, data: castData } = foundData;
  const archetypeId = castData?.prototypeCastKey || workingCast?.archetypeId;

  const {
    tagModifiers,
    baseTags,
    archetypeDisplay,
    groupedTags,
    archetype
  } = useArchetype(archetypeId);

  const editArchetypeDisplay = archetypeDisplay;
  const archetypeData = archetype;

  const tagDescriptionsMap = useMemo(() => {
    return tagsToMap(baseTags);
  }, [baseTags]);

  const { 
    displayArchetypeMembers,
    displayArchetypeMembersMap,
  } = useDisplayArchetypeMembers({
    archetypeMembers: archetype?.data?.cast,
    tagModifiers,
    tagDescriptionsMap: tagDescriptionsMap
  });

  const archetypeMembersMap = useMemo(() => {
    return new Map<string, CastMemberType>((archetype?.data?.cast || []).map((c) => {
      return [c.key, c]
    }));
  }, [archetype?.data?.cast])

  const tags = castData?.tags || [];

  const [updatedTags, setUpdatedTags] = useState<Array<TagType> | undefined>();

  // Use Key where found to replace archetype base.
  const curWorkingCast = (workingCast?.data?.cast || []).reduce((
    resultCast: CastMemberType[], curVal: CastMemberType) => {
      const newVal = {...curVal};
      const archetypeCast = newVal.archetypeKey ? archetypeMembersMap.get(newVal.archetypeKey) : undefined;
      if (archetypeCast) {
        newVal.archetypeCast = archetypeCast;
      }
      resultCast.push(newVal); 
      return resultCast;
  }, []);

  const { 
    displayArchetypeMembersMap: displayCastMembersMap,
  } = useDisplayArchetypeMembers({
    archetypeMembers: curWorkingCast,
    tagModifiers,
    tagDescriptionsMap: tagDescriptionsMap
  });

  const displayCast = useMemo(() => {
    const cast: Array<CastMemberType> = curWorkingCast;
    return cast.map<CastMemberType>((c) => {
      const archetypeCast = c.archetypeKey ? archetypeMembersMap.get(c.archetypeKey) : undefined;
      const calculatedArchetypeCast = c.archetypeKey ? displayArchetypeMembersMap.get(c.archetypeKey) : undefined;
      const calculatedCast = c.key ? displayCastMembersMap.get(c.key) : undefined;
      return {
        ...c,
        archetypeCast: archetypeCast,
        calculatedArchetypeCast: calculatedArchetypeCast,
        calculatedCast: calculatedCast
      };
    });
  }, [archetypeMembersMap, curWorkingCast, displayArchetypeMembersMap, displayCastMembersMap]);

  const displayCastMap = useMemo(() => {
    return new Map(displayCast.map((dc) => {
      return [dc.key, dc]
    }));
  }, [displayCast]);

  const totalPoints = useMemo(() => {
    const curCast = displayCast;
    return (curCast && sumCast(curCast)) || 0;
  }, [displayCast]);

  const totalUnits = useMemo(() => {
    const curCast = displayCast;
    const total = curCast.reduce((total, c: CastMemberType) => {
      return total + ((c.type !== CastMemberClassType.tactic && c.type !== CastMemberClassType.team) ? 1 : 0);
    }, 0);
    return total || 0;
  }, [displayCast]);  

  const totalCastSize = useMemo(() => {
    const curCast = workingCast?.data?.cast;
    return (curCast && sumCastSize(curCast)) || 0;
  }, [workingCast]);

  const workingCastMemberMap = useMemo(() => {
    return new Map(workingCast.data?.cast?.map((dc) => {
      return [dc.key, dc];
    })) || new Map<string, CastMemberType>();
  }, [workingCast]);

  const addArchetypeToWorkingCast = (castMember: CastMemberType, amount: number) => {
    if (amount > 0) {
      const archetypeKey = castMember.key || castMember?.archetypeKey || castMember.archetypeCast?.key || "" ; 
      const baseArchetypeCastMember = workingCastMemberMap.get(archetypeKey) || archetypeMembersMap.get(archetypeKey) || castMember; 
      const newWorkingCast = { ...workingCast};

      newWorkingCast.data = {...workingCast.data };
      newWorkingCast.data.cast = [...(newWorkingCast.data.cast || []) ];
      times(amount, () => {
        let updatedWorkingCastMember = cloneDeep(baseArchetypeCastMember);
        const nc = newCast(updatedWorkingCastMember); 
        nc.key = uuidv4();
        newWorkingCast?.data?.cast?.push(nc); 
      });
      setWorkingCast(newWorkingCast); 
    }
  }

  // Each gets an entry.
  const updateNumberOfMembersInWorkingCast = (castMember: CastMemberType, amount: number) => {
    if ( amount > 0 || amount < 0) {
      const workingCastMember = workingCastMemberMap.get(castMember.key);
      const newWorkingCastMemberMap = new Map(workingCastMemberMap);
      const baseWorkingCastMember = workingCastMember ? workingCastMember : castMember; 

      if (amount < 0) {
        newWorkingCastMemberMap.delete(castMember.key);
      } else if (!workingCastMember || amount > 0) {
        // Doesn't exist, create a new one.
        const updatedWorkingCastMember = cloneDeep(baseWorkingCastMember);
        const nc = newCast(updatedWorkingCastMember);
        nc.key = uuidv4();

        newWorkingCastMemberMap.set(nc.key, nc);
      }

      const newWorkingCast = { ...workingCast};
      newWorkingCast.data = {...workingCast.data };

      newWorkingCast.data.cast = Array.from(newWorkingCastMemberMap.values());
      setWorkingCast(newWorkingCast); 
    }
  }

  // Update Cast member
  const updateCastMemberInWorkingCast = useCallback((castMember: CastMemberType, castMemberUpdates: UpdateCastMemberType) => {
    const cc = updateCastMember(castMember, castMemberUpdates); 
  
    const newWorkingCast = { ...workingCast};
    newWorkingCast.data = {...workingCast.data };
    const newWorkingCastMemberMap = [...(workingCast.data?.cast || [])]; 
    newWorkingCast.data.cast = newWorkingCastMemberMap; 
  
    const newCast = newWorkingCastMemberMap.reduce((result: CastMemberType[], curVal) => {
      const foundCast = curVal.key === cc.key;

      if(foundCast) {
        result.push(cc);
      } else {
        result.push(curVal);
      }

      return result;
    }, []);

    newWorkingCast.data.cast = newCast;
    setWorkingCast(newWorkingCast);  
  }, [setWorkingCast, workingCast]);

  const updateOrderInWorkingCast = useCallback((castMember: CastMemberType, amount: number) => { 
    if(!amount) {
      // no amount shift, don't change.
      return;
    } else {
      const newWorkingCast = { ...workingCast};
      newWorkingCast.data = {...workingCast.data };
      const newWorkingCastMemberMap = [...(workingCast.data?.cast || [])];
      newWorkingCast.data.cast = newWorkingCastMemberMap;

      let insertedVal = false;

      const newCast = newWorkingCastMemberMap.reduce((result: CastMemberType[], curVal, index) => {
        const indexOffset = index + -1 * amount;
        const foundOffsetCast = workingCast?.data?.cast?.[indexOffset];

        // Move this cast here
        if (foundOffsetCast?.key === castMember.key && amount < 0) {
          result.push(foundOffsetCast);
          insertedVal = true;
        }

        if (curVal.key !== castMember.key || !amount) {
          result.push(curVal);
        }

        if (foundOffsetCast?.key === castMember.key && amount > 0) {
          result.push(foundOffsetCast);
          insertedVal = true;
        }

        return result;
      }, []);

      if(!insertedVal && amount && amount !== 0) {
        // If negative move, put in front, otherwise, move to back.
        if(amount > 0) {
          newCast.push(castMember);
        } else {
          newCast.unshift(castMember);
        }
      }

      newWorkingCast.data.cast = newCast;
      setWorkingCast(newWorkingCast);  
    }
  },[workingCast, setWorkingCast]);

  // Update Cast member
  const updateWorkingCast = useCallback((castToUpdate: CastType, excludeCast = false) => {
    const newUpdatedCast = { ...castToUpdate};
    const newWorkingCast = { ...workingCast};

    const finalWorkingCastData = { ...newWorkingCast.data, ...newUpdatedCast.data };
    finalWorkingCastData.cast = (!excludeCast 
      && newUpdatedCast?.data?.cast
      && newWorkingCast?.data?.cast !== newUpdatedCast?.data?.cast) ? 
      newUpdatedCast?.data?.cast 
      : newWorkingCast.data?.cast;
   
    // we should not update the following: id
    // userId (giving userId to another person would give your cast to them.)
    const finalCast = {
        ...workingCast,
        ...castToUpdate,
        id: workingCast.id,
        userId: workingCast.userId,
        data: finalWorkingCastData 
      };
  
    setWorkingCast(finalCast);  
  }, [setWorkingCast, workingCast]);

  const saveWorkingCast = (newCastId?: string | number) => {
    if(!userId) {
      console.log('no user Id, unknown save');
    } else if(workingCast.id) {
      updateCast(workingCast.id, workingCast, userId, worldId);
      sendToastal(`Saved ${workingCast.name || workingCast.id}`);
    } else {
      createCast(workingCast, userId, worldId, newCastId);
      sendToastal(`Saved ${workingCast.name || workingCast.id}`);
    }
  }

  const displayTagOptions = addTagDescriptions(archetypeData?.data?.tagOptions, tagDescriptionsMap) || []; 


  const { tactics: allTactics } = useGetTactics(displayCast, archetype?.data?.defaultFactions);


  const { 
    displayArchetypeMembersMap: displayTacticsMap,
    displayArchetypeMembers: displayTactics
  } = useDisplayArchetypeMembers({
    archetypeMembers: allTactics,
    tagModifiers,
    tagDescriptionsMap: tagDescriptionsMap
  });

  return {
    name,
    tags: updatedTags || tags,
    totalPoints: totalPoints,
    totalUnits,
    totalCastSize: totalCastSize,
    displayCast,
    tagOptions: archetypeData?.data?.tagOptions,
    tagDescriptionsMap,
    displayCastMap,
    displayArchetypeMembers,
    displayArchetypeMembersMap,
    displayTagOptions: displayTagOptions,
    archetypeDisplay,
    editArchetypeDisplay,
    addArchetypeToWorkingCast,
    updateNumberOfMembersInWorkingCast,
    updateOrderInWorkingCast,
    updateCastMemberInWorkingCast,
    updateWorkingCast,
    updateCast,
    createCast,
    deleteCast,
    setUpdatedTags: (tagsToUpdate: Array<TagType> | undefined) => { setUpdatedTags(tagsToUpdate); },
    workingCast,
    saveWorkingCast,
    setWorkingCast,
    updateCastMember,
    isDirty,
    initialLoad,
    groupedTags,
    allTactics,
    displayTactics,
    displayTacticsMap
  };
}

const updateCastMember = ( baseCast: CastMemberType, castMemberUpdates: UpdateCastMemberType): CastMemberType => {
  const {
      key, 
      name,
      title,
      type,
      tags,
      tagOptions,
      archetypeKey,
      archetypeCast,
      amount
  } = baseCast;

  const baseArchetypeCast = archetypeCast;
  const clonedArchetypeCast = baseArchetypeCast ? clone(baseArchetypeCast) : undefined;
  const clonedArchetypeKey = archetypeKey || (clonedArchetypeCast?.key ? clonedArchetypeCast.key : undefined);
  let newTags = tags ? [...tags] : []; 
  const newTitle = castMemberUpdates?.title ? castMemberUpdates?.title : title;
  const baseTagMap = tagsToMap(newTags); 

  // we replace tagOptions
  const newTagOptions = castMemberUpdates?.tagOptions || tagOptions;

  if (castMemberUpdates.tags) {
    castMemberUpdates.tags.forEach((t) => {
      baseTagMap.set(t.key, t);
    })

    // replace tags
    newTags = flatten(Array.from(baseTagMap.values()));
  }

  return {
    key, 
    name,
    title: newTitle,
    type,
    tags: newTags,
    tagOptions: newTagOptions,
    tagsMap: baseTagMap,
    archetypeKey: clonedArchetypeKey,
    archetypeCast: clonedArchetypeCast,
    amount
  };
};

// New cast.
const newCast = (baseCast: CastMemberType): CastMemberType => {
  const {
      key, 
      name,
      type,
      tags,
      tagsMap,
      archetypeKey,
      archetypeCast,
      amount
  } = baseCast;

  const baseArchetypeCast = archetypeCast ? archetypeCast : baseCast;
  const clonedArchetypeCast = baseArchetypeCast ? clone(baseArchetypeCast) : undefined;
  const clonedArchetypeKey = archetypeKey || (clonedArchetypeCast?.key ? clonedArchetypeCast.key : undefined);
  const newTags = tags ? [...tags] : []; 

  return {
    key, 
    name,
    type,
    tags: newTags,
    tagsMap: tagsMap ? new Map(tagsMap) : tagsToMap(newTags), 
    archetypeKey: clonedArchetypeKey,
    archetypeCast: clonedArchetypeCast,
    amount
  };
};

export interface CastPageParams {
  id: string;
};
