import Layout from "Layout";
import { Link } from "react-router-dom";
import { useGetLocalCasts } from "actions/localCastActions";
import { useUserState } from "atoms/users.atom";
import styles from './styles.module.scss';
import { routeLinks } from "App/routing";
import { useWorld } from "hooks/worldHooks";
import { useGetCasts } from "actions/castActions";
import { useState } from "react";
import { DisplayKeys } from "lib/config";

export const LocalCasts = () => {
  const { state: userState } = useUserState();
  const { chosenWorldId} = useWorld();
  const { data } = useGetLocalCasts();
  const casts = data || [];
  const { data: renownData } = useGetCasts(`guest`, chosenWorldId, DisplayKeys.renown, true);

  const [showRenown, setShowRenown] = useState(false);
  
  return (
    <Layout>
      <div >
        <div className={styles.sectionHeader}>
          <h1>
            Local Teams {userState?.userName}
          </h1>
        </div>
        <div>
          {
            casts?.map((cast) => {
              return (<div key={cast.id} className={styles.entries}><Link to={`/${routeLinks.guest}/${routeLinks.localTeams}/${cast.id}`} >{cast.id}: {cast.name}</Link></div>)
            })
          }
        </div>
        <div>
          <div className={styles.sectionCastsOfRenown} onClick={() => { setShowRenown(!showRenown); }}>
            Teams of Renown
          </div>
          {showRenown && <div>
            {
              renownData?.map((cast, idx) => {
                return (<div key={cast.id} className={styles.entries}><Link to={`/${routeLinks.guest}/${routeLinks.renownTeams}/${cast.id}`} >{idx + 1}: { !cast.name ? `${cast.id}` : `${cast.name}`}</Link></div>)              })
            }
          </div>}
        </div>
      </div>
    </Layout>
  );
}
