import Layout from "Layout";
import { RequestForm } from './RequestForm';

export const RequestEmailVerification = () => {

  return (
    <Layout>
      <div>
          <h2>Re Request Email Verification</h2>
          <RequestForm />
      </div>
    </Layout>
  );
}
