import {useState, useCallback} from "react";
import Layout from "Layout";
import { Button } from "components/Buttons";
import UserInfo from "components/UserInfo";
import Modal from "components/Modal";
import { useAuthState } from 'atoms/auth.atom';
import { useUserState } from 'atoms/users.atom';
import { deleteUser } from "actions/userActions";
import { contactEmail } from "lib/config";

export const UserMe = () => {
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
  const [deleteError, setDeleteError] = useState("");
  const { setState: setAppState } = useAuthState();
  const { userId, setState: setUserState } = useUserState();
  const deleteAccount = useCallback(async () => {
    if(userId) {
      setDeleteError("")
      
      try{
      await deleteUser(userId);

      setUserState({});
      setAppState({ Authenticated: false, Authenticating: false });
      } catch(err) {
        setDeleteError(`Sorry, we failed to delete your account. Please contact ${contactEmail}`)
      }
    }
  },[setUserState, setAppState, userId]);

  return (
    <Layout>
      <div>
          <h2>Your User Info</h2>
          <UserInfo />

          <div>
            If you Wish to delete your account, you can press the following:
            <Button onClick={() => {setShowDeleteUserModal(true)}}>Delete Account</Button>
          </div>
      </div>
      {showDeleteUserModal && (<Modal onBackgroundClick={() => { setShowDeleteUserModal(false); }}>
        <h2>Delete your Account?</h2>
          You will no longer be able to use the app, and will not be able to re-register with this email.
          {deleteError && <div>{deleteError}</div>}
          <div>
            <Button onClick={deleteAccount}>Yes, Delete</Button>
          </div>
          <div>
            <Button onClick={() => { setShowDeleteUserModal(false)}}>No, go Back.</Button>
          </div>
      </Modal>)}
    </Layout>
  );
}
