import Layout from "Layout";
import { ResetForm } from './ResetForm';

export const RequestPasswordReset = () => {

  return (
    <Layout>
      <div>
          <h2>Request Password Reset</h2>
          <ResetForm />
      </div>
    </Layout>
  );
}
