import React from 'react';
import { useAuthState } from 'atoms/auth.atom';
import { useUserState } from 'atoms/users.atom';
import { Button, LinkButton } from 'components/Buttons';
import { logoutUser } from 'actions/userActions';

/// stateless Component.
/// If we need some presentation state (Forms, etc), use React.Component
const Nav = () => {
  // Nav for all pages
  const { state: appState, setState: setAppState } = useAuthState();
  const { setState: setUserState } = useUserState();
  const onLogout = async () => {
    await logoutUser();
      
    setUserState({});
    setAppState({ Authenticated: false, Authenticating: false });
  };

  return (
    <>
      { appState && appState.Authenticated
        ? (
          <Button onClick={onLogout}>Logout</Button>
        ) : (
          <LinkButton to="/login"> Login </LinkButton>
        )
      }
    </>
  );
};

export default Nav;
