import Layout from "Layout";
import Logo from "assets/images/icons/BloodOfStarsLogo.png";
import styles from "./styles.module.scss";
import { externalLinks, routeLinks } from 'App/routing/routeLinks';
import { Link } from "react-router-dom";
import { useGetCurrentUser } from "hooks/userHooks";

export const Home = () => {
  const { isGuest } = useGetCurrentUser(); 
  
  return (
    <Layout>
      <div className={styles.centre}>
          <img src={Logo} className={styles.logo} alt="logo"></img>
          <p>Hello, and welcome to the Blood of Stars Force Builder.</p>
          <p>View <Link to={`${isGuest ? `/${routeLinks.guest}` : ``}/${routeLinks.factions}`}>Factions</Link></p>
          <p>View or Create <Link to={`${isGuest ? `/${routeLinks.guest}/${routeLinks.localTeams}` : `/${routeLinks.teams}`}`}>Team Lists</Link></p>
          <p><Link to={`${externalLinks.bloodOfStars}`}>Blood of Stars Main Site</Link></p>
      </div>
    </Layout>
  );
}
