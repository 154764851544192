import Layout from "Layout";
import { Link } from "react-router-dom";
import { routeLinks } from "App/routing";
import { useGetUsers } from "actions/userActions";
import {  useGetCurrentUser } from "hooks/userHooks";

import styles from "./styles.module.scss";
export const Users = () => {
  const { isAdmin } = useGetCurrentUser(); 
  const { data } = useGetUsers(); 

  if(!isAdmin) {
    return (<Layout>
      <div >
        <h1>
          Users:
        </h1>
        <div>
           Requires Admin Params. 
        </div>
      </div>
    </Layout>);
  }

  return (
    <Layout>
      <div >
        <h1>
          Users:
        </h1>
        <div>
          {
            data?.map((user) => {
              return (<div key={user.id} className={user.deleted ? styles.deleted : ``}>
                <Link to={`/${routeLinks.users}/${user.id}`}  >
                  {user.id}: {user.email} {user.deleted ? `(deleted)` : ``} {user.firstName ? `(${user.firstName}${user.lastName ? ` ${user.lastName}` : ``})` : ``}{ user.role ? ` [${user.role}]` : ``}
                </Link>
              </div>);
            })
          }
        </div>
      </div>
    </Layout>
  );
}
