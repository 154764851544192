import { useCallback } from "react";
import axios from "axios";
import * as base from "./lib/baseActions";
import { apiEndpoints } from "lib/config";
import { TagType,
   TagModifiersGroupType,
  } from "actions/tag.types";
import useSWR from 'swr';
import { mutate } from 'swr';
import { 
  exampleTagModifiersGroupMock, 
} from "actions/mocks/tagMocks";

export interface TagsResult {
  data: Array<TagType>;
};

export interface TagResult {
  data: TagType;
};

export const cleanTagModifiersGroup = (tagModifierGroup: TagModifiersGroupType) => {
  return {
    ...tagModifierGroup,
    tagModifiers: tagModifierGroup?.data?.tagModifiers,
  }
}

export const getTagModifiersGroup = async(tagModifiersGroupId?: string | number | (string | number)[], worldId?: string | number, userId?: string | number) : Promise<TagModifiersGroupType> => {
  let endpoint = ``;
  if( tagModifiersGroupId && Array.isArray(tagModifiersGroupId)) {
      endpoint = `${worldId ? `${apiEndpoints.worlds}/${worldId}/` : ``}${userId ? `${apiEndpoints.users}/${userId}/` : `${apiEndpoints.users}/guest/`}${apiEndpoints.tagModifiersGroups}/?tagModifierIds=${tagModifiersGroupId.join(',')}`;
  } else {
    const foundTagModifiersGroup = [exampleTagModifiersGroupMock].find((ea) => { return `${ea.id}` === `${tagModifiersGroupId}` });
    if (foundTagModifiersGroup) {
      const archetype =  cleanTagModifiersGroup(foundTagModifiersGroup);
      return archetype;
    } else {
      endpoint = `${worldId ? `${apiEndpoints.worlds}/${worldId}/` : ``}${userId ? `${apiEndpoints.users}/${userId}/` : `${apiEndpoints.users}/guest/`}${apiEndpoints.tagModifiersGroups}/${tagModifiersGroupId}`;
    } 
  }
  try {
    const tag = await base.get(endpoint);

    return cleanTagModifiersGroup(tag.data);
  } catch (err: any) {
    err.message = "Failed to get a tag.";
    throw err;
  }
}

export const getAllTagModifiersGroup = async(worldId?: string | number, userId?: string | number) : Promise<Array<TagModifiersGroupType>> => {
  try {
    const tagModifiers = await base.get(`${worldId ? `${apiEndpoints.worlds}/${worldId}/` : ``}${userId ? `${apiEndpoints.users}/${userId}/` : `${apiEndpoints.users}/guest/`}${apiEndpoints.tagModifiersGroups}`);
    const tagModifiersData = tagModifiers.data ? tagModifiers.data : [];
    return [...[], ...tagModifiersData]
      .map((tagModifiersGroup) => {
        return cleanTagModifiersGroup(tagModifiersGroup);
      });
  } catch (err: any) {
    err.message = "Failed to get a tags.";
    throw err;
  }
}

type useGetTagModifiersGroupSWRType = [
  string, string | number | undefined, string | number | undefined, string | number | undefined
] 

export const useGetTagModifiersGroup = (tagModifiersGroupId?: string | number, worldId?: string | number, userId?: string | number) => {
  return useSWR<TagModifiersGroupType>(tagModifiersGroupId ?
    [
      `${worldId ? `${apiEndpoints.worlds}/${worldId}/` : ``}${userId ? `${apiEndpoints.users}/${userId}/` : `${apiEndpoints.users}/guest/`}${apiEndpoints.tagModifiersGroups}/${tagModifiersGroupId}`,
      tagModifiersGroupId,
      worldId,
      userId
    ] : null,
    ([key, tagModifierGroupId, worldId, userId]: useGetTagModifiersGroupSWRType) => { 
      return getTagModifiersGroup(tagModifierGroupId, worldId, userId);
    }
  );
};

type useGetTagModifiersGroupsSWRType = [
  string, (string | number)[], string | number | undefined, string | number | undefined
] 

export const useGetTagModifiersGroups = (tagModifiersGroupIds?: (string | number)[], worldId?: string | number, userId?: string | number) => {
  return useSWR<TagModifiersGroupType>(tagModifiersGroupIds ?
    [
      `${worldId ? `${apiEndpoints.worlds}/${worldId}/` : ``}${userId ? `${apiEndpoints.users}/${userId}/` : `${apiEndpoints.users}/guest/`}${apiEndpoints.tagModifiersGroups}/?tagModifierGroupsIds=${tagModifiersGroupIds.join(',')}`,
      tagModifiersGroupIds.join(','),
      worldId,
      userId
    ] : null,
    ([key, tagModifiersGroupIds, worldId]: useGetTagModifiersGroupsSWRType) => { 
      return getTagModifiersGroup(tagModifiersGroupIds, worldId, userId);
    }
  );
};

type  useGetAllTagModifierGroupsSWRType = [
  string, string | number | undefined, string | number | undefined
] 

export const useGetAllTagModifierGroups = (worldId?: string | number, userId?: string | number) => {
  return useSWR<Array<TagModifiersGroupType>>(
    [
      `${worldId ? `${apiEndpoints.worlds}/${worldId}/` : ``}${userId ? `${apiEndpoints.users}/${userId}/` : `${apiEndpoints.users}/guest/`}${apiEndpoints.tagModifiersGroups}`,
      worldId,
      userId
    ],
    ([key, worldId, userId]: useGetAllTagModifierGroupsSWRType) => { 
      return getAllTagModifiersGroup(worldId, userId);
    }
  );
};

// Create Tag Modifiers Group
export const createTagModifiersGroup = async (tagModifiersGroup: TagModifiersGroupType, worldId?: number | string) : Promise<TagModifiersGroupType> => {
  try {
    const endpoint = worldId ? `${apiEndpoints.worlds}/${worldId}/${apiEndpoints.tagModifiersGroups}` : `${apiEndpoints.tagModifiersGroups}`; 
    
    const result = await base.post(endpoint, tagModifiersGroup);
    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      err.message = "Failed to get a tag.";
    }
    throw err;
  }
};

export const updateTagModifiersGroup = async (tagModifierGroupId : number | string, tagModifiersGroup: TagModifiersGroupType, worldId?: number | string) : Promise<TagModifiersGroupType> => {
    try {
      const result = await base.put(`${worldId ? `${apiEndpoints.worlds}/${worldId}/` : ``}${apiEndpoints.tagModifiersGroups}/${tagModifierGroupId}`, tagModifiersGroup);
      return result.data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        err.message = "Failed to get a tag.";
      }
      throw err;
    }
};

export const deleteTagModifiersGroup = async (tagModifierGroupId : number | string, worldId?: number | string) : Promise<TagModifiersGroupType> => {
  try {
    const result = await base.del(`${worldId ? `${apiEndpoints.worlds}/${worldId}/` : ``}${apiEndpoints.tagModifiersGroups}/${tagModifierGroupId}`);
    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      err.message = "Failed to delete a tag modifiers group.";
    }
    throw err;
  }
};

export interface CreateTagModifiersGroupFunctionType {
  (tagModifiersGroup: TagModifiersGroupType, worldId?: string | number):  Promise<TagModifiersGroupType> 
}

export const useCreateTagModifiersGroup = () : CreateTagModifiersGroupFunctionType => {
  return useCallback(async (tagModifiersGroup: TagModifiersGroupType, worldId?: string | number) => {
    const result = await createTagModifiersGroup(tagModifiersGroup, worldId);
    const tagModifierGroupId = result.id;
    mutate([`/world/${worldId || ``}/archetype/${tagModifierGroupId}`], result); 
    return result;
  }, []);
};

export interface UpdateTagModifiersGroupFunctionType {
  (tagModifierGroupId: number | string, cast: TagModifiersGroupType, worldId?: number | string):  Promise<TagModifiersGroupType> 
} 

export const useUpdateTagModifiersGroup = () : UpdateTagModifiersGroupFunctionType => {
  return useCallback(async (tagModifierGroupId: number | string, tagModifiersGroup: TagModifiersGroupType, worldId?: number | string) => {
    const result = await updateTagModifiersGroup(tagModifierGroupId, tagModifiersGroup, worldId);
    mutate([`/world/${worldId || ``}/archetype/${tagModifierGroupId}`], result);
    return result;
  },[]);
}

export interface DeleteTagModifiersGroupFunctionType {
  (tagModifierGroupId: number | string, worldId?: number | string):  Promise<TagModifiersGroupType> 
} 

export const useDeleteTagModifiersGroup = () : DeleteTagModifiersGroupFunctionType => {
  return useCallback(async (tagModifierGroupId: number | string, worldId?: number | string) => {
    const result = await deleteTagModifiersGroup(tagModifierGroupId, worldId);
    mutate([`/world/${worldId || ``}/archetype/${tagModifierGroupId}`], result);
    return result;
  },[]);
}

