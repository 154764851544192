import { useState, useMemo, useCallback } from "react";
import classNames from "classnames";

import { getTagKey, tagsToMap } from "actions/helpers/tagHelpers";
import { TagType,  } from "actions/types";
import { UpdateCastMemberType } from "actions/cast.types";
import { ArchetypeDisplayType, ArchetypeSectionType, CastMemberType } from "actions/cast.types";
import { filterTagsBySelectors } from "lib/thresholds";

import styles from "./styles.module.scss";
import { StandingType, getCastStanding } from "lib/archetype.helpers";
import { SectionKeys } from "hooks/archetypeMemberHooks";
import {  DisplayTag, DisplayTagVal } from "components/ViewArchetypeDisplayOptions"

interface CastMemberEntryProps {
   castMember: CastMemberType;
   archetypeDisplay?: ArchetypeDisplayType;
   editTagOptions?: Array<TagType>;
   saveCastMemberEntry?: (castMember: CastMemberType, updateEntries?: UpdateCastMemberType) => void;
   closeEdit?: (castMember?: CastMemberType) => void;
   onClickDescriptor?: (tag?: TagType) => void;
}

interface CastEntrySectionDisplay {
  section: ArchetypeSectionType;
  castMember: CastMemberType;
  editTagOptions?: Array<TagType>;
  tags?: Array<TagType>;
  tagsMap?: Map<string, TagType>;
  allTagsMap?: Map<string, TagType>;
}

// Blood of Stars Section Keys
export const sectionKeys = {
  primary: 'primary',
  points: 'points',
  statline: 'statline',
  profileTraits: 'traits',
  abilityTraits: 'abilityTraits',
  gear: 'gear',
  factionSpecialRules: 'factionSpecialRules',
  factionCampaignUpgrades: 'factionCampaignUpgrades',  
  injuries: 'injuries',
  upgrades: 'upgrades',
  traitConditions: 'traitConditions',
  leaderTraits: 'leaderTraits',
  xp: 'xp',
  tacticOptions: 'tacticOptions',
};

export const BaseCastDisplaySection = ({castMember, section, editTagOptions, tags, tagsMap }: CastEntrySectionDisplay) => {
  const tagOptions = new Map<string,TagType>(tagsToMap(editTagOptions));
  const filteredEditTagOptions = filterTagsBySelectors(section.tagKeys, Array.from(tagOptions.values()));

  switch(section.key) {
    case SectionKeys.factionSpecialRules:
    case SectionKeys.gear:
      return (<CastDisplaySectionDefault 
        castMember={castMember}
        section={section}
        tags={tags}
        tagsMap={tagsMap}
        editTagOptions={filteredEditTagOptions}
      />);
    default:
      return null;
  }
};

export const CampaignCastDisplaySection = ({castMember, section, editTagOptions, tags, tagsMap }: CastEntrySectionDisplay) => {
  const tagOptions = new Map<string,TagType>(tagsToMap(editTagOptions));
  const filteredEditTagOptions = filterTagsBySelectors(section.tagKeys, Array.from(tagOptions.values()));

  switch(section.key) {
    case SectionKeys.upgrades:
    case SectionKeys.factionCampaignUpgrades:
    case SectionKeys.injuries:
    case SectionKeys.xp:
      return (<CastDisplaySectionDefault 
        castMember={castMember}
        section={section}
        tags={tags}
        tagsMap={tagsMap}
        editTagOptions={filteredEditTagOptions}
      />);
    default:
      return null;
  }
};

export const TacticCastDisplaySection = ({castMember, section, editTagOptions, tags, tagsMap }: CastEntrySectionDisplay) => {
  const tagOptions = new Map<string,TagType>(tagsToMap(editTagOptions));
  const filteredEditTagOptions = filterTagsBySelectors(section.tagKeys, Array.from(tagOptions.values()));

  switch(section.key) {
    case SectionKeys.tacticOptions:
      return (<CastDisplaySectionDefault 
        castMember={castMember}
        section={section}
        tags={tags}
        tagsMap={tagsMap}
        editTagOptions={filteredEditTagOptions}
      />);
    default:
      return null;
  }
};

export const CastDisplaySectionStatline = ({castMember, section }: CastEntrySectionDisplay) => {
  const keys = filterTagsBySelectors(section.tagKeys, castMember.tags);

  if (keys) {
    return <div>
      <h4>
        {section.name}:
      </h4>
      <div className={styles.statline}>
        {
          keys.map((k, index) => {
            return <div key={k.key} className={styles.statlineEntry}>
                {k.key}: {Array.isArray(k.val) ? k.val.join(', ') : k.val}{ keys.length > 0 && index !== keys.length - 1 ? `,` : ``}
              </div>
          })
        }
      </div>
    </div>
  } else {
    return null;
  }
}

export const CastDisplaySectionDefault = ({
  section,
  editTagOptions,
  tagsMap
}: CastEntrySectionDisplay) => {
  return <div>
    <h4>
      {section.name}:
    </h4>
    <div>
      <div>
        {editTagOptions?.map((etg) => {
          const exists = tagsMap && tagsMap.get(getTagKey(etg));
          const finalTag = exists ? { ...etg, ...exists} : etg;
          
          if(finalTag.valCanBeModified) {
            return (<div key={getTagKey(finalTag)}>
              <div
                className={classNames(styles.tagOptionButton, exists && styles.selectedTagOptionButton)}
              >
                <DisplayTagVal tag={finalTag} />
              </div>
            </div>)  
          }
          return (<div key={getTagKey(etg)}>
            <div
            className={classNames(styles.tagOptionButton, exists && styles.selectedTagOptionButton)}
            >
              <DisplayTag tag={etg} />
            </div>
          </div>)
        })}
      </div>
    </div>
  </div>
}

export const CastDisplaySectionTrait = ({castMember, section }: CastEntrySectionDisplay) => {
  const keys = filterTagsBySelectors(section.tagKeys, castMember.tags);

  if (keys) {
    return <div>
      <h4>
        {section.name}:
      </h4>
      <div className={styles.statline}>
        {
          keys.map((k, index) => {
            return <div key={k.key} className={styles.statlineEntry}>
              {k.key}{k.val ? ` (${Array.isArray(k.val) ? k.val.join(', ') : k.val})` : null}{ keys.length > 0 && index !== keys.length - 1 ? `,` : ``}
            </div>
          })
        }
      </div>
    </div>
  } else {
    return null;
  }
}

export const CastDisplaySectionCondition = ({castMember, section }: CastEntrySectionDisplay) => {
  const keys = filterTagsBySelectors(section.tagKeys, castMember.tags);

  if (keys) {
    return <div>
      <h4>
        {section.name}:
      </h4>
      <div>
        {
          keys.map((k) => {
            return <div key={k.key}>
              {k.key}{k.val ? `(${Array.isArray(k.val) ? k.val.join(', ') : k.val})` : null}
            </div>
          })
        }
      </div>
    </div>
  } else {
    return null;
  }
}

export const ViewCastMemberDisplayOptions = ({
    castMember,
    archetypeDisplay,
    editTagOptions}:CastMemberEntryProps) => {

  const tagsMap = useMemo(() => {
    return castMember.tagsMap || tagsToMap(castMember?.tags);
  }, [castMember]);
  const [updateTagOptions, setUpdateTagOptions] = useState<TagType[]>(castMember?.tagOptions || []);

  const filteredTagOptions = useMemo(() => {
    const newEditTagOptions = editTagOptions?.filter((eto) => {
      const foundTag = tagsMap.get(getTagKey(eto));
      if(foundTag) {
        return false;
      } else {
        return true;
      }
    });

    return newEditTagOptions;

  }, [tagsMap, editTagOptions]);
  
  // chosen tagOptions
  const allTagsMap = useMemo(() => {
    const startTagsMap = new Map(castMember.tagsMap || tagsToMap(castMember.tags));

    castMember?.tagOptions?.forEach((to) => {
      startTagsMap.set(to.key, to);
    });

    updateTagOptions.forEach((ut) => {
     startTagsMap.set(ut.key, ut);
    });

   return startTagsMap;

  }, [castMember, updateTagOptions]);

  // availableTagOptions.
  const updateTagOptionsMap = useMemo(() => {
    const tagsMap = tagsToMap(updateTagOptions); 

    return tagsMap;
  },[updateTagOptions]);

  const setUpdateTagOption = useCallback(() => {
  }, [])

  const standing = useMemo(() => {
    return getCastStanding(castMember);
  }, [castMember]) ; 

  return (
      <div className={styles.archetypeEntryCard}>
          <div className={classNames(styles.archetypeTitle)}>
          </div>
          {standing === StandingType.tactic ? <TacticOptions
            {...{ 
              archetypeDisplay,
               castMember,
                updateTagOptions,
                 updateTagOptionsMap, 
                 allTagsMap, 
                 setUpdateTagOptions, 
                 setUpdateTagOption, 
                 filteredTagOptions
            }}
          /> :
          <UnitOptions
            {...{ 
              archetypeDisplay,
               castMember,
                updateTagOptions,
                 updateTagOptionsMap, 
                 allTagsMap, 
                 setUpdateTagOptions, 
                 setUpdateTagOption, 
                 filteredTagOptions
            }}
          />}
      </div>
  )
};

interface UnitOptionsProps {
  archetypeDisplay?: ArchetypeDisplayType;
   castMember: CastMemberType;
   updateTagOptions: TagType[];
    updateTagOptionsMap: Map<string, TagType>;
  allTagsMap: Map<string, TagType>;
  filteredTagOptions?: TagType[];
}


const TacticOptions = ({
  archetypeDisplay,
   castMember,
   updateTagOptions, 
   updateTagOptionsMap, 
   allTagsMap, 
   filteredTagOptions
}: UnitOptionsProps) => {
  const filterSection = useMemo(() => {
    const standing = getCastStanding(castMember);;
    return archetypeDisplay?.data?.sections.filter((s) => {
    if(standing === StandingType.tactic) {
      switch(s.key) {
        case SectionKeys.tacticOptions:
          return true
        default: 
            return null;
      }
    } else {
      return true;
    }
  }) || []; 
  }
  ,[archetypeDisplay?.data?.sections, castMember])
  return <div>
    <div>
      {filterSection.map((s) => {
        return <TacticCastDisplaySection
          key={s.key}
          section={s}
          castMember={castMember}
          tags={updateTagOptions}
          tagsMap={updateTagOptionsMap}
          editTagOptions={filteredTagOptions} />;
      })}
    </div> 
  </div>;
}


const UnitOptions = ({
  archetypeDisplay,
   castMember,
   updateTagOptions, 
   updateTagOptionsMap, 
   allTagsMap, 
   filteredTagOptions
}: UnitOptionsProps) => {
  const filterSection = archetypeDisplay?.data?.sections || [];
  return <div>
    <div>
      {filterSection.map((s) => {
        return <BaseCastDisplaySection
          key={s.key}
          section={s}
          castMember={castMember}
          tags={updateTagOptions}
          tagsMap={updateTagOptionsMap}
          editTagOptions={filteredTagOptions} />;
      })}
    </div>
    <h3>Campaign Options</h3>
    <div>
      {filterSection.map((s) => {
        return <CampaignCastDisplaySection
          key={s.key}
          section={s}
          castMember={castMember}
          tags={updateTagOptions}
          tagsMap={updateTagOptionsMap}
          editTagOptions={filteredTagOptions} />;
      })}
    </div>
  </div>;
}
