import { useEffect, useState } from "react";
import Layout from "Layout";
import { Link, useNavigate, useParams } from "react-router-dom";
import { confirmEmail } from 'actions/userActions';
import { userIsAuthorized } from 'actions/userActions';
import { useUserState } from "atoms/users.atom";
import { useAuthState } from 'atoms/auth.atom';
import Modal from 'components/Modal';
import UserInfo from "components/UserInfo";
import { Button, LinkButton } from "components/Buttons";
import styles from "./styles.module.scss";
import { helpEmail } from "lib/config";

export const ConfirmEmail = () => {
  const { token } = useParams();
  const [emailConfirmed, setEmailConfirmed] = useState(false);
  const [attemptLoad, setAttemptLoad] = useState(false);
  const navigate = useNavigate(); 
  const { setState: setUserState } = useUserState();
  const { setState: setAppState } = useAuthState();
  const [viewConfirmedModal, setViewConfirmedModal] = useState(false);
  
  useEffect(() => {
    if(token && !attemptLoad) {
      setAttemptLoad(true);
      const tryConfirmEmail = async () => {
        try {
          const result = await confirmEmail(token);
          setEmailConfirmed(true);

          if(result && result.data) {
            setUserState(result.data); // { email: results.data.email, userName: results.data.first_name });
          }
          setAppState({ Authenticated: userIsAuthorized(), Authenticating: false });
          setViewConfirmedModal(true);
        } catch(err) {
          console.log("err", err);
        }
      }
      tryConfirmEmail(); 
    }
  }, [token, navigate, attemptLoad, setAppState, setUserState]);

  return (
    <Layout>
      <div>
          <h2>Confirm Email</h2>
          <p>
            Confirmed: {emailConfirmed ? `yes` : `not yet`}.
          </p>
          <p>
            If things are not confirming, please check that your url is correct, and refresh the page.
          </p>
          <Link className={styles.button} to={"/"}>Go Home</Link>
      </div>
      {viewConfirmedModal && <Modal onBackgroundClick={() => { 
          setViewConfirmedModal(false); 
          //navigate(routeLinks.home); 
        }}>
          <div className={styles.confirmModal}>

          <div>
            <p>
              Thank you for confirming your email. You can save your personal casts. You user details are below. If you wish to be kept up to date on major milestones for Blood of Stars, please click 'Allow Contact'.
            </p>
            <UserInfo className={styles.userInfo} />
            <p>
              If you are having issues with staying logged in, you can contact us at {helpEmail}.
              </p>
          </div>
          <div className={styles.buttons}>
            <LinkButton className={styles.button} to={"/"}>Go Home</LinkButton>
            <Button  className={styles.button} onClick={() => { setViewConfirmedModal(false)}}>close</Button>
            </div>
          </div>
        </Modal>
      }
    </Layout>
  );
}
