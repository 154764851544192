import { useCallback } from "react";
import axios from "axios";
import * as base from "./lib/baseActions";
import { apiEndpoints } from "lib/config";
import {
   WorldType,
  } from "actions/world.types";
import useSWR from 'swr';
import { mutate } from 'swr';

export interface DocsResult {
  data: Array<WorldType>;
};

export interface DocResult {
  data: WorldType;
};


export const setChosenTactics = (tactics: Array<number>) => {
    const tacticsString = tactics.map((t) => { return t.toString() }).join(',');
    localStorage.setItem(`/${apiEndpoints.localGameData}/${apiEndpoints.chosenTactics}`, tacticsString);
    return tacticsString;
}

export const getChosenTactics = () => {
  const result = localStorage.getItem(`/${apiEndpoints.localGameData}/${apiEndpoints.chosenTactics}`) || "";
  if(!result) {
    return []
  } else {
    return result.split(',').map((r) => { return parseInt(r, 10); });
  }
}

type useSetChosenWorldResultType =  ( worldId: Array<number>) =>  Promise<string> 


export const useSetChosenTactics = () : useSetChosenWorldResultType => {
  return async (worldId: Array<number>) => {
    let result;

    result = await setChosenTactics(worldId);
    mutate([`/${apiEndpoints.localGameData}/${apiEndpoints.chosenTactics}`], worldId);
    return result;
  }
}

type useGetLocalCastSWRType = [
  string
] 

export const useGetChosenTactics = () => {
  return useSWR<Array<number>>(
    [
      `/${apiEndpoints.localGameData}/${apiEndpoints.chosenTactics}`,
    ],
    ([key]: useGetLocalCastSWRType) => {
      return getChosenTactics();
    },
  );
};
