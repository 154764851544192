import Layout from "Layout";
import { useParams } from "react-router-dom";
import { useGetDoc } from 'actions/docActions';
import { LinkButton } from "components/Buttons/Button";
import styles from "./styles.module.scss";
import { routeLinks } from "App/routing/routeLinks";
import {  useGetCurrentUser } from "hooks/userHooks";
import ReactMarkdown from 'react-markdown'
 
type DocParams = {
  docId: string;
};

export const Doc = () => {
  const { isAdmin, id } = useGetCurrentUser();
  const { docId } = useParams<DocParams>();
  const { data } = useGetDoc(docId, undefined, id);
  const doc = data;

  return (
    <Layout>
      <div >
        <h1>
          {!doc?.name ? `${docId}` : `${doc?.name}`}
        </h1>
        {isAdmin && <div className={styles.editButtons}>
          <LinkButton to={`/${routeLinks.docs}/${docId}/${routeLinks.edit}`} >Edit</LinkButton> 
        </div>}
        <div className={styles.doc}><ReactMarkdown children={doc?.markdown || ``} /></div>
        <div>
        </div>
      </div>
    </Layout>
  );
}
