import { useCallback, useState } from "react";
import classNames from "classnames";

import { Button, ButtonStyle } from 'components/Buttons';
import { Modal } from "components/Modal";

import { useForm } from 'react-hook-form';

import { TextField, TextFieldType } from 'components/Forms/TextField';
import { Form } from 'components/Forms/Form';

import styles from "./styles.module.scss";
import { CastType } from "actions/cast.types";

interface EditCastDetailsProps {
  cast: CastType;
  updateWorkingCast: (updateCast: CastType, excludeCast?: boolean) => void;
  closeEdit: () => void;
  isAdmin?: boolean;
}

interface FormValuesType {
  name?: string;
  description?: string;
  display?: string;
}

export const EditCastDetailsModal = ({
  cast,
  closeEdit,
  updateWorkingCast,
  isAdmin
}: EditCastDetailsProps) => {

  const { register, handleSubmit, formState } = useForm({
    defaultValues: { name: cast.name, description: cast.description, display: cast.display ? `${cast.display}` : `1` },
  });
  const errors = formState?.errors;
  const [isSubmitting, setSubmitting] = useState(false);

  const onSubmit = useCallback(async (newCast: FormValuesType) => {
    let parsedDisplay = cast.display;
    try {
       parsedDisplay = parseInt(newCast.display || `${cast.display}`) 
    } catch(err) {

    }

      const newEntry = {
        name: newCast.name,
        display: parsedDisplay,
        description: newCast.description,
      };

      const createdCast = await updateWorkingCast(newEntry, true);
      return createdCast;
  }, [updateWorkingCast, cast.display]);

  return  (
    <Modal>
      <div className={styles.archetypeEntryCard}>
        <Form
             onSubmit={handleSubmit(async (vals: FormValuesType) => { 
              setSubmitting(true);
              try {
                await onSubmit(vals); 
              
                setSubmitting(false);
                closeEdit();
              } catch(err) {
                console.log("Submit Error:", err);
                setSubmitting(false);
              }
            })} 
          >
          <div >
            <div className={classNames(styles.archetypeTitle)}>
              <h3>
                {`${cast.name}${cast.name && ` `}Details`}
              </h3>
              <div className={styles.updateButtons}>
                <Button
                  buttonStyle={ButtonStyle.primary}
                  disabled={isSubmitting} 
                  type="submit"
                  className={styles.viewArchetypeButton}
                >
                 Done 
                </Button>
                <Button
                  buttonStyle={ButtonStyle.secondary}
                  onClick={() => { closeEdit && closeEdit(); }}
                  className={styles.viewArchetypeCloseButton}
                >
                 x 
                </Button>
              </div>
            </div>
            <div className={styles.entries}>
              <TextField
                type={TextFieldType.text}
                placeholder={"Name"}
                label="Name"
                className={styles.field}
                labelClassName={styles.labels}
                error={errors?.name}
                {...register("name", {
                })}
              />
              {isAdmin && <TextField
                type={TextFieldType.number}
                placeholder={"Display"}
                label="Display"
                className={styles.field}
                labelClassName={styles.labels}
                error={errors?.display}
                {...register("display", {
                })}
              />}
              <TextField
                type={TextFieldType.textArea}
                placeholder={"Description"}
                label="Description"
                className={styles.field}
                fieldClassName={styles.textArea}
                labelClassName={styles.labels}
                error={errors?.description}
                {...register("description", {
                })}
              />
            </div>
          </div>
          </Form>
        </div>
    </Modal>
  );
  
}