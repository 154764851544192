import React , { useState } from 'react'
import { createUser, userIsAuthorized, registrationErrorType } from 'actions/userActions';
import { useNavigate } from "react-router-dom";
import { Button } from 'components/Buttons';
import { useForm } from 'react-hook-form';
import { TextField, TextFieldType } from 'components/Forms/TextField';
import { Form } from 'components/Forms/Form';
import styles from './styles.module.scss';
import { useAuthState, AuthStateAtomType } from 'atoms/auth.atom';
import { useUserState } from 'atoms/users.atom';
import { Link } from "react-router-dom";
import { routeLinks } from "App/routing";
import Modal from 'components/Modal';
import { helpEmail } from "lib/config";

interface RegisterFormValProps {
  email: string;
  password: string;
  passwordConfirmation: string;
};

const useOnRegister = () => {
  const { setState: setUserState } = useUserState();
  const { state: appState, setState: setAppState } = useAuthState();
  const mergeAppState = (newState: AuthStateAtomType) => { return { ...appState, ...newState} };

  const onSubmit = async ({ email, password, passwordConfirmation } : { email: string; password: string; passwordConfirmation: string; }) => {
    mergeAppState({ Authenticating: true });

    try {
      const results = await createUser({email, password, passwordConfirmation});

      if(results?.user) {
        setUserState(results.user); // { email: results.data.email, userName: results.data.first_name });
      }
  
      setAppState({ Authenticated: userIsAuthorized(), Authenticating: false });
        
      return results;
    } catch(err) {
      setAppState({ Authenticated: false, Authenticating: false });
      throw err;
    }
  };

  return onSubmit;
};

export const RegisterFormComponent = () => {

  const navigate = useNavigate();
  const onSubmitLogin = useOnRegister();
  const [isSubmitting, setSubmitting] = useState(false);
  const [viewRegisteredModal, setViewRegisteredModal] = useState(false);
  const [submitError, setSubmitError] = useState('');
  const { register, handleSubmit, formState } = useForm({
    reValidateMode: 'onSubmit',
    defaultValues: { email: ``, password: ``, passwordConfirmation: ``},
  });
  const errors = formState?.errors;


  return (
    <Form
      onSubmit={handleSubmit(async (vals: RegisterFormValProps) => { 
        try {
          setSubmitting(true);
          setSubmitError('');

         const result =  await onSubmitLogin(vals); 
          if(result) {

            setSubmitting(false);
            setViewRegisteredModal(true);
          }
        // forward to next page
        } catch(err: any) {
          setSubmitting(false);
          if(err?.type === registrationErrorType.alreadyExists ) {

            setSubmitError('Register failed. Account with that email already exists.');
          } else {
            setSubmitError('Register failed. Please try again, or reach out to us.');
          }
        }
      })}
    >
      <TextField 
        type={TextFieldType.email}
        placeholder="Email"
        label="email"
        className={styles.field}
        labelClassName={styles.labels}
        error={errors?.email}
        {...register("email", {
          validate:{
            validEmail: (val?: string) => {
              return (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(val || ``)) || "Must be a valid email";
            }
          }
        })}
      />
      <TextField
        type={TextFieldType.password}
        placeholder="Password"
        label="password"
        className={styles.field}
        labelClassName={styles.labels}
        error={errors?.password}
        {...register("password", {
          validate:{
            validLength: (val?: string) => {
              return   (val || ``).length >= 5 || "Length must be greater than 5";
            }
          }
        })}
      />
            <TextField
        type={TextFieldType.password}
        placeholder="Confirm Password"
        label="confirm password"
        className={styles.field}
        labelClassName={styles.labels}
        error={errors?.passwordConfirmation}
        {...register("passwordConfirmation", {
          validate:{
            matchesPassword: (val: string, values) => {
              return (values.passwordConfirmation === values.password) || "password and confirm password must be the same.";
            }
          }
        })}
      />
      { submitError && (<div className={styles.submitError}>{submitError}</div>)}
      <Button disabled={isSubmitting} className={styles.submitButton} type="submit">
        Submit
      </Button>
      <div>
        <Link to={`/${routeLinks.confirmEmail}`}>Need to confirm your Email? Confirm</Link>
      </div>
      <div>
        <Link to={`/${routeLinks.login}`}>Already have a login? Register</Link>
      </div>
      {viewRegisteredModal &&<Modal onClick={() => { 
        setViewRegisteredModal(false); 
        navigate(routeLinks.home); }}>
        <div >
          <p>
            Thank you for registering. You will get an email with a confirmation code. Please click on that link to activate your account.
            In the mean time, you can go back to home, and view things as a guest.
            </p>
          <p>
            If you are having issues with login, you can contact us at {helpEmail}.
            </p>
        </div>
        </Modal>}
    </Form>
  );
};

export const RegisterForm = RegisterFormComponent;
