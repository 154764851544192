import { TagType, TagThresholdType, TagSelector } from "actions/tag.types";

export enum CastMemberClassType {
    hero = "hero",
    archetype = "archetype",
    tactic = "tactic",
    team = "team"
};

export interface UpdateCastMemberType {
  title?: string;
  type?: CastMemberClassType;
  tags?: Array<TagType>;
  tagOptions?: Array<TagType>;
  amount?: number;
}

export interface CastMemberType {
  key: string;
  name: string;
  title?: string;
  description?: string;
  type?: CastMemberClassType;
  tags: Array<TagType>;
  tagsMap?: Map<string, TagType>;
  tagOptions?: Array<TagType>;
  tagOptionsMap?: Map<string, TagType>;
  archetypeKey?: string;
  archetypeCast?: CastMemberType;
  calculatedCast?: CastMemberType;
  amount?: number;
}

export interface ArchetypeType {
  id?: number;
  key?: string;
  type?: CastMemberClassType;
  display?: number; // enum, 0 = only user, 1 = everyone, 2 = linked groups
  userId?: number;
  worldId?: number;
  name: string;
  description?: string;
  icon?: string;
  data: {
    cast?: Array<CastMemberType>;
    tagOptions?: Array<TagType>;
    tags?: Array<TagType>;
    defaultFactions?: Array<number | string>;
    prototypeCastKey?: string;
    tagModifiersKeys?: Array<string>;
    tagDescriptionsKeys?: Array<string>;
    storyKeys?: Array<string>;
    icon?: string;
  };
  createdAt?: string;
  updatedAt?: string;
}

export interface CastType {
  id?: number;
  userId?: number;
  name?: string;
  key?: string;
  type?: CastMemberClassType;
  worldId?: number;
  archetypeId?: number;
  display?: number;
  description?: string;
  data?: {
    cast?: Array<CastMemberType>;
    tagOptions?: Array<TagType>;
    tags?: Array<TagType>;
    prototypeCastKey?: string;
    tagModifiersKey?: string;
    tagDescriptionsKeys?: Array<string>;
    storyKeys?: Array<string>;
    archetype?: ArchetypeType; 
  };
  baseDataMap?: {
    cast?: Map<string, CastMemberType>;
    tags?: Map<string, TagType>;   
  };
  ephemeralData?: {
    cast?: Map<string, CastMemberType>;
    tags?: Map<string, TagType>;
  };
  createdAt?: string;
  updatedAt?: string;
};


export enum SectionType {
  default = `default`,
  options = `options`,
  tags = `tags`
};
 
export interface ArchetypeSectionType {
  key: string;
  name: string;
  description?: string;
  createdAt?: string;
  updatedAt?: string;
  tagKeys: Array<string | TagSelector>;
  type?: SectionType;
}

// Group Archetypes
export interface ArchetypeGroupType {
  key: string;
  name: string;
  description?: string;
  createdAt?: string;
  updatedAt?: string;
  tagThresholds?: Array<TagThresholdType>;
  casts?: Array<CastMemberType>;
}

export interface ArchetypeDisplayType {
  id?: number;
  userId?: number;
  key?: string;
  display?: number; // enum, 0 = only user, 1 = everyone, 2 = linked groups
  name: string;
  description?: string;
  data?: {
    sections: Array<ArchetypeSectionType>;
    groups?: Array<ArchetypeGroupType>;
  };
  sections?: Array<ArchetypeSectionType>;
  groups?: Array<ArchetypeGroupType>;
  createdAt?: string;
  updatedAt?: string;
}