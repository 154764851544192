import React, { useMemo, useState, useCallback, useEffect } from "react";
import { useGetBaseTagsGroup,
   useUpdateBaseTagsGroup,
   useCreateBaseTagsGroup,
   CreateBaseTagsGroupFunctionType,
   UpdateBaseTagsGroupFunctionType } from "actions/tagActions";
import { BaseTagsGroupType, TagGroupingType, TagModifierType, TagType } from "actions/tag.types";
import { tagsToMap } from "actions/helpers/tagHelpers";
import { useForm } from 'react-hook-form';
import { FormType } from 'components/Forms/Form';
import { isArrayValidator, createValidateJsonString } from "components/JsonEditor"
import { groupTags } from "lib/tagGrouping.helpers";
import { useWorld } from "hooks/worldHooks";
import { useGetCurrentUser } from "hooks/userHooks";

interface UseTagsProps {
  tagsGroupKey?: string | number;
  name?: string;
  description?: string;
  baseTagsGroup?: BaseTagsGroupType;
  tagModifiers?: Array<TagModifierType>;
  tags?: Array<TagType>;
  groupings?: Array<TagType>;
  tagDescriptions?: Array<TagType>;
  createBaseTagsGroup?: CreateBaseTagsGroupFunctionType;
  updateBaseTagsGroup?: UpdateBaseTagsGroupFunctionType;
  loaded: boolean;
}

interface UseGetTagsProps {
  tagsGroupKey?: string | number;
  name?: string;
  description?: string;
  baseTagsGroup?: BaseTagsGroupType;
  tagModifiers?: Array<TagModifierType>;
  tags?: Array<TagType>;
  groupings?: Array<TagGroupingType>;
  tagDescriptions?: Array<TagType>;
  loaded: boolean;
  isLoading: boolean;
  groupedTags?: Array<TagGroupingType>;
}

export const useGetTags = (tagsGroupKey?: string | number, worldId?: string | number, userId?: string | number): UseGetTagsProps => {
  const { data: baseTagsGroup, isLoading } = useGetBaseTagsGroup(tagsGroupKey, worldId, userId);
   
  const [loaded, setLoaded]= useState(false);

  useEffect(() => {
    if(baseTagsGroup) {
      setLoaded(true);
    }
  }, [baseTagsGroup]);

  const groupedTags = useMemo(() => {
  if(baseTagsGroup?.groupings) {
    return groupTags(baseTagsGroup?.groupings, baseTagsGroup?.tags);

  } else {
    return [];
  }
},[baseTagsGroup]);

  return {
    tagsGroupKey,
    name: baseTagsGroup?.name || "",
    baseTagsGroup,
    tagModifiers: [],
    tags: baseTagsGroup?.tags,
    groupings: baseTagsGroup?.groupings,
    description: baseTagsGroup?.description || "",
    loaded,
    isLoading,
    groupedTags
  };
}

const useTags = (tagsGroupKey?: string | number): UseTagsProps => {
  const { chosenWorldId } = useWorld()
  const { id } = useGetCurrentUser();
  const { data: baseTagsGroup } = useGetBaseTagsGroup(tagsGroupKey, chosenWorldId, id);
  const createBaseTagsGroup = useCreateBaseTagsGroup();
  const updateBaseTagsGroup = useUpdateBaseTagsGroup();
  const [loaded, setLoaded]= useState(false);

  useEffect(() => {
    if(baseTagsGroup) {
      setLoaded(true);
    }
  }, [baseTagsGroup]);
  
  return {
    tagsGroupKey,
    name: baseTagsGroup?.name || "",
    baseTagsGroup,
    tagModifiers: [],
    tags: baseTagsGroup?.tags,
    groupings: baseTagsGroup?.groupings,
    description: baseTagsGroup?.description || "",
    loaded,
    createBaseTagsGroup: (cast: BaseTagsGroupType) => { return createBaseTagsGroup(cast,chosenWorldId); },
    updateBaseTagsGroup: (tagModifiersGroupId: string | number, cast: BaseTagsGroupType) => { return updateBaseTagsGroup(tagModifiersGroupId, cast,chosenWorldId); }, 
  };
}

interface SubmitTagModifiersGroupTypes {
  name?: string;
  display?: number;
  description?: string;
  tags: string;
  groupings: string;
}

const translateValues = (vals: SubmitTagModifiersGroupTypes): BaseTagsGroupType => {

  try {
    const parsedTags = JSON.parse(vals?.tags);
    
    const parsedGroupings = JSON.parse(vals?.groupings);

    return {
      name: vals?.name,
      display: vals?.display,
      description: vals?.description,
      data: { 
        tags: parsedTags,
        groupings: parsedGroupings,
      },
      tags: parsedTags,
      groupings: parsedGroupings
    }
  } catch(err) {
    console.log('failed to translate values');
    throw err;
  }
}

interface useTagFormProps {
  tagsGroupId?: string;
  formType?: FormType;
  callback?: (baseTagsGroup?: BaseTagsGroupType) => void ;
}

export const useEditTagsGroupForm = ({
  tagsGroupId, 
  formType = FormType.Update,
  callback = () => {} 
}: useTagFormProps = {}) => {
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState('');

  const {
    name,
    description,
    tags,
    baseTagsGroup,
    loaded,
    createBaseTagsGroup,
    updateBaseTagsGroup
  } = useTags(tagsGroupId);

  const [formLoaded, setFormLoaded ] = useState(false);  

  const loadFormValues = useCallback((baseTagsGroup?: BaseTagsGroupType) => {
    return { 
      name: baseTagsGroup?.name ,
      display: baseTagsGroup?.display || 0,
      description: baseTagsGroup?.description,
      tags: JSON.stringify(baseTagsGroup?.tags),
      groupings: JSON.stringify(baseTagsGroup?.groupings)
    };
  },[]);

  const baseTags = useMemo(() => { return baseTagsGroup?.tags; }, [baseTagsGroup]);
  const baseGroupings = useMemo(() => { return baseTagsGroup?.groupings; }, [baseTagsGroup]);

  const { register, handleSubmit, formState, getValues, setValue, reset, setError, clearErrors } = useForm({
    defaultValues: loadFormValues(baseTagsGroup)
   });

  const formTags = getValues('tags');
  const formGroupings = getValues('groupings');

  useEffect(() => {
    if(loaded && !formLoaded) {
      reset(loadFormValues(baseTagsGroup));
      setFormLoaded(true);
    }
  }, [setFormLoaded, reset, loaded, loadFormValues, baseTagsGroup, formLoaded]);

  const tagDescriptionsMap = useMemo(() => {
    return tagsToMap(baseTagsGroup?.tags);
  },[baseTagsGroup])

  const saveAndMerge = useCallback(async (vals: SubmitTagModifiersGroupTypes) => {
    try {
      setSubmitting(true);
      setSubmitError('');
      const translatedValues = translateValues({ ...vals });
      
      if(formType === FormType.Create) {
        const tagsGroup = await (createBaseTagsGroup && createBaseTagsGroup(translatedValues));
        setSubmitting(false);

        callback(tagsGroup);
        return tagsGroup; 
      } else {
        const tagsGroup = await (tagsGroupId && updateBaseTagsGroup ? updateBaseTagsGroup(tagsGroupId, translatedValues) : undefined);
        setSubmitting(false);
        callback(tagsGroup);
        return tagsGroup;
      }
    } catch (err) {
        setSubmitting(false);
        setSubmitError('Update to Cast Member Failed.');
    }
  }, [formType, createBaseTagsGroup, callback, tagsGroupId, updateBaseTagsGroup]);
  const errors = formState?.errors;

  const tagsJsonValidator = useMemo(() => {
   return isArrayValidator();
  },
  []);

  const groupingsJsonValidator = useMemo(() => {
    return isArrayValidator();
   },
   []);
 
  const validateTagsJsonString = useMemo(() => {
    return createValidateJsonString(tagsJsonValidator);
  },
  [tagsJsonValidator]);

  const validateGroupingsJsonString = useMemo(() => {
    return createValidateJsonString(groupingsJsonValidator);
  },
  [groupingsJsonValidator]);

  const onSubmit = async (e:  React.FormEvent<HTMLFormElement>) => {
    await handleSubmit(saveAndMerge)(e);
  }

  return {
    name,
    description,
    tags,
    baseTagsGroup,
    loaded,
    submitting,
    submitError,
    formTags,
    baseTags,
    baseGroupings,
    formGroupings,
    tagDescriptionsMap,
    saveAndMerge,
    validateTagsJsonString,
    validateGroupingsJsonString,
    tagsJsonValidator,
    groupingsJsonValidator,
    register,
    setValue,
    onSubmit,
    errors,
    setError,
    handleSubmit,
    clearErrors
  };
}
