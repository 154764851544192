import { Link } from 'react-router-dom';
import { routeLinks, externalLinks } from 'App/routing';

import styles from './styles.module.scss';

// Footer for all pages
// Dumb component, so use stateless Function declaration
// no props
const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerText}>
        Blood of Stars - Copyright 2023
      </div>
      <div className={styles.footerText}>
        <Link
          to={`${externalLinks.bloodOfStars}`}
        >
          Blood of Stars
        </Link>
      </div>
      <div className={styles.footerText}>
        <Link
          to={`${externalLinks.diceOverEverything}`}
        >
          Dice Over Everything
        </Link>
      </div>
      <div className={styles.footerText}>
        <Link
          to={`/${routeLinks.termsAndConditions}`}
        >
          Terms And Conditions
        </Link>
      </div>
    </footer>
  );
}

export default Footer;
