import * as base from "./lib/baseActions";
import { apiEndpoints } from "lib/config";
import useSWR from 'swr';
import { mutate } from 'swr';
import { WorldId } from 'lib/config';
import { CastType } from 'actions/cast.types';
import axios from "axios";
import { cleanCast } from "./helpers/castHelpers";
export type { TagType } from "actions/types";

export const getCasts = async (userId :  number | string, worldId?: number | string, display?: number, filterByDisplay?: boolean, archetypeId?: number) : Promise<Array<CastType>> => {
  try {
    const params = {
      params: {
        archetypeId,
        display,
        filterByDisplay: filterByDisplay ? true : undefined
      }
    };

    const casts = await base.get(`${worldId ? `${apiEndpoints.worlds}/${worldId}/` : ``}${apiEndpoints.users}/${userId}/${apiEndpoints.casts}`, 
      params);

    return casts.data?.map((c:CastType) => {
      return cleanCast(c);
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      err.message = "Failed to get a casts.";
    }
    throw err;
  }
};

export const getCast = async (userId:  number | string, castId : number | string, worldId?: number | string) : Promise<CastType> => {
  try {
    const cast = await base.get(`${worldId ? `${apiEndpoints.worlds}/${worldId}/` : ``}${apiEndpoints.users}/${userId}/${apiEndpoints.casts}/${castId}`);
    return cleanCast(cast.data);
  } catch (err) {
    if (axios.isAxiosError(err)) {
      err.message = "Failed to get a cast.";
    }
    throw err;
  }
};

export const createCast = async (userId:  number | string, cast: CastType, worldId?: number | string) : Promise<CastType> => {
  try {
    const result = await base.post(`${worldId ? `${apiEndpoints.worlds}/${worldId}/` : ``}${apiEndpoints.users}/${userId}/${apiEndpoints.casts}`, cast);
    return cleanCast(result.data);
  } catch (err) {
    if (axios.isAxiosError(err)) {
      err.message = "Failed to get a tag.";
    }
    throw err;
  }
};

export const updateCast = async (userId: number | string, castId :  number | string, cast: CastType) : Promise<CastType> => {
    try {
      const result = await base.put(`${apiEndpoints.users}/${userId}/${apiEndpoints.casts}/${castId}`, cast);
      return cleanCast(result.data);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        err.message = "Failed to update a tag.";
      }
      throw err;
    }
};

export const deleteCast = async (userId: number | string, castId : number) : Promise<CastType> => {
  try {
    const result = await base.del(`${apiEndpoints.users}/${userId}/${apiEndpoints.casts}/${castId}`);
    return cleanCast(result.data);
  } catch (err) {
    if (axios.isAxiosError(err)) {
      err.message = "Failed to delete a tag.";
    }
    throw err;
  }
};

interface useCreateCastResultType {
  (cast: CastType, userId: number | string, worldId?: number | string, castId?: number | string):  Promise<CastType> 
} 

export const useCreateCast = () : useCreateCastResultType => {
  return async (cast: CastType, userId: number | string, worldId?: number | string, castId?: number | string) => {
    worldId = worldId || WorldId;
    let result;
    result = await createCast(userId, cleanCast(cast), worldId);

    const newCastId = result.id;
    mutate(`${worldId ? `${apiEndpoints.worlds}/${worldId}/` : ``}/users/${userId}/casts/${newCastId}`, result); 
    return result;
  }
}

interface useUpdateCastResultType {
  (castId: number, cast: CastType, userId: number, worldId?: number | string):  Promise<CastType> 
} 

export const useUpdateCast = () : useUpdateCastResultType => {
  return async (castId: number, cast: CastType, userId: number, worldId?: number | string) => {
    worldId = worldId || WorldId;
    let result;
    result = await updateCast(userId, castId, cleanCast(cast));
    mutate(`${worldId ? `${apiEndpoints.worlds}/${worldId}/` : ``}/users/${userId}/casts/${castId}`, result);
    
    return result;
  }
}

interface useDeleteCastResultType {
  (userId: number, castId: number):  Promise<CastType> 
} 

export const useDeleteCast = () : useDeleteCastResultType => {
  return async (castId: number, userId:  number | string,  worldId?: number | string) => {
    worldId = worldId || WorldId;
    const result = await deleteCast(userId, castId);
    mutate(`${worldId ? `${apiEndpoints.worlds}/${worldId}/` : ``}/users/${userId}/casts/${castId}`, result);
    
    return result;
  }
}

type useGetCastsSWRType = [
  string, string | number, number | string | undefined, number | undefined, boolean | undefined, number | undefined
] 

export const useGetCasts = (userId?: number | string, worldId?: number | string, display?: number, filterByDisplay?: boolean, archetypeId?: number) => {
  worldId = worldId || WorldId;
  return useSWR<CastType[]>(userId ?
    [
      `${worldId ? `${apiEndpoints.worlds}/${worldId}/` : ``}users/${userId}/casts`,
      userId,
      worldId,
      display,
      filterByDisplay,
      archetypeId,
    ] : null,
    ([key, userId, worldId, display, filterByDisplay, archetypeId]: useGetCastsSWRType) => { 
        return getCasts(userId, worldId, display, filterByDisplay, archetypeId);
    }
  );
};

type useGetCastSWRType = [
  string, string | number,  number, number | string | undefined 
] 

export const useGetCast = (userId?: number, castId?: string | number, config?: any, worldId?: number) => {
  worldId = worldId || WorldId;
  const curUserId = userId || 'guest';
  return useSWR<CastType>(curUserId && castId ?
    [
      `${worldId ? `${apiEndpoints.worlds}/${worldId}/` : ``}users/${curUserId}/casts/${castId}`,
      curUserId,
      castId,
      worldId
    ] : null,
    ([key, userId, castId, worldId]: useGetCastSWRType) => {
      return getCast(curUserId, castId, worldId);
    },
    config
  );
};
