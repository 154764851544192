
export const routeLinks = {
  archetypeDisplays: 'archetypeDisplays',
  archetypes: 'factions',
  worlds: 'worlds',
  factions: 'factions',
  new: 'new',
  tagModifiersGroups: 'tagModifiersGroups',
  traits: 'traits',
  teams: 'teams',
  printable: 'printable',
  localTeams: 'localTeams',
  renownTeams: 'renownTeams',
  stories: 'stories',
  edit: 'edit',
  copy: 'copy',
  delete: 'delete',
  register: 'register',
  login: 'login',
  users: 'users',
  guest: `guest`,
  guestTeams: `guest/localTeams` ,
  docs: `docs` ,
  termsAndConditions: `termsAndConditions` ,
  requestPasswordReset: `requestPasswordReset` ,
  resetPassword: `resetPassword`,
  confirmEmail: `confirmEmail`,
  userMe: `userMe`,
  home: ``
}

export const externalLinks = {
  bloodOfStars: `https://bloodofstars.com`,
  diceOverEverything: `https://diceovereverything.com`,
  rules: `https://bloodofstars.com/the-rules`
}

export default routeLinks;