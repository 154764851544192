/// Base Actions - calling external API
import axios, { AxiosResponse, AxiosRequestConfig } from "axios";
import _keys from "lodash.keys";
import { wrapRefreshAuthTokenOnFail } from './jWTCalls';
import { fullEndpoint, getConfig } from 'actions/lib/urls';

/// Get from endpoint
const get = <T,>(endpoint: string, params?: AxiosRequestConfig) => {
  const callAxios = () => {
    return axios.get<any, AxiosResponse<T>>(fullEndpoint(endpoint), getConfig(params));
  };

  return wrapRefreshAuthTokenOnFail(callAxios);
};

/// get Blob from endpoint
const getBlob = (endpoint: string, params?: AxiosRequestConfig) => {
  const callAxios = () => {
    return axios.get(fullEndpoint(endpoint), getConfig({responseType:'blob', ...params}));
  };

  return wrapRefreshAuthTokenOnFail(callAxios);
};

/// post data to endpoint
const post = (endpoint: string, data : any, params?: AxiosRequestConfig) => {
  const callAxios = () => {
    return axios.post(fullEndpoint(endpoint), data, getConfig(params));
  };

  return wrapRefreshAuthTokenOnFail(callAxios);
};

/// post file data to endpoint
const postFile = (endpoint: string, data : any, params?: AxiosRequestConfig) => {
  let formData = new FormData();
  let keys = _keys(data);
  let config = getConfig({'content-type':'multipart/form-data', ...params});
  
  keys.forEach((k: any) => {
    formData.append(k, data[k]);
  });

  const callAxios = () => {
    return axios.post(fullEndpoint(endpoint), formData, config);
  };

  return wrapRefreshAuthTokenOnFail(callAxios);
};

/// post file data to endpoint
const putFile = (endpoint : string, data: any, params?: AxiosRequestConfig) => {
  let formData = new FormData();
  let keys = _keys(data);
  let config = getConfig({'content-type':'multipart/form-data', ...params});
  
  keys.forEach((k: any) => {
    formData.append(k, data[k]);
  });

  const callAxios = () => {
    return axios.put(fullEndpoint(endpoint), formData, config);
  };

  return wrapRefreshAuthTokenOnFail(callAxios);
};

/// put data to endpoint
const put = (endpoint : string, data : any, params?: AxiosRequestConfig) => {
  const callAxios = () => {
    return axios.put(fullEndpoint(endpoint), data, getConfig(params));
  };

  return wrapRefreshAuthTokenOnFail(callAxios);
};

/// delete endpoint
const del = (endpoint : string, params?: AxiosRequestConfig) => {
  const callAxios = () => {
    return axios.delete(fullEndpoint(endpoint), getConfig(params))
  };

  return wrapRefreshAuthTokenOnFail(callAxios);
};

/// create general http request
const request = (config : AxiosRequestConfig) => {
  let newConfig = { ...getConfig(), ...config };

  const callAxios = () => {
    return axios.request(newConfig);
  };

  return wrapRefreshAuthTokenOnFail(callAxios);
};

export {
  get,
  getBlob,
  post,
  postFile,
  put,
  putFile,
  del,
  request
};
