import Layout from "Layout";
import { useParams } from "react-router-dom";
import { CastPageParams } from "pages/Casts/id";
import { useGetLocalCastProps } from "pages/LocalCasts/id/cast.hook";
import { routeLinks } from "App/routing";
import { EditCastPresentation } from "pages/Casts/id/EditCast/EditCast.Presentation";
import { sendToastal } from "components/Toastal";
import { useEffect } from "react";
import { useGetCurrentUser } from "hooks/userHooks";

export const EditLocalCast = () => {
  const { id: castId } = useParams<CastPageParams>();
  const { isAdmin, isGuest, id } = useGetCurrentUser();  
  const {
    displayCast,
    displayArchetypeMembers,
    archetypeDisplay,
    editArchetypeDisplay,
    tags,
    name,
    displayTagOptions,
    totalPoints,
    totalUnits,
    workingCast,
    updateNumberOfMembersInWorkingCast,
    addArchetypeToWorkingCast,
    updateOrderInWorkingCast,
    updateCastMemberInWorkingCast,
    updateWorkingCast,
    saveWorkingLocalCast,
    groupedTags
  } = useGetLocalCastProps(id, castId);

  useEffect(() => {
    sendToastal("Swipe right to add, swipe left to remove.");
  }, []);

  const showWorkingCast = displayCast || [];
  
  return (
    <Layout>
      {castId ? <EditCastPresentation {...{
        displayCast: showWorkingCast,
        workingCast,
        editTagOptions: displayTagOptions,
        updateNumberOfMembersInWorkingCast,
        updateCastMemberInWorkingCast,
        addArchetypeToWorkingCast,
        updateOrderInWorkingCast,
        updateWorkingCast,
        displayArchetypeMembers,
        archetypeDisplay,
        editArchetypeDisplay,
        tags,
        name,
        totalPoints,
        totalUnits,
        castId,
        saveWorkingCast: saveWorkingLocalCast,
        viewLinkTo: `/${routeLinks.guestTeams}/${castId}`,
        groupedTags,
        isAdmin,
        isGuest
        }} 
      /> : <div>No Cast Id</div>}
    </Layout>
  );
}