import { CastMemberType } from 'actions/cast.types';
import { getTagKey } from 'actions/helpers/tagHelpers';

export enum StatTags {
  points =  "Points",
  standing =  "Standing",
  move =  "Move",
  fight =  "Fight",
  shoot =  "Shoot",
  evade =  "Evade",
  life =  "Life",
}

export enum SectionTagKey {
  stats =  "stats",
  statline =  "statline",
  traits =  "traits",
  abilityTraits =  "abilityTraits",
  fightTraits =  "fightTraits",
  shootTraits =  "shootTraits",
  injuries =  "injuries",
  gear =  "gear",
  factionSpecialRules =  "factionSpecialRules",
  traitConditions =  "traitConditions",
  factionCampaignUpgrades =  "factionCampaignUpgrades",
  upgrades =  "upgrades",
  tacticOptions =  "tacticOptions",
}

export enum StandingType {
  leaders = "leaders",
  elites = "elites",
  rankAndFile = "rankAndFile",
  tactic = "tactic",
  bases = "bases",
  gear = "gear",
  structures = "structures",
  monsters = "monsters",
  factions = "factions",
  subfactions = "subfactions",
  team = "team"
}

export const getStandingTag = (castMember: CastMemberType) => {
  const tagsMap = castMember.tagsMap || new Map(castMember.tags.map((t) => { return [getTagKey(t), t]; }))
   const standingTag = tagsMap?.get(StatTags.standing);
   
   return standingTag;
}

export const getCastStanding = (castMember: CastMemberType) => {
  const standingTag = getStandingTag(castMember);

  return standingTag?.val;
}
