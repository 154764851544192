import { useEffect, useState } from "react";
import { useUserState } from 'atoms/users.atom';
import { useAuthState } from 'atoms/auth.atom';
import { fetchUser, userIsAuthorized } from 'actions/userActions';

import { useInterval } from 'hooks/timeoutHook';
import { useGetChosenWorld } from "actions/worldActions";

const refreshVal= 10000;

export const useGetAuth = async () => {
  const { setState: setUserState } = useUserState();
  const { setState: setAppState } = useAuthState();
  const { data: chosenWorldData } = useGetChosenWorld();
  const [currentAuth2, setCurrentAuth2] = useState(false);

  useInterval(() => {
    const userIsAuth = userIsAuthorized();
    if(userIsAuth !== currentAuth2) {
      setCurrentAuth2(userIsAuth);
    }
  },refreshVal, true);

  useEffect(() => {
    setAppState({ Authenticated: currentAuth2, Authenticating: false });
  }, [currentAuth2, setAppState]);

  useEffect(() => {
    const fetchData = async () => {
      let currentAuth = userIsAuthorized();

      setAppState({ Authenticated: currentAuth, Authenticating: true });

      try {
        const result = await fetchUser();
        if(result && result.data) {
          const userData = {...result.data};
          if (chosenWorldData) {
            userData.worldId = chosenWorldData;
          }

          setUserState(result.data);
          setAppState({ Authenticated: currentAuth, Authenticating: false });
        }
      } catch(err) {
        // Failed to fetch user, unauthorized
        setAppState({ Authenticated: false, Authenticating: false });
      }
    };

    fetchData();
  }, [chosenWorldData, setAppState, setUserState]);
};


export default useGetAuth;
