import { ReactNode } from "react";
import { Routing } from './routing';
import { useGetAuth } from 'App/useGetAuth';
import {
  RecoilRoot,
} from 'recoil';
import "./App.scss";

interface GetAuthProps  {
  children?: ReactNode;
}

const GetAuth = ({children}: GetAuthProps) => {
  useGetAuth();

  return <>{children}</>
};

const App = () => {

  return (
    <>
        <RecoilRoot>
          <GetAuth>
            <Routing />
          </GetAuth>
        </RecoilRoot>
    </>
  );
};

export default App;
