import { useAuthState } from 'atoms/auth.atom';
import { useUserState } from 'atoms/users.atom';
import { UserRoles } from "lib/config";

export const useGetCurrentUser = () => {

  const { state: userState } = useUserState();

  const { state: authState } = useAuthState();

  const userRole =  userState?.role;

  if (!authState.Authenticated && !authState.Authenticating) {
    // Not authenticated, not authenticating, so anon
    return {
      user: undefined,
      id: undefined,
      role: UserRoles.anon,
      isAdmin: false,
      isGuest: true,
      isAnon: true,
      name: `guest`,
    }; 
  } else {
    return {
      user: userState,
      id: userState?.id,
      role: userState?.role,
      isAdmin: userRole === UserRoles.admin,
      isTester: userRole === UserRoles.tester,
      isGuest: userState?.id ? false : true,
      isAnon: userState && userRole && userRole !== UserRoles.anon,
      name: `${userState?.firstName}${userState?.firstName && userState?.lastName && ` `}${userState?.lastName}`,
      worldId: userState?.worldId
    };
  }
};
