import * as base from "./lib/baseActions";
import { apiEndpoints } from "lib/config";
import useSWR from 'swr';
import { mutate } from 'swr';
import {  ArchetypeDisplayType } from 'actions/cast.types';
import axios from "axios";
import { cleanCast } from "./helpers/castHelpers";
import { useCallback } from "react";
export type { TagType } from "actions/types";

const cleanDisplay = (display: ArchetypeDisplayType) => {
  return {
    ...display,
    sections: display?.data?.sections || [],
    groups: display?.data?.groups || [],
  }
}

export const getArchetypeDisplay = async (archetypeDisplayId?: string, worldId?: string | number, userId?: string | number): Promise<ArchetypeDisplayType> => {
  // Can possibly ask for default

  try {
    const casts = await base.get(`${worldId ? `${apiEndpoints.worlds}/${worldId}/` : ``}${userId ? `${apiEndpoints.users}/${userId}/` : `${apiEndpoints.users}/guest/`}${apiEndpoints.archetypeDisplays}/${archetypeDisplayId}`, {
    });
    return cleanDisplay(casts.data);
  } catch (err) {
    if (axios.isAxiosError(err)) {
      err.message = "Failed to get a tags.";
    }
    throw err;
  }
};

export const getArchetypeDisplays = async (worldId?: string | number, userId?: string | number, archetypeDisplayIds?: string[]): Promise<ArchetypeDisplayType[]> => {

  try {
    const casts = await base.get(`${worldId ? `${apiEndpoints.worlds}/${worldId}/` : ``}${userId ? `${apiEndpoints.users}/${userId}/` : `${apiEndpoints.users}/guest/`}${apiEndpoints.archetypeDisplays}`, {
    });

    const actualCasts = casts.data;
    if (Array.isArray(actualCasts)) {
      return casts.data.map((cd: ArchetypeDisplayType) => {
        return cleanDisplay(cd);
      })
    } else {
      return casts.data;
    }
  } catch (err) {
    if (axios.isAxiosError(err)) {
      err.message = "Failed to get a tags.";
    }

    throw err;
  }
}; 


export const createArchetypeDisplay = async ( archetypeDisplay: ArchetypeDisplayType, worldId?: number | string) : Promise<ArchetypeDisplayType> => {
  try {
    const endpoint = `${worldId ? `${apiEndpoints.worlds}/${worldId}/` : ``}${apiEndpoints.archetypeDisplays}`; 
    const result = await base.post(endpoint, archetypeDisplay);
    return cleanCast(result.data);
  } catch (err) {
    if (axios.isAxiosError(err)) {
      err.message = "Failed to get a tag.";
    }
    throw err;
  }
};

export const updateArchetypeDisplay = async (archetypeDisplayId : number | string, archetypeDisplay: ArchetypeDisplayType, worldId?: number | string) : Promise<ArchetypeDisplayType> => {
    try {
      const result = await base.put(`${worldId ? `${apiEndpoints.worlds}/${worldId}/` : ``}${apiEndpoints.archetypeDisplays}/${archetypeDisplayId}`, archetypeDisplay);
      return cleanCast(result.data);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        err.message = "Failed to get a tag.";
      }
      throw err;
    }
};

export const deleteArchetypeDisplay = async (archetypeId : number | string, worldId?: number | string) : Promise<ArchetypeDisplayType> => {
  try {
    const result = await base.del(`${worldId ? `${apiEndpoints.worlds}/${worldId}/` : ``}${apiEndpoints.archetypeDisplays}/${archetypeId}`);
    return cleanCast(result.data);
  } catch (err) {
    if (axios.isAxiosError(err)) {
      err.message = "Failed to get a tag.";
    }
    throw err;
  }
};

type useGetArchetypeDisplaySWRType = [
  string, string | number | undefined, string | number | undefined,  string | number | undefined
] 

export const useGetArchetypeDisplay = (archetypeDisplayKey?: string | number, worldId?: string | number, userId?: string | number) => {

  return useSWR<ArchetypeDisplayType>(archetypeDisplayKey ?
    [
      `worldId/${worldId}}/archetypeDisplay/${archetypeDisplayKey}`,
      archetypeDisplayKey,
      worldId,
      userId
    ] : null,
    ([key, archetypeDisplayKey, worldId, userId]: useGetArchetypeDisplaySWRType) => {
      return getArchetypeDisplay(archetypeDisplayKey ? `${archetypeDisplayKey}` : undefined, worldId, userId);
    }
  );
};


type useGetAllArchetypeDisplaySWRType = [
  string, string | number | undefined, string | number | undefined, string[] | undefined
] 

export const useGetAllArchetypeDisplay = (worldId?: string | number, userId?: string | number, archetypeIds?: string[]) => {
  return useSWR<ArchetypeDisplayType[]>(
    [
      `${worldId ? `${apiEndpoints.worlds}/${worldId}/` : ``}${userId ? `${apiEndpoints.users}/${userId}/` : `${apiEndpoints.users}/guest/`}${apiEndpoints.archetypeDisplays}`,
      worldId,
      userId,
      archetypeIds
    ],
    ([key, worldId, userId, archetypeIds]: useGetAllArchetypeDisplaySWRType) => {
      return getArchetypeDisplays(worldId, userId, archetypeIds);
    }
  );
};


export interface CreateArchetypeDisplayFunctionType {
  (tagModifiersGroup: ArchetypeDisplayType, worldId?: string | number):  Promise<ArchetypeDisplayType> 
}

export const useCreateArchetypeDisplay = () : CreateArchetypeDisplayFunctionType => {
  return useCallback(async (archetypeDisplay: ArchetypeDisplayType, worldId?: string | number) => {
    const result = await createArchetypeDisplay(archetypeDisplay, worldId);
    const tagModifierGroupId = result.id;
    mutate(`${apiEndpoints.worlds}/${worldId || ``}/${apiEndpoints.archetypeDisplays}/${tagModifierGroupId}`, result); 
    return result;
  }, []);
};

export interface UpdateArchetypeDisplayFunctionType {
  (tagModifierGroupId: number | string, cast: ArchetypeDisplayType, worldId?: number | string):  Promise<ArchetypeDisplayType> 
} 

export const useUpdateArchetypeDisplay = () : UpdateArchetypeDisplayFunctionType => {
  return useCallback(async (archetypeDisplayId: number | string, archetypeDisplay: ArchetypeDisplayType, worldId?: number | string) => {
    const result = await updateArchetypeDisplay(archetypeDisplayId, archetypeDisplay, worldId);
    mutate(`${apiEndpoints.worlds}/${worldId || ``}/${apiEndpoints.archetypeDisplays}/${archetypeDisplayId}`, result);
    return result;
  },[]);
}

export interface DeleteTagModifiersGroupFunctionType {
  (tagModifierGroupId: number | string, worldId?: number | string):  Promise<ArchetypeDisplayType> 
} 

export const useDeleteArchetypeDisplay = () : DeleteTagModifiersGroupFunctionType => {
  return useCallback(async (archetypeDisplayId: number | string, worldId?: number | string) => {
    const result = await deleteArchetypeDisplay(archetypeDisplayId, worldId);
    mutate(`/${apiEndpoints.worlds}/${worldId || ``}/${apiEndpoints.archetypeDisplays}/${archetypeDisplayId}`, result);
    return result;
  },[]);
}
