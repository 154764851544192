import { useCallback } from "react";
import axios from "axios";
import * as base from "./lib/baseActions";
import { apiEndpoints } from "lib/config";
import { TagType,
   TagModifiersGroupType,
   BaseTagsGroupType,
  } from "actions/tag.types";
import useSWR from 'swr';
import { mutate } from 'swr';

export interface TagsResult {
  data: Array<TagType>;
};

export interface TagResult {
  data: TagType;
};

export const cleanBaseTagsGroup = (baseTagsGroup: BaseTagsGroupType) => {
  return {
    ...baseTagsGroup,
    tags: baseTagsGroup?.data?.tags,
    groupings: baseTagsGroup?.data?.groupings
  }
}

export const getBaseTagsGroup = async (tagsGroupId?: string | number, worldId?: string | number, userId?: string | number) : Promise<BaseTagsGroupType> => {

    try {
      const tag = await base.get(`${worldId ? `${apiEndpoints.worlds}/${worldId}/` : ``}${userId ? `${apiEndpoints.users}/${userId}/` : `${apiEndpoints.users}/guest/`}${apiEndpoints.tagsGroups}/${tagsGroupId}`);
      return cleanBaseTagsGroup(tag?.data);
    } catch (err: any) {
      err.message = "Failed to get a tag.";
      throw err;
    }
}

type useGetBaseTagsGroupFetcherProps = [string, string| number, string | number | undefined]

export const useGetBaseTagsGroup = (tagsGroupId?: string | number, worldId?: string | number, userId?: string | number) => {
  return useSWR<BaseTagsGroupType>(tagsGroupId ?
    [
      `${worldId ? `${apiEndpoints.worlds}/${worldId}/` : ``}${userId ? `${apiEndpoints.users}/${userId}/` : `${apiEndpoints.users}/guest/`}${apiEndpoints.tagsGroups}/${tagsGroupId}`,
      tagsGroupId,
      worldId,
      userId
    ] : null,
    ([key, tagsGroupId, worldId]: useGetBaseTagsGroupFetcherProps) => {
      return getBaseTagsGroup(tagsGroupId, worldId, userId);
    }
  );
};

export const getAllBaseTagsGroup = async(worldId?: string | number, userId?: string | number) : Promise<Array<BaseTagsGroupType>> => {
  try {
    const tags = await base.get(`${worldId ? `${apiEndpoints.worlds}/${worldId}/` : ``}${userId ? `${apiEndpoints.users}/${userId}/` : `${apiEndpoints.users}/guest/`}${apiEndpoints.tagsGroups}`);
    const tagsData = tags.data ? tags.data : [];
    return [ ...tagsData].map((group) => {
      return cleanBaseTagsGroup(group);
    });
  } catch (err: any) {
    err.message = "Failed to get a tag.";
    throw err;
  }
}

type useGetAllBaseTagsGroupSWRType = [
  string, string | number | undefined, string | number | undefined
] 

export const useGetAllBaseTagsGroup = (worldId?: string | number, userId?: string | number) => {
  return useSWR<Array<BaseTagsGroupType>>(
    [
      `${worldId ? `${apiEndpoints.worlds}/${worldId}/` : ``}${userId ? `${apiEndpoints.users}/${userId}/` : `${apiEndpoints.users}/guest/`}${apiEndpoints.tagsGroups}`,
      worldId,
      userId
    ],
    ([key, worldId, userId]: useGetAllBaseTagsGroupSWRType) => { 

      return getAllBaseTagsGroup(worldId, userId);
    }
  );
};

// Create Base Tags
export const createBaseTagsGroup = async (tagsGroup: BaseTagsGroupType, worldId?: number | string) : Promise<BaseTagsGroupType> => {
  try {
    const endpoint = worldId ? `${apiEndpoints.worlds}/${worldId}/${apiEndpoints.tagsGroups}` : `${apiEndpoints.tagsGroups}`; 
    const result = await base.post(endpoint, tagsGroup);
    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      err.message = "Failed to get a tag.";
    }
    throw err;
  }
};

export const updateBaseTagsGroup = async (tagsGroupId : number | string, tagsGroup: BaseTagsGroupType, worldId?: number | string ) : Promise<BaseTagsGroupType> => {
    try {
      const endpoint = worldId ? `${apiEndpoints.worlds}/${worldId}/${apiEndpoints.tagsGroups}/${tagsGroupId}` : `${apiEndpoints.tagsGroups}/${tagsGroupId}`;
      const result = await base.put(endpoint, tagsGroup);
      return result.data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        err.message = "Failed to get a tag.";
      }
      throw err;
    }
};

export const deleteBaseTagsGroup = async (tagsGroupId : number | string,  worldId?: number | string ) : Promise<BaseTagsGroupType> => {
  try {
    const endpoint = worldId ? `${apiEndpoints.worlds}/${worldId}/${apiEndpoints.tagsGroups}/${tagsGroupId}` : `${apiEndpoints.tagsGroups}/${tagsGroupId}`;
    const result = await base.del(endpoint);
    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      err.message = "Failed to get a tag.";
    }
    throw err;
  }
};

export interface CreateBaseTagsGroupFunctionType {
  (cast: BaseTagsGroupType, worldId?: number | string):  Promise<BaseTagsGroupType> 
}

export const useCreateBaseTagsGroup = () : CreateBaseTagsGroupFunctionType => {

  return useCallback(async (tagsGroup: BaseTagsGroupType, worldId?: number | string) => {
    const result = await createBaseTagsGroup(tagsGroup, worldId);
    const tagGroupId = result.id;
    mutate([`/worlds/${worldId || ``}/tagsGroups/${tagGroupId}`], result); 
    return result;
  }, []);
};

export interface UpdateBaseTagsGroupFunctionType {
  (tagModifierGroupId: number | string, cast: BaseTagsGroupType, worldId?: number | string):  Promise<BaseTagsGroupType> 
} 

export const useUpdateBaseTagsGroup = () : UpdateBaseTagsGroupFunctionType => {

  return useCallback(async (tagsGroupId: number | string, baseTagsGroup: BaseTagsGroupType, worldId?: number | string) => {
    const result = await updateBaseTagsGroup(tagsGroupId, baseTagsGroup, worldId);
    mutate([`/worlds/${worldId || ``}/tagsGroups/${tagsGroupId}`], result);
    return result;
  },[]);
}

export interface DeleteBaseTagsGroupFunctionType {
  (tagModifierGroupId: number | string, worldId?: number | string):  Promise<BaseTagsGroupType> 
} 

export const useDeleteBaseTagsGroup = () : DeleteBaseTagsGroupFunctionType => {

  return useCallback(async (tagsGroupId: number | string,  worldId?: number | string) => {
    const result = await deleteBaseTagsGroup(tagsGroupId, worldId);
    mutate([`/worlds/${worldId || ``}/tagsGroups/${tagsGroupId}`], result);
    return result;
  },[]);
}

// Create Tag Modifiers Group
export const createTagModifiersGroup = async (tagModifiersGroup: TagModifiersGroupType, worldId?: number | string) : Promise<TagModifiersGroupType> => {
  try {
    const endpoint = worldId ? `${apiEndpoints.worlds}/${worldId}/${apiEndpoints.tagModifiersGroups}` : `${apiEndpoints.tagModifiersGroups}`; 
    
    const result = await base.post(endpoint, tagModifiersGroup);
    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      err.message = "Failed to get a tag.";
    }
    throw err;
  }
};

export const updateTagModifiersGroup = async (tagModifierGroupId : number | string, tagModifiersGroup: TagModifiersGroupType, worldId?: number | string) : Promise<TagModifiersGroupType> => {
    try {
      const result = await base.put(`${worldId ? `${apiEndpoints.worlds}/${worldId}/` : ``}${apiEndpoints.tagModifiersGroups}/${tagModifierGroupId}`, tagModifiersGroup);
      return result.data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        err.message = "Failed to get a tag.";
      }
      throw err;
    }
};

export interface CreateTagModifiersGroupFunctionType {
  (tagModifiersGroup: TagModifiersGroupType, worldId?: string | number):  Promise<TagModifiersGroupType> 
}

export const useCreateTagModifiersGroup = () : CreateTagModifiersGroupFunctionType => {
  return useCallback(async (tagModifiersGroup: TagModifiersGroupType, worldId?: string | number) => {
    const result = await createTagModifiersGroup(tagModifiersGroup, worldId);
    const tagModifierGroupId = result.id;
    mutate([`/world/${worldId || ``}/archetype/${tagModifierGroupId}`], result); 
    return result;
  }, []);
};

export interface UpdateTagModifiersGroupFunctionType {
  (tagModifierGroupId: number | string, cast: TagModifiersGroupType, worldId?: number | string):  Promise<TagModifiersGroupType> 
} 

export const useUpdateTagModifiersGroup = () : UpdateTagModifiersGroupFunctionType => {
  return useCallback(async (tagModifierGroupId: number | string, tagModifiersGroup: TagModifiersGroupType, worldId?: number | string) => {
    if (!worldId) {
      throw new Error("No worldId supplied for creating archetype")
    }

    const result = await updateTagModifiersGroup(tagModifierGroupId, tagModifiersGroup, worldId);
    mutate([`/world/${worldId || ``}/archetype/${tagModifierGroupId}`], result);
    return result;
  },[]);
}

