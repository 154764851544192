import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
  } from "react-router-dom";

import { Home } from 'pages/Home';
import { Login } from "pages/Login";
import { Register } from "pages/Register";

import { Archetypes } from "pages/Archetypes";
import { Archetype } from "pages/Archetypes/id";
import { CopyArchetype } from "pages/Archetypes/copy";
import { EditArchetype } from "pages/Archetypes/edit";
import { NewArchetype } from "pages/Archetypes/new";

import { Docs } from "pages/Docs";
import { Doc } from "pages/Docs/id";
import { EditDoc } from "pages/Docs/edit";
import { NewDoc } from "pages/Docs/new";

import { Worlds } from "pages/Worlds";
import { World } from "pages/Worlds/id";
import { EditWorld } from "pages/Worlds/edit";
import { NewWorld } from "pages/Worlds/new";

import { ArchetypeDisplays } from "pages/ArchetypeDisplays";
import { ArchetypeDisplay } from "pages/ArchetypeDisplays/id";
import { EditArchetypeDisplay } from "pages/ArchetypeDisplays/edit";
import { NewArchetypeDisplay } from "pages/ArchetypeDisplays/new";

import { TagModifiersGroups } from "pages/TagModifiersGroups";
import { TagModifiersGroup } from "pages/TagModifiersGroups/id";
import { CopyTagModifier } from "pages/TagModifiersGroups/copy";
import { EditTagModifier } from "pages/TagModifiersGroups/edit";
import { NewTagModifier } from "pages/TagModifiersGroups/new";


import { TagsGroups } from "pages/TagsGroups";
import { EditTagsGroup } from "pages/TagsGroups/edit";
import { CopyTagsGroup } from "pages/TagsGroups/copy";
import { TagsGroup } from "pages/TagsGroups/id";
import { NewTagsGroup } from "pages/TagsGroups/new";

import { Casts } from "pages/Casts";
import { LocalCasts } from "pages/LocalCasts";
import { LocalCast } from "pages/LocalCasts/id";
import { EditLocalCast } from "pages/LocalCasts/id/EditCast";
import { NewLocalCast } from "pages/LocalCasts/NewCast";
import { Cast } from "pages/Casts/id";
import { PrintableCast } from "pages/Casts/printable";
import { NewCast } from "pages/Casts/NewCast";
import { EditCast } from "pages/Casts/id/EditCast";

import { CastOfRenown } from "pages/CastsOfRenown/id";

import { EditUser } from "pages/Users/edit";
import { Users } from "pages/Users";
import { User } from "pages/Users/id";

import { TermsAndConditions } from "pages/TermsAndConditions";
import { ConfirmEmail } from "pages/ConfirmEmail";
import { RequestEmailVerification } from "pages/RequestEmailVerification";

import { useAuthState } from 'atoms/auth.atom';

import { routeLinks } from "App/routing/routeLinks"
import { Fragment } from "react";
import { ResetPassword } from "pages/ResetPassword";
import { RequestPasswordReset } from "pages/RequestPasswordReset";
import { UserMe } from "pages/UserMe";
export { routeLinks, externalLinks } from "App/routing/routeLinks"

const AuthenticatedRoutes = () => {
  return (
    <Routes>
        <Route path={`${routeLinks.archetypeDisplays}/${routeLinks.new}`}
          element={<NewArchetypeDisplay />}>
        </Route>
        <Route path={`${routeLinks.archetypeDisplays}/:archetypeDisplayId/${routeLinks.edit}`}
          element={<EditArchetypeDisplay />}>
        </Route>
        <Route path={`${routeLinks.archetypeDisplays}/:archetypeDisplayId`}
          element={<ArchetypeDisplay />}>
        </Route>
        <Route path={`${routeLinks.archetypeDisplays}`}
          element={<ArchetypeDisplays />}>
        </Route>
        <Route path={`${routeLinks.factions}/${routeLinks.new}`}
          element={<NewArchetype />}>
        </Route>
        <Route path={`${routeLinks.factions}/:archetypeId/${routeLinks.teams}/${routeLinks.new}`} 
          element={<NewCast />}>
        </Route>
        <Route path={`${routeLinks.factions}/:archetypeId/${routeLinks.copy}`} 
          element={<CopyArchetype />}>
        </Route>
        <Route path={`${routeLinks.factions}/:archetypeId/${routeLinks.edit}`}
          element={<EditArchetype />}>
        </Route>
        <Route path={`${routeLinks.factions}/:archetypeId`}
          element={<Archetype />}>
        </Route>
        <Route path={`${routeLinks.factions}`}
          element={<Archetypes />}>
        </Route>
        <Route path={`${routeLinks.factions}/${routeLinks.new}`}
          element={<NewArchetype />}>
        </Route>
        <Route path={`${routeLinks.tagModifiersGroups}/${routeLinks.new}`}
          element={<NewTagModifier />}>
        </Route>
        <Route path={`${routeLinks.tagModifiersGroups}/:tagModifiersGroupId/${routeLinks.copy}`} 
          element={<CopyTagModifier />}>
        </Route>
        <Route path={`${routeLinks.tagModifiersGroups}/:tagModifiersGroupId/${routeLinks.edit}`}
          element={<EditTagModifier />}>
        </Route>
        <Route path={`${routeLinks.tagModifiersGroups}/:tagModifiersGroupId`}
          element={<TagModifiersGroup />}>
        </Route>
        <Route path={`${routeLinks.tagModifiersGroups}/`}
          element={<TagModifiersGroups />}>
        </Route>
        <Route path={`${routeLinks.traits}/${routeLinks.new}`}
          element={<NewTagsGroup />}>
        </Route>
        <Route path={`${routeLinks.traits}/:tagsGroupId/${routeLinks.copy}`}
          element={<CopyTagsGroup />}>
        </Route>
        <Route path={`${routeLinks.traits}/:tagsGroupId/${routeLinks.edit}`}
          element={<EditTagsGroup />}>
        </Route>
        <Route path={`${routeLinks.traits}/:tagsGroupId`}
          element={<TagsGroup />}>
        </Route>
        <Route path={`${routeLinks.traits}`}
          element={<TagsGroups />}>
        </Route>
        <Route path={`${routeLinks.teams}/:id/${routeLinks.renownTeams}/:id`}
          element={<CastOfRenown />}>
        </Route>
        <Route path={`${routeLinks.teams}/${routeLinks.new}`}
          element={<NewCast />}>
        </Route>
        <Route path={`${routeLinks.teams}/:id/${routeLinks.edit}`}
          element={<EditCast />}>
        </Route>
        <Route path={`${routeLinks.teams}/:id`}
          element={<Cast />}>
        </Route>
        <Route path={`${routeLinks.teams}/:id/${routeLinks.printable}`}
          element={<PrintableCast />}>
        </Route>
        <Route path={`${routeLinks.teams}`}
        element={<Casts />}>
        </Route>
        <Route path={`${routeLinks.users}/:id/${routeLinks.edit}`}
          element={<EditUser />}>
        </Route>
        <Route path={`${routeLinks.users}/:id`}
          element={<User />}>
        </Route>
        <Route path={`${routeLinks.users}`}
        element={<Users />}>
        </Route>
        <Route path={`${routeLinks.docs}/${routeLinks.new}`}
          element={<NewDoc />}>
        </Route>
        <Route path={`${routeLinks.docs}/:docId/${routeLinks.edit}`}
          element={<EditDoc />}>
        </Route>
        <Route path={`${routeLinks.docs}/:docId`}
          element={<Doc />}>
        </Route>
        <Route path={`${routeLinks.docs}`}
          element={<Docs />}>
        </Route>
        <Route path={`${routeLinks.worlds}/${routeLinks.new}`}
          element={<NewWorld />}>
        </Route>
        <Route path={`${routeLinks.worlds}/:worldId/${routeLinks.edit}`}
          element={<EditWorld/>}>
        </Route>
        <Route path={`${routeLinks.worlds}/:worldId`}
          element={<World />}>
        </Route>
        <Route path={`${routeLinks.worlds}`}
          element={<Worlds />}>
        </Route>
        <Route path={`${routeLinks.userMe}`}
          element={<UserMe />}>
        </Route>  
    </Routes>
  )
}

export const Routing = () => {
  const { state: appState } = useAuthState();
  
  return (
    <Router>
      <Routes>
        <Route path={`${routeLinks.login}`}
          element={<Login />}>
        </Route>
        <Route path={`${routeLinks.register}`}
          element={<Register />}>
        </Route>
        <Route path={`${routeLinks.guest}/${routeLinks.factions}/:archetypeId/${routeLinks.guestTeams}/:id/${routeLinks.new}`}
          element={<NewLocalCast />}>
        </Route>
        <Route path={`${routeLinks.guest}/${routeLinks.factions}/:archetypeId`}
          element={<Archetype />}>
        </Route>
        <Route path={`${routeLinks.guest}/${routeLinks.factions}`}
          element={<Archetypes />}>
        </Route>
        <Route path={`${routeLinks.guest}/${routeLinks.tagModifiersGroups}/:tagModifiersGroupId`}
          element={<TagModifiersGroup />}>
        </Route>
        <Route path={`${routeLinks.guest}/${routeLinks.tagModifiersGroups}/`}
          element={<TagModifiersGroups />}>
        </Route>
        <Route path={`${routeLinks.guest}/${routeLinks.traits}/:tagsGroupId`}
          element={<TagsGroup />}>
        </Route>
        <Route path={`${routeLinks.guest}/${routeLinks.traits}/`}
          element={<TagsGroups />}>
        </Route>
        <Route path={`${routeLinks.guest}/${routeLinks.docs}/:docId`}
          element={<Doc />}>
        </Route>
        <Route path={`${routeLinks.guest}/${routeLinks.docs}`}
          element={<Docs />}>
        </Route> 
        <Route path={`${routeLinks.guestTeams}/:id/${routeLinks.edit}`}
          element={<EditLocalCast />}>
        </Route>
        <Route path={`${routeLinks.guestTeams}/:id/${routeLinks.new}`}
          element={<NewLocalCast />}>
        </Route>
        <Route path={`${routeLinks.guestTeams}/:id`}
          element={<LocalCast />}>
        </Route>
        <Route path={`${routeLinks.guestTeams}/`}
          element={<LocalCasts />}>
        </Route>
        <Route path={`${routeLinks.guest}/${routeLinks.renownTeams}/:id`}
          element={<CastOfRenown />}>
        </Route>
        <Route path={`${routeLinks.termsAndConditions}/`}
          element={<TermsAndConditions />}>
        </Route>
        <Route path={`${routeLinks.confirmEmail}/`}
          element={<RequestEmailVerification />}>
        </Route>
        <Route path={`${routeLinks.confirmEmail}/:token`}
          element={<ConfirmEmail />}>
        </Route>
        <Route path={`${routeLinks.requestPasswordReset}/`}
          element={<RequestPasswordReset />}>
        </Route>
        <Route path={`${routeLinks.resetPassword}/:token`}
          element={<ResetPassword />}>
        </Route>
        <Route path="/"
          element={<Home />}>
        </Route>
        <Route
          path={`*`}
          element={<RedirectElement appState={appState} />}
        ></Route>
      </Routes>
    </Router>
  );
};

const RedirectElement = ({ appState }: { appState: any;}) => {

  if(appState.Authenticating && !appState.Authenticated) {
    return  <>null</>;
  } else if (appState.Authenticated) {
    return <AuthenticatedRoutes />
  } else {
    return <Routes>
      <Route
      path={`*`}
        element={<Navigate
        to={`${routeLinks.login}`}
        replace={true}
      />}>
      </Route>
    </Routes>
  }
} 