import {  DisplayTagType } from 'actions/tag.types';

const handleTagValuesAsNumbers = (handle: (a: number, b: number) => number, valA?: string | number, valB?: string | number) => {
  const numValA = typeof valA === 'string' ? parseFloat(valA || "0") : valA || 0;
  const numValB = typeof valB === 'string' ? parseFloat(valB || "0") : valB || 0;

  if(Number.isNaN(numValA) || Number.isNaN(numValB)) {
      return Number.NaN;
  } else {
      return handle(numValA,numValB);
  }
};

export const addTagVals = (valA?: string | number, valB?: string | number) => {
  return handleTagValuesAsNumbers((a,b) => { return a + b; }, valA, valB);
};

export const multiplyTagVals = (valA?: string | number, valB?: string | number) => {
  return handleTagValuesAsNumbers((a,b) => { return a * b; }, valA, valB); 
};

export const parseToNumber = (val?: number | string) => {
  if (val === undefined) {
    return 0;
  }

  return typeof val === 'string' ? parseFloat(val || "0") : val || 0;

}

export const getTagDisplayName = (tag?: DisplayTagType) => {
  if (!tag) {
    return '(Unknown)';
  }

  let valueToDisplay = null;

  // if already has label, show label.
  if(tag.label) {
    return `${tag.label}`
  }

  if(tag.val  && Array.isArray(tag.val) && tag.valAreTagKeys) {
    const v2: string[] = (tag.val || []).map((v) => { return `${v}`; });
    const values2 = v2.reduce((result: string, v: string | number) => {
      return `${result}${result ? `, ` :``}${v}`;
    }, "");
    valueToDisplay = `(${values2})`;
  } else if (tag.val) {
    valueToDisplay = `(${tag.val})`;
  } else {
    valueToDisplay = null;
  }
  return `${tag.title || tag.baseKey || tag.key}${valueToDisplay ? valueToDisplay : ``}`;
}
