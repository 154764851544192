import { useMemo, useState } from "react";
import Layout from "Layout";
import { useParams } from "react-router-dom";
import { LinkButton, Button } from "components/Buttons/Button";
import { ShowArchetypeMember } from "components/ArchetypeMembers/ShowArchetypeMember/BloodOfStarsArchetype";
import { useGetCurrentUser } from "hooks/userHooks";
import { useArchetype, useGroupCasts } from 'hooks/archetypeMemberHooks';
import { ArchetypeIcon } from "components/Icons/Archetypes";
import { TagDescriptorModal, useTagDescriptorModal } from "components/ArchetypeMembers/TagDescriptorModal";
import { tagsToMap } from "actions/helpers/tagHelpers";
import { GroupedTagsModal } from "components/Modals/GroupedTagsModal";
import styles from "./styles.module.scss";
import { routeLinks } from "App/routing";
import { ViewArchetypeDisplayOptions } from "components/ViewArchetypeDisplayOptions";
import Modal from "components/Modal";
import classNames from "classnames";
import { ShrinkButton, useShrinkList } from "components/ShrinkList";

type CastPageParams = {
  archetypeId: string;
};

export const Archetype = () => {
  const { archetypeId } = useParams<CastPageParams>();
  const { isAdmin, isGuest } = useGetCurrentUser(); 
  const {
    name,
    archetype,
    tagModifiers,
    baseTags,
    displayTagOptions,
    groupedTags,
    archetypeDisplay,
  } = useArchetype(archetypeId);
  const tagDescriptionsMap = useMemo(() => {
    return tagsToMap(baseTags);
  },[baseTags]);
  const [showTraitsModal, setShowTraitsModal] = useState(false);
  const [showArchetypeOptions, setShowArchetypeOptions] = useState(false);
  const { showTagDescriptor, setShowTagDescriptor, onClickDescriptor } = useTagDescriptorModal();
  const { displayGroups } = useGroupCasts(archetype?.data?.cast || [], archetypeDisplay);
  const { toggleShrink, toggleShrinkAll, allShrunk, isShrunk } = useShrinkList(archetype?.data?.cast);

  const showingAModal = showTraitsModal || showArchetypeOptions || showTagDescriptor;

  return (
    <Layout>
      <div >
        <h1>
          {archetype && <ArchetypeIcon archetype={archetype} />}{`${name}`}
        </h1>
        <div className={styles.controlButtonsWrapper}>
          <div className={styles.editButtons}>
            <Button 
              className={styles.editButton}
              onClick={() => { 
                setShowTraitsModal(!showTraitsModal);
              }}
            >Traits</Button>
            <Button 
              className={styles.editButton}
              onClick={() => { 
                setShowArchetypeOptions(!showArchetypeOptions);
              }}
            >Options</Button>
           {isAdmin && <><LinkButton to={`/${routeLinks.factions}/${archetypeId}/${routeLinks.edit}`} >Edit</LinkButton> 
            <LinkButton to={`/${routeLinks.factions}/${archetypeId}/${routeLinks.copy}`} >Copy</LinkButton> 
            </>}
            <ShrinkButton {...{toggleShrinkAll, allShrunk, className: classNames(styles.editButton, styles.mobileHide) }} />
            {isGuest ? <>
              <LinkButton 
                className={styles.editButton}
                to={`/${routeLinks.guestTeams}`}
              >Create Team</LinkButton>
            </> : <>
              <LinkButton 
                className={styles.editButton}
                to={`/${routeLinks.factions}/${archetypeId}/${routeLinks.teams}/${routeLinks.new}`}
              >Create Team</LinkButton>
            </>}
          </div>
        </div>
        <div className={classNames(styles.mobileSelectFooter,(showTagDescriptor || showingAModal) && styles.mobileHide)}>
            <Button
              onClick={() => {
                toggleShrinkAll();
              }}
              className={classNames(styles.mobileSelectButton)}
            >{allShrunk ? `expand` : `shrink`}</Button>
      </div>
        {displayGroups?.map((dg)=> {
          return (
            <div className={styles.archetypeGroupWrapper} key={dg.key}>
            <h4 className={styles.groupName}>{dg.name}</h4>
            <div className={styles.archetypeMembersWrapper}>
              {dg.casts?.map((archetypeMember) => {
                return (<ShowArchetypeMember
                  key={archetypeMember.key}
                  className={styles.showArchetypeMember}
                  {...{ 
                    archetypeMember,
                    archetypeDisplay,
                    tagModifiers,
                    tagDescriptionsMap,
                    onClickDescriptor,
                    isShrunk: isShrunk(archetypeMember.key),
                    toggleShrink: () => { toggleShrink(archetypeMember.key); }
                  }}
                />);
              })}
            </div>
            </div>
          )
        })}
        { showTagDescriptor && <TagDescriptorModal
            tag={showTagDescriptor}
            close={() => {
              setShowTagDescriptor(undefined)
            }} 
          />
        }
        {!showTraitsModal ? null : <GroupedTagsModal
            closeModal={() => {
              setShowTraitsModal(false);
            }}
            groupedTags={groupedTags}
          />
        }
        { showArchetypeOptions && archetype?.data?.cast && archetype?.data?.cast.length > 0 && archetype?.data?.cast[0] && <Modal
          onBackgroundClick={() => { setShowArchetypeOptions(false)}}
          onClick={() => { setShowArchetypeOptions(false) }}
          closeModal={() => { setShowArchetypeOptions(false) }}
          includeCloseButton={true}
        >
          <ViewArchetypeDisplayOptions 
            archetype={ archetype}
            archetypeDisplay={archetypeDisplay}
            editTagOptions={displayTagOptions}
          ></ViewArchetypeDisplayOptions>
        </Modal>
        }
      </div>
    </Layout>
  );
}
