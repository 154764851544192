import React, {  useCallback } from "react";
import Layout from "Layout";
import classNames from "classnames";
import { useParams } from "react-router-dom";

import { BaseTagsGroupType } from "actions/tag.types";
import { Button } from "components/Buttons/Button";
import { TextField, TextFieldType } from "components/Forms/TextField";
import { ShowTag } from "components/Tags/ShowTag";
import styles from "./styles.module.scss";
import { useEditTagsGroupForm } from "pages/TagsGroups/useTagsGroupForm";
import { Form } from 'components/Forms/Form';
import { JSONEditor } from "components/JsonEditor"
import { routeLinks } from "App/routing";
import { useNavigate } from "react-router-dom";
import { getTagKey } from "actions/helpers/tagHelpers";

type CastPageParams = {
  tagsGroupId: string;
};
export const EditTagsGroup = () => {
  const { tagsGroupId } = useParams<CastPageParams>();
  const navigate = useNavigate();
  
  const submitCallback = useCallback((baseTagsGroup?: BaseTagsGroupType) => {
    navigate(`/${routeLinks.traits}/${baseTagsGroup?.id || tagsGroupId}`);
  }, [navigate, tagsGroupId]);

  const {
    name,
    tags,
    errors,
    onSubmit,
    register,
    submitting,
    submitError,
    setValue,
    validateTagsJsonString,
    validateGroupingsJsonString,
    tagsJsonValidator,
    groupingsJsonValidator,
    formTags,
    formGroupings
  } = useEditTagsGroupForm({tagsGroupId, callback: submitCallback});

  return (
    <Layout>
      <div >
        <h1>
          { `${tagsGroupId} : ${name}`}
        </h1>
        <div>
          <Form
            onSubmit={onSubmit}
          >
            <Button type="submit">Save</Button>
            {!submitting && submitError && (<div>{submitError}</div>)}
            <TextField 
              type={TextFieldType.text}
              placeholder="key"
              label="Name"
              labelClassName={styles.label}
              className={styles.entry}
              error={errors?.name && { ...errors.name, ...{message:"Name must not be empty"}}}
              {...register("name",{
                validate:{
                  validLength: (val?: string) => {
                    return val && val?.length > 0;
                  }
                }
              })}
            />
            <TextField 
              type={TextFieldType.text}
              placeholder="Description"
              label="Description"
              labelClassName={styles.label}
              className={classNames(styles.entry, errors?.description && styles.textError)}
              error={errors?.description && { ...errors.description, ...{message:"Description must not be empty"}}}
              {...register("description",{
                validate:{
                  validLength: (val?: string) => {
                    return val && val?.length > 0;
                  }
                }
              })}
            />
            <TextField 
              type={TextFieldType.number}
              labelClassName={styles.label}
              className={styles.entry}
              placeholder="Display"
              label="Display"
              error={errors?.display}
              {...register("display",{
              })}
            />
            <TextField
              type={TextFieldType.textArea}
              label="Tags" 
              fieldClassName={styles.dataText}
              labelClassName={styles.label}
              className={styles.hidden}
              error={errors?.tags}
              {...register("tags",{
                validate:{
                  validLength: (val?: string) => {
                    return val && val?.length > 0;
                  },
                  validJson:(val: string) => {
                    return validateTagsJsonString(val);
                  }
                }
              })}
            />
           <TextField
              type={TextFieldType.textArea}
              label="Grouping" 
              fieldClassName={styles.dataText}
              labelClassName={styles.label}
              className={styles.hidden}
              error={errors?.groupings}
              {...register("groupings",{
                validate:{
                  validJson:(val?: string) => {
                    return !val || validateGroupingsJsonString(val);
                  }
                }
              })}
            />
            <JSONEditor
              value={formTags}
              className={styles.dataBlock}
              fieldClassName={styles.dataBlockField}
              label="Tags"
              labelClassName={styles.label}
              error={errors?.tags}
              onChange={(changed, oldChanged) => {
                if(changed !== oldChanged && (typeof changed === "string" || typeof changed === 'undefined')) {
                  setValue('tags', changed || '');
                }
              }}
              validator={tagsJsonValidator}
            />
            <JSONEditor
              value={formGroupings}
              className={styles.dataBlock}
              fieldClassName={styles.dataBlockField}
              label="Groupings"
              labelClassName={styles.label}
              error={errors?.groupings}
              onChange={(changed, oldChanged) => {
                if(changed !== oldChanged && (typeof changed === "string" || typeof changed === 'undefined')) {
                  setValue('groupings', changed || '');
                }
              }}
              validator={groupingsJsonValidator}
            />
          </Form>
        </div>
        <div>
          {tags?.map((tag) => {
              return (<ShowTag 
                key={`${tag.id}-${getTagKey(tag)}`}
                tag={tag}
              />);
          })}
        </div>
      </div>
    </Layout>
  );
}
