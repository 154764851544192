import React, { useState } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { CastMemberType, ArchetypeDisplayType, ArchetypeSectionType } from 'actions/cast.types';
import { TagModifierType, TagDescriptionType, TagType, TagSelector } from 'actions/tag.types';
import { SectionType } from 'actions/cast.types';
import { useDisplayArchetypeMember, SectionKeys } from "hooks/archetypeMemberHooks";
import { filterTagsBySelectors } from 'lib/thresholds';

export const getTags = (tags: Array<string | TagSelector>, archetypeMember?:  Map<string, TagType>) => {
  //const statBlocks = tags;
  if (!archetypeMember) {
    return [];
  }
  return filterTagsBySelectors(tags, archetypeMember ? Array.from(archetypeMember.values()) : []);
};

interface PrimarySectionProps {
  memberTagsMap?: Map<string, TagType>;
  section: ArchetypeSectionType;
};

const PrimarySection = ({ memberTagsMap, section }: PrimarySectionProps) => {
  const stats = getTags(section.tagKeys, memberTagsMap);

  return <div>
    <ul className={styles.memberStats}>
    { stats.map((s) => {
      return <li key={s.key}><TagDescription tag={s} alwaysShowVal={true} description={s.description} /></li>
    })}
    </ul>
  </div>
};

interface ShowStatsProps {
  memberTagsMap?: Map<string, TagType>;
  section: ArchetypeSectionType;
};

interface TagStatsProps {
  tag: TagType;
  alwaysShowVal?: boolean;
  description?: string;
  valDescription?: Array<TagType>;
}

const DisplayStat = ({ tag }: DisplayTagProps) => {
  const minTitle = (tag.title || tag.key || '');
  const minInitial = minTitle.length > 0 ? minTitle.substring(0,1) : '';

  const valTags = tag.valDescriptions ? tag.valDescriptions.map((vd) => {
    return minInitial;
  }).join(', ') : (Array.isArray(tag.val) ? tag.val.join(', ') : tag.val);
 return <>
    {minInitial}{(tag.val) && `: `}
    {valTags}
 </> 
}

const TagStats = ({
  tag
}:TagStatsProps) => {
  const [showTagDescription, setShowTagDescription] = useState(false);


  return <div 
    onMouseDown={() => { setShowTagDescription(true); }}
    onTouchStart={() => { setShowTagDescription(true); }}
    onMouseUp={() => { setShowTagDescription(false); }}
    onMouseLeave={() => { setShowTagDescription(false); }}
    onTouchEnd={() => { setShowTagDescription(false); }}
    onTouchMove={() => { setShowTagDescription(false); }}
  >
    <DisplayStat tag={tag} />
    {
      showTagDescription && <div className={classNames(styles.tagDescription)}>
         {tag.title || tag.key}: {tag.description}{tag.valDescriptions && tag.valDescriptions.length > 0 ? <ValTagDescriptions tagDescriptions={tag.valDescriptions} /> : undefined}
      </div>
    }
  </div>
}

const ShowStats = ({ memberTagsMap, section }: ShowStatsProps) => {
  const stats = getTags(section.tagKeys, memberTagsMap);

  return <div>
    <ul className={styles.memberStats}>
    { stats.map((s) => {
      return <li key={s.key}><TagStats tag={s} alwaysShowVal={true} /></li>
    })}
    </ul>
  </div>
};

interface ValTagDescriptionsProps {
  tagDescriptions?: Array<TagDescriptionType>;
}

const ValTagDescriptions = ({ tagDescriptions}: ValTagDescriptionsProps) => {
  return <>
    {(tagDescriptions && tagDescriptions.length > 0) ? ` (` : ``}
    { tagDescriptions?.map((t) => {
      return <div key={t.id}>
        {t.title || t.key}: {t.description}
      </div>
    })}
    {tagDescriptions && tagDescriptions.length > 0 ? `)` : ``}
  </>
}

interface DisplayTagProps {
  tag: TagType;
}

const DisplayTag = ({ tag }: DisplayTagProps) => {
  const valTags = tag.valDescriptions ? tag.valDescriptions.map((vd) => {
    return vd.title || vd.key;
  }).join(', ') : (Array.isArray(tag.val) ? tag.val.join(', ') : tag.val);
 return <>
    {tag.title || tag.key}{(tag.val) && `: `}
    {valTags}
 </> 
}

interface tagDescriptionProps {
  tag: TagType;
  alwaysShowVal?: boolean;
  description?: string;
  valDescription?: Array<TagType>;
}

const TagDescription = ({ tag, alwaysShowVal }: tagDescriptionProps) => {
  const [showTagDescription, setShowTagDescription] = useState(false);

  return <div 
    onMouseDown={() => { setShowTagDescription(true); }}
    onTouchStart={() => { setShowTagDescription(true); }}
    onMouseUp={() => { setShowTagDescription(false); }}
    onMouseLeave={() => { setShowTagDescription(false); }}
    onTouchEnd={() => { setShowTagDescription(false); }}
    onTouchMove={() => { setShowTagDescription(false); }}
  >
    <DisplayTag tag={tag} />
    {
      showTagDescription && <div className={classNames(styles.tagDescription)}>
         {tag.title || tag.key}: {tag.description}{tag.valDescriptions && tag.valDescriptions.length > 0 ? <ValTagDescriptions tagDescriptions={tag.valDescriptions} /> : undefined}
      </div>
    }
  </div>
}

interface ShowSectionProps {
  memberTagsMap?: Map<string, TagType>;
  tagOptionsMap?: Map<string, TagType>;
  section: ArchetypeSectionType;
  sectionType?: string;
};

const DefaultSection = ({ memberTagsMap, section, sectionType, tagOptionsMap }: ShowSectionProps) => {
  const mainTags = getTags(section.tagKeys, memberTagsMap);
  const tagOptions = getTags(section.tagKeys, tagOptionsMap);
  const tags = sectionType === SectionType.options ? tagOptions : mainTags;

  if (!tags || tags.length <= 0) {
    return null;
  }

  return <div>
    <ul className={styles.memberStats}>
      {section.name}: 
    {tags.map((t) => {
      return (<li key={t.key}><TagDescription tag={t} /></li>);
    })}
    </ul>
  </div>
};

interface ShowArchetypeProps {
  archetypeMember: CastMemberType;
  archetypeDisplay?: ArchetypeDisplayType;
  tagModifiers?: Array<TagModifierType>;
  tagDescriptionsMap?: Map<string, TagType>;
}

interface ShowDisplayArchetypeMemberProps {
  displayArchetypeMember: CastMemberType;
  archetypeDisplay?: ArchetypeDisplayType;
}

export const ShowDisplayArchetypeMember = ({ 
  displayArchetypeMember,
  archetypeDisplay
}: ShowDisplayArchetypeMemberProps ) => {

  const tagsMap = displayArchetypeMember?.tagsMap;
  const tagOptionsMap = displayArchetypeMember?.tagOptionsMap;
  const sections = archetypeDisplay?.data?.sections;
  return (<div>
    <h4 className={styles.name}>{displayArchetypeMember.name}</h4>
      <div>
        {sections?.map((sam) => {
          switch(sam.key) {
            case SectionKeys.primary:
              return <PrimarySection key={sam.key} {...{ memberTagsMap: tagsMap, section: sam }} />
            case SectionKeys.statline:
              return <ShowStats key={sam.key} {...{ memberTagsMap: tagsMap, section: sam }} />
            case SectionKeys.injuries:
            case SectionKeys.tagOptions:
            case SectionKeys.gear:
              return <DefaultSection key={sam.key} {...{ memberTagsMap: tagsMap, tagOptionsMap, section: sam, sectionType: sam.type }} />
            case SectionKeys.traits:
            case SectionKeys.traitConditions:
              return <DefaultSection key={sam.key} {...{ memberTagsMap: tagsMap, tagOptionsMap, section: sam, sectionType: sam.type }} />
            case SectionKeys.leaderTraits:
            case SectionKeys.upgrades:
              return null;
            default:
              // return null;
              return <DefaultSection key={sam.key} {...{ memberTagsMap: tagsMap, tagOptionsMap, section: sam, sectionType: sam.type }} />
          }
        })}
      </div>
  </div>);
};

export const ShowArchetypeMember = ({ 
  archetypeMember,
  archetypeDisplay,
  tagModifiers,
  tagDescriptionsMap
  }: ShowArchetypeProps ) => {
  const {
    displayArchetypeMember,
    displayMemberTagMaps,
  } = useDisplayArchetypeMember({ archetypeMember, tagModifiers, tagDescriptionsMap});
  const sections = archetypeDisplay?.data?.sections;
  return (<div className={classNames(styles.archetypeMemberGroup, styles.bookView)}>
    <h4 className={styles.name}>{displayArchetypeMember.name}</h4>
      <div>
        {sections?.map((sam) => {
          switch(sam.key) {
            case SectionKeys.primary:
              return <PrimarySection key={sam.key} {...{ memberTagsMap: displayMemberTagMaps, section: sam }} />
            case SectionKeys.statline:
              return <ShowStats key={sam.key} {...{ memberTagsMap: displayMemberTagMaps, section: sam }} />
            case SectionKeys.injuries:
              return <ShowStats key={sam.key} {...{ memberTagsMap: displayMemberTagMaps, section: sam }} />
            default:
              return <DefaultSection key={sam.key} {...{memberTagsMap: displayMemberTagMaps, section: sam}} />
          }
        })}
      </div>
  </div>);
};
