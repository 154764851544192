import React, { useMemo, useState, useCallback, useEffect } from "react";
import { 
  useGetArchetypeDisplay,
  useCreateArchetypeDisplay,
  useUpdateArchetypeDisplay,
  CreateArchetypeDisplayFunctionType,
  UpdateArchetypeDisplayFunctionType
} from 'actions/archetypeDisplayActions';
import { ArchetypeDisplayType } from "actions/cast.types";
import { useForm } from 'react-hook-form';
import { isArrayValidator, createValidateJsonString } from "components/JsonEditor"
import { useWorld } from "hooks/worldHooks";
import { useGetCurrentUser } from "hooks/userHooks";
interface UseArchetypeProps {
  userId?: number;
  archetypeDisplayKey?: string | number;
  name?: string;
  description?: string;
  archetypeDisplay?: ArchetypeDisplayType;
  ArchetypeDisplay?: Array<ArchetypeDisplayType>;
  createArchetypeDisplay?: CreateArchetypeDisplayFunctionType;
  updateArchetypeDisplay?: UpdateArchetypeDisplayFunctionType;
  loaded?: boolean;
}

const useArchetypDisplay = (archetypeDisplayKey?: string | number): UseArchetypeProps => {
  const [loaded, setLoaded]= useState(false);
  const { chosenWorldId} = useWorld();
  const { id } = useGetCurrentUser(); 
  const { data } = useGetArchetypeDisplay(archetypeDisplayKey, chosenWorldId, id);

  useEffect(() => {
    if(data) {
      setLoaded(true);
    }
  }, [data])

  const createArchetypeDisplay = useCreateArchetypeDisplay();
  const updateArchetypeDisplay = useUpdateArchetypeDisplay();

  return {
    archetypeDisplayKey: archetypeDisplayKey || undefined,
    name: data?.name,
    description: data?.description,
    archetypeDisplay: data,
    createArchetypeDisplay: (data:ArchetypeDisplayType) => { return createArchetypeDisplay(data, chosenWorldId); } ,

    updateArchetypeDisplay: (id: string | number, data:ArchetypeDisplayType) => { return updateArchetypeDisplay(id, data, chosenWorldId); },
    loaded
  };
}

interface SubmitArchetypeDisplayTypes {
  name?: string;
  display?: number;
  description?: string;
  data?: string;
  sections?: string;
  groups?: string;
}

const translateValues = (vals: SubmitArchetypeDisplayTypes): ArchetypeDisplayType => {

  try {
    const parsedSections = JSON.parse(vals?.sections || "[]");
    const parsedGroups = JSON.parse(vals?.groups || "[]");
  
  
    return {
      name: vals?.name || "",
      display: vals?.display || 0,
      description: vals?.description,
      data: {
        sections: parsedSections,
        groups: parsedGroups
      },
      sections: parsedSections,
      groups: parsedGroups
    }
  } catch(err) {
    console.log('failed to translate values');
    throw err;
  }
}

export enum FormType {
  Update,
  All,
  Create,
  Get
}

interface useArchetypeDisplayFormProps {
  archetypeDisplayId?: string;
  formType?: FormType;
  callback?: (archetypeDisplay?: ArchetypeDisplayType) => void ;
}

export const useArchetypeDisplayForm = ({
  archetypeDisplayId, 
  formType = FormType.Update,
  callback = () => {} 
}: useArchetypeDisplayFormProps = {}) => {
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState('');

  const {
    name,
    description,
    archetypeDisplay,
    loaded,
    createArchetypeDisplay,
    updateArchetypeDisplay, 
  } = useArchetypDisplay(archetypeDisplayId);

  const [formLoaded, setFormLoaded ] = useState(false);  

  const loadFormValues = useCallback((archetypeDisplay?: ArchetypeDisplayType) => {
    return { 
      name: archetypeDisplay?.name ,
      display: archetypeDisplay?.display || 0,
      description: archetypeDisplay?.description,
      sections: JSON.stringify(archetypeDisplay?.data?.sections),
      groups: JSON.stringify(archetypeDisplay?.data?.groups)
    };
  },[]);

  const { register, handleSubmit, formState, getValues, setValue, reset, setError, clearErrors } = useForm({
    defaultValues: loadFormValues(archetypeDisplay)
  });

  const formSections = getValues('sections');
  const formGroups = getValues('groups');

  useEffect(() => {
    if((loaded && !formLoaded) || (!archetypeDisplayId && formType === FormType.Create)) {
      reset(loadFormValues(archetypeDisplay));
      setFormLoaded(true);
    }
  }, [setFormLoaded, reset, loaded, loadFormValues, formLoaded, archetypeDisplay, archetypeDisplayId, formType]);

  const saveAndMerge = useCallback(async (vals: SubmitArchetypeDisplayTypes) => {
    try {
      setSubmitting(true);
      setSubmitError('');
      const translatedValues = translateValues({ ...vals});


      let archetypeDisplay: ArchetypeDisplayType | undefined;
      if(formType === FormType.Create) {
        archetypeDisplay = await (createArchetypeDisplay && createArchetypeDisplay(translatedValues));
        setSubmitting(false);
      } else {
        archetypeDisplay = await (updateArchetypeDisplay && archetypeDisplayId ? updateArchetypeDisplay(archetypeDisplayId, translatedValues) : undefined);
        setSubmitting(false);
      }

      await callback(archetypeDisplay);
      return archetypeDisplay;
      // forward to next page
    } catch(err) {
      setSubmitting(false);
      setSubmitError('Update to Cast Member Failed.');
    }
  }, [formType, callback, createArchetypeDisplay, updateArchetypeDisplay, archetypeDisplayId]);
  const errors = formState?.errors;

  const jsonValidator = useMemo(() => {
   return isArrayValidator();
  },
  []);

  const validateJsonString = useMemo(() => {
    return createValidateJsonString(jsonValidator);
  },
  [jsonValidator]);

  const onSubmit = async (e:  React.FormEvent<HTMLFormElement>) => {
    await handleSubmit(saveAndMerge)(e);
  }

  return {
    name,
    description,
    archetypeDisplay,
    loaded,
    submitting,
    submitError,
    formSections,
    formGroups,
    saveAndMerge,
    validateJsonString,
    jsonValidator,
    register,
    setValue,
    onSubmit,
    errors,
    setError,
    handleSubmit,
    clearErrors
  };
}
