import { TagGroupingType } from "actions/tag.types";
import styles from './styles.module.scss';
import { ShowTag } from "components/Tags/ShowTag";
import { getTagKey } from "actions/helpers/tagHelpers";

interface ShowTagProps {
  groupedTags?: Array<TagGroupingType>;
  tagClassName?: string;
}

export const ShowTagsGroups = ({ groupedTags, tagClassName }: ShowTagProps) => {
  return (        <div>
    {groupedTags?.map((group) => {
      return <div key={group.id || group.key}>
        <h3>{group.title}</h3>
        <div>
          {group.tags?.map((tag) => {
            return tag && <div key={tag.id || getTagKey(tag)} className={styles.tagWrapper}><ShowTag key={tag.id} className={tagClassName}
              {...{ tag }}
            /></div>;
          })}
        </div>
      </div>;
    })}
    
  </div>);
};

export default ShowTagsGroups;