import React, { ButtonHTMLAttributes } from "react";
import { Link } from 'react-router-dom';
import classNames from "classnames";

import styles from "./styles.module.scss";

interface ExtendedButtonProps {
}

export enum ButtonStyle {
  primary = "primary",
  primaryFlat = "primaryFlat",
  secondary = "secondary",
  inline = "inline",
  none = "none"
}

export enum TargetType {
  blank = "_blank"
}

interface IButtonProps extends ButtonHTMLAttributes<ExtendedButtonProps> {
  buttonStyle?: ButtonStyle; //"primary" | "secondary" | "none";
  onClick?: (event: any) => void;
}

interface LinkButtonProps extends IButtonProps {
  to: string;
  target?: TargetType;
};

export const LinkButton = ({
  to,
  buttonStyle= ButtonStyle.primary,
  children,
  className,
  target
}: LinkButtonProps) => {
  return (
    <Link
      to={to}
      target={target}
      className={classNames(styles.button, styles.linkButton, buttonStyle && styles[buttonStyle], className)}
    >
      {children}
    </Link>
  );
};

export const Button = ({
  buttonStyle = ButtonStyle.primary,
  children,
  className,
  onClick = () => {},
  disabled = false,
  type="button"
}: IButtonProps) => {
  if (buttonStyle === ButtonStyle.none) {
    return (<div
      className={classNames(styles.none, className)}
      tabIndex={0}
      role="button"
      {...{
        onClick,
        onKeyPress: onClick,
      }}
    >
      {children}
    </div>);
  }

  return (
    <button
      className={classNames(styles.button, buttonStyle && styles[buttonStyle], className)}
      {...{
        type,
        disabled,
        onClick
      }}
    >
      {children}
    </button>
  );
};
