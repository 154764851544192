import * as base from "./lib/baseActions";
import { apiEndpoints } from "lib/config";
import useSWR from 'swr';
import { mutate } from 'swr';
import { exampleArchetypes  } from 'actions/mocks/bloodOfStars.castMocks';
import {  ArchetypeType } from 'actions/cast.types';
import axios from "axios";
import { cleanCast } from "./helpers/castHelpers";
import { useCallback } from "react";
export type { TagType } from "actions/types";

interface getArchetypesProps {
  archetypeKeys?: Array<string>;
  archetypeTag?: string;
  worldId?: string | number;
  userId?: string | number;
}

export const getArchetypes = async ({ archetypeKeys, archetypeTag, worldId, userId}: getArchetypesProps): Promise<Array<ArchetypeType>> => {
  
  try {
    const casts = await base.get(`${worldId ? `${apiEndpoints.worlds}/${worldId}/` : ``}${userId ? `${apiEndpoints.users}/${userId}/` : `${apiEndpoints.users}/guest/`}${apiEndpoints.archetypes}`, {
      params: {archetypeKeys: archetypeKeys?.join(','),
      archetypeTag: archetypeTag,
    }});

    const cleanedCasts = casts.data.map((a: ArchetypeType) => {
      return cleanCast(a);
    });
    
    return [...cleanedCasts];
  } catch (err: any) {
    err.message = "Failed to get a tags.";

    throw err;
  }
};

export const getArchetype = async (archetypeKey?: string | number, worldId?: string | number, userId?: string | number) : Promise<ArchetypeType> => {
  const foundArchetype = exampleArchetypes.find((ea) => { return ea.key === archetypeKey; });

  if (foundArchetype) {
    const archetype =  cleanCast(foundArchetype);
    return archetype;
  } else {
    try {
      const cast = await base.get(`${worldId ? `${apiEndpoints.worlds}/${worldId}/` : ``}${userId ? `${apiEndpoints.users}/${userId}/` : `${apiEndpoints.users}/guest/`}${apiEndpoints.archetypes}/${archetypeKey}`);
      if(cast.data) {
        return cleanCast(cast.data);
      } else {
        throw new Error("Failed to get a tag.");
      }
    } catch (err: any) {
      err.message = "Failed to get a tag.";

      if(!foundArchetype) {
        throw new Error("Failed to get a tag.");
      }
      return err;
    }
  }
};

export const createArchetype = async ( archetype: ArchetypeType, worldId?: number | string) : Promise<ArchetypeType> => {
  try {
    const endpoint = `${worldId ? `${apiEndpoints.worlds}/${worldId}/` : ``}${apiEndpoints.archetypes}`; 
    const result = await base.post(endpoint, archetype);
    return cleanCast(result.data);
  } catch (err) {
    if (axios.isAxiosError(err)) {
      err.message = "Failed to get a tag.";
    }
    throw err;
  }
};

export const updateArchetype = async (archetypeId : number | string, archetype: ArchetypeType, worldId?: number | string) : Promise<ArchetypeType> => {
    try {
      const result = await base.put(`${worldId ? `${apiEndpoints.worlds}/${worldId}/` : ``}${apiEndpoints.archetypes}/${archetypeId}`, archetype);
      return cleanCast(result.data);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        err.message = "Failed to get a tag.";
      }
      throw err;
    }
};

export const deleteArchetype = async (archetypeId : number | string, worldId?: number | string) : Promise<ArchetypeType> => {
  try {
    const result = await base.del(`${worldId ? `${apiEndpoints.worlds}/${worldId}/` : ``}${apiEndpoints.archetypes}/${archetypeId}`);
    return cleanCast(result.data);
  } catch (err) {
    if (axios.isAxiosError(err)) {
      err.message = "Failed to get a tag.";
    }
    throw err;
  }
};

type useGetArchetypesSWRType = [
  string, Array<string> | undefined, string | undefined, string | number | undefined,  string | number | undefined
] 

export const useGetArchetypes = (archetypeKeys?: Array<string>, archetypeTag?: string, worldId?: string | number, userId?: string | number) => {
  return useSWR<Array<ArchetypeType>>((archetypeKeys && archetypeKeys.length === 0) ?
    null :
    [
      `${worldId ? `${apiEndpoints.worlds}/${worldId}/` : ``}${userId ? `${apiEndpoints.users}/${userId}/` : `${apiEndpoints.users}/guest/`}${apiEndpoints.archetypes}`,
      archetypeKeys,
      archetypeTag,
      worldId,
      userId
    ],
    ([key, archetypeKeys, archetypeTag, worldId, userId]: useGetArchetypesSWRType) => { 
      return getArchetypes({archetypeKeys, archetypeTag, worldId, userId});
    }
  );
};

type useGetArchetypeSWRType = [
  string, string | number | undefined, string | number  | undefined,  string | number  | undefined
] 

export const useGetArchetype = (archetypeId?: string | number, worldId?: string | number, userId?: string | number) => {
  return useSWR<ArchetypeType>(archetypeId ?
    [
      `${worldId ? `${apiEndpoints.worlds}/${worldId}/` : ``}${userId ? `${apiEndpoints.users}/${userId}/` : `${apiEndpoints.users}/guest/`}${apiEndpoints.archetypes}/${archetypeId}`,
      archetypeId,
      worldId,
      userId
    ] : null,
    ([key, archetypeId, worldId, userId]: useGetArchetypeSWRType) => { 
      return getArchetype(archetypeId, worldId, userId);
    }
  );
};

export interface CreateArchetypeFunctionType {
  (cast: ArchetypeType, worldId?: string | number):  Promise<ArchetypeType> 
}

export const useCreateArchetype = () : CreateArchetypeFunctionType => {
  return useCallback(async (cast: ArchetypeType, worldId?: string | number) => {
    const result = await createArchetype(cleanCast(cast), worldId);
    const castId = result.id;
    mutate(`${worldId ? `${apiEndpoints.worlds}/${worldId}/` : ``}${apiEndpoints.archetypes}/${castId}`, result); 
    return result;
  }, []);
};

export interface UpdateArchetypeFunctionType {
  (archetypeId: number | string, cast: ArchetypeType, worldId?: string | number):  Promise<ArchetypeType> 
} 

export interface DeleteArchetypeFunctionType {
  (archetypeId: number | string, worldId?: string | number):  Promise<ArchetypeType> 
} 

export const useUpdateArchetype = () : UpdateArchetypeFunctionType => {
  return useCallback(async (archetypeId: number | string, archetype: ArchetypeType, worldId?: string | number) => {
    // if (!worldId) {
    //   throw new Error("No user supplied for creating archetype")
    // }

    const result = await updateArchetype(archetypeId, cleanCast(archetype), worldId);
    mutate(`${worldId ? `${apiEndpoints.worlds}/${worldId}/` : ``}/${apiEndpoints.archetypes}/${archetypeId}`, result);
    return result;
  },[]);
}

export const useDeleteArchetype = () : DeleteArchetypeFunctionType => {

  return useCallback(async (archetypeId: number | string, worldId?: string | number) => {

    const result = await deleteArchetype(archetypeId, worldId);
    mutate(`${worldId ? `${apiEndpoints.worlds}/${worldId}/` : ``}/${apiEndpoints.archetypes}/${archetypeId}`, result);
    return result;
  },[]);
}

