import { useEffect, useState } from 'react';
import { UserType } from 'actions/user.types';
import { fetchUser,  changeAllowContact } from 'actions/userActions';
import classNames from "classnames";
import { helpEmail } from "lib/config";
import styles from './styles.module.scss';
import { cleanSpecialEmailSuffixes } from 'lib/display.helpers';


interface UserInfoProps {
  user?: UserType;
  className?: string;
}
// may add some cast information in future
const useUserInfo = () => {
  
  const [user, setUser] = useState<UserType | undefined>(undefined);
  const [allowContactError, setAllowContactError] = useState<boolean>(false);

  useEffect(() => {
    const getUser = async () => {
    const userRes = await fetchUser();

      setUser(userRes.data);
    };

    getUser();
  },[setUser]);

  const toggleAllowContact = async () => {
    try{
      setAllowContactError(false);
      const userRes = await changeAllowContact(user?.allowContact ? false : true);
      setUser(userRes.data);
    } catch(err) {
      setAllowContactError(true);
      console.log('failed to change allow contact', err);
    }
  }

  return {
    user: user,
    toggleAllowContact,
    allowContactError
  };
}

// May add some cast information in future
export const UserInfo = ({className}: UserInfoProps ) => {
  const { user, toggleAllowContact, allowContactError } = useUserInfo(); 
  return <div className={classNames(styles.userGrid, className)}>
    <div className={styles.label}>
      Email: 
    </div>
    <div className={styles.field}>
      {cleanSpecialEmailSuffixes(user?.email)} 
    </div> 
    {user?.firstName && (<>
      <div className={styles.label}>
        Name:
      </div>
    <div className={styles.field}>
        {user?.firstName}
      </div>
    </> )}
    <div className={styles.label}>Allow Contact:</div>
    <div className={styles.field}>
     <input type="checkbox" onClick={toggleAllowContact} checked={user?.allowContact}/>
     {allowContactError ? `There was an error in changing allow contact. Please try again, or contact us at ${helpEmail}`: null}
    </div> 
    <div className={styles.label}>Login Type:</div>
    <div className={styles.field}>
      {user?.loginType || 'password'}
    </div>
  </div>

};


export default UserInfo;