import { useState } from 'react'
import { requestPasswordReset } from 'actions/userActions';
import { Button, LinkButton } from 'components/Buttons';
import { useForm } from 'react-hook-form';
import { TextField, TextFieldType } from 'components/Forms/TextField';
import { Form } from 'components/Forms/Form';
import styles from './styles.module.scss';
import { Link } from "react-router-dom";
import { routeLinks } from "App/routing";
import Modal from 'components/Modal';
import { errorCodes, helpEmail } from "lib/config";

interface RegisterFormValProps {
  email: string;
  password: string;
  passwordConfirmation: string;
};

const useOnReset = () => {

  const onSubmit = async ({ email } : { email: string; password: string; passwordConfirmation: string; }) => {

    try {
      const results = await requestPasswordReset(email);

      return results;
    } catch(err) {
      throw err;
    }
  };

  return onSubmit;
};

export const RegisterFormComponent = () => {

  const onSubmitReset = useOnReset();
  const [viewConfirmedModal, setViewConfirmedModal] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState('');
  const { register, handleSubmit, formState } = useForm({
    reValidateMode: 'onSubmit',
    defaultValues: { email: ``, password: ``, passwordConfirmation: ``},
  });
  const errors = formState?.errors;

  return (
    <Form
      onSubmit={handleSubmit(async (vals: RegisterFormValProps) => { 
        try {
          setSubmitting(true);
          setSubmitError('');

          await onSubmitReset(vals); 

          setSubmitting(false);
          setViewConfirmedModal(true);
        // forward to next page
        } catch(err: any) {
          setSubmitting(false);
          if(err && err.code === errorCodes.tooManyRequests) {
            setSubmitError("You have requested too many emails. Please check your mailbox and spam folders. If you have not recieved the email, please try again in 24 hours, or reach out to us.");
          } else {
          setSubmitError('Request failed. Please try again, try again tomorrow, or reach out to us.');
          }
        }
      })}
    >
      <TextField 
        type={TextFieldType.email}
        placeholder="Email"
        label="email"
        className={styles.field}
        labelClassName={styles.labels}
        error={errors?.email}
        {...register("email", {
          validate:{
            validEmail: (val?: string) => {
              return (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(val || ``)) || "Must be a valid email";
            }
          }
        })}
      />
      
      { submitError && (<div className={styles.submitError}>{submitError}</div>)}
      <Button disabled={isSubmitting} className={styles.submitButton} type="submit">
        Submit
      </Button>
      <div>
        <Link to={`${routeLinks.register}`}>Don't have an account? Register</Link>
      </div>
      {viewConfirmedModal && <Modal onBackgroundClick={() => { 
          setViewConfirmedModal(false); 
        }}>
          <div className={styles.confirmModal}>

          <div>
            <p>
              We have sent you a reset email.
            </p>
            <p>
              If you have not gotten an email, or are having issues with logging in, you can contact us at {helpEmail}.
              </p>
          </div>
          <div className={styles.buttons}>
            <LinkButton className={styles.button} to={`/${routeLinks.login}`}>Go to Login</LinkButton>
            <Button  className={styles.button} onClick={() => { setViewConfirmedModal(false)}}>close</Button>
            </div>
          </div>
        </Modal>
      }
    </Form>
  );
};

export const ResetForm = RegisterFormComponent;
