import { forwardRef, useState, useCallback } from 'react';
import classNames from 'classnames';
import { FieldError } from 'react-hook-form';
import styles from './styles.module.scss';
import BaseReactSelect, { MultiValue, SingleValue } from 'react-select';
import { Controller } from 'react-hook-form';

interface OptionType {
  value: string;
  label: string;
}

interface TextFieldProps {
    name: string;
    type?: 'select' | 'multi' | 'html';
    label?: string;
    error?: FieldError;
    required?: boolean;
    placeholder?: string;
    className?: string;
    labelClassName?: string;
    fieldClassName?: string;
    register?: any;
    onChange: any;
    control?: any;
    value?: any;
    options?: Array<OptionType>;
}

interface ErrorMessageProps {
  error?: FieldError;
  className: string;
}

export const ErrorMessage = ({error, className}: ErrorMessageProps) => {
  return <div className={className}>{error && error.message}</div>
}

// Note, can't specify HTMLElement type as it could be for input or textarea, using any
export const Select = forwardRef<any, TextFieldProps>(({
  type,
  className,
  control,
  ...restProps
}: TextFieldProps, ref) => {
  const useReactSelect = (type === 'html' || !type) ? false : true;
  if (control) {
    return <MultiSelect
      type={type}
      className={classNames(styles.select, className)}
      control={control}
      {...restProps}
    />
  }
  return (<>
    {useReactSelect ?
      <ReactSelect className={classNames(styles.select, className)} {...restProps} type={type} ref={ref} />
      : <BaseSelect className={classNames(styles.select, className)} {...restProps} ref={ref} />
    }
  </>);
});

export enum SelectTypes {
  html = `html`,
  multi = `multi`,
  select = `select`
};

function isMultiValue<T>(arg: MultiValue<T> | SingleValue<T>): arg is MultiValue<T> {
  return Array.isArray(arg);
}

// Multiselect requires control
const MultiSelect = forwardRef<any, TextFieldProps>((
  {
   control,
   options,
   type,
   name,
    ...rest
  }, ref) => {
  const isMulti = type === "multi";
  if (isMulti && !control) {
    return <div>Requires a use-form control</div>;
  }
 
  return <Controller
  control={control}
  name={name}
  render={({
      field: { onChange, onBlur, value, name, ref },
  }) => {
    const valMap = new Map((value && !isMultiValue(value) ? [value] : (value || [])).map((v: any) => { return [v, v]; }));
    const selectedOptions = options?.filter((o) => { 
      return typeof valMap.get(o.value) !== "undefined" ? true : false ;
    });

      return <BaseReactSelect
          options={options}
          {...rest}
          onChange={(nVal, a) => { 
            const results = isMultiValue(nVal) ? nVal?.map((val: OptionType) => { return val.value }) : nVal?.value;
          
            return onChange(results); 
          }}

          isMulti={isMulti}
          onBlur={onBlur}
          value={selectedOptions}
          name={name}
      />;
  }}
  /> 
});

// Note, can't specify HTMLElement type as it could be for input or textarea, using any
const BaseSelect = forwardRef<any, TextFieldProps>(({
  name,
  label,
  required,
  type,
  placeholder,
  className,
  labelClassName,
  fieldClassName,
  error,
  options,
  onChange,
  ...restProps
 }: TextFieldProps, ref) => {
  return (
    <div className={className}>
      { (label || required) 
        && (
          <label htmlFor={name} className={classNames(styles.label, labelClassName)}>
            {label}
              {required && (<span className={styles.required}>&emsp;</span>)}
          </label>
        )
      }
      <select  { ...restProps} name={name} onChange={onChange} ref={ref} className={classNames(styles.field, fieldClassName)}>
        {options?.map((o) => {
          return <option key={o.value} value={o.value} >{o.label}</option>
        })}
      </select>
      <div>
        <ErrorMessage className={styles.alert} error={error} />
      </div>
    </div>
  );
});

// Note, can't specify HTMLElement type as it could be for input or textarea, using any
export const ReactSelect = forwardRef<any, TextFieldProps>(({
  name,
  label,
  required,
  type,
  placeholder,
  className,
  labelClassName,
  fieldClassName,
  error,
  options,
  onChange,
  ...restProps
 }: TextFieldProps, ref) => {
  const isMulti = type === "multi";
  const [selectedValue, setSelectedValue] = useState();
  
  const newOnChange = useCallback((newValue: any) => {
    const newEvent = {
      target: {
        name: name,
        value: newValue?.value
      }
    };
    onChange(newEvent);
    setSelectedValue(newValue.value);
  },[name, onChange]);
  return (
    <div className={className}>
      { (label || required) 
        && (
          <label htmlFor={name} className={classNames(styles.label, labelClassName)}>
            {label}
              {required && (<span className={styles.required}>&emsp;</span>)}
          </label>
        )
      }
      <select style={{display:'none'}}  { ...restProps} name={name} value={selectedValue} onChange={onChange} ref={ref}>
        {options?.map((o) => {
          return <option key={o.value} value={o.value}>{o.label}</option>
        })}
      </select>
      <BaseReactSelect
        options={options}
        isMulti={isMulti} 
        className={fieldClassName}
        {...restProps}
        onChange={newOnChange}
      />
      <div>
        <ErrorMessage className={styles.alert} error={error} />
      </div>
    </div>
  );
});

export default Select;
