import { ReactNode } from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";

export enum BorderType {
  default= "string",
  large= "large",
  small= "small"
}

interface DivCutCornerBorderProps {
  className?: string,
  backgroundClassName?: string,
  classNameBorder?: string,
  classNameWrapper?: string,
  maskChildren?: boolean,
  children?: ReactNode,
  backgroundChildren?: ReactNode,
  borderType?: BorderType
}

export const DivCutCornerBorder = ({
  className,
  classNameBorder,
  classNameWrapper,
  backgroundClassName,
  maskChildren = true,
  children,
  backgroundChildren,
  borderType
}: DivCutCornerBorderProps) => {
    return (<div 
      className={classNames(
        styles.archetypeWrapper,
        borderType && borderType === BorderType.large ? styles.cutBorderThick : null,
        classNameWrapper)}
      >
      {!maskChildren &&  <div className={classNames(styles.childWrapper, className)}>
          {children}
        </div>}
        <div className={styles.backgroundOuterChildrenWrapper}>
        <div 
          className={classNames(styles.backgroundChildrenWrapper,
            borderType && borderType === BorderType.large ? styles.cutBorderThick : null
            
          )}
        >
          <div className={classNames(styles.innerBackgroundChildreWrapper, backgroundClassName)}>
            {backgroundChildren}
          </div>
        </div>
      </div>
      <div 
        className={classNames(
          styles.archetypeBorderWrapper,
          borderType && borderType === BorderType.large ? styles.cutBorderThick : null,
          classNameBorder)}
        >

      </div>
      <div 
        className={classNames(styles.childrenWrapper,
          borderType && borderType === BorderType.large ? styles.cutBorderThick : null
        )}
      >
        <div className={className}>
        {maskChildren && children}
        </div>
      </div>

     </div>)

}

// export const DivCutCornerBorder = ({
//   className,
//   classNameBorder,
//   classNameWrapper,
//   children,
//   borderType
// }: DivCutCornerBorderProps) => {
//     return (<div 
//       className={classNames(
//         styles.archetypeWrapper,
//         borderType && borderType === BorderType.large ? styles.cutBorderThick : null,
//         classNameWrapper)}
//       >
//         <div className={classNames(styles.childWrapper, className)}>
//           {children}
//         </div>
//       <div 
//         className={classNames(
//           styles.archetypeBorderWrapper,
//           borderType && borderType === BorderType.large ? styles.cutBorderThick : null,
//           classNameBorder)}
//         >
// 
//       </div>
//       <div 
//         className={classNames(styles.childrenWrapper,
//           borderType && borderType === BorderType.large ? styles.cutBorderThick : null
//         )}
//       >
//         <div className={className}>
//           {children}
//         </div>
//       </div>
//      </div>)
// 
// }
