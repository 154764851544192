import { useCallback } from "react";
import axios from "axios";
import * as base from "./lib/baseActions";
import { apiEndpoints } from "lib/config";
import {
   DocType,
  } from "actions/doc.types";
import useSWR from 'swr';
import { mutate } from 'swr';

export interface DocsResult {
  data: Array<DocType>;
};

export interface DocResult {
  data: DocType;
};

export const cleanDoc = (doc: DocType) => {
  return {
    ...doc,
    markdown: doc?.data?.markdown,
  }
}

export const getDoc = async(docId?: string | number | (string | number)[], worldId?: string | number, userId?: string | number) : Promise<DocType> => {
  let endpoint = ``;
  if( docId && Array.isArray(docId)) {
    endpoint = `${worldId ? `${apiEndpoints.worlds}/${worldId}/` : ``}${userId ? `${apiEndpoints.users}/${userId}/` : `${apiEndpoints.users}/guest/`}${apiEndpoints.docs}/?tagModifierIds=${docId.join(',')}`;
  } else {
    endpoint = `${worldId ? `${apiEndpoints.worlds}/${worldId}/` : ``}${userId ? `${apiEndpoints.users}/${userId}/` : `${apiEndpoints.users}/guest/`}${apiEndpoints.docs}/${docId}`;
  }
  try {
    const doc = await base.get(endpoint);

    return cleanDoc(doc.data);
  } catch (err: any) {
    err.message = "Failed to get a tag.";
    throw err;
  }
}

export const getAllDocs = async(worldId?: string | number, userId?: string | number) : Promise<Array<DocType>> => {
  try {
    const docs = await base.get(`${worldId ? `${apiEndpoints.worlds}/${worldId}/` : ``}${userId ? `${apiEndpoints.users}/${userId}/` : `${apiEndpoints.users}/guest/`}${apiEndpoints.docs}`);
    const docsData = docs.data ? docs.data : [];
    return [...[], ...docsData]
      .map((doc) => {
        return cleanDoc(doc);
      });
  } catch (err: any) {
    err.message = "Failed to get a tags.";
    throw err;
  }
}

type useGetDocSWRType = [
  string, string | number | undefined, string | number | undefined, string | number | undefined
] 

export const useGetDoc = (docId?: string | number, worldId?: string | number, userId?: string | number) => {
  return useSWR<DocType>(docId ?
    [
      `${worldId ? `${apiEndpoints.worlds}/${worldId}/` : ``}${userId ? `${apiEndpoints.users}/${userId}/` : `${apiEndpoints.users}/guest/`}${apiEndpoints.docs}/${docId}`,
      docId,
      worldId,
      userId
    ] : null,
    ([key, docId, worldId, userId]: useGetDocSWRType) => { 
      return getDoc(docId, worldId, userId);
    }
  );
};

type useGetTagModifiersGroupsSWRType = [
  string, (string | number)[], string | number | undefined, string | number | undefined
] 

export const useGetDocs = (docIds?: (string | number)[], worldId?: string | number, userId?: string | number) => {
  return useSWR<DocType>(docIds ?
    [
      `${worldId ? `${apiEndpoints.worlds}/${worldId}/` : ``}${userId ? `${apiEndpoints.users}/${userId}/` : `${apiEndpoints.users}/guest/`}${apiEndpoints.docs}/?docIds=${docIds.join(',')}`,
      docIds.join(','),
      worldId,
      userId
    ] : null,
    ([key, docIds, worldId]: useGetTagModifiersGroupsSWRType) => { 
      return getDoc(docIds, worldId, userId);
    }
  );
};

type  useGetAllDocsSWRType = [
  string, string | number | undefined, string | number | undefined
] 

export const useGetAllDocs = (worldId?: string | number, userId?: string | number) => {
  return useSWR<Array<DocType>>(
    [
      `${worldId ? `${apiEndpoints.worlds}/${worldId}/` : ``}${userId ? `${apiEndpoints.users}/${userId}/` : `${apiEndpoints.users}/guest/`}${apiEndpoints.docs}`,
      worldId,
      userId
    ],
    ([key, worldId, userId]: useGetAllDocsSWRType) => { 
      return getAllDocs(worldId, userId);
    }
  );
};

// Create Tag Modifiers Group
export const createDoc = async (doc: DocType, worldId?: number | string) : Promise<DocType> => {
  try {
    const endpoint = worldId ? `${apiEndpoints.worlds}/${worldId}/${apiEndpoints.docs}` : `${apiEndpoints.docs}`; 
    
    const result = await base.post(endpoint, doc);
    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      err.message = "Failed to get a tag.";
    }
    throw err;
  }
};

export const updateDoc = async (docId : number | string, doc: DocType, worldId?: number | string) : Promise<DocType> => {
    try {
      const result = await base.put(`${worldId ? `${apiEndpoints.worlds}/${worldId}/` : ``}${apiEndpoints.docs}/${docId}`, doc);
      return result.data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        err.message = "Failed to get a tag.";
      }
      throw err;
    }
};

export const deleteDoc = async (docId : number | string, worldId?: number | string) : Promise<DocType> => {
  try {
    const result = await base.del(`${worldId ? `${apiEndpoints.worlds}/${worldId}/` : ``}${apiEndpoints.docs}/${docId}`);
    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      err.message = "Failed to delete a tag modifiers group.";
    }
    throw err;
  }
};

export interface CreateDocFunctionType {
  (tagModifiersGroup: DocType, worldId?: string | number):  Promise<DocType> 
}

export const useCreateDoc = () : CreateDocFunctionType => {
  return useCallback(async (doc: DocType, worldId?: string | number) => {
    const result = await createDoc(doc, worldId);
    const tagModifierGroupId = result.id;
    mutate(`/${apiEndpoints.worlds}/${worldId || ``}/${apiEndpoints.docs}/${tagModifierGroupId}`, result); 
    return result;
  }, []);
};

export interface UpdateDocFunctionType {
  (tagModifierGroupId: number | string, cast: DocType, worldId?: number | string):  Promise<DocType> 
} 

export const useUpdateDoc = () : UpdateDocFunctionType => {
  return useCallback(async (docId: number | string, doc: DocType, worldId?: number | string) => {
    const result = await updateDoc(docId, doc, worldId);
    mutate(`/${apiEndpoints.worlds}/${worldId || ``}/${apiEndpoints.docs}/${docId}`, result);
    return result;
  },[]);
}

export interface DeleteDocFunctionType {
  (tagModifierGroupId: number | string, worldId?: number | string):  Promise<DocType> 
} 

export const useDeleteDoc = () : DeleteDocFunctionType => {
  return useCallback(async (docId: number | string, worldId?: number | string) => {
    const result = await deleteDoc(docId, worldId);
    mutate(`/${apiEndpoints.worlds}/${worldId || ``}/${apiEndpoints.docs}/${docId}`, result);
    return result;
  },[]);
}

