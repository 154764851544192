import { useState } from "react";
import Layout from "Layout";
import { useParams } from "react-router-dom";
import { useGetUser } from 'actions/userActions';
import { LinkButton, Button } from "components/Buttons/Button";
import {  useGetCurrentUser } from "hooks/userHooks";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.scss";
import { routeLinks } from "App/routing";
import { Modal } from "components/Modal";
import classNames from "classnames";

type UserParams = {
  id: string;
};

export const User = () => {
  const { id: userId } = useParams<UserParams>();
  const { data } = useGetUser(userId);
  const { isAdmin, id } = useGetCurrentUser(); 
  const user = data;

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUnDeleteModal, setShowUnDeleteModal] = useState(false);

  const navigate = useNavigate();

  const onClickDelete = async () => {
    navigate(`/${routeLinks.users}`);
  }

  const onClickUnDelete = async () => {
    
    setShowUnDeleteModal(false);
  }

  // Get tags for this modifier.
  return (
    <Layout>{ (isAdmin || (user?.id && id && userId && `${user.id}` === `${id}` && userId === `${id}`)) && (
      <div >
        <h1 className={classNames(user?.deleted && styles.deleted)}>
          { `${userId}:${user?.email}`}{user?.deleted ? `(deleted)` : ``}
        </h1>
        { isAdmin && <div className={styles.editButtons}>
          <LinkButton to={`/${routeLinks.users}/${userId}/${routeLinks.edit}`} >Edit</LinkButton> 
          {!(user?.deleted) ? <Button onClick={() => { setShowDeleteModal(true); }}>Delete</Button> : ``}
          {user?.deleted ? <Button onClick={() => { setShowUnDeleteModal(true); }}>UnDelete</Button> : `` }
        </div>}
        <div>email: {`${user?.email || ``}`}</div>
        <div>username: {`${user?.username || ``}`}</div>
        <div>firstName: {`${user?.firstName || ``}`}</div>
        <div>lastName: {`${user?.lastName || ``}`}</div>
        <div>role: {`${user?.role || ``}`}</div>
        <div>deleted: {`${user?.deleted || false}`}</div>
      </div>
      )}
      {!showDeleteModal ? null : <Modal>
        <div className={styles.deleteModal}>
          <div>
            Are you Sure you want to delete?
          </div>
          <div className={styles.deleteModalButtons}>

          <Button
                      className={styles.editButton}
                      onClick={onClickDelete}
          >
            Yes, Delete</Button>
          <Button
                      className={styles.editButton}
                      onClick={() => {
                        setShowDeleteModal(false);
                      }}
          > No, go Back
          </Button>
          </div>
        </div>
      </Modal>}
      {!showUnDeleteModal ? null : <Modal>
        <div className={styles.deleteModal}>
          <div>
            Are you Sure you want to undelete?
          </div>
          <div className={styles.deleteModalButtons}>

          <Button
                      className={styles.editButton}
                      onClick={onClickUnDelete}
          >
            Yes, UnDelete</Button>
          <Button
                      className={styles.editButton}
                      onClick={() => {
                        setShowUnDeleteModal(false);
                      }}
          > No, go Back
          </Button>
          </div>
        </div>
      </Modal>}
    </Layout>
  );
}
