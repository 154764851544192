import Layout from "Layout";
import Logo from "assets/images/icons/BloodOfStarsLogo.png";
import { RegisterForm } from './RegisterForm';

export const Register = () => {
  return (
    <Layout>
      <div>
          <img src={Logo} className="App-logo" alt="Logo" ></img>
          <p>Hello, and welcome to Fatepath</p>
          <p> Please Register</p>
          <RegisterForm />
      </div>
    </Layout>
  );
}
