import { ReactNode, useRef, useEffect, useMemo, useCallback } from "react";
import classNames from "classnames";

import styles from "./styles.module.scss";

import { JSONEditor as BaseJSONEditor, JSONContent, TextContent } from "vanilla-jsoneditor";
import { Mode as JSONEditorType, JSONValue, ValidationError, createAjvValidator, Content, OnChangeStatus } from "vanilla-jsoneditor";
import { FieldError } from 'react-hook-form';

enum ContentType {
  text = 'text',
  json = 'json'
}

interface FormProps {
  value?: string;
  onChange?: ((content: JSONValue, previousContent: JSONValue, patchResult?: OnChangeStatus) => void)
  className?: string;
  children?: ReactNode;
  contentType?: ContentType;
  label?: string;
  labelClassName?: string;
  fieldClassName?: string;
  mode?: JSONEditorType;
  mainMenuBar?: boolean;
  navigationBar?: boolean;
  statusBar?: boolean;
  readOnly?: boolean;
  indentation?: number | string;
  required?: boolean;
  validator?: (json: JSONValue) => ValidationError[];
  error?: FieldError;
}

export const isArrayValidator = () => {
  return createAjvValidator({ schema: {
    title:"isArray",
    "description":"type",
    "type":"array"}
  });
}

export const createValidateJsonString = (jsonValidator: (json: JSONValue) => ValidationError[]) => {

  return (val: string) => {
    try {
      const validateParsedVal = JSON.parse(val);
      const errs = jsonValidator(validateParsedVal);
      return errs && errs.length > 0 ? false : true;
    } catch(err) {
      return false;
    }
  }
}


export const JSONEditor = ({
  value,
  onChange,
  contentType,
  mode,
  mainMenuBar,
  navigationBar,
  statusBar,
  readOnly,
  indentation,
  validator,
  label,
  labelClassName,
  fieldClassName,
  required,
  className,
  error
}: FormProps) => {
  const defaultContentType = useMemo(() => { return contentType || ContentType.text;},[contentType]);

  const jsonEditorRef = useRef<BaseJSONEditor | null>(null);
  const baseDivRef = useRef(null);
  
  // Fix previous Text
  const prevTextRef = useRef(value);

  const content = useMemo(() => {
    return {
      text: value || '',
      json: undefined
    }
  },[value]);

  const onChangeCallback = useCallback((newContent: JSONContent | TextContent, previousContent: Content, patchResult?: OnChangeStatus) => {
    let returnContent: string | JSONValue = {};
    let returnPreviousContent: string | JSONValue = {};

    if (defaultContentType === ContentType.text) {
      const stringReturnContent = 'json' in newContent && newContent?.json ? JSON.stringify(newContent.json) : (('text' in newContent && newContent.text) || '');
      returnPreviousContent = prevTextRef.current || '';
      prevTextRef.current = stringReturnContent;
      returnContent = stringReturnContent;
    } else {
      returnContent =  'json' in newContent && newContent.json ? JSON.stringify(newContent.json) :(('text' in newContent && newContent.text) || '');
      returnPreviousContent = 'json' in previousContent &&  previousContent.json ? JSON.stringify(previousContent.json) : (('text' in previousContent && previousContent.text) || '');
    }

    if(onChange) { 
      onChange(returnContent, returnPreviousContent, patchResult);
    }
  }, [onChange, defaultContentType]);

  useEffect(() => {
      if (baseDivRef.current) {
      jsonEditorRef.current = new BaseJSONEditor({
      target: baseDivRef.current,
      props: {
        content: content,
        onChange: onChangeCallback,
        mode,
        mainMenuBar,
        navigationBar,
        statusBar,
        readOnly,
        indentation,
        validator 
      }
    });
    
    }
    return () => {
      jsonEditorRef.current?.destroy();
      jsonEditorRef.current = null;
    }
  // Handled by further useEffects
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[baseDivRef]);

  useEffect(() => {
    if (jsonEditorRef.current) {
      const currentData = jsonEditorRef.current.get();
      if(currentData !== content) {
        jsonEditorRef.current.updateProps({content: content });

        if(onChangeCallback) {
          onChangeCallback(content, currentData, undefined)
        }
      }
    }
  }, [content, onChangeCallback]);

  useEffect(() => {
    if (jsonEditorRef.current) {
      jsonEditorRef.current.updateProps({onChange: onChangeCallback });
    }
  }, [onChangeCallback]);

  return <div className={classNames(styles.wrapper, className)}>
    { (label || required) 
      && (
        <label className={classNames(styles.label, labelClassName)}>
          {label}
            {required && (<span className={styles.required}>&emsp;</span>)}
        </label>
      )
    }    
    <div className={classNames(styles.jsonEditor, fieldClassName)} ref={baseDivRef} />
  </div>

}

export default JSONEditor;