import {useCallback} from "react";
import Layout from "Layout";
import classNames from "classnames";
import { useParams } from "react-router-dom";

import { Button } from "components/Buttons/Button";
import { TextField, TextFieldType } from "components/Forms/TextField";
import styles from "./styles.module.scss";

import { Form } from 'components/Forms/Form';
import { useDocForm } from "pages/Docs/useDocForm";
import { routeLinks } from "App/routing";
import { useNavigate } from "react-router-dom";
import { DocType } from "actions/doc.types";
import { Editor } from "components/MonacoEditor";

type DocParams = {
  docId: string;
};

export const EditDoc = () => {
  const { docId } = useParams<DocParams>();
  const navigate = useNavigate();

  const submitCallback = useCallback((doc?: DocType) => {
    navigate(`/${routeLinks.docs}/${doc?.id || docId}`);
  }, [navigate, docId]);

  const {
    name,
    markdown,
    errors,
    onSubmit,
    register,
    submitting,
    submitError,
    setValue,
  } = useDocForm({docId, callback: submitCallback});

  return (
    <Layout>
      <div >
        <h1>
          { `${docId} : ${name}`}
        </h1>
        <div>
          <Form
            onSubmit={onSubmit}
          >
            <Button type="submit">Save</Button>
            {!submitting && submitError && (<div>{submitError}</div>)}
            <TextField 
              type={TextFieldType.text}
              placeholder="key"
              label="Name"
              labelClassName={styles.label}
              className={styles.entry}
              error={errors?.name && { ...errors.name, ...{message:"Name must not be empty"}}}
              {...register("name",{
                validate:{
                  validLength: (val?: string) => {
                    return val && val?.length > 0;
                  }
                }
              })}
            />
            <TextField 
              type={TextFieldType.text}
              placeholder="Description"
              label="Description"
              labelClassName={styles.label}
              className={classNames(styles.entry, errors?.description && styles.textError)}
              error={errors?.description && { ...errors.description, ...{message:"Description must not be empty"}}}
              {...register("description",{
                validate:{
                  validLength: (val?: string) => {
                    return val && val?.length > 0;
                  }
                }
              })}
            />
            <TextField 
              type={TextFieldType.number}
              labelClassName={styles.label}
              className={styles.entry}
              placeholder="Display"
              label="Display"
              error={errors?.display}
              {...register("display",{
              })}
            />
            <TextField
              type={TextFieldType.textArea}
              label="Data" 
              fieldClassName={styles.dataText}
              labelClassName={styles.label}
              className={classNames(styles.hidden)}
              error={errors?.markdown}
              {...register("markdown",{
                validate:{
                  validLength: (val?: string) => {
                    return val && val?.length > 0;
                  }
                }
              })}
            />
          </Form>
        </div>
        <div>
        <Editor
          height="80vh"
          value={markdown}
          onChange={(val) => {
            setValue("markdown", val);
          }}
        />
        </div>
      </div>
    </Layout>
  );
}
