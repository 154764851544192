import { Modal } from "components/Modal";
import styles from "./styles.module.scss";
import AddAUnitGif from "assets/images/help/bos-AddAUnit.gif";
import RemoveAUnitGif from "assets/images/help/bos-RemoveAUnit.gif";
import FlipFromFactionToTeamGif from "assets/images/help/bos-FlipFromFactionToTeam.gif";
import AddSpecialRulesAndGearGif from "assets/images/help/bos-addSpecialRulesAndGear.gif";

interface HelpModalProps {
  closeModal: () => void;
}

export const HelpModal = ({
  closeModal
}: HelpModalProps) => {

  return (<Modal
    includeCloseButton={true}
    closeModal={() => { closeModal(); }}
    onBackgroundClick={() => { closeModal(); }}
  >
    <div className={styles.modal} onClick={() => { closeModal(); }}>
      <div>
        <h3>Using Edit Team Page</h3>
      </div>
      <div>
        Click Faction or Team button to switch from Faction Units Available, and Units added to Team.
      </div>
      <div>
        <img src={FlipFromFactionToTeamGif} className={styles.helpImage} alt="example"></img>
      </div>
      <div>
        Swipe Faction o Team Units Right to add to Team.
      </div>
      <div>
        <img src={AddAUnitGif} className={styles.helpImage}  alt="example"></img>
      </div>
      <div>
        Swipe team Units Left to remove them from the Team.
      </div>
      <div>
        <img src={RemoveAUnitGif} className={styles.helpImage}  alt="example"></img>
      </div>
      <div>
        Press "+" button to add Faction Special Rules, gear, and Campaign options, injuries and Upgrades.
      </div>
      <div>
        <img src={AddSpecialRulesAndGearGif} className={styles.helpImage}  alt="example"></img>
      </div>
    </div>
  </Modal>);
};

export default HelpModal;