import Layout from "Layout";
import { useParams } from "react-router-dom";
import { useGetWorld } from 'actions/worldActions';
import { Button, LinkButton } from "components/Buttons/Button";
import styles from "./styles.module.scss";
import { routeLinks } from "App/routing/routeLinks";
import {  useGetCurrentUser } from "hooks/userHooks";
import { useWorld } from "hooks/worldHooks";
 
type DocParams = {
  worldId: string;
};

export const World = () => {
  const { isAdmin, id: userId } = useGetCurrentUser();
  const { worldId } = useParams<DocParams>();
  const { setChosenWorld, isMultiWorld } = useWorld();
  const { data } = useGetWorld(worldId, userId);
  const doc = data;
  const chooseWorld = () => {
    worldId && setChosenWorld(worldId);
  }

  return (
    <Layout>
      <div >
        <h1>
          {!doc?.name ? `${worldId}` : `${doc?.name}`}
        </h1>
        { (isAdmin || isMultiWorld) && (<div className={styles.editButtons}>
          {isAdmin && 
            <LinkButton to={`/${routeLinks.worlds}/${worldId}/${routeLinks.edit}`} >Edit</LinkButton> 
          }
          {isMultiWorld && <>
            <Button onClick={chooseWorld} >Choose World</Button> 
          </>}
        </div>)}
        <div className={styles.doc}>version {doc?.version || ``}</div>
        <div className={styles.doc}>display: {doc?.display || ``}</div>
        <h5>description</h5>
        <div className={styles.doc}>{doc?.description || ``}</div>
        <div>
        </div>
      </div>
    </Layout>
  );
}
