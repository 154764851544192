
// Display Val
export const displayVal = (val?: string | string[] | number | number[] | (string | number)[]) => {
  if (val) {
    if (Array.isArray(val)) {
      return val.join(', ');
    } else {
      return val.toString();
    }
  } else {
    return '';
  }
}

export const cleanSpecialEmailSuffixes = (email?: string) => {
  const cleanedEmail = email && email?.startsWith(`google@`) ? email.substring(`google@`.length) : email;
  return cleanedEmail;
}