import { filterTagsBySelectors } from "lib/thresholds";
import { TagType, TagGroupingType } from "actions/tag.types";

export const groupTags = (groupings?: Array<TagGroupingType>, tags?: Array<TagType>) => {
//const groupings = baseTagsGroup?.groupings;
//const tags = baseTagsGroup?.tags;
return groupings?.map((group) => {
  const newTags = (tags && filterTagsBySelectors(group?.thresholds, tags)) || [];
  return { ...group, tags: newTags};
});
}