import classNames from "classnames";
import styles from "./styles.module.scss";


export interface DefaultCounterProps {
  count: number;
  counterStyle?: string
}
export const UpOrDownCounter = ({count, counterStyle}: DefaultCounterProps) => {
  return !count ? null : (<div className={classNames(styles.counter, counterStyle)}>
    {count < 0 ? `↓` : `↑` }{Math.abs(count)}
    </div>);
}


export default UpOrDownCounter;