import { useCallback } from "react";
import Layout from "Layout";
import { Button } from "components/Buttons/Button";
import { TextField, TextFieldType } from "components/Forms/TextField";
import styles from "./styles.module.scss";
import { FormType } from "components/Forms/Form";
import { useWorldForm } from "pages/Worlds/useWorldForm";
import { Form } from 'components/Forms/Form';
import { useNavigate } from "react-router-dom";
import { WorldType } from "actions/world.types";
import { routeLinks } from "App/routing";

export const NewWorld = () => {
  const navigate = useNavigate();

  const submitCallback = useCallback((docs?: WorldType) => {
    navigate(`/${routeLinks.worlds}/${docs?.id}`);
  }, [navigate]);

  const {
    errors,
    onSubmit,
    register,
    submitting,
    submitError  } = useWorldForm({ formType: FormType.Create, callback: submitCallback });

  return (
    <Layout>
      <div >
        <h1>
          New World 
        </h1>
        <div>
          <Form
            onSubmit={onSubmit}
          >
            <Button type="submit">Save</Button>
            {!submitting && submitError && (<div>{submitError}</div>)} 
            <TextField 
              type={TextFieldType.text}
              placeholder="Name"
              label="Name"
              labelClassName={styles.label}
              className={styles.entry}
              error={errors?.name}
              {...register("name",{
                validate:{
                  validLength: (val?: string) => {
                    return val && val?.length > 0;
                  }
                }
              })}
            />
            <TextField 
              type={TextFieldType.text}
              placeholder="Description"
              label="Description"
              labelClassName={styles.label}
              className={styles.entry}
              error={errors?.description}
              {...register("description",{
                validate:{
                  validLength: (val?: string) => {
                    return (val && val?.length > 0) || "Field Must not be empty.";
                  }
                }
              })}
            />
            <TextField
              type={TextFieldType.text}
              placeholder="Version"
              label="Version" 
              labelClassName={styles.label}
              className={styles.entry}
              error={errors?.version}
              {...register("version",{
                validate:{
                  validLength: (val?: string) => {
                    return val && val?.length > 0;
                  }
                }
              })}
            />
          </Form>
        </div>
      </div>
    </Layout>
  );
}
