import { useState } from "react";
import Layout from "Layout";
import { LoginForm } from './LoginForm';
import Logo from "assets/images/icons/BloodOfStarsLogo.png";
import { CredentialResponse, GoogleOAuthProvider } from '@react-oauth/google';
import { googleOAuthClientId } from "lib/config";
import { GoogleLogin } from '@react-oauth/google';
import styles from './styles.module.scss';
import { Button, LinkButton } from "components/Buttons";
import { googleLoginUser } from "actions/googleActions";
import { useUserState } from "atoms/users.atom";
import { useAuthState, AuthStateAtomType } from 'atoms/auth.atom';
import { userIsAuthorized } from 'actions/userActions';
import { Link, useNavigate } from "react-router-dom";
import { routeLinks } from "App/routing";
import Modal from "components/Modal";
import { UserInfo } from "components/UserInfo";
import { helpEmail } from "lib/config";

const useOnLogin = () => {
  const navigate = useNavigate();
  const { setState: setUserState } = useUserState();
  const { state: appState, setState: setAppState } = useAuthState();
  const mergeAppState = (newState: AuthStateAtomType) => { return { ...appState, ...newState} };
  const [viewConfirmedModal, setViewConfirmedModal] = useState(false);
  
  const onLogin = async (credentialResponse: CredentialResponse) => {
    mergeAppState({ Authenticating: true });
    // fetching userinfo can be done on the client or the server
    try {
      if(credentialResponse.credential) {
        const results = await googleLoginUser({ token: credentialResponse.credential});

        if(results && results.user) {
          setUserState(results.user); // { email: results.data.email, userName: results.data.first_name });
        }
        setAppState({ Authenticated: userIsAuthorized(), Authenticating: false });
        if(results.new) {
          setViewConfirmedModal(true);
          return results;
        } else {
          navigate('/');
          return results;
        }
      } else {
        setAppState({ Authenticated: false, Authenticating: false });
      }
    } catch(err) {
      setAppState({ Authenticated: false, Authenticating: false });
    }
  }

   return { onLogin, viewConfirmedModal, setViewConfirmedModal };
};

export const Login = () => {
  const { onLogin, viewConfirmedModal, setViewConfirmedModal } = useOnLogin();
  
  return (
    <Layout>
      <div>
          <img src={Logo} className="App-logo" alt="logo"></img>
          <p>Hello, and welcome to Blood of Stars</p>
          <p> Please Login</p>
          <LoginForm />
          <div className={styles.googleOAuthButton}>
            <GoogleOAuthProvider clientId={googleOAuthClientId || ``}>
              <GoogleLogin
                onSuccess={credentialResponse => {
                  onLogin(credentialResponse);
                }}
                onError={() => {
                  console.log('Login Failed');
                }}
              />
              </GoogleOAuthProvider>
          </div>
          <div className={styles.loginLinks}>
            <div>
              <Link to={`/${routeLinks.register}`}>Don't have a login? Register</Link>
            </div>
            <div>
              <Link to={`/${routeLinks.requestPasswordReset}`}>Forgot Password? Send Password Email.</Link>
            </div>
          </div>
          {viewConfirmedModal && <Modal onBackgroundClick={() => { 
          setViewConfirmedModal(false); 
        }}>
          <div className={styles.confirmModal}>
          <div>
            <p>
              Thank you for registering in with google. You can now save personal teams. You user details are below. If you wish to be kept up to date on major milestones for Blood of Stars, please click 'Allow Contact'.
            </p>
            <UserInfo className={styles.userInfo} />
            <p>
              If you are having issues with staying logged in, you can contact us at {helpEmail}.
              </p>
          </div>
          <div className={styles.buttons}>
            <LinkButton className={styles.button} to={"/"}>Go Home</LinkButton>
            <Button  className={styles.button} onClick={() => { setViewConfirmedModal(false)}}>close</Button>
            </div>
          </div>
        </Modal>
      }
      </div>
    </Layout>
  );
}
