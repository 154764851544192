import React from "react";
import Layout from "Layout";
import { Link } from "react-router-dom";
import { routeLinks } from "App/routing/routeLinks"
import { useGetAllTagModifierGroups } from "actions/tagModifiersActions";
import { LinkButton } from "components/Buttons";
import {  useGetCurrentUser } from "hooks/userHooks";

import styles from "./styles.module.scss";
import { useWorld } from "hooks/worldHooks";

export const TagModifiersGroups = () => {
  const { isAdmin, id } = useGetCurrentUser();
  const { chosenWorldId } = useWorld(); 
  const { data } = useGetAllTagModifierGroups(chosenWorldId, id);

  return (
    <Layout>
      { isAdmin &&
      <div >
        <h1>
          Tag Modifier Groups:
        </h1>
        <div>
          <div className={styles.actions}>
            <LinkButton to={`/${routeLinks.tagModifiersGroups}/${routeLinks.new}`} >New</LinkButton> 
          </div>
          {
            data?.map((tagModifierGroup) => {
              return (<div key={tagModifierGroup.id}><Link to={`/${routeLinks.tagModifiersGroups}/${tagModifierGroup.id}`} >{tagModifierGroup.id}: {tagModifierGroup.name}</Link></div>)
            })
          }
        </div>
      </div>}
    </Layout>
  );
}
