import  { useState } from "react";
import Nav from "./Nav";
import { HamburgerIcon } from "components/Icons";
import styles from "./styles.module.scss";
import { useUserState } from 'atoms/users.atom';
import { Link } from 'react-router-dom';
import { routeLinks } from 'App/routing';
import {  useGetCurrentUser } from "hooks/userHooks";
import classNames from "classnames";
import logo from "assets/images/icons/BloodOfStarsLogo.png";
import { cleanSpecialEmailSuffixes } from "lib/display.helpers";
import { useWorld } from "hooks/worldHooks";

// Header for all pages
// Dumb component, so use stateless Function declaration
// no props
const Header = () => {
  const { state: userState } = useUserState();
  const { isAdmin, isGuest } = useGetCurrentUser(); 
  const [showNav, setShowNav] = useState(false);
  const { chosenWorld, isMultiWorld} = useWorld();
  const toggleShowNav = () => {
    setShowNav(!showNav);
  }
  return (
    <header>
      <div className={classNames(styles.header,  showNav && styles.headerShowNav)}>
        <div className={styles.headerTitle}>
          <Link to={`/${routeLinks.home}`}>
            <img src={logo}  className={styles.headerLogo} alt="logo"></img> 
          </Link>
          Blood of Stars
          {chosenWorld && isMultiWorld ? ` - ${chosenWorld.name}(${chosenWorld.id})` : null}
        </div>
        <div className={styles.hamburgerWrapper} onClick={toggleShowNav}><HamburgerIcon className={styles.hamburger} /></div>
        <div className={classNames(styles.nav, showNav && styles.showNav)}>
          { isMultiWorld && <>
             <Link
             to={`/${routeLinks.worlds}`}
           >
             Worlds
           </Link></>
          }
          {isAdmin && <>
           
            <Link
              to={`/${routeLinks.users}`}
            >
              Users
            </Link>
            <Link
              to={`/${routeLinks.tagModifiersGroups}`}
            >
              Trait Modifiers
            </Link>
            <Link
              to={`/${routeLinks.archetypeDisplays}`}
            >
              Faction Displays
            </Link>
          </>}
          <Link
            to={`${isGuest ? `/${routeLinks.guest}` : ``}/${routeLinks.docs}`}
          >
           Docs 
          </Link>
          <Link
            to={`${isGuest ? `/${routeLinks.guest}` : ``}/${routeLinks.traits}`}
          >
           Traits 
          </Link>
          <Link
            to={`${isGuest ? `/${routeLinks.guest}` : ``}/${routeLinks.factions}`}
          >
           Factions 
          </Link>
          <Link
            to={`${isGuest ? `/${routeLinks.guest}/${routeLinks.localTeams}` : `/${routeLinks.teams}`}`}
          >
            Teams
          </Link>
          <div className={styles.loginNav}>
            <div className={styles.name}>
              {userState && userState ? <Link to={`/${routeLinks.userMe}`}>
                { `${ (userState && cleanSpecialEmailSuffixes(userState.email)) || 'anon' } ` }
              </Link> : 'anon'}
            </div>
            <Nav />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
