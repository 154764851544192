import Layout from "Layout";
import { Link } from "react-router-dom";
import { routeLinks } from "App/routing/routeLinks"
import { useGetAllWorlds } from "actions/worldActions";
import { LinkButton } from "components/Buttons";
import {  useGetCurrentUser } from "hooks/userHooks";

import styles from "./styles.module.scss";
export const Worlds = () => {
  const { isAdmin, id: userId } = useGetCurrentUser(); 
  const { data } = useGetAllWorlds(userId);

  return (
    <Layout>
      <div >
        <h1>
          Worlds:
        </h1>
        <div>
          {isAdmin && (<div className={styles.actions}>
            <LinkButton to={`/${routeLinks.worlds}/${routeLinks.new}`} >New</LinkButton> 
          </div>)}
          <div>
          {
            data?.map((doc) => {
              return (<div key={doc.id}><Link to={`/${routeLinks.worlds}/${doc.id}`} >{doc.id}: {doc.name}</Link></div>)
            })
          }
          </div>
        </div>
      </div>
    </Layout>
  );
}
