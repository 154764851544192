import React, {  useCallback, useEffect, useState } from "react";
import Layout from "Layout";
import { useParams } from "react-router-dom";

import { UserType } from "actions/user.types";
import { Button } from "components/Buttons/Button";
import { TextField, TextFieldType } from "components/Forms/TextField";
import styles from "./styles.module.scss";
import { Form } from 'components/Forms/Form';
import { routeLinks } from "App/routing";
import { useNavigate } from "react-router-dom";
import { useGetUser, useEditUser } from 'actions/userActions';
import { useForm } from 'react-hook-form';
import { UserRoles } from "lib/config";
import Select from "components/Forms/Select";

type UserParams = {
  id: string;
};

interface SubmitUserType {
  email?: string;
  username?: string;
  firstName?: string;
  lastName?: string;
  role?: string;
}

const translateValues = (vals: any): UserType => {
  // title?: string;
  // type?: CastMemberClassType;
  // tags?: Array<TagType>;
  // amount?: number;

  return {
    email: vals?.email,
    username: vals?.username,
    firstName: vals?.firstName,
    lastName: vals?.lastName,
    role: vals?.role
  };
}

const roles = [
  { id: UserRoles.admin, name: "Admin"},
  { id: UserRoles.user, name: "User"},
  { id: UserRoles.guest, name: "Guest"},
  { id: UserRoles.tester, name: "Tester"}
];

export const EditUser = () => {
  const { id: userId } = useParams<UserParams>();
  const { data, isLoading } = useGetUser(userId);
  const updateUser = useEditUser();
  const user = data;
  const navigate = useNavigate();
  const [, setSubmitting] = useState(false);
  const [, setSubmitError] = useState('');
  const [formLoaded, setFormLoaded ] = useState(false);  

  const loadFormValues = useCallback((user?: SubmitUserType) => {
    return { 
      email: user?.email ,
      username: user?.username ,
      firstName: user?.firstName ,
      lastName: user?.lastName ,
      role: user?.role,
    };
  },[]);

  const { register, handleSubmit, formState, reset, control } = useForm({
    defaultValues: loadFormValues(user)
  });
  
   useEffect(() => {
    if(!isLoading && !formLoaded) {
      reset(loadFormValues(user));
      setFormLoaded(true);
    }
  }, [setFormLoaded, reset, isLoading, loadFormValues, formLoaded, user]);

  const callback = useCallback(async (user?: UserType) => {
    user && navigate(`/${routeLinks.users}/${user?.id || userId}`);
  },[navigate, userId]);

  const saveAndMerge = useCallback(async (vals: SubmitUserType) => {
    try {

      setSubmitting(true);
      setSubmitError('');
      const translatedValues = translateValues({ ...vals });

      const updatedUser = await (userId && updateUser ? updateUser(userId, translatedValues) : undefined);
      setSubmitting(false);
      callback(updatedUser);
      
      return updatedUser;
      // forward to next page
    } catch(err) {
      setSubmitting(false);
      setSubmitError('Update to Cast Member Failed.');
    }
  }, [userId, updateUser, callback]);
  const errors = formState?.errors;

  const onSubmit = async (e:  React.FormEvent<HTMLFormElement>) => {
    await handleSubmit(saveAndMerge)(e);
  }

  return (
    <Layout>
      {user && (<div >
        <h1>
          { `${user.id} : ${user.email}`}
        </h1>
        <div>
          <Form
            onSubmit={onSubmit}
          >
            <Button type="submit">Save</Button>
            <TextField 
              type={TextFieldType.text}
              placeholder="Email"
              label="Email"
              labelClassName={styles.label}
              className={styles.entry}
              error={errors?.email && { ...errors.email, ...{message:"Email must not be empty"}}}
              {...register("email",{
                validate:{
                  validLength: (val?: string) => {
                    return val && val?.length > 0;
                  }
                }
              })}
            />
            <TextField 
              type={TextFieldType.text}
              placeholder="Username"
              label="Username"
              labelClassName={styles.label}
              className={styles.entry}
              error={errors?.username && { ...errors.username, ...{message:"username"}}}
              {...register("username",{
              })}
            />
            <TextField 
              type={TextFieldType.text}
              placeholder="First Name"
              label="First Name"
              labelClassName={styles.label}
              className={styles.entry}
              error={errors?.firstName && { ...errors.firstName, ...{message:"firstName"}}}
              {...register("firstName",{
              })}
            />
            <TextField 
              type={TextFieldType.text}
              placeholder="Last Name"
              label="Last Name"
              labelClassName={styles.label}
              className={styles.entry}
              error={errors?.lastName && { ...errors.lastName, ...{message:"lastName"}}}
              {...register("lastName",{
              })}
            />
            <Select
              placeholder={"Role"}
              type={"select"}
              control={control}
              label="Role"
              className={styles.fieldWrapper} 
              fieldClassName={styles.field}
              labelClassName={styles.labels}
              error={errors?.role}
              options={roles?.map((a) => {
                return { value: `${a.id}`, label: a.name};
              })}
              {...register("role", {
              })}
            />
          </Form>
        </div>
      </div>)}
    </Layout>
  );
}
