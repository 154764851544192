import { CastMemberType } from 'actions/cast.types';
import { TagType } from 'actions/types';
import { TagModifierType, TagModifierSymbol, TagThresholdType, TagThresholdSymbolType, TagThresholdAndOr, TagSelector } from 'actions/tag.types';
import { ArchetypeGroupType } from 'actions/cast.types';
import { addTagVals, multiplyTagVals, parseToNumber } from 'lib/tag.helpers';
import { tagsToMap, getTagKey } from 'actions/helpers/tagHelpers';
// extras

function modifyTagsMapWithAddValueAsTag(tm: TagModifierType, tagsMap: Map<string,TagType>) {
  const newTagsMap = new Map(tagsMap);
  const primaryTags = Array.from(newTagsMap.values()).filter((nt) => {
    if (nt.baseKey === tm.primaryTagKey && tm.primaryIsBaseKey) {
      return true;
    } else {
      return false;
    }
  });
  if (primaryTags && primaryTags.length > 0) {
    primaryTags.forEach((primaryTag) => {
      if (primaryTag && primaryTag.val) {
        const valueTags = Array.isArray(primaryTag.val) ? primaryTag.val : [primaryTag.val];
        // Turn valueTags into Modifiers that Add.
        const newModifiers = valueTags.map((vt) => {
          const newModifier: TagModifierType = {
            primaryTagKey: getTagKey(primaryTag),
            relatedTagKey: `${vt}`,
            symbol: TagModifierSymbol.Add,
            priority: tm.priority
          };

          return newModifier;
        });

        const newTagsMapFiltered = new Map(modifyTagsMap(newModifiers, new Map(newTagsMap), false));
        // once we add it, we need to add entries based on this.
        newTagsMapFiltered.forEach((val, key) => {
          newTagsMap.set(key, val);
        });
      } else {
        //  ValueTag But no primaryTag.val
      }
    });
  }

  return newTagsMap;
}

// Defaults to ""
const getThresholdKey = (threshold: TagThresholdType) => {
 return (threshold.tag && getTagKey(threshold.tag)) || threshold.thresholdKey  || ""; 
}

const thresholdMatchesTag = (threshold: TagThresholdType, tag?: TagType) => {
  const thresholdKey = getThresholdKey(threshold);
  const tagKey = tag && getTagKey(tag);

  return tag && (( tagKey === thresholdKey)
    || (!threshold.exactMatch && (thresholdKey === tag.baseKey || thresholdKey ===  tag.key))
  )
}

const thresholdMatchesTagOrKey = (threshold: TagThresholdType, tag?: TagType | string) => {
  const thresholdKey = getThresholdKey(threshold);
  const tagKey = tag && getTagKey(tag);

  return tag && (( tagKey === thresholdKey)
    || (!threshold.exactMatch && typeof tag !== "string" && (thresholdKey === tag.baseKey || thresholdKey ===  tag.key))
  )
}

const isThresholdMustPassAll = (threshold: TagThresholdType) => {
  switch(threshold.tagThresholdSymbol) {
    case TagThresholdSymbolType.DoesNotExist:
    case TagThresholdSymbolType.LessOrEqualOrNotExists:
    case TagThresholdSymbolType.LessOrNotExists:
      return true;
    default:
      return false;
  }
}

// assumes tag.key === threshold.thresholdKey
const passedThreshold = (threshold?: TagThresholdType, tag?: TagType) => {
  if (!threshold) {
    return true;
  }

  const thresholdAndKeyMatches = thresholdMatchesTag(threshold, tag);
  if(threshold.tagThresholdSymbol) {
    // GreaterOrEqual = "greaterOrEqual",
    // LessOrEqual = "lessOrEqual",
    // Greater = "greater",
    // Less = "less",
    // Equal = "equal",
    // Exists = "exists",
    // DoesNotExist = "doesNotExist"
    switch(threshold.tagThresholdSymbol) {
      case (TagThresholdSymbolType.GreaterOrEqual):
        return (
          thresholdAndKeyMatches
          && tag
          && typeof tag.val !== "undefined" 
          && typeof threshold?.threshold !== "undefined" 
          && tag.val >= threshold?.threshold
        )
      case (TagThresholdSymbolType.Greater):
        return (
          tag 
          && thresholdAndKeyMatches
          && typeof tag.val !== "undefined" 
          && typeof threshold?.threshold !== "undefined" 
          && tag.val > threshold?.threshold
        )
      case (TagThresholdSymbolType.LessOrEqual):
        return (
          tag 
          && thresholdAndKeyMatches
          && typeof tag.val !== "undefined" 
          && typeof threshold?.threshold !== "undefined" 
          && tag.val <= threshold?.threshold
        )
      case (TagThresholdSymbolType.Less):
        return (
          tag 
          && thresholdAndKeyMatches
          && typeof tag.val !== "undefined" 
          && typeof threshold?.threshold !== "undefined" 
          && tag.val < threshold?.threshold
        )
      case (TagThresholdSymbolType.Equal):
        return (
          tag 
          && thresholdAndKeyMatches
          && typeof tag.val !== "undefined" 
          && typeof threshold?.threshold !== "undefined" 
          && tag.val === threshold?.threshold
        );
      case (TagThresholdSymbolType.NotEqual):
        return (
          tag 
          && thresholdAndKeyMatches
          && typeof tag.val !== "undefined" 
          && typeof threshold?.threshold !== "undefined" 
          && tag.val !== threshold?.threshold
        );
      case (TagThresholdSymbolType.DoesNotExist):
        return (
          !tag || !thresholdAndKeyMatches
        );
      case (TagThresholdSymbolType.LessOrEqualOrNotExists):
        return (
          !tag || !thresholdAndKeyMatches
          || ( 
            tag && thresholdAndKeyMatches
            && typeof tag.val !== "undefined" 
            && typeof threshold?.threshold !== "undefined" 
            && tag.val <= threshold.threshold
          )
        );
      case (TagThresholdSymbolType.LessOrNotExists):
        return (
          !tag || !thresholdAndKeyMatches
          || ( 
            tag && thresholdAndKeyMatches
            && typeof tag.val !== "undefined" 
            && typeof threshold?.threshold !== "undefined" 
            && tag.val < threshold.threshold
          )
        );
        case (TagThresholdSymbolType.InGroup):
          return !!tag?.groups?.find((g) => {
              return g === threshold?.thresholdVal;
            });
      case (TagThresholdSymbolType.ExistsInVal):
        if (tag && tag.val && typeof tag.val === 'string') {
          return thresholdMatchesTagOrKey(threshold, tag.val);
        } else if (tag && tag.val && Array.isArray(tag.val) && tag.valAreTagKeys) {
          const tv = tag.val.find((val) => {
            if (typeof val === "number") {
              return false;
            }

            return thresholdMatchesTagOrKey(threshold, val);
          });
          return typeof tv !== 'undefined';
          
        }
        return false;
      case (TagThresholdSymbolType.Exists):
      default:
        return thresholdAndKeyMatches;
    }
  }

  // If tag doesn't exist, and threshold is below and threshold is undefined
  if(!tag && threshold.thresholdTriggerBelow && threshold.threshold === undefined) {
    return true;
  }

  // If tag exists, and threshold is equal or above and threshold is undefined
  if (tag && !threshold.thresholdTriggerBelow && threshold.threshold === undefined) {
    return true;
  }

  if (tag && tag.val && threshold.threshold) {

  // if tag exists, and value is below threshold
    if (threshold.thresholdTriggerBelow && tag.val < threshold.threshold) {
      return true;
    }

    if (!threshold.thresholdTriggerBelow && tag.val >= threshold.threshold) {
      return true;
    }
  }

  return false;
}

const tagOrTagArrayPassedThreshold = (threshold: TagThresholdType, tag?: TagType | Array<TagType>) => {
  let didPass: boolean = true;
  if(tag && Array.isArray(tag)) {
    const  thresholdMustPassAll = isThresholdMustPassAll(threshold);

    if(threshold.tagThresholdSymbol === TagThresholdSymbolType.InGroup && threshold.thresholdVal === "Trained" ) {
    }
    didPass = tag.reduce((curResult: boolean, curTag) => {
      if(!thresholdMustPassAll) {
        return !!(passedThreshold(threshold, curTag) || curResult);
      } else {
        return !!(passedThreshold(threshold, curTag) && curResult);

      }
    }, thresholdMustPassAll);
  } else {
    didPass = !!passedThreshold(threshold, tag);
  }

  return didPass;
}

const passedThresholdChildren = (thresholds: Array<TagThresholdType>, andOrThresholds?: TagThresholdAndOr, tagMap?: Map<string, TagType> ): boolean => {
  if (!thresholds || thresholds.length === 0) {
    return true;
  } else {
    if(andOrThresholds === TagThresholdAndOr.Or) {
      // if we have a success, we have a success
      return thresholds.find((t) => {
        if(t.thresholds && t.thresholds.length > 0) {
          // if succeeded, we have found a success
          const ptc = passedThresholdChildren(t.thresholds, t.andOrThresholds, tagMap) ? true : false;
          return ptc;
        } else {
         // Thresholds require itterating over tags as an Array
         if (tagMap) {
            const tagsArray = Array.from(tagMap?.values()); 
              return !!tagOrTagArrayPassedThreshold(t, tagsArray);
          } else {
            return false;
          }
        }        
      }) ? true : false;
    } else {
        // and
        // if we found a failure failed, we have a failure 
      return thresholds.find((t) => {
        if(t.thresholds && t.thresholds.length > 0) {
          // if failed, we have found a failure
          return (!passedThresholdChildren(t.thresholds, t.andOrThresholds, tagMap)) ? true : false;
        } else {
          if (tagMap) {
            // Thresholds require itterating over tags as an Array
            const tagsArray = Array.from(tagMap?.values());
            return !tagOrTagArrayPassedThreshold(t, tagsArray);
          } else {
            return false;
          } 
        }        
      }) ? false : true;
    }
  }
}

export const passedThresholds = passedThresholdChildren;

// pass any selectors
export const filterTagsBySelectors = (selectors: Array<string | TagSelector>, tags: Array<TagType>) => {

  return tags.filter((tag) => {
    // a tag passes if it passes any of the selectors
    return !!selectors.find((selector) => {

      // a tag passes the selector if it by itself passes the selector
      return !!tagPassedSelector(selector, tag, new Map<string, TagType>([[ getTagKey(tag),tag]]));
    }) 
  });
}

export const tagPassedSelector = (tagKey: string | TagSelector, tag?: TagType, tagsMap?: Map<string, TagType>) => {
  const foundTagKey = typeof tagKey === "string" ? tagKey : (tagKey.tagKey || (tagKey.tag && !(Array.isArray(tagKey.tag)) && getTagKey(tagKey?.tag)) || '');
  // if there is a tag to match on selector, then check it.
  // if there is no tagKey or tag on selector, then you only need to match the Thresholds.

  const foundTag = ((tag && foundTagKey && getTagKey(tag) === foundTagKey) || !foundTagKey ) ? tag : undefined;

  if (typeof tagKey === "string") {
    return (foundTag && foundTagKey === getTagKey(foundTag) && foundTag) || undefined;
  } else {
    // If the type is a Selector, and has thresholds
    const thresholds =  tagKey.thresholds;
    if (thresholds && thresholds.length > 0 && foundTag) {
      const curTagsMap = tagsMap;
      const didPassThresholds = passedThresholds(thresholds, tagKey.andOrThresholds, curTagsMap);

      if (didPassThresholds) {
        return foundTag;
      } else {
        return undefined;
      }
    } else {
        // no thresholds
        return foundTag;
    }
  } 
}

// TagKey: The selector to check
// tagOptions: the array that contains the main selector tag
// tagsMap: The tags map passed.
export const selectorPassedThresholds = (tagKey: string | TagSelector, tagOptions?: Map<string, TagType>, tagsMap?:  Map<string, TagType> ) => {
  const foundTagKey = typeof tagKey === "string" ? tagKey : (tagKey.key || (tagKey.tag && !(Array.isArray(tagKey.tag)) && getTagKey(tagKey?.tag)) || '');
  const foundTag = tagOptions?.get(foundTagKey);
  const x = tagPassedSelector(tagKey, foundTag, tagsMap);

  return x;
}

const tagModifiersPassedThresholds = ( tagModifier?: TagModifierType, tagMap?: Map<string, TagType>) : Boolean => {
  if(!tagModifier || !tagModifier.thresholds || (tagModifier.thresholds.length === 0)) {
    return true;
  }

  const thresholdsFailed = tagModifier.thresholds.find((threshold) => {
    if(threshold.thresholds
    && threshold.thresholds.length > 0) {
      const thresholdChildrenPassed = passedThresholdChildren(threshold.thresholds, threshold.andOrThresholds, tagMap);
      return !thresholdChildrenPassed ? true : false;
        // we have found a failure
    } else {
      const thresholdChildrenPassed = passedThresholdChildren([threshold], threshold.andOrThresholds, tagMap);
      return !thresholdChildrenPassed;
    }
    // If they have children, first check children.

  }) || false;

  return !thresholdsFailed;
}

export const modifyTagsMap = (tagModifiers: Array<TagModifierType>, tagsMap: Map<string,TagType>, calcThresholds?: boolean,  compareTagsMap?: Map<string, TagType>, tagDescriptionsMap?: Map<string, TagType>) : Map<string, TagType> => {
  const sortedTagModifiers = [...tagModifiers]?.sort((m1, m2) => {
    const m1HasThresholds = m1?.thresholds && m1.thresholds.length > 0;
    const m2HasThresholds = m2?.thresholds && m2.thresholds.length > 0;
    if (m2.priority === m1.priority) {
      if ( m2HasThresholds && !m1HasThresholds) {
        return -1;
      } else if (m1HasThresholds && !m2HasThresholds) {
        return 1;
      }
    }

    return m2.priority > m1.priority ? -1 : 1;
  }) || [];
  let newTagsMap = new Map(tagsMap);

  sortedTagModifiers.forEach((tm) => {
    const primaryTag = compareTagsMap ? compareTagsMap.get(tm.primaryTagKey) : tagsMap.get(tm.primaryTagKey);
    const relatedTag = newTagsMap.get(tm.relatedTagKey);
    const hasThresholds = tm?.thresholds && tm.thresholds.length > 0;

    if (hasThresholds && !tagModifiersPassedThresholds(tm, newTagsMap)) { 
    } else if (primaryTag) {
      switch(tm.symbol) {
        case TagModifierSymbol.AddArray:
          // If related Tag exists and an array, edit related tag
          // otherwise create tag.
          // Add entries for each entry in array.
          newTagsMap = modifyTagsMapAddArray(primaryTag, tm, relatedTag, newTagsMap);
        break;
        case TagModifierSymbol.Add:
          // If related Tag exists and is not an array.
          newTagsMap = modifyTagsMapAdd(relatedTag, tm, newTagsMap);
          break;
        case TagModifierSymbol.AddValue:
          // If related Tag exists and is not an array.
          newTagsMap = modifyTagsMapAddValue(primaryTag, relatedTag, tm, newTagsMap);
          break;
        case TagModifierSymbol.Cancel:
          newTagsMap = modifyTagsMapCancel(relatedTag, newTagsMap, tm);
        break;
        case TagModifierSymbol.Multiply:
          newTagsMap = modifyTagsMapMultiply(relatedTag, tm, newTagsMap);
        break;
        case TagModifierSymbol.ValueTag:
          newTagsMap = modifyTagsMapValueTag(primaryTag, newTagsMap, tagModifiers);
        break;
        case TagModifierSymbol.ValueTagFilterResults:
          // Get val tags, then filter out from list of tags in val2.
          newTagsMap = modifyTagsMapValueTagFilterResults(primaryTag, tagModifiers, newTagsMap, tm);
        break;
        case TagModifierSymbol.ValueTagFilterOutResults:
          newTagsMap = modifyTagsMapValueTagFilterOutResults(primaryTag, tagModifiers, newTagsMap, tm);
        break;
        case TagModifierSymbol.AddValueAsTags:
          newTagsMap = modifyTagsMapWithAddValueAsTag(tm, newTagsMap);
        break; 
        case TagModifierSymbol.MultiplyAdd:
          // If related Tag exists and is not an array.
          newTagsMap = modifyTagsMapMultiplyAdd(relatedTag, primaryTag, tm, newTagsMap);
        break;
      }
    } else {
      // If no primary tag found, it could be because the modifiers work on other traits.
      // For those modifiers that don't work on exact matches, go through again, and see
      // If we can match the values.
      switch( tm.symbol) {
        case TagModifierSymbol.ValueTagFilterOutResults:
          // remove this tagModifier
          const remainingTagModifiers = tagModifiers.filter((tm2) => { return tm !== tm2 });
          newTagsMap = modifyTagsMapValueTagFilterOutResultsNoPrimary(remainingTagModifiers, newTagsMap, tm);
        break;
        case TagModifierSymbol.AddValueAsTags:
          // The only one that can work without primary being found initially is AddValue.
          // NO Primary Tag found, so we need to see if we can find a tag matching, using base Value.
          newTagsMap = modifyTagsMapWithAddValueAsTag(tm, newTagsMap);
          break;
        default:
          // If no primary tag, don't do anything either
          break;
      }
    }
  });
  return newTagsMap;
}

interface displayArchetypeMemberProps {
  archetypeMember: CastMemberType;
  tagModifiers?: Array<TagModifierType>;
  tagDescriptionsMap?: Map<string, TagType>;
};

export const calculateDisplayArchetypeMemberTags =({
  archetypeMember, tagModifiers, tagDescriptionsMap
}: displayArchetypeMemberProps) => {
  const memberTagsMap = tagsToMap(archetypeMember?.tags, tagDescriptionsMap);
  let modifiedTagMaps = tagModifiers ? modifyTagsMap(tagModifiers, memberTagsMap, false, undefined, tagDescriptionsMap) : memberTagsMap;
  modifiedTagMaps =  tagsToMap(Array.from(modifiedTagMaps.values()), tagDescriptionsMap); 

  return {
    ...archetypeMember,
    tags: Array.from(modifiedTagMaps.values()),
    tagsMap: modifiedTagMaps,
  };
}

interface groupResults {
  groupDisplayCasts: Array<ArchetypeGroupType>;
  remainingCasts: Array<CastMemberType>;
}
interface internalGroupResults {
  displayCasts: Array<CastMemberType>;
  remainingCasts: Array<CastMemberType>;
}


const castFulfillsGrouping = (g: ArchetypeGroupType, c: CastMemberType) => {
  const tagsMap = c.tagsMap || tagsToMap(c.tags);
  const passesThreshold = g.tagThresholds?.find((tt)=> {
    const thresholdKey  =  getThresholdKey(tt);
    const curTag = tagsMap.get(thresholdKey);
    return tagOrTagArrayPassedThreshold(tt, curTag);
  });
  // convert to boolean
  return !!passesThreshold;
}

export const groupCasts = (groupings: Array<ArchetypeGroupType>, cast: Array<CastMemberType> ) => {
  const castsResult = groupings.reduce((r: groupResults, g) => {
    const curGroupsCast = [ ...(r.groupDisplayCasts)];
  
    const res2 = r.remainingCasts.reduce((r2: internalGroupResults, rc2) => {
      if(castFulfillsGrouping(g, rc2)) {
        return {
          displayCasts: [...r2.displayCasts, rc2],
          remainingCasts: [...r2.remainingCasts]
        }
      } else {
        return {
          displayCasts: [...r2.displayCasts],
          remainingCasts: [...r2.remainingCasts, rc2]
        }
      }
    },{
      displayCasts: [],
      remainingCasts:[]
    });

    const curGroup: ArchetypeGroupType = {
       ...g,
       casts: res2.displayCasts
    };
  
    return {
      groupDisplayCasts: [...curGroupsCast, curGroup],
      remainingCasts: res2.remainingCasts
    };
  
  },{
    groupDisplayCasts: [],
    remainingCasts: [...cast]
  });
 
  
  const finalResults = [...castsResult.groupDisplayCasts];
  if(castsResult.remainingCasts.length > 0) {
    const lastGrouping = createUngroupedGroup(castsResult.remainingCasts);

    finalResults.push(lastGrouping);
  }
  
  return finalResults; 

}

export const createUngroupedGroup = (casts: Array<CastMemberType>): ArchetypeGroupType => {
  return {
    key: "ungrouped",
    name: "Ungrouped",
    description: "Ungrouped casts",
    createdAt: new Date().toString(),
    updatedAt: new Date().toString(),
    tagThresholds:[],
    casts: [...casts]
  }
}

const modifyTagsMapAddArray = (primaryTag: TagType, tm: TagModifierType, relatedTag: TagType | undefined, tagsMap: Map<string, TagType>) => {
  const newTagsMap = new Map(tagsMap);
  const amount = (primaryTag?.val
    && Array.isArray(primaryTag.val)
    && primaryTag.val.length * parseToNumber(tm.val))
    || (
      primaryTag?.val
      && typeof primaryTag?.val === "string"
      && parseToNumber(tm.val)
    ) || 0;
  if (relatedTag
    && relatedTag.val
    && primaryTag?.val
    && (Array.isArray(primaryTag.val) || amount !== 0)
    && !Array.isArray(relatedTag.val)
    && (
      relatedTag.val === undefined
      || !Number.isNaN(relatedTag.val)
    )) {
    const addedVal = addTagVals(relatedTag?.val, amount);

    if (!Number.isNaN(addedVal)) {
      const newTag = {
        ...relatedTag,
        val: addedVal,
        modified: true,
      };
      newTagsMap.set(tm.relatedTagKey, newTag);
    }
  } else if (!relatedTag && Array.isArray(primaryTag.val)) {
    // related Tag doesn't exist. Add it to tags.
    newTagsMap.set(tm.relatedTagKey, {
      key: tm.relatedTagKey,
      val: amount ? amount : undefined,
      computed: true,
      modified: true,
    });
  } else {
  }

  return newTagsMap;
}

function modifyTagsMapCancel(relatedTag: TagType | undefined, tagsMap: Map<string, TagType>, tm: TagModifierType) {
  const newTagsMap = new Map(tagsMap);
  if (relatedTag) {
    newTagsMap.delete(tm.relatedTagKey);
  }
  return newTagsMap
}

function modifyTagsMapMultiply(relatedTag: TagType | undefined, tm: TagModifierType, tagsMap: Map<string, TagType>) {
  const newTagsMap = new Map(tagsMap);
  if (relatedTag && relatedTag.val && !Array.isArray(relatedTag.val)) {
    const addedVal = multiplyTagVals(relatedTag.val, tm.val);
    if (!Number.isNaN(addedVal)) {
      const newTag = {
        ...relatedTag,
        val: addedVal,
        modified: true,
      };
      newTagsMap.set(tm.relatedTagKey, newTag);
    }
  }
  return newTagsMap;
}

function modifyTagsMapMultiplyAdd(relatedTag: TagType | undefined, primaryTag: TagType, tm: TagModifierType, tagsMap: Map<string, TagType>) {
  const newTagsMap = new Map(tagsMap);
  if (relatedTag && relatedTag.val && !Array.isArray(relatedTag.val) && !Array.isArray(primaryTag.val)) {
    const totalVal = parseToNumber(primaryTag.val) * parseToNumber(tm.val) + parseToNumber(tm.val2);
    const addedVal = Math.round(addTagVals(relatedTag.val, totalVal));
    if (!Number.isNaN(addedVal)) {
      const newTag = {
        ...relatedTag,
        val: addedVal,
        modified: true,
      };
      newTagsMap.set(tm.relatedTagKey, newTag);
    }
  } else if (relatedTag && relatedTag.val && Array.isArray(relatedTag.val)) {
    // can't add val if it's an Array.
  } else {
    // related Tag doesn't exist. Add it to tags.
    newTagsMap.set(tm.relatedTagKey, {
      key: tm.relatedTagKey,
      val: tm.val2 ? tm.val2 : undefined,
      computed: true,
      modified: true,
    });
  }
  return newTagsMap;
}

function modifyTagsMapValueTagFilterOutResultsNoPrimary(tagModifiers: TagModifierType[], tagsMap: Map<string,TagType>, tm: TagModifierType) {
  const newTagsMap = new Map(tagsMap);
  const primaryTags = Array.from(newTagsMap.values()).filter((nt) => {
    if (nt.baseKey === tm.primaryTagKey && tm.primaryIsBaseKey) {
      return true;
    } else {
      return false;
    }
  });
  if (primaryTags && primaryTags.length > 0) {
    const returnMap = primaryTags.reduce((result, primaryTag) => {
      if (primaryTag && primaryTag.val) {

        return modifyTagsMapValueTagFilterOutResults(primaryTag, tagModifiers, result, tm); 
      } else {
        //  ValueTag But no primaryTag.val
        return result;
      }
    }, newTagsMap);

    return returnMap;
  }

  return newTagsMap;
}

function modifyTagsMapValueTagFilterOutResults(primaryTag: TagType, tagModifiers: TagModifierType[], tagsMap: Map<string, TagType>, tm: TagModifierType) {
  const newTagsMap = new Map(tagsMap);
  if (primaryTag && primaryTag.val) {
    const valueTags = Array.isArray(primaryTag.val) ? primaryTag.val : [primaryTag.val];

    const calcEntries = valueTags.reduce((result, valTag) => {
      result.set(valTag, {
        key: valTag,
        val: undefined,
        computed: true,
        modified: true,
      });

      return result;
    }, new Map());
    const newTagsMapFiltered = new Map(modifyTagsMap(tagModifiers, new Map(newTagsMap), false, calcEntries));

    if (Array.isArray(tm?.val2)) {
      tm?.val2?.forEach((sv) => {
        const val2TagKey = typeof sv === "string" ? sv : getTagKey(sv);
        const newVal = newTagsMapFiltered.get(`${val2TagKey}`);
        if (newVal) {
          newTagsMapFiltered.delete(`${val2TagKey}`);
        }
      });
    } else {
      // No primary Tag
    }

    newTagsMapFiltered.forEach((val, key) => {
      newTagsMap.set(key, val);
    });
  } else {
    //  ValueTag But no primaryTag.val
  }
  return newTagsMap;
}

const modifyTagsMapValueTagFilterResults = (primaryTag: TagType, tagModifiers: TagModifierType[], tagsMap: Map<string, TagType>, tm: TagModifierType) => {
  const newTagsMap = new Map(tagsMap);
  if (primaryTag && primaryTag.val) {
    const valueTags = Array.isArray(primaryTag.val) ? primaryTag.val : [primaryTag.val];

    const calcEntries = valueTags.reduce((result, valTag) => {
      result.set(valTag, {
        key: valTag,
        val: undefined,
        computed: true,
        modified: true,
      });
      return result;
    }, new Map());
    const newTagsMapFiltered = modifyTagsMap(tagModifiers, new Map(newTagsMap), false, calcEntries);

    if (Array.isArray(tm?.val2)) {
      tm?.val2?.forEach((sv) => {
        const val2TagKey = typeof sv === "string" ? sv : getTagKey(sv);
        const newVal = newTagsMapFiltered.get(`${val2TagKey}`);
        if (newVal) {
          newTagsMap.set(`${val2TagKey}`, newVal);
        }
      });
    } else {
      // No primary Tag
    }
  } else {
    //  ValueTag But no primaryTag.val
  }
  return newTagsMap
}

function modifyTagsMapValueTag(primaryTag: TagType, tagsMap: Map<string, TagType>, tagModifiers: TagModifierType[]) {
  let newTagsMap = new Map(tagsMap);
  if (primaryTag && primaryTag.val) {
    const valueTags = Array.isArray(primaryTag.val) ? primaryTag.val : [primaryTag.val];

    const calcEntries = valueTags.reduce((result, valTag) => {
      result.set(valTag, {
        key: valTag,
        val: undefined,
        computed: true,
        modified: true,
      });
      return result;
    }, new Map());
    newTagsMap = modifyTagsMap(tagModifiers, newTagsMap, false, calcEntries);
  } else {
    // ValueTag But no primaryTag.val
  }
  return newTagsMap;
}

function modifyTagsMapAdd(relatedTag: TagType | undefined, tm: TagModifierType, tagsMap: Map<string, TagType>) {
  const newTagsMap = new Map(tagsMap);
  if (relatedTag && relatedTag.val && !Array.isArray(relatedTag.val)) {
    const addedVal = addTagVals(relatedTag.val, tm.val);
    if (!Number.isNaN(addedVal)) {

      const newTag = {
        ...relatedTag,
        val: addedVal,
        modified: true,
      };
      newTagsMap.set(tm.relatedTagKey, newTag);
    }
  } else if (relatedTag && relatedTag.val && Array.isArray(relatedTag.val)) {
    // can't add val if it's an Array.
  } else {
    // related Tag doesn't exist. Add it to tags.
    newTagsMap.set(tm.relatedTagKey, {
      key: tm.relatedTagKey,
      val: tm.val ? tm.val : undefined,
      computed: true,
      modified: true,
    });
  }
  return newTagsMap;
}

function modifyTagsMapAddValue(primaryTag: TagType, relatedTag: TagType | undefined, tm: TagModifierType, tagsMap: Map<string, TagType>) {
  const newTagsMap = new Map(tagsMap);
  if(!primaryTag || !primaryTag.val || Array.isArray(primaryTag.val)) {
    // no primary tag val, so this can't work.
    return newTagsMap;
  }

  if (relatedTag && relatedTag.val && !Array.isArray(relatedTag.val)) {
    const addedVal = addTagVals(relatedTag.val, primaryTag.val);
    if (!Number.isNaN(addedVal)) {
      const newTag = {
        ...relatedTag,
        val: addedVal,
        modified: true,
      };
      newTagsMap.set(tm.relatedTagKey, newTag);
    }
  } else if (relatedTag && relatedTag.val && Array.isArray(relatedTag.val)) {
    // can't add val if it's an Array.
  } else {
    // related Tag doesn't exist. Add it to tags.
    newTagsMap.set(tm.relatedTagKey, {
      key: tm.relatedTagKey,
      val: primaryTag.val ? primaryTag.val : undefined,
      computed: true,
      modified: true,
    });
  }
  return newTagsMap;
}
