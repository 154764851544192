import React from "react";
import Layout from "Layout";
import { Link } from "react-router-dom";
import { routeLinks, externalLinks } from "App/routing/routeLinks"
import { useGetAllDocs } from "actions/docActions";
import { LinkButton } from "components/Buttons";
import {  useGetCurrentUser } from "hooks/userHooks";

import styles from "./styles.module.scss";
import { useWorld } from "hooks/worldHooks";


export const Docs = () => {
  const { isAdmin, id, isGuest } = useGetCurrentUser();
  const { chosenWorldId } = useWorld(); 
  const { data } = useGetAllDocs(chosenWorldId, id);

  return (
    <Layout>
      <div >
        <h1>
          Docs:
        </h1>
        <div>
          {isAdmin && (<div className={styles.actions}>
            <LinkButton to={`/${routeLinks.docs}/${routeLinks.new}`} >New</LinkButton> 
          </div>)}
          <div>
          {
            data?.map((doc) => {
              return (<div key={doc.id}><Link to={`${isGuest ? `/${routeLinks.guest}` : ``}/${routeLinks.docs}/${doc.id}`} >{doc.id}: {doc.name}</Link></div>)
            })
          }
          </div>
          <div className={styles.otherLinks}><Link to={externalLinks.rules}>For other downloads, go to the Blood of Stars rules page.</Link></div>
        </div>
      </div>
    </Layout>
  );
}
