import { useCallback } from "react";
import axios from "axios";
import * as base from "./lib/baseActions";
import { jwtData, apiEndpoints, errorCodes } from "lib/config";
import {  loginUserData,  UserType } from "actions/user.types";
import useSWR from "swr";
import { mutate } from 'swr';

/// Fetch data for logged in user
const fetchUser = async () => {
  return base.get(apiEndpoints.usersMe);
};

export interface authResult {
  data: {
    token: string;
  }
}

export interface UserResult {
  data: {
    firstName?: string;
    lastName?: string;
    username?: string;
    email?: string;
  };
};

/// Login
/// data = { email:"login email", password: "login pass" }
const loginUser = async (data : loginUserData) : Promise<UserResult> => {
  const email = data.email;
  const password = data.password;

  try {
    // logout if you try to login again.
    await logoutUser();

    const loginResult = await base.post(apiEndpoints.login, {
      email:email,
      password:password
    });

    localStorage.setItem(jwtData.authToken, loginResult.data.authToken);
    localStorage.setItem(jwtData.authTokenExpiry, loginResult.data.expiresIn);
    localStorage.setItem(jwtData.refreshToken, loginResult.data.refreshToken);
    localStorage.setItem(jwtData.refreshTokenExpiry, loginResult.data.refreshExpiresIn);

    return fetchUser();
  } catch (err: any) {

    if(err.status === 400) {
      err.message = "Incorrect Email or Password.";
    }

    throw err;
  }
};

/// logout user
const logoutUser = async () => {
  localStorage.removeItem(jwtData.authToken);
  localStorage.removeItem(jwtData.authTokenExpiry);
  localStorage.removeItem(jwtData.refreshToken);
  localStorage.removeItem(jwtData.refreshTokenExpiry);

  return;
};

export const registrationErrorType = {
  alreadyExists: "alreadyExists" ,
  googleMustBeEmailVerified: "googleMustBeEmailVerified"
};

// create login user
// data : { email: "email@email.com", password:"password", confirmPassword:"password" }
const createUser = async (data: loginUserData) => {
  const registrationCheck = checkRegistrationInformation(data);
  if(!registrationCheck.success) {
    throw new Error('cannot create user, info failed');
  } else {
    let userResult;
    try {
      userResult = await base.post(
        apiEndpoints.users,
        data
      );
    } catch(err: any) {
      if(err?.original?.response?.data?.errType) {
        err.type = err?.original?.response?.data?.errType 
      }
      throw err;
    }

    if(userResult.data.requiresEmailConfirmation) {
      return {
        requiresEmailConfirmation: true,
        user: null
      };
    } else {
      localStorage.setItem(jwtData.authToken, userResult.data.authToken);
      localStorage.setItem(jwtData.refreshToken, userResult.data.refreshToken);
    
      const user = await fetchUser();
      return {
        requiresEmailConfirmation: false,
        user: user.data
      }
    }
  }
};

/// check confirmation helper function
const checkRegistrationInformation = (data : loginUserData) => {
  let check : { success: boolean; errors?: any } ={ success:true};
  let errors = [];
  if(!data.email || data.email.length < 5) {
    // does not have email
    errors.push(new Error("You must fill in a valid email"));
  }

  if(!data.password || !data.passwordConfirmation) {
      errors.push(new Error("You must fill in the password and password confirmation field"));    
  } else if (data.password.length < 8) {
    errors.push(new Error("The password has a minimum length of 8"));
  } else if(data.password !== data.passwordConfirmation) {
    errors.push(new Error("The password and password confirmation must be the same"));
  }
  if(errors.length > 0) {
    check.success=false;
    check.errors = errors;
  }

  return check;
};

// check if user is Authorized
const userIsAuthorized = () => {
  const initialAuthToken = localStorage && localStorage.getItem(jwtData.authToken);
  return initialAuthToken ? true : false;
}

export const getUser = async(userId: string | number) : Promise<UserType> => {
  try {
    const user = await base.get(`${apiEndpoints.users}/${userId}`);
    return user.data;
  } catch (err: any) {
    err.message = "Failed to get a tag.";
    throw err;
  }
}

export const useGetUser = (userId?: string | number) => {
  return useSWR<UserType>(userId ?
      `${apiEndpoints.users}/${userId}`
     : null,
    (key) => { 
      return getUser(userId || 0);
    }
  );
};

interface getAllUsersType {
  search?: string;
  role?: string;
  ids?: Array<string>;
}

export const getUsers = async ({ search, role, ids }: getAllUsersType) : Promise<Array<UserType>> => {
  try {
    const params: getAllUsersType = {};
    if(search) { params.search = search; }
    if(role) { params.role = role; }
    if(ids) { params.ids = ids; }
    const users = await base.get(`${apiEndpoints.users}`, { params: params} );
    const usersData = users.data ? users.data : [];
    return [...usersData];
  } catch (err: any) {
    err.message = "Failed to get a tags.";
    throw err;
  }
}
type useGetUsersFetcherProps = [string, string| undefined, string| undefined, string[] | undefined]

export const useGetUsers = (search?: string, role?: string, ids?: Array<string>) => {

  return useSWR<Array<UserType>>(
    [
      `${apiEndpoints.users}`,
      search,
      role,
      ids?.join(`,`)
    ],
    ([key, searchString, roleString, idsString]: useGetUsersFetcherProps) => { 
      return getUsers({ search, role, ids });
    }
  );
};

export const updateUser = async (userId : number | string, user: UserType) : Promise<UserType> => {
  try {
    const result = await base.put(`${apiEndpoints.users}/${userId}`, user);
    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      err.message = "Failed to get user.";
    }
    throw err;
  }
};

export interface UserFunctionType {
  (userId: number | string, user: UserType):  Promise<UserType> 
} 

export const useEditUser = (): UserFunctionType  => {

  return useCallback(async (userId: number | string, user: UserType) => {
    const result = await updateUser(userId, user);
    mutate(`users/${userId}`, result);

    return result;
  }, []);
};

export const requestPasswordReset = async (email : string) : Promise<UserType> => {
  try {
    const result = await base.put(`${apiEndpoints.users}/reset`,  { email });
    return result.data;
  } catch (err: any) {
    if (axios.isAxiosError(err)) {
      err.message = "Failed to get user.";
    } else if (err?.original && axios.isAxiosError(err.original)) {
      const originalError = err.original;
      const errorResponse = originalError.response;
      err.status = errorResponse?.status;
      if(err.status === 429) {
        err.code = errorCodes.tooManyRequests;
      } else {
        err.code = errorCodes.default;
      }      
      err.message = "Failed to get user.";
    }
    throw err;
  }
};

export const requestVerifyEmail = async (email : string) : Promise<UserType> => {
  try {
    const result = await base.put(`${apiEndpoints.users}/confirm`,  { email });
    return result.data;
  } catch (err: any) {
    
    if (axios.isAxiosError(err)) {
      err.message = "Failed to get user.";
    } else if (err?.original && axios.isAxiosError(err.original)) {
      const originalError = err.original;
      const errorResponse = originalError.response;
      err.status = errorResponse?.status;
      if(err.status === 429) {
        err.code = errorCodes.tooManyRequests;
      } else {
        err.code = errorCodes.default;
      }
      err.message = "Failed to get user.";
    }
    throw err;
  }
};

export const resetPassword = async (token: string, password: string, passwordConfirmation?: string ) : Promise<UserType> => {
  try {
    const result = await base.put(`${apiEndpoints.users}/reset/${token}`,  { password });
    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      err.message = "Failed to get user.";
    }
    throw err;
  }
};

export const confirmEmail = async ( token: string) => {
  try {
    const userResult = await base.put(`${apiEndpoints.users}/confirm/${token}`,  { });

     localStorage.setItem(jwtData.authToken, userResult.data.authToken);
     localStorage.setItem(jwtData.refreshToken, userResult.data.refreshToken);
     return fetchUser();
  } catch (err) {
    if (axios.isAxiosError(err)) {
      err.message = "Failed to get user.";
    }
    throw err;
  }
};

export const changeAllowContact = async (allowContact: boolean) => {
  try {
    await base.put(`${apiEndpoints.users}/me/allowContact`,  { allowContact });

     return fetchUser();
  } catch (err) {
    if (axios.isAxiosError(err)) {
      err.message = "Failed to get user.";
    }
    throw err;
  }
};

export const deleteUser = async (userId : number | String) : Promise<UserType> => {
  try {
    const result = await base.del(`${apiEndpoints.users}/${userId}`);
    return result.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      err.message = "Failed to get user.";
    }
    throw err;
  }
};

export interface UserDelFunctionType {
  (userId: number | string):  Promise<UserType> 
} 

export const useDeleteUser = (): UserDelFunctionType  => {

  return useCallback(async (userId: number | string) => {
    const result = await deleteUser(userId);
    mutate(`users/${userId}`, result);

    return result;
  }, []);
};

export const useUnDeleteUser = (): UserDelFunctionType  => {

  return useCallback(async (userId: number | string) => {
    const user = await getUser(userId);
    user.deleted = 0;
    const result = await updateUser(userId, user);
    mutate(`users/${userId}`, result);

    return result;
  }, []);
};


export {
  fetchUser,
  loginUser,
  logoutUser,
  createUser,
  userIsAuthorized
};
