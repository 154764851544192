import React, { useMemo, useState, useCallback, useEffect } from "react";
import { 
  useGetWorld,
  useCreateWorld,
  useUpdateWorld,
  CreateWorldFunctionType,
  UpdateWorldFunctionType
} from 'actions/worldActions';

import { WorldType } from "actions/world.types";
import { useForm } from 'react-hook-form';
import { isArrayValidator, createValidateJsonString } from "components/JsonEditor"
import { useGetCurrentUser } from "hooks/userHooks";

interface UseWorldProps {
  userId?: number;
  worldId?: string | number;
  name?: string;
  description?: string;
  world?: WorldType;
  version: string;
  createWorld?: CreateWorldFunctionType;
  updateWorld?: UpdateWorldFunctionType;
  loaded?: boolean;
}

const useWorldFormInfo = (worldId?: string | number, userId?: string | number): UseWorldProps => {
  const [loaded, setLoaded]= useState(false);

  const { data } = useGetWorld(worldId, userId);

  useEffect(() => {
    if(data) {
      setLoaded(true);
    }
  }, [data])

  const createDoc = useCreateWorld();
  const updateDoc = useUpdateWorld();

  return {
    worldId: worldId || undefined,
    name: data?.name,
    description: data?.description,
    world: data,
    version: data?.version || "",
    createWorld: createDoc,
    updateWorld: updateDoc,
    loaded
  };
}

interface SubmitDocTypes {
  name?: string;
  display?: number;
  description?: string;
  version?: string;
}

const translateValues = (vals: SubmitDocTypes): WorldType => {
  // title?: string;
  // type?: CastMemberClassType;
  // tags?: Array<TagType>;
  // amount?: number;

  return {
    name: vals?.name,
    display: vals.display || 0,
    description: vals?.description,
    data: { 
      version: vals.version || ""
    },
    version: vals.version
  }
}

export enum FormType {
  Update,
  All,
  Create,
  Get
}

interface useDocFormProps {
  worldId?: string;
  formType?: FormType;
  callback?: (doc?: WorldType) => void ;
}

export const useWorldForm = ({
  worldId, 
  formType = FormType.Update,
  callback = () => {} 
}: useDocFormProps = {}) => {
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState('');
  const { id: userId } = useGetCurrentUser();

  const {
    name,
    description,
    world,
    version,
    loaded,
    createWorld,
    updateWorld
  } = useWorldFormInfo(worldId, userId);

  const [archetypeLoaded, setArchetypeLoaded ] = useState(false);  

  const loadFormValues = useCallback((doc?: WorldType) => {
    return { 
      name: doc?.name ,
      display: 0,
      description: doc?.description,
      version: doc?.version
    };
  },[]);

  const { register, handleSubmit, formState, setValue, reset, setError, clearErrors } = useForm({
    defaultValues: loadFormValues(world)
  });

  useEffect(() => {
    if(loaded && !archetypeLoaded) {
      reset(loadFormValues(world));
      setArchetypeLoaded(true);
    }
  }, [setArchetypeLoaded, reset, loaded, loadFormValues, archetypeLoaded, world]);

  const saveAndMerge = useCallback(async (vals: SubmitDocTypes) => {
      try {
        setSubmitting(true);
        setSubmitError('');
        const translatedValues = translateValues({ ...vals });
        let world: WorldType | undefined;
        if(formType === FormType.Create) {
          world = await (createWorld && createWorld(translatedValues));
          setSubmitting(false);
        } else {
          world = await (updateWorld && worldId ? updateWorld(worldId, translatedValues) : undefined);
          setSubmitting(false);
        }
        callback(world);
        return world
        // forward to next page
      } catch(err) {
        setSubmitting(false);
        setSubmitError('Update to Cast Member Failed.');
      }
  }, [formType, callback, createWorld, updateWorld, worldId]);
  const errors = formState?.errors;

  const jsonValidator = useMemo(() => {
   return isArrayValidator();
  },
  []);

  const validateJsonString = useMemo(() => {
    return createValidateJsonString(jsonValidator);
  },
  [jsonValidator]);

  const onSubmit = async (e:  React.FormEvent<HTMLFormElement>) => {
    await handleSubmit(saveAndMerge)(e);
  }

  return {
    name,
    description,
    version,
    world,
    loaded,
    submitting,
    submitError,
    saveAndMerge,
    validateJsonString,
    jsonValidator,
    register,
    setValue,
    onSubmit,
    errors,
    setError,
    handleSubmit,
    clearErrors
  };
}
