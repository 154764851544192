import { ArchetypeType, CastType } from 'actions/cast.types';
import { cloneDeep } from "lodash";
export type { TagType } from "actions/types";

export const cleanCast = <cleanCastType extends CastType | ArchetypeType>(cast: cleanCastType): cleanCastType => {
  const newCast = cloneDeep(cast);
  if( newCast?.data?.cast) {
    const newCasts = newCast?.data?.cast;
    newCast.data.cast = newCasts.map((nc) => {
      const { tagsMap, archetypeCast, ...rest} = nc;

      if(archetypeCast) {
        const { tagsMap, ...archetypeRest} = archetypeCast;
      
        return {
          archetypeCast: archetypeRest,
          ...rest
        };
      } else {
        return rest;
      }
    });
  }
  return newCast;
}