import Layout from "Layout";
import { Link } from "react-router-dom";
import { useGetArchetypes } from "actions/archetypeActions";
import { LinkButton } from "components/Buttons";
import styles from "./styles.module.scss";
import {  useGetCurrentUser } from "hooks/userHooks";
import { ArchetypeIcon } from "components/Icons/Archetypes";
import { DivCutCornerBorder, BorderType } from "components/DivCutCornerBorder";
import { routeLinks } from "App/routing";
import { useWorld } from "hooks/worldHooks";

export const Archetypes = () => {
  const { isAdmin, id, isGuest } = useGetCurrentUser();
  const { chosenWorldId } = useWorld();  
  const { data } = useGetArchetypes(undefined, undefined, chosenWorldId, id);
 
  return (
    <Layout>
      <div >
        <h1>
          Factions 
        </h1>
        <div >
          { isAdmin && <div className={styles.actionsWrapper}>
              <div className={styles.actions}>
                <LinkButton to={`/${routeLinks.factions}/${routeLinks.new}`} >New</LinkButton> 
              </div>
            </div>
          }
          <div className={styles.archetypesWrapper}>{
            data?.map((archetype) => {
              return (
                  <div className={styles.archetypeEntryWrapper} key={archetype.id || archetype.key}>
                  <DivCutCornerBorder borderType={BorderType.large} className={styles.archetypeBack}>
                    <div key={archetype.id} className={styles.archetypeLink}>
                      <Link to={`${isGuest ? `/${routeLinks.guest}` : ``}/${routeLinks.factions}/${archetype.id}`} >
                        <ArchetypeIcon className={styles.archetypeSize} archetype={archetype} /> {archetype.name}
                      </Link>
                    </div>
                  </DivCutCornerBorder>
                  </div>
              )
            })
          }</div>
        </div>
      </div>
    </Layout>
  );
}
