import {useCallback} from "react";
import Layout from "Layout";
import classNames from "classnames";
import { useParams } from "react-router-dom";

import { Button } from "components/Buttons/Button";
import { TextField, TextFieldType } from "components/Forms/TextField";
import { ShowTagModifier } from "components/TagModifiers/ShowTagModifier";
import styles from "./styles.module.scss";

import { Form } from 'components/Forms/Form';
import { JSONEditor } from "components/JsonEditor"
import { useTagModifierForm } from "pages/TagModifiersGroups/useTagModifiersGroupForm";
import { routeLinks } from "App/routing";
import { useNavigate } from "react-router-dom";
import { TagModifiersGroupType } from "actions/tag.types";

type CastPageParams = {
  tagModifiersGroupId: string;
};

export const EditTagModifier = () => {
  const { tagModifiersGroupId } = useParams<CastPageParams>();
  const navigate = useNavigate();

  const submitCallback = useCallback((tagModifiersGroups?: TagModifiersGroupType) => {
    navigate(`/${routeLinks.tagModifiersGroups}/${tagModifiersGroups?.id || tagModifiersGroupId}`);
  }, [navigate, tagModifiersGroupId]);

  const {
    name,
    tagModifiers,
    tagDescriptionsMap,
    errors,
    onSubmit,
    register,
    submitting,
    submitError,
    setValue,
    validateJsonString,
    jsonValidator,
    formData
  } = useTagModifierForm({tagModifiersGroupId, callback: submitCallback});

  return (
    <Layout>
      <div >
        <h1>
          { `${tagModifiersGroupId} : ${name}`}
        </h1>
        <div>
          <Form
            onSubmit={onSubmit}
          >
            <Button type="submit">Save</Button>
            {!submitting && submitError && (<div>{submitError}</div>)}
            <TextField 
              type={TextFieldType.text}
              placeholder="key"
              label="Name"
              labelClassName={styles.label}
              className={styles.entry}
              error={errors?.name && { ...errors.name, ...{message:"Name must not be empty"}}}
              {...register("name",{
                validate:{
                  validLength: (val?: string) => {
                    return val && val?.length > 0;
                  }
                }
              })}
            />
            <TextField 
              type={TextFieldType.text}
              placeholder="Description"
              label="Description"
              labelClassName={styles.label}
              className={classNames(styles.entry, errors?.description && styles.textError)}
              error={errors?.description && { ...errors.description, ...{message:"Description must not be empty"}}}
              {...register("description",{
                validate:{
                  validLength: (val?: string) => {
                    return val && val?.length > 0;
                  }
                }
              })}
            />
            <TextField 
              type={TextFieldType.number}
              labelClassName={styles.label}
              className={styles.entry}
              placeholder="Display"
              label="Display"
              error={errors?.display}
              {...register("display",{
              })}
            />
            <TextField
              type={TextFieldType.textArea}
              label="Data" 
              fieldClassName={styles.dataText}
              labelClassName={styles.label}
              className={styles.hidden}
              error={errors?.data}
              {...register("data",{
                validate:{
                  validLength: (val?: string) => {
                    return val && val?.length > 0;
                  },
                  validJson:(val: string) => {
                    return validateJsonString(val);
                  }
                }
              })}
            />
            <JSONEditor
              value={formData}
              className={styles.dataBlock}
              fieldClassName={styles.dataBlockField}
              label="Data"
              labelClassName={styles.label}
              error={errors?.data}
              onChange={(changed, oldChanged) => {
                if(changed !== oldChanged && (typeof changed === "string" || typeof changed === 'undefined')) {
                  setValue('data', changed || '');
                }
              }}
              validator={jsonValidator}
            />
          </Form>
        </div>
        <div>
          {tagModifiers?.map((tagModifier) => {
              return (<ShowTagModifier 
                key={tagModifier.id}
                tagModifier={tagModifier}
                tagDescriptionsMap={tagDescriptionsMap}
              />);
          })}
        </div>
      </div>
    </Layout>
  );
}
