import Layout from "Layout";
import { Link } from "react-router-dom";
import { routeLinks } from "App/routing/routeLinks"
import { LinkButton } from "components/Buttons";
import {  useGetCurrentUser } from "hooks/userHooks";

import styles from "./styles.module.scss";
import { useGetAllArchetypeDisplay } from "actions/archetypeDisplayActions";
import { useWorld } from "hooks/worldHooks";

export const ArchetypeDisplays = () => {
  const { isAdmin, id } = useGetCurrentUser(); 
  const { chosenWorldId } = useWorld(); 
  const { data } = useGetAllArchetypeDisplay(chosenWorldId, id);

  return (
    <Layout>
      { isAdmin &&
      <div >
        <h1>
          Faction Displays:
        </h1>
        <div>
          <div className={styles.actions}>
            <LinkButton to={`/${routeLinks.archetypeDisplays}/${routeLinks.new}`} >New</LinkButton> 
          </div>
          {
            data?.map((archetypeDisplay) => {
              return (<div key={archetypeDisplay.id}><Link to={`/${routeLinks.archetypeDisplays}/${archetypeDisplay.id}`} >{archetypeDisplay.id}: {archetypeDisplay.name}</Link></div>)
            })
          }
        </div>
      </div>}
    </Layout>
  );
}
