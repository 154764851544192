import Layout from "Layout";
import {  useState } from "react";
import { useParams } from "react-router-dom";
import classNames from "classnames";

import { CastMemberType } from "actions/cast.types";
import { useUserState } from "atoms/users.atom";
import { useNavigate } from "react-router-dom";
import { LinkButton, Button } from 'components/Buttons';
import { Modal } from "components/Modal";
import styles from "./styles.module.scss";
import { useGetLocalCastProps } from "./cast.hook";
import { TagDescriptorModal, useTagDescriptorModal } from "components/ArchetypeMembers/TagDescriptorModal";
import { GroupedTagsModal } from "components/Modals/GroupedTagsModal";
import TacticsModal from "components/Modals/TacticsModal";
import UpdateLinkCastModal from "components/Modals/UpdateLinkCastModal";
import { routeLinks } from "App/routing/routeLinks";
import { ViewCastMemberDisplayOptions } from "components/ViewCastMemberDisplayOptions";
import { ShrinkButton, useShrinkList } from "components/ShrinkList";
import { DivCutCornerBorder } from "components/DivCutCornerBorder";
import { ShowCast } from "pages/Casts/id";

export type CastPageParams = {
  id: string;
};

export const LocalCast = () => {
  const { id: castId } = useParams<CastPageParams>();
  const { state: { id } } = useUserState();
  const navigate = useNavigate();
  const {
    name,
    workingCast, 
    displayCast,
    totalPoints,
    totalUnits,
    archetypeDisplay,
    groupedTags,
    displayTagOptions,
    displayTactics
  } = useGetLocalCastProps(id, castId);
  const { showTagDescriptor, setShowTagDescriptor, onClickDescriptor } = useTagDescriptorModal();
  const [showChangeTeamModal, setShowChangeTeamModal] = useState(false);
  const onClickReplace = async () => {
    navigate(`/${routeLinks.guestTeams}/${castId}/${routeLinks.new}`);
  }

  const [showTraitsModal, setShowTraitsModal] = useState(false);
  const [showCastMemberOptions, setShowCastMemberOptions ] = useState(false);
  const [openDetailsCastMember, setOpenDetailsCastMember ] = useState<CastMemberType | undefined>(undefined);
  const [showTacticsModal, setShowTacticsModal] = useState(false);
  const [showLinkModal, setShowLinkModal] = useState(false);
 
  const showingAModal = showTraitsModal || showTacticsModal || showLinkModal || showCastMemberOptions;

  const hasRealCast = workingCast && workingCast.archetypeId; 

  const openDetails = (openMember: CastMemberType) => {
    setOpenDetailsCastMember(openMember);
    setShowCastMemberOptions(true);
  }

  const { toggleShrink, toggleShrinkAll, allShrunk, isShrunk } = useShrinkList(displayCast);

  return (
    <Layout>
      <div>
        {!hasRealCast ? ( 
          <>
            <div className={styles.sectionHeader}>
              <h1>
                New Team: {castId}
              </h1>
            </div>
            <div className={styles.editCast}>
            <div >
              <LinkButton to={`/${routeLinks.guestTeams}/${castId}/${routeLinks.new}`} className={styles.editButton}>Create New Team</LinkButton>
              <Button 
                className={styles.editButton}
                onClick={() => { 
                  setShowTraitsModal(!showTraitsModal);
                }}
              >Traits</Button>
            </div>
            </div>
          </>
        )
        :(<>
          <div className={styles.sectionHeader}>
            <h1>
              { `${name}`} 
            </h1>
            <div className={styles.editCast}>
             <Button 
                className={styles.editButton}
                onClick={() => { 
                  setShowTraitsModal(!showTraitsModal);
                }}
              >Traits</Button>
              <Button 
                className={styles.editButton}
                onClick={() => { 
                  setShowTacticsModal(!showTacticsModal);
                }}
              >Tactics</Button>
            <Button 
              className={styles.editButton}
              onClick={() => { 
                setShowLinkModal(!showLinkModal);
              }}
            >Code</Button>
             <LinkButton to={`/${routeLinks.guestTeams}/${castId}/${routeLinks.edit}`} className={styles.editButton}>Edit</LinkButton>
              <Button 
                className={styles.editButton}
                onClick={() => { 
                  setShowChangeTeamModal(!showChangeTeamModal);
                }}
              >Change Faction</Button>
              <ShrinkButton {...{toggleShrinkAll, allShrunk, className: classNames(styles.editButton, styles.mobileHide) }} />
            </div>
          </div>
          <div className={classNames(styles.mobileSelectFooter,(showTagDescriptor || showingAModal ) && styles.mobileHide)}>
          <DivCutCornerBorder
              className={classNames(styles.mobileSelect)}
              backgroundClassName={styles.mobilePointsBackground}
            ><div className={styles.mobilePoints}>Pts: {totalPoints}  ({totalUnits})</div></DivCutCornerBorder>
            <Button
              onClick={() => {
                toggleShrinkAll();
              }}
              className={classNames(styles.mobileSelectButton)}
            >{allShrunk ? `expand` : `shrink`}</Button>
          </div>
          <div className={styles.detailsHeader}>
            <div className={styles.unitCount}>
              Units: {totalUnits}
            </div>
            <div>
              Points: {totalPoints}
            </div>
            </div>
          <div className={classNames(styles.chooseCasts)}>
            <div>
              <ShowCast
                {...{
                  displayCast,
                  archetypeDisplay,
                  onClickDescriptor,
                  openDetails,
                  checkIsShrunk: isShrunk,
                  toggleShrink 
                }}
              />
            </div>
          </div>
        </>)}
      </div>
      {!showChangeTeamModal ? null : <Modal>
        <div className={styles.deleteModal}>
          <div>
            Are you Sure you want to Replace this Team with a new Team?
          </div>
          <div>

          <Button
                      className={styles.editButton}
                      onClick={onClickReplace}
          >
            Yes, Replace</Button>
          <Button
                      className={styles.editButton}
                      onClick={() => {
                        setShowChangeTeamModal(false);
                      }}
          > No, go Back
          </Button>
          </div>
        </div>
      </Modal>}
      { showTagDescriptor && <TagDescriptorModal
          tag={showTagDescriptor}
          close={() => {
            setShowTagDescriptor(undefined)
          }} 
        />
      }
      {!showTraitsModal ? null : <GroupedTagsModal
        closeModal={() => {
          setShowTraitsModal(false);
        }}
        groupedTags={groupedTags}
      />}
      {!showTacticsModal ? null : <TacticsModal
          closeModal={() => {
            setShowTacticsModal(false);
          }}
          tacticCasts={displayTactics}
          {...{onClickDescriptor,
            archetypeDisplay,
          openDetails
          }}
        />
      }
      { showCastMemberOptions && openDetailsCastMember && <Modal
          onBackgroundClick={() => { setShowCastMemberOptions(false)}}
          onClick={() => { setShowCastMemberOptions(false) }}
          closeModal={() => { setShowCastMemberOptions(false) }}
          includeCloseButton={true}
        >
          <ViewCastMemberDisplayOptions 
            castMember={ openDetailsCastMember}
            archetypeDisplay={archetypeDisplay}
            editTagOptions={displayTagOptions}
          ></ViewCastMemberDisplayOptions>
        </Modal>
        }
        {!showLinkModal ? null : <UpdateLinkCastModal
        closeModal={() => {
          setShowLinkModal(false);
        }}
          workingCast={workingCast}
          getOnly={true}
      />}
            { showTagDescriptor && <TagDescriptorModal
          tag={showTagDescriptor}
          close={() => {
            setShowTagDescriptor(undefined)
          }} 
        />
      }
    </Layout>
  );
}
