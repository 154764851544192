import React, { useCallback } from "react";
import Layout from "Layout";
import { BaseTagsGroupType } from "actions/tag.types";
import { Button } from "components/Buttons/Button";
import { TextField, TextFieldType } from "components/Forms/TextField";
import { ShowTag } from "components/Tags/ShowTag";
import { Form, FormType } from 'components/Forms/Form';
import JSONEditor from "components/JsonEditor"
import { useNavigate } from "react-router-dom";
import { routeLinks } from "App/routing";
import { useEditTagsGroupForm } from "pages/TagsGroups/useTagsGroupForm";
import styles from "./styles.module.scss";

export const NewTagsGroup = () => {
  const navigate = useNavigate();
  
  const submitCallback = useCallback((baseTagsGroup?: BaseTagsGroupType) => {
    navigate(`/${routeLinks.traits}/${baseTagsGroup?.id}`);
  }, [navigate]);

  const {
    tags,
    errors,
    onSubmit,
    register,
    submitting,
    submitError,
    setValue,
    formGroupings,
    formTags,
    tagsJsonValidator,
    groupingsJsonValidator
  } = useEditTagsGroupForm({ formType: FormType.Create, callback: submitCallback});

  return (
    <Layout>
      <div >
        <h1>
          New Tag Groups
        </h1>
        <div>
          <Form
            onSubmit={onSubmit}
          >
            <Button type="submit">Save</Button>
            {!submitting && submitError && (<div>{submitError}</div>)} 
            <TextField 
              type={TextFieldType.text}
              placeholder="key"
              label="Name"
              labelClassName={styles.label}
              className={styles.entry}
              error={errors?.name}
              {...register("name",{
                validate:{
                  validLength: (val?: string) => {
                    return val && val?.length > 0;
                  }
                }
              })}
            />
            <TextField 
              type={TextFieldType.text}
              placeholder="Description"
              label="Description"
              labelClassName={styles.label}
              className={styles.entry}
              error={errors?.description}
              {...register("description",{
                validate:{
                  validLength: (val?: string) => {
                    return val && val?.length > 0;
                  }
                }
              })}
            />
            <TextField 
              type={TextFieldType.number}
              labelClassName={styles.label}
              className={styles.entry}
              placeholder="Display"
              label="Display"
              error={errors?.display}
              {...register("display",{
              })}
            />
            <TextField
              type={TextFieldType.textArea}
              label="Tags" 
              fieldClassName={styles.dataText}
              labelClassName={styles.label}
              className={styles.hidden}
              error={errors?.tags}
              {...register("tags",{
                validate:{
                  validLength: (val?: string) => {
                    return val && val?.length > 0;
                  }
                }
              })}
            />
            <JSONEditor
              value={formTags}
              className={styles.dataBlock}
              label="Tags"
              labelClassName={styles.label}
              onChange={(changed, oldChanged) => {
                if(changed !== oldChanged && (typeof changed === "string" || typeof changed === 'undefined')) {
                  setValue('tags', changed || '');
                }
              }}
              validator={tagsJsonValidator}
            />
           <TextField
              type={TextFieldType.textArea}
              label="Groupings" 
              fieldClassName={styles.dataText}
              labelClassName={styles.label}
              className={styles.hidden}
              error={errors?.groupings}
              {...register("groupings",{
                validate:{
                  validLength: (val?: string) => {
                    return val && val?.length > 0;
                  }
                }
              })}
            />
            <JSONEditor
              value={formGroupings}
              className={styles.dataBlock}
              label="Groupings"
              labelClassName={styles.label}
              onChange={(changed, oldChanged) => {
                if(changed !== oldChanged && (typeof changed === "string" || typeof changed === 'undefined')) {
                  setValue('groupings', changed || '');
                }
              }}
              validator={groupingsJsonValidator}
            />
          </Form>
        </div>
        <div>
          {tags?.map((tag) => {
            return (<ShowTag key={tag.id} tag={tag} 
            />);
          })}
        </div>
      </div>
    </Layout>
  );
}
