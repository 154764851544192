import React, { useMemo, useState, useCallback, useEffect } from "react";
import { 
  useGetArchetype,
  useCreateArchetype,
  useUpdateArchetype,
  CreateArchetypeFunctionType,
  UpdateArchetypeFunctionType
} from 'actions/archetypeActions';
import { ArchetypeType } from "actions/cast.types";
import { useForm } from 'react-hook-form';
import { isArrayValidator, createValidateJsonString } from "components/JsonEditor"
import { useWorld } from "hooks/worldHooks";
import { useGetCurrentUser } from "hooks/userHooks";

interface UseArchetypeProps {
  userId?: number;
  archetypeKey?: string | number;
  name?: string;
  description?: string;
  archetype?: ArchetypeType;
  Archetype?: Array<ArchetypeType>;
  createArchetype?: CreateArchetypeFunctionType;
  updateArchetype?: UpdateArchetypeFunctionType;
  loaded?: boolean;
}

const useArchetype = (archetypeKey?: string | number): UseArchetypeProps => {
  const [loaded, setLoaded]= useState(false);
  const { chosenWorldId} = useWorld();
  const { id } = useGetCurrentUser(); 
  const { data } = useGetArchetype(archetypeKey, chosenWorldId, id);

  useEffect(() => {
    if(data) {
      setLoaded(true);
    }
  }, [data])

  const createArchetype = useCreateArchetype();
  const updateArchetype = useUpdateArchetype();


  return {
    name: data?.name || "",
    description: data?.description,
    archetype: data,
    createArchetype: (data:ArchetypeType) => { return createArchetype(data, chosenWorldId); } ,

    updateArchetype: (id: string | number, data:ArchetypeType) => { return updateArchetype(id, data, chosenWorldId); },
    loaded
  };
}

interface SubmitArchetypeTypes {
  name?: string;
  display?: number;
  description?: string;
  data?: string;
}

const translateValues = (vals: SubmitArchetypeTypes): ArchetypeType => {

  try {
    const parsedData = JSON.parse(vals?.data || "[]");
  
    return {
      name: vals?.name || "",
      display: vals?.display,
      description: vals?.description,
      data: parsedData
    }

  } catch(err) {
    console.log('failed to translate values');
    throw err;
  }
}

export enum FormType {
  Update,
  All,
  Create,
  Get
}

interface useArchetypeFormProps {
  archetypeId?: string;
  formType?: FormType;
  callback?: (archetype?: ArchetypeType) => void ;
}

export const useArchetypeForm = ({
  archetypeId, 
  formType = FormType.Update,
  callback = () => {} 
}: useArchetypeFormProps = {}) => {
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState('');

  const {
    name,
    description,
    archetype,
    loaded,
    createArchetype,
    updateArchetype, 
  } = useArchetype(archetypeId);

  const [formLoaded, setFormLoaded ] = useState(false);  

  const loadFormValues = useCallback((archetype?: ArchetypeType) => {
    return { 
      name: archetype?.name || "" ,
      display: archetype?.display || 0,
      description: archetype?.description || "",
      data: JSON.stringify(archetype?.data) || "{}"
    };
  },[]);

  const { register, handleSubmit, formState, getValues, setValue, reset, setError, clearErrors } = useForm({
    defaultValues: loadFormValues(archetype)
  });

  const formData = getValues('data');
  const formName = getValues('name');

  useEffect(() => {
    if((loaded && !formLoaded) || (!archetypeId && formType === FormType.Create)) {
      reset(loadFormValues(archetype));
      setFormLoaded(true);
    }
  }, [setFormLoaded, reset, loaded, loadFormValues, formLoaded, archetype, archetypeId, formType]);

  const saveAndMerge = useCallback(async (vals: SubmitArchetypeTypes) => {
    try {
      setSubmitting(true);
      setSubmitError('');
      const translatedValues = translateValues({ ...vals});


      let archetype: ArchetypeType | undefined;
      if(formType === FormType.Create) {
        archetype = await (createArchetype && createArchetype(translatedValues));
        setSubmitting(false);
      } else {
        archetype = await (updateArchetype && archetypeId ? updateArchetype(archetypeId, translatedValues) : undefined);
        setSubmitting(false);
      }

      await callback(archetype);
      return archetype;
      // forward to next page
    } catch(err) {
      setSubmitting(false);
      setSubmitError('Update to Cast Member Failed.');
    }
  }, [formType, callback, createArchetype, updateArchetype, archetypeId]);
  const errors = formState?.errors;

  const jsonValidator = useMemo(() => {
   return isArrayValidator();
  },
  []);

  const validateJsonString = useMemo(() => {
    return createValidateJsonString(jsonValidator);
  },
  [jsonValidator]);

  const onSubmit = async (e:  React.FormEvent<HTMLFormElement>) => {
    await handleSubmit(saveAndMerge)(e);
  }

  return {
    name,
    description,
    archetype,
    loaded,
    submitting,
    submitError,
    formName,
    formData,
    saveAndMerge,
    validateJsonString,
    jsonValidator,
    register,
    setValue,
    onSubmit,
    errors,
    setError,
    handleSubmit,
    clearErrors
  };
}
