import { useState } from 'react'
import { resetPassword } from 'actions/userActions';
import { useParams } from "react-router-dom";
import { Button, LinkButton } from 'components/Buttons';
import { useForm } from 'react-hook-form';
import { TextField, TextFieldType } from 'components/Forms/TextField';
import { Form } from 'components/Forms/Form';
import styles from './styles.module.scss';
import { Link } from "react-router-dom";
import { routeLinks } from "App/routing";
import Modal from 'components/Modal';
import { helpEmail } from "lib/config";

interface RegisterFormValProps {
  email: string;
  password: string;
  passwordConfirmation: string;
};

const useOnReset = (token?: string) => {
  const onSubmit = async ({ password, passwordConfirmation } : { email: string; password: string; passwordConfirmation: string; }) => {

    try {
      const results = await resetPassword(token || ``, password, passwordConfirmation);

      return results;
    } catch(err) {
      throw err;
    }
  };

  return onSubmit;
};

export const ResetFormComponent = () => {
  const { token } = useParams();

  const onSubmitReset = useOnReset(token);
  const [isSubmitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState('');
  const [viewConfirmedModal, setViewConfirmedModal] = useState(false);
  const { register, handleSubmit, formState } = useForm({
    reValidateMode: 'onSubmit',
    defaultValues: { email: ``, password: ``, passwordConfirmation: ``},
  });
  const errors = formState?.errors;


  return (
    <Form
      onSubmit={handleSubmit(async (vals: RegisterFormValProps) => { 
        try {
          setSubmitting(true);
          setSubmitError('');

          await onSubmitReset(vals); 

          setSubmitting(false);
          setViewConfirmedModal(true);  
        // forward to next page
        } catch(err) {
          setSubmitting(false);
          setSubmitError('Register failed. Please try again, or reach out to us.');
        }
      })}
    >
      <TextField
        type={TextFieldType.password}
        placeholder="Password"
        label="password"
        className={styles.field}
        labelClassName={styles.labels}
        error={errors?.password}
        {...register("password", {
          validate:{
            validLength: (val?: string) => {
              return   (val || ``).length >= 5 || "Length must be greater than 5";
            }
          }
        })}
      />
            <TextField
        type={TextFieldType.password}
        placeholder="Confirm Password"
        label="confirm password"
        className={styles.field}
        labelClassName={styles.labels}
        error={errors?.passwordConfirmation}
        {...register("passwordConfirmation", {
          validate:{
            matchesPassword: (val: string, values) => {
              return (values.passwordConfirmation === values.password) || "password and confirm password must be the same.";
            }
          }
        })}
      />
      { submitError && (<div className={styles.submitError}>{submitError}</div>)}
      <Button disabled={isSubmitting} className={styles.submitButton} type="submit">
        Submit
      </Button>
      <div>
        <Link to={`${routeLinks.register}`}>Don't have an account? Register</Link>
      </div>
      {viewConfirmedModal && <Modal onBackgroundClick={() => { 
          setViewConfirmedModal(false); 
          //navigate(routeLinks.home); 
        }}>
          <div className={styles.confirmModal}>

          <div>
            <p>
              Thank you for reseting your email. Please Login with your new password.
            </p>
            <p>
              If you are still having issues logging in, you can contact us at {helpEmail}.
            </p>
          </div>
          <div className={styles.buttons}>
            <LinkButton className={styles.button} to={`/${routeLinks.login}`}>Go to Login</LinkButton>
            <Button  className={styles.button} onClick={() => { setViewConfirmedModal(false)}}>close</Button>
            </div>
          </div>
        </Modal>
      }
    </Form>
  );
};

export const ResetForm = ResetFormComponent;
