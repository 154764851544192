import Layout from "Layout";
import { useParams } from "react-router-dom";
import { useGetArchetypeDisplay } from 'actions/archetypeDisplayActions';
import { LinkButton } from "components/Buttons/Button";
import styles from "./styles.module.scss";
import { routeLinks } from "App/routing/routeLinks";
import {  useGetCurrentUser } from "hooks/userHooks";
import { useWorld } from "hooks/worldHooks";

type ArchetypeDisplayParams = {
  archetypeDisplayId: string;
};

export const ArchetypeDisplay = () => {
  const { id } = useGetCurrentUser(); 
  const { archetypeDisplayId } = useParams<ArchetypeDisplayParams>();
  const { chosenWorldId} = useWorld();
  const { data } = useGetArchetypeDisplay(archetypeDisplayId, chosenWorldId, id);
  const archetypeDisplay = data;
  const sectionsArray = archetypeDisplay?.sections || [];
  const groupsArray = archetypeDisplay?.groups || [];

  return (
    <Layout>
      <div >
        <h1>
          { `${archetypeDisplayId}:${archetypeDisplay?.name}`}
        </h1>
        <div className={styles.editButtons}>
          <LinkButton to={`/${routeLinks.archetypeDisplays}/${archetypeDisplayId}/${routeLinks.edit}`} >Edit</LinkButton> 
        </div>
        <div>description: {`${archetypeDisplay?.description}`}</div>
        <div>display: {`${archetypeDisplay?.display}`}</div>
        <div>
          <h3>Sections</h3>
          {sectionsArray?.map((section) => {
            return (<div key={section.key}>
                  {section?.name}
              </div>)
            ;
          })}
        </div>
        <div>
          <h3>Groups</h3>
        {groupsArray?.map((section) => {
            return (<div key={section.key}>
                  {section?.name}
              </div>)
            ;
          })}
        </div>
      </div>
    </Layout>
  );
}
