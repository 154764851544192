/// Base Actions - calling external API
import { baseAPIUrl, jwtData } from 'lib/config';

export const config = {
  authToken: (jwtData && jwtData.authToken) ? jwtData.authToken : 'auth_token',
  refreshToken: (jwtData && jwtData.refreshToken) ? jwtData.refreshToken : 'refresh_token'
};

export const fullEndpoint = (endpoint : string) => {
  if( endpoint && endpoint.startsWith("http")) {
    return `/${endpoint}`;

  } else {
    return `${baseAPIUrl}/${endpoint}`;
  }
}

/// Private Functions
export const getHeaders = () => {
  // we store the auth for accessing the api in auth_token
  const token = localStorage.getItem(config.authToken);
  let headers : { Authorization?: string } = {};

  if(token) {
    headers.Authorization = `Bearer ${token}`;
  }

  return headers;
};

export const getConfig = (overrideParams = {}) => {
  return {
    headers: getHeaders(),
    ...overrideParams
  };
};
