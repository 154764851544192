import { Button, ButtonStyle } from 'components/Buttons';
import { Modal } from "components/Modal";
import styles from "./styles.module.scss";
import { TagType } from 'actions/tag.types';
import { ArchetypeDisplayType, CastMemberType } from 'actions/cast.types';
import { ShowDisplayArchetypeMember } from 'components/ArchetypeMembers/ShowArchetypeMember/BloodOfStarsArchetype';
import { useGetChosenTactics, useSetChosenTactics } from 'actions/chosenTacticsActions';
import classNames from "classnames";
import { useCallback, useMemo } from 'react';

interface GroupedTagsModalProps {
  closeModal: () => void;
  tacticCasts?: Array<CastMemberType>;
  archetypeDisplay?: ArchetypeDisplayType;
  onClickDescriptor?: (tagType?: TagType) => void;
  openDetails?: (castMember: CastMemberType) => void;
  className?: string;
}

const isTacticSelected = (id: number, chosenTacticsMap : Map<number, number>) => {
  return chosenTacticsMap.get(id);
}

export const TacticsModal = ({
  tacticCasts,
  closeModal,
  archetypeDisplay,
  onClickDescriptor,
  className
}: GroupedTagsModalProps) => {
 const { data: chosenTactics } = useGetChosenTactics();
 const saveChosenTactics = useSetChosenTactics();
  const chosenTacticsMap =useMemo(() => {
    return new Map(chosenTactics ? chosenTactics.map((ct) => { return [ct,ct]; }) : []);
  },[chosenTactics]);

  const setChosenTactics =useCallback( (tacticId: number) => {
    const foundTactic = chosenTacticsMap.get(tacticId);

    if (foundTactic) {
      chosenTacticsMap.delete(tacticId);
    } else {

      chosenTacticsMap.set(tacticId, tacticId);
    }

    saveChosenTactics(Array.from(chosenTacticsMap.values()));
  },[chosenTacticsMap, saveChosenTactics])

  const clearSelected = useCallback(() => {
    saveChosenTactics([]);
  },[saveChosenTactics]);

  const selectRandom = useCallback(() => {
    if(!tacticCasts || tacticCasts.length ===0 ){
      return [];
    }

    let foundEntries: Array<number> = [...(chosenTactics || [])];
    let numberToFind = 1; 
    let choicesMap = new Map(Array.from(Array(tacticCasts.length).keys()).map((a) => { return [a,a]}));
    foundEntries.forEach((fe) => {
      choicesMap.delete(fe-1);
    })

    let choices = Array.from(choicesMap.values());

    numberToFind = foundEntries.length >= tacticCasts.length ? 0 : 1;

    Array.from(Array(numberToFind).keys()).forEach((x, i) => {
      const firstNumber = Math.floor(Math.random()*choices.length);

      if(choices.length > 0 && firstNumber === choices.length - 1) {
        const foundEntry = choices.pop();
        if(typeof foundEntry !== "undefined") {
          foundEntries.push(foundEntry+1);
        }
      } else {
        foundEntries.push(choices[firstNumber]+1);
        choices = [...choices.slice(0,firstNumber), ...choices.slice(firstNumber+1)];
      }
    });
    saveChosenTactics(foundEntries);
  },[chosenTactics, saveChosenTactics, tacticCasts]);

  return (<Modal
    onBackgroundClick={() => { closeModal(); }}
    includeCloseButton={true}
    closeModal={() => { closeModal(); }}
  >
  <div className={styles.traitsList}>
    <h2>Tactics {chosenTactics && chosenTactics?.length > 0 ? `(${chosenTactics?.length})` : ``}</h2>
    <div className={styles.buttons}>
    <Button
        buttonStyle={ButtonStyle.secondary}
        onClick={() => { clearSelected && clearSelected(); }}
        className={styles.button}
      >
       {`Clear`}
      </Button>   
      <Button
        buttonStyle={ButtonStyle.secondary}
        onClick={() => { selectRandom && selectRandom(); }}
        className={styles.button}
      >
       {`Random`}
      </Button>   
      </div>
    <div>
      {tacticCasts?.map((tc, key) => {
        return <div className={styles.spacing} key={tc.key}>
          <div 
          onClick={() => {setChosenTactics(key+1)}}
          className={classNames(styles.tacticHeader, isTacticSelected(key+1,chosenTacticsMap) ? styles.selected : null)}
          >{key+1}: </div> <ShowDisplayArchetypeMember
              {...{ 
                displayArchetypeMember: tc,
                archetypeDisplay,
                onClickDescriptor,
                className
              }}
            />
        </div>
      })}
     </div>
  </div>
</Modal>)
};

export default TacticsModal;