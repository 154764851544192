import React, { forwardRef } from 'react';
import classNames from 'classnames';
import { FieldError } from 'react-hook-form';
import styles from './styles.module.scss';

export enum TextFieldType {
  email = 'email',
  password = 'password',
  text = 'text',
  textArea = 'textArea',
  number = 'number'
}

interface TextFieldProps {
    name: string;
    type: TextFieldType;
    label?: string;
    error?: FieldError;
    required?: boolean;
    placeholder?: string;
    className?: string;
    labelClassName?: string;
    fieldClassName?: string;
    register?: any;
    value?: any;
    onChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

interface ErrorMessageProps {
  error?: FieldError;
  className: string;
}

export const ErrorMessage = ({error, className}: ErrorMessageProps) => {
  return <div className={className}>{error && error.message}</div>
}

// Note, can't specify HTMLElement type as it could be for input or textarea, using any
export const TextField = forwardRef<any, TextFieldProps>(({
  name,
  label,
  required,
  type,
  placeholder,
  className,
  labelClassName,
  fieldClassName,
  error,
  ...restProps
 }: TextFieldProps, ref) => {
  return (
    <div className={className}>
      { (label || required) 
        && (
          <label htmlFor={name} className={classNames(styles.label, labelClassName)}>
            {label}
              {required && (<span className={styles.required}>&emsp;</span>)}
          </label>
        )
      }
      {type === TextFieldType.textArea ? (<textarea {...restProps} name={name} ref={ref} placeholder={placeholder} className={classNames(styles.field, fieldClassName)} />)
        : (<input {...restProps} type={type} name={name} ref={ref} placeholder={placeholder} className={classNames(styles.field, fieldClassName)}  />)
      }
      <div className={styles.error}>
        <ErrorMessage className={styles.alert} error={error} />
      </div>
    </div>
  );
});

export default TextField;