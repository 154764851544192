
import { useState, useCallback, useEffect, useRef } from 'react';

export interface cePropTimeout {
  timing: boolean;
  lastLoad?: number;
  startTime?: number;
  timeoutLength: number;
  timeout?: NodeJS.Timeout;
  callback: () => void;
};

export const useTimeout = (callback: () => void, timeoutLength?: number, ) => {
  const [timing, setTiming] = useState(false);

  const ce = useRef<cePropTimeout>({
    timing:false,
    startTime: undefined,
    timeoutLength:timeoutLength || 1000,    
    callback: () => {},
    timeout: undefined
  });

  useEffect(() => {
    if (ce.current) {
      ce.current.callback = callback;
    }
  }, [callback]);

  useEffect(() => {
    const ceCurrent = ce.current;
    if(timing && ceCurrent && !ceCurrent.timing) {
      ceCurrent.timing = true;
      ceCurrent.timeout = setTimeout(() => {   
        ceCurrent.callback(); 
        ceCurrent.timing = false;
        setTiming(false);
      }, ce?.current?.timeoutLength);
    } else {
      if(!timing) {
        if(ceCurrent.timeout) {
          clearTimeout(ceCurrent.timeout);
        }
        ceCurrent.timing = false;
      }
    }

    return () => {
      if(ceCurrent?.timeout) {
        clearTimeout(ceCurrent.timeout);
      }
    };
  },[timing]);

  const cancelTimeout = useCallback(() => {
    const ceCurrent = ce?.current;
    if(ceCurrent?.timeout) {
      clearTimeout(ceCurrent.timeout);
    }
    if(ceCurrent.timing) {
      ceCurrent.timing = false;
    }

    setTiming(false);
  }, [ce]);

  const startTimeout = useCallback(() => {
    setTiming(true);
  }, [setTiming]);

  const restartTimeout = useCallback(() => {
    if(ce?.current?.timeout) {
      clearTimeout(ce.current.timeout);
      ce.current.timing=false;
    }
    setTiming(true);
  }, [setTiming]);

  return {
    timing,
    cancelTimeout,
    startTimeout,
    restartTimeout,
  }
}

export interface ceIntervalProp {
  timing: boolean;
  // intervals: Array<NodeJS.Timeout>;
  lastLoad?: number;
  startTime?: number;
  timeoutLength: number;
  timeout?: NodeJS.Timeout;
  callback: () => void;
};

export const useInterval = (callback: () => void, timeoutLength?: number, startOn?: boolean) => {
  const [timing, setTiming] = useState(startOn || false);
  const [lastLoad, setLastLoad] = useState(new Date().getTime());
  // const [timeoutLength, setTimeoutLength] = useState(1000);
  const ce = useRef<ceIntervalProp>({
    timing: false,
    startTime: undefined,
    lastLoad: new Date().getTime(),
    timeoutLength:timeoutLength || 1000,
    callback: () => {},
    timeout: undefined
  });

  const reload = useCallback(() => {
    setLastLoad(new Date().getTime());
  }, [setLastLoad]);

  useEffect(() => {
    if (ce.current && ce.current.callback !== callback) {
      ce.current.callback = callback;
    }
  }, [callback]);

  useEffect(() => {
    if (ce.current && timeoutLength !== ce.current.timeoutLength && typeof timeoutLength === "number") {
      ce.current.timeoutLength = timeoutLength;
      if (ce.current.timeout && ce.current.timing) {
        clearInterval(ce.current.timeout);
        reload();
      }
    }
  }, [timeoutLength, ce, reload]);

  useEffect(() => {
    const ceCurrent = ce.current;
    if(timing && ceCurrent && !ceCurrent.timing) {
      ceCurrent.timing = true;
      ceCurrent.timeout = setInterval(() => {
        ceCurrent.callback(); 
      }, ce?.current?.timeoutLength);
    } else {
      if(!timing) {
        if(ceCurrent.timeout) {

          clearInterval(ceCurrent.timeout);
        }
        ceCurrent.timing = false;
      }
    }

    return () => {
      if(ceCurrent?.timeout) {
        clearInterval(ceCurrent.timeout);
      }
    };
  },[timing, lastLoad]);

  const cancelTimeout = useCallback(() => {
    const ceCurrent = ce?.current;
    if(ceCurrent?.timeout) {
      clearInterval(ceCurrent.timeout);
    }
    if(ceCurrent.timing) {
      ceCurrent.timing = false;
    }

    setTiming(false);
  }, [ce]);

  const startTimeout = useCallback(() => {
    setTiming(true);
  }, [setTiming]);

  const restartTimeout = useCallback(() => {
    if(ce?.current?.timeout) {
      clearInterval(ce.current.timeout);
      ce.current.timing=false;
    }
    setTiming(true);
    reload();
  }, [setTiming, reload]);

  return {
    timing,
    cancelTimeout,
    startTimeout,
    restartTimeout,
  }

}

// const useFlash = (onFlashed) => {
//   const [flashing, setFlashing] = useState(false);
//   const [justFlashing, setJustFlashing] = useState(false);
// 
//   const ce = useRef<ceProp>({
//     time:0,
//     startTime:0,
//     justFlashing: false,
//     postSwipeStarted: false,
//     transitionTime: 0,
//   });
// 
//   const setFlash = useCallback(() => {
//     setJustFlashing(true);
//   }, []);
//   const justSwiped = ce?.current?.justFlashing || false;
// 
//   useEffect(() => {
//     const ceCurrent = ce.current;
//     if(justFlashing) {
//       ce.current.postFlashStarted = true;
//       if(ce.current.timeout) {
//         clearTimeout(ce.current.timeout);
//       }
// 
//       ce.current.timeout = setTimeout(() => {   
//         if(ce?.current?.justFlashing) {
//           ce.current.justFlashing = false;
//         } 
//         if(ce?.current?.postFlashStarted) {
//           ce.current.postFlashStarted = false;
//         } 
//         if(justFlashing) {        
//           setJustFlashing(false);
//         }
//         
//       }, ce?.current?.transitionTime);
//     } else {
//     }
// 
//     return () => {
//       if(ceCurrent?.timeout) {
//         clearTimeout(ceCurrent.timeout);
//       }
//     };
//   }, [justFlashing]);
// 
//   return {
//     flashing: flashing,
//     setFlash
//   }
// 
// }
