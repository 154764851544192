import { useCallback, useMemo, useState } from "react";
import Layout from "Layout";
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { useCreateLocalCast } from "actions/localCastActions";
import { useGetArchetypes } from "actions/archetypeActions";
import styles from './styles.module.scss';
import { Button } from 'components/Buttons';
import { TextField, TextFieldType } from 'components/Forms/TextField';
import { Select } from 'components/Forms/Select';
import { useForm } from 'react-hook-form';
import { Form } from 'components/Forms/Form';
import { routeLinks } from "App/routing/routeLinks"
import { useWorld } from "hooks/worldHooks";
import { useGetCurrentUser } from "hooks/userHooks";

interface FormValuesType {
  name?: string;
  description?: string;
  archetypeId?: number;
}

export type CastPageParams = {
  id: string;
  archetypeId?: string;
};

// name: string;
//description?: string;
export const NewLocalCast = () => {
  const { id: castId, archetypeId } = useParams<CastPageParams>();
  const { id } = useGetCurrentUser(); 
  const { register, handleSubmit, formState } = useForm({
    defaultValues: { name: '', description: '', archetypeId: undefined},
  });

  const navigate = useNavigate();

  const createCast = useCreateLocalCast();
  const { chosenWorldId } = useWorld();
  const { data: archetypes } = useGetArchetypes(undefined, undefined, chosenWorldId, id );

  const foundArchetype = useMemo(() => {
    let foundArchetypeId: number;
    let fullFoundArcetype;
    if(archetypeId) {
      try {
        foundArchetypeId = parseInt(archetypeId, 10);
        fullFoundArcetype = archetypes?.find((a) => {
          return a.id === foundArchetypeId;
        })
      } catch(err) {
      }
    }
    return fullFoundArcetype;
  },[archetypes, archetypeId]);

  const [isSubmitting, setSubmitting] = useState(false);
  const [, setSubmitError] = useState<any>();

  const onSubmit = useCallback(async (cast: FormValuesType) => {
      const newEntry = {
        userId: id,
        name: cast.name,
        description: cast.description,
        data: {},
        archetypeId: foundArchetype?.id || cast?.archetypeId 
      };
      const createdCast = await createCast(newEntry, undefined, castId);
      return createdCast;
  }, [id, castId, createCast, foundArchetype?.id]);

  const errors = formState?.errors;

  return (
    <Layout>
      <Form
         onSubmit={handleSubmit(async (vals: FormValuesType) => { 
          setSubmitting(true);
          try {
            await onSubmit(vals); 
          
            setSubmitting(false);
            navigate(`/${routeLinks.guestTeams}/${castId}/${routeLinks.edit}`);
          } catch(err) {

            setSubmitError(err);
            setSubmitting(false);
          }
        })} 
      >
      <div >
        <div className={styles.sectionHeader}>
          <h1>
            Teams
          </h1>
          <div className={styles.updateButtons}>
            <Button
              disabled={isSubmitting} 
              type="submit"
              className={styles.submitButton}
            >
              {`Save`}
            </Button>
          </div>
        </div>
        <div className={styles.entries}>
          {foundArchetype ? (
              <div className={styles.fieldWrapper}><div className={styles.labelHeader}>Archetype:</div> {foundArchetype.name}</div>
            ) : <Select
              placeholder={"Archetype"}
              type={"select"}
              label="Archetype"
              className={styles.fieldWrapper} 
              fieldClassName={styles.field}
              labelClassName={styles.labels}
              error={errors?.description}
              options={archetypes?.map((a) => {
                return { value: `${a.id}`, label: a.name};
              })}
              {...register("archetypeId", {
              })}
            />
          }
          <TextField
            type={TextFieldType.text}
            placeholder={"Name"}
            label="Name"
            className={styles.fieldWrapper}
            fieldClassName={styles.field}
            labelClassName={styles.labels}
            error={errors?.name}
            {...register("name", {
            })}
          />
          <TextField
            type={TextFieldType.text}
            placeholder={"Description"}
            label="Description"
            className={styles.fieldWrapper}
            fieldClassName={styles.field}
            labelClassName={styles.labels}
            error={errors?.description}
            {...register("description", {
            })}
          />
        </div>
      </div>
      </Form>
    </Layout>
  );
}


export default NewLocalCast;