import { useCallback, useState } from "react";
import classNames from "classnames";

import { XSwipeableAndYCounter, swipedDirectionType, axisType } from 'components/Swipeable2';
import { TagType } from "actions/types";

import { LinkButton, Button, ButtonStyle } from 'components/Buttons';
import { Modal } from "components/Modal";

import { UpdateCastMemberType, CastMemberType, ArchetypeDisplayType } from "actions/cast.types";
import { useGroupCasts  } from 'hooks/archetypeMemberHooks';
import { ArchetypeEntry } from "./components/ArchetypeEntry";
import { CastEntry } from "./components/CastEntry";
import { EditCastMemberEntry } from "./components/CastMemberEntry";
import { EditCastDetailsModal } from "./components/EditCastDetailsModal";
import { TagDescriptorModal, useTagDescriptorModal } from "components/ArchetypeMembers/TagDescriptorModal";
import { GroupedTagsModal } from "components/Modals/GroupedTagsModal";

import styles from "./styles.module.scss";
import { CastType } from "actions/cast.types";
import { TagGroupingType } from "actions/tag.types";
import UpdateLinkCastModal from "components/Modals/UpdateLinkCastModal";
import { DivCutCornerBorder } from "components/DivCutCornerBorder";

import { useShrinkList, ShrinkButton } from "components/ShrinkList";
import HelpModal from "components/Modals/HelpModal";
import UpOrDownCounter from "./components/UpOrDownCounter";

interface EditCastProps {
    displayCast?: Array<CastMemberType>;
    displayArchetypeMembers?: Array<CastMemberType>; 
    archetypeDisplay?: ArchetypeDisplayType;
    editArchetypeDisplay?: ArchetypeDisplayType;
    tags?: Array<TagType>;
    editTagOptions?: Array<TagType>;
    totalPoints: number;
    totalUnits?: number;
    castId: string;
    name?: string;
    workingCast?: CastType;
    updateNumberOfMembersInWorkingCast?: (castMember: CastMemberType, amount: number) => void;
    updateCastMemberInWorkingCast?: (castMember: CastMemberType, castMemberUpdates: UpdateCastMemberType) => void;
    addArchetypeToWorkingCast?: (castMember: CastMemberType, amount: number) => void;
    updateOrderInWorkingCast?: (castMember: CastMemberType, amount: number) => void;
    updateWorkingCast?: (castMember: CastType, excludeCast?: boolean) => void;
    saveWorkingCast: () => void;
    viewLinkTo: string;
    groupedTags?: Array<TagGroupingType>;
    isAdmin?: boolean;
    isGuest?: boolean; 
}

const MAX_COUNTER = 12;
const MAX_STEP = 8
const CANCEL_SELECTOR: string = 'cancelSelector';

export const EditCastPresentation = ({
  name,
  displayCast,
  workingCast,
  displayArchetypeMembers,
  archetypeDisplay,
  editArchetypeDisplay,
  editTagOptions,
  totalUnits,
  totalPoints,
  castId,
  updateNumberOfMembersInWorkingCast,
  updateCastMemberInWorkingCast,
  addArchetypeToWorkingCast,
  updateOrderInWorkingCast,
  updateWorkingCast,
  saveWorkingCast,
  viewLinkTo,
  groupedTags,
  isAdmin,
  isGuest
}: EditCastProps) => {
  const onSwipedArchetype = useCallback((castMember: CastMemberType, direction: swipedDirectionType, amount: number) => {
    const modAmount = amount === 0 ? 1 : Math.abs(amount);
    const amountMod = swipedDirectionType.left === direction ? -1 : 1;
    addArchetypeToWorkingCast && addArchetypeToWorkingCast(castMember,  Math.abs(modAmount) * amountMod);
  }, [addArchetypeToWorkingCast]);

  const onSwipedCast = useCallback((castMember: CastMemberType, direction: swipedDirectionType, amount: number) => {
    const modAmount = amount === 0 ? 1 : amount;
    const amountMod = swipedDirectionType.left === direction ? -1 : 1;
      updateNumberOfMembersInWorkingCast && updateNumberOfMembersInWorkingCast(castMember,  Math.abs(modAmount) * amountMod);
  }, [updateNumberOfMembersInWorkingCast]);

  const onDraggedCast = useCallback((castMember: CastMemberType, x: number, y: number, amount: number) => {
    const yDirection = y > 0 ? 1 : -1;
    const arrayChange = yDirection * Math.abs(amount);
    updateOrderInWorkingCast && updateOrderInWorkingCast(castMember, arrayChange); 
  }, [updateOrderInWorkingCast]);

  const onSaveWorkingCast = () => {
    saveWorkingCast();
  }

  // Update Cast Member
  const onUpdateCastMember = (castMember: CastMemberType, castMemberUpdates?: UpdateCastMemberType) => {
    if(castMemberUpdates) {
      updateCastMemberInWorkingCast && updateCastMemberInWorkingCast(castMember, castMemberUpdates);
    }
  }
  
  const [mobileShowTeam, setMobileShowteam ] = useState<boolean>(true);
  const [editingCastEntry, setEditingCastEntry] = useState<CastType | undefined>();
  const [editingCastMemberEntry, setEditingCastMemberEntry] = useState<CastMemberType | undefined>();
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [openUpOrDownModalEntry, setOpenUpOrDownModalEntry] = useState<CastMemberType | undefined>();

  //showingTagDescriptor
  const { showTagDescriptor, setShowTagDescriptor, onClickDescriptor } = useTagDescriptorModal();

  const { displayGroups: displayArchetypeGroups } = useGroupCasts(displayArchetypeMembers || [], archetypeDisplay);
  const { displayGroups: displayCastGroups } = useGroupCasts(displayCast || [], archetypeDisplay);
  const [showTraitsModal, setShowTraitsModal] = useState(false);
  const [showLinkModal, setShowLinkModal] = useState(false);


  const showingModal = showLinkModal || showTraitsModal || editingCastEntry || editingCastMemberEntry ? true : false;

  const { toggleShrink, toggleShrinkAll, shrinkList, allShrunk } = useShrinkList(displayCast, displayArchetypeMembers);

  return (
    <div>
      <div className={styles.sectionHeader}>
        <h1>
            { `${name}`} 
        </h1>
        <div className={styles.headerDetails}>
          <div className={styles.headerPoints}>Points: {totalPoints}</div>
          <div className={styles.helpButton} onClick={() => { setShowHelpModal(!showHelpModal); }}>?</div>
        </div>
        <div className={styles.editCast}>
          <ShrinkButton {...{toggleShrinkAll, allShrunk, className: classNames(styles.editButton, styles.mobileHide) }} />
          <Button 
            className={styles.editButton}
            onClick={() => { 
              setShowTraitsModal(!showTraitsModal);
            }}
          >Traits</Button>
          <LinkButton 
            to={viewLinkTo}
            className={styles.editButton}
          >List View</LinkButton>
          <Button 
            onClick={() => {
                setEditingCastEntry(workingCast);
            }}
            className={styles.editButton} 
          >Update Details</Button>
          <Button 
              className={styles.editButton}
              onClick={() => {
                setShowLinkModal(true);
              }}
          >Code</Button>
          <Button 
            onClick={onSaveWorkingCast}
            className={styles.editButton}
          >Save</Button>
        </div>
      </div>
      <div className={classNames(styles.mobileSelectFooter,(editingCastMemberEntry || editingCastEntry || showTagDescriptor || showingModal) && styles.mobileHide)}>
            <Button
              onClick={() => {
                setMobileShowteam(false);
              }}
              className={classNames(!mobileShowTeam && styles.mobileHighlight, styles.mobileSelectButton)}
            >Faction</Button>
            <Button
              onClick={() => {
                setMobileShowteam(true);
              }} 
              className={classNames(mobileShowTeam && styles.mobileHighlight, styles.mobileSelectButton)}
            >Team</Button>
            <DivCutCornerBorder
              className={classNames(styles.mobileSelect)}
              backgroundClassName={styles.mobilePointsBackground}
            ><div className={styles.mobilePoints}>Pts: {totalPoints} ({totalUnits})</div></DivCutCornerBorder>
                      <ShrinkButton {...{toggleShrinkAll, allShrunk, }} />
      </div>
      <div className={styles.editContainer}>
        <div className={classNames(styles.displayMembers, styles.container, mobileShowTeam && styles.mobileHide)}>

          <div className={styles.castHeader}>
            <h2>Faction</h2>
          </div>
          <div>
            {displayArchetypeGroups?.map((dg)=> {
              return (
                <div className={styles.archetypeGroupWrapper} key={dg.key}>
                  <h4 className={styles.groupName}>{dg.name}</h4>
                  <div className={styles.archetypeMembersWrapper}>
                    {dg.casts?.map((ad) => {
                      return <XSwipeableAndYCounter
                        key={ad.key} 
                        axis={axisType.x}
                        pixelYStep={0.5/MAX_STEP}
                        pixelYStepInPercent={true}
                        maxCounter={MAX_COUNTER}
                        onSwiped={(direction, amount) => { onSwipedArchetype(ad, direction, amount); }}
                        cancelSelector={`.${CANCEL_SELECTOR}`}
                        classNameDragging={styles.dragging}
                        classNameJustDragged={styles.dragged}
                      >
                        <ArchetypeEntry
                          key={ad.key}
                          castMember={ad}
                          archetypeDisplay={archetypeDisplay}
                          cancelSelector={CANCEL_SELECTOR}
                          onClickDescriptor={onClickDescriptor}
                          isShrunk={shrinkList.get(ad.key) ? true : false}
                          toggleShrink={() => { toggleShrink(ad.key)}}
                        />
                      </XSwipeableAndYCounter>
                    })}
                  </div>
                </div>
              )
            })}  
          </div>
        </div>
        <div className={classNames(styles.cast, styles.container, !mobileShowTeam && styles.mobileHide)}>
          <div className={styles.castHeader}>
            <h2>Team</h2>
            <div className={styles.castPoints}>Units: {totalUnits}</div>
            <div className={styles.castPoints}>Points: {totalPoints}</div>
          </div>
          <div>
            {displayCastGroups?.map((dg)=> {
              return (
                <div className={styles.archetypeGroupWrapper} key={dg.key}>
                  <h4 className={styles.groupName}>{dg.name}</h4>
                  <div className={styles.archetypeMembersWrapper}>
                    {dg.casts?.map((dc) => {
                      return (<XSwipeableAndYCounter 
                        key={dc.key}
                        pixelYStep={0.5/MAX_STEP}
                        pixelYStepInPercent={true}
                        maxCounter={MAX_COUNTER}
                        axis={axisType.x} 
                        onDragged={(x, y, count) => { onDraggedCast(dc, x, y, count); }}
                        onSwiped={(direction, amount) => { onSwipedCast(dc, direction, amount); }}
                      >
                          <CastEntry 
                            key={dc.key} 
                            castMember={dc.calculatedCast || dc} 
                            archetypeDisplay={archetypeDisplay} 
                            cancelSelector={CANCEL_SELECTOR}
                            openEdit={() => {
                              setEditingCastMemberEntry(dc);
                            }}

                            openUpOrDown={() => {
                              setOpenUpOrDownModalEntry(dc);
                            }}
                            onClickDescriptor={onClickDescriptor}
                            isShrunk={shrinkList.get(dc.key) ? true : false}
                            toggleShrink={() => { toggleShrink(dc.key)}}
                            numberOfEntries={dg.casts?.length || 0}
                          />
                        </XSwipeableAndYCounter>
                      );
                    })}
                  </div>
                </div>
              )
            })}  
          </div>
        </div>
      </div>
      {editingCastMemberEntry && (
        <Modal>
          <EditCastMemberEntry 
            key={editingCastMemberEntry.key} 
            castMember={editingCastMemberEntry} 
            archetypeDisplay={editArchetypeDisplay} 
            editTagOptions={editTagOptions}
            saveCastMemberEntry={(castMember: CastMemberType, updateCastMemberType?: UpdateCastMemberType) => {
              onUpdateCastMember(castMember, updateCastMemberType);
            }}
            closeEdit={() => {
              setEditingCastMemberEntry(undefined);
            }}
            onClickDescriptor={onClickDescriptor}
          /> 
        </Modal>
      )}
      {openUpOrDownModalEntry && (
        <Modal
          onBackgroundClick={() => {setOpenUpOrDownModalEntry(undefined)}}
        >
          <div className={styles.upOrDownModal}>
          <Button
              buttonStyle={ButtonStyle.secondary}
              className={styles.viewUpDownButton}
            >
          <XSwipeableAndYCounter 
                        key={openUpOrDownModalEntry.key}
                        pixelYStep={0.5/MAX_STEP}
                        pixelYStepInPercent={true}
                        maxCounter={MAX_COUNTER}
                        axis={axisType.yCount} 
                        onDragged={(x, y, count) => {
                            onDraggedCast(openUpOrDownModalEntry, x, y, count);
                            setOpenUpOrDownModalEntry(undefined); 
                        }}
                        onSwiped={(direction, amount) => { 
                          onSwipedCast(openUpOrDownModalEntry, direction, amount);
                          setOpenUpOrDownModalEntry(undefined);
                        }}
                        CounterComponent={UpOrDownCounter}
                      >

            <div>Drag up or down to move that many slots. ↑ ↓</div>
          </XSwipeableAndYCounter>
            </Button>
          </div>
        </Modal>
      )}
      { editingCastEntry && workingCast && updateWorkingCast && <EditCastDetailsModal
          cast={workingCast}
          updateWorkingCast={updateWorkingCast}
          closeEdit={() => {
            setEditingCastEntry(undefined);
          }}
          isAdmin={isAdmin} 
        />
      }
      { showTagDescriptor && <TagDescriptorModal
          tag={showTagDescriptor}
          close={() => {
            setShowTagDescriptor(undefined)
          }} 
        />
      }
      {!showTraitsModal ? null : <GroupedTagsModal
        closeModal={() => {
          setShowTraitsModal(false);
        }}
        groupedTags={groupedTags}
      />}
      {!showLinkModal ? null : <UpdateLinkCastModal
        closeModal={() => {
          setShowLinkModal(false);
        }}
          workingCast={workingCast}
          updateWorkingCast={updateWorkingCast}
      />}
      {!showHelpModal ? null : <HelpModal
        closeModal={() => {
          setShowHelpModal(false);
        }}
      />}
    </div>
  );
}
