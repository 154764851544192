import { useState } from 'react';
import { CastMemberType } from 'actions/cast.types';
// extras
import { Button } from 'components/Buttons';
import classNames from 'classnames';

export const useShrinkList = (displayCast?: CastMemberType[], displayArchetypeMembers?: CastMemberType[]) => {
  const [shrinkList, setShrinkList] = useState<Map<string,string>>(new Map<string,string>());

  const totalShrinkable = (displayCast?.length || 0)  + (displayArchetypeMembers?.length || 0);

  const allShrunk = shrinkList.size >= totalShrinkable;

  const toggleShrink = (key: string) => {
    const existsKey = shrinkList.get(key);
    const newList = new Map(shrinkList);
    if(existsKey) {
      newList.delete(key);
    } else {
      newList.set(key,key);
    }
    setShrinkList(newList);
  }

  const toggleShrinkAll = () => {
    if(allShrunk) {
      setShrinkList(new Map());
    } else {
      const newMap = new Map(shrinkList);
      displayCast?.forEach((dc) => {
        newMap.set(dc.key,dc.key);
      });
      displayArchetypeMembers?.forEach((dam) => {
        newMap.set(dam.key, dam.key);
      });
      setShrinkList(newMap);
    }
  }

  const isShrunk = (key?: string) => {
    return key && shrinkList.get(key) ? true : false;
  }

  return {
    shrinkList,
    totalShrinkable,
    allShrunk,
    toggleShrink,
    toggleShrinkAll,
    isShrunk,
  };
}

interface ShrinkButtonProps {
    allShrunk?: boolean;
   toggleShrinkAll?: () =>  void;
   className?: string;
}

export const ShrinkButton = ({allShrunk, toggleShrinkAll, className}: ShrinkButtonProps) => {

  return <Button 
  className={classNames(className)}
  onClick={() => { 
    toggleShrinkAll && toggleShrinkAll();
  }}
  >{allShrunk ? `expand` : `shrink`}</Button>
}