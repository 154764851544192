import React, { useState, ReactNode } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { CastMemberType, ArchetypeDisplayType, ArchetypeSectionType } from 'actions/cast.types';
import { TagModifierType, DisplayTagType, TagType } from 'actions/tag.types';
import { useDisplayArchetypeMember } from "hooks/archetypeMemberHooks";
import { ValTagDescription } from "./../ValTagDescription"
import { DivCutCornerBorder } from "components/DivCutCornerBorder"
import { getTagDisplayName } from "lib/tag.helpers";
import { getTags } from "components/ArchetypeMembers/ShowArchetypeMember";
import { getTagKey } from 'actions/helpers/tagHelpers';
import { useSearchParams } from 'react-router-dom';
import { StatTags, SectionTagKey, StandingType } from "lib/archetype.helpers";

enum DisplayType {
  bookView = "bookView",
  default = "defaultView"
}

interface TagStatsProps {
  tag?: TagType;
  alwaysShowVal?: boolean;
  description?: string;
  valDescription?: Array<TagType>;
  children?: ReactNode;
  onClickDescriptor?: (tag?: TagType) => void;
  pressToHold?: boolean;
}

const AddOnClickDescriptor = ({
  tag,
  children,
  onClickDescriptor}:TagStatsProps) => {
  const [showTagDescription, setShowTagDescription] = useState(false);

  if (!tag) {
    return <div className={styles.entryWrapper}>
      {children}
      </div>
  }

  return <div
    onClick={() => {
       onClickDescriptor ? onClickDescriptor(tag) : setShowTagDescription(true); 
      }}
    onMouseDown={() => {
     //  onClickDescriptor ? onClickDescriptor(tag) : setShowTagDescription(true); 

      }}
    onTouchStart={() => {
     // onClickDescriptor ? onClickDescriptor(tag) : setShowTagDescription(true);
     }}
    onMouseUp={() => { 
      // false && pressToHold && (onClickDescriptor ? onClickDescriptor(undefined) : setShowTagDescription(false)); 
    }}
    onMouseLeave={() => { 
      //onClickDescriptor ? onClickDescriptor(undefined) : setShowTagDescription(false); 
    }}
    onTouchEnd={() => {
      //pressToHold && onClickDescriptor ? onClickDescriptor(undefined) :  setShowTagDescription(false);
     }}
    onTouchMove={() => { 
      //pressToHold && onClickDescriptor ? onClickDescriptor(undefined) :  setShowTagDescription(false); 
    }}
    className={styles.entryWrapper}
  >
    {children}
    {
      ( showTagDescription) && 
        <ValTagDescription tag={tag} />
    }
  </div>
}

interface DisplayTraitProps {
  tag: DisplayTagType;
  className?: string;
}

const DisplayTrait = ({tag, className}: DisplayTraitProps) => {
  return <div className={className}>
    {getTagDisplayName(tag)}
  </div>
}

interface ShowArchetypeProps {
  archetypeMember: CastMemberType;
  archetypeDisplay?: ArchetypeDisplayType;
  tagModifiers?: Array<TagModifierType>;
  tagDescriptionsMap?: Map<string, TagType>;
  displayType?: DisplayType;
  onClickDescriptor?: (tag?: TagType) => void;
  className?: string;
  isShrunk?: boolean;
  toggleShrink?: () => void;
}

interface ShowDisplayArchetypeMemberProps {
  displayArchetypeMember: CastMemberType;
  archetypeDisplay?: ArchetypeDisplayType;
  onClickDescriptor?: (tag?: TagType) => void;
  className?: string;
  showPtsFractions?: boolean;
  isShrunk?: boolean;
  toggleShrink?: () => void;
  printMode?: boolean;
}

interface ShowDisplayArchetypeMemberTacticProps {
  name: string;
  subName: string;
  sections?: ArchetypeSectionType[];
  standingTag?: TagType;
  pts?: string | number | (string | number)[];
  ptsTag?: TagType;
  stats: DisplayTagType[];
  abilityTraits: DisplayTagType[];
  statTraits: DisplayTagType[];
  traitConditions: DisplayTagType[];
  injuries: DisplayTagType[];
  factionCampaignUpgrades: DisplayTagType[];
  upgrades: DisplayTagType[];
  displayArchetypeMember: CastMemberType;
  archetypeDisplay?: ArchetypeDisplayType;
  onClickDescriptor?: (tag?: TagType) => void;
  tacticOptions: DisplayTagType[];
  className?: string;
  toggleShrink?: () => void;
  printMode?: boolean;
};

export const ShowDisplayArchetypeMemberTactic = ({ 
  name,
  subName,
  pts,
  ptsTag,
  upgrades,
  displayArchetypeMember,
  onClickDescriptor,
  tacticOptions,
  className, 
  toggleShrink,
  printMode 
}: ShowDisplayArchetypeMemberTacticProps ) => {
  const archetypeKey = displayArchetypeMember.archetypeKey || displayArchetypeMember.key;

  const mainTag = displayArchetypeMember?.tagsMap?.get(archetypeKey) || {
    key: displayArchetypeMember?.key,
    title: displayArchetypeMember.name,
    description: displayArchetypeMember?.description,
    val: displayArchetypeMember?.description
  };

  return (<div className={classNames(styles.archetypeMemberGroup, className)}>
    <div className={classNames(styles.baseWrapper, styles.descriptionWrapper)}>
      <div className={styles.nameWrapper}>
        <div className={classNames(styles.nameBox,  printMode && styles.lineStyleTactic)}>
          {name}{subName ? ` (${subName})`: ``}
          {!printMode && <div className={styles.expandSpace}><div onClick={toggleShrink} className={styles.expandButton}>-</div></div>}
        </div>
        <div className={styles.ptsBox}>
          <AddOnClickDescriptor tag={ptsTag} onClickDescriptor={onClickDescriptor} >
          <div className={classNames(styles.header, styles.ptsHeader)}>
            Pts
          </div>
          <div className={styles.ptsText}>
            {pts}
          </div>
          </AddOnClickDescriptor>
        </div>
      </div>
    </div>
    <div className={styles.traitsOuterBox}>
    <DivCutCornerBorder
      className={styles.traitsBoxWrapper}
      backgroundClassName={classNames(styles.backgroundTraitsBoxWrapper, styles.backgroundDescriptionBoxWrapper)}
      maskChildren={false}
    >
      <div className={styles.traitsBox}>
        <div  className={classNames(styles.skillTraitsWrapper, styles.easeRightMargin)}>
          {mainTag.description}
        </div>
      </div>
      {upgrades && upgrades.length > 0 && <div className={styles.traitsBox}>
       <div className={styles.traitsHeader}>
         Traits 
        </div>
        {upgrades && upgrades.length > 0 && <div className={styles.skillTraitsWrapper}>
          {upgrades.map((st,key) => {
            return (
              <AddOnClickDescriptor key={getTagKey(st)} tag={st}  onClickDescriptor={onClickDescriptor} >
                {key > 0 ? `, ` : ``}
                <div className={styles.traitEntry}>{st.label}</div>
              </AddOnClickDescriptor>
            )
          })}
        </div>}
      </div>}
        {tacticOptions && tacticOptions.length > 0 && <div className={styles.traitsBox}>
       <div className={styles.traitsHeader}>
         Options 
        </div>
        {tacticOptions && tacticOptions.length > 0 && <div className={styles.skillTraitsWrapper}>
          {tacticOptions.map((st,key) => {
            return (
              <AddOnClickDescriptor key={getTagKey(st)} tag={st}  onClickDescriptor={onClickDescriptor} >
                {key > 0 ? `, ` : ``}
                <div className={styles.traitEntry}>{st.label}</div>
              </AddOnClickDescriptor>
            )
          })}
        </div>}
      </div>}
    </DivCutCornerBorder>
    </div>
  </div>);
};

export const ShowDisplayArchetypeMember = ({ 
  displayArchetypeMember,
  archetypeDisplay,
  onClickDescriptor,
  className,
  showPtsFractions,
  isShrunk,
  toggleShrink,
  printMode
}: ShowDisplayArchetypeMemberProps ) => {
  const {
    name,
    subName,
    pts,
    ptsTag  } = useBloodOfStarsMembers({displayArchetypeMember, archetypeDisplay, showPtsFractions});

  const unitDescriptor = {
    key: displayArchetypeMember?.key,
    title: `${name}${subName ? ` (${subName})`: ``}`,
    description: displayArchetypeMember?.description
  }

  if(!isShrunk) {
    return <ShowDisplayArchetypeMemberExpanded
      {...{displayArchetypeMember, archetypeDisplay, onClickDescriptor, className, showPtsFractions, toggleShrink, printMode }} 
    />
  }

  return (<div className={classNames(styles.archetypeMemberGroup, className)}>
        <div className={styles.traitsOuterBoxShrunk}>

    <DivCutCornerBorder
      className={styles.traitsBoxWrapper}
      backgroundClassName={styles.backgroundTraitsBoxWrapperClosed}
      maskChildren={false}
    >
      <div>
        <div className={styles.nameWrapper}>
          <div className={classNames(styles.nameBox,  printMode && styles.lineStyle)} >
            { unitDescriptor.description ? (
               <AddOnClickDescriptor tag={unitDescriptor} onClickDescriptor={onClickDescriptor} >
                {name}{subName ? ` (${subName})`: ``}
               </AddOnClickDescriptor>)
            : (<>{name}{subName ? ` (${subName})`: ``}</>)
            }
            {!printMode && <div className={styles.expandSpace}><div onClick={toggleShrink} className={styles.expandButton}>+</div></div>}
          </div>
          <div className={styles.ptsBoxClosed}>
            <AddOnClickDescriptor tag={ptsTag} onClickDescriptor={onClickDescriptor} >
            <div className={classNames(styles.header, styles.ptsHeaderClosed)}>
              Pts
            </div>
            <div className={styles.ptsText}>
              {pts}
            </div>
            </AddOnClickDescriptor>
          </div>
        </div>
      </div>
    </DivCutCornerBorder>
    </div>
  </div>);
};

export const ShowDisplayArchetypeMemberExpanded = ({ 
  displayArchetypeMember,
  archetypeDisplay,
  onClickDescriptor,
  className,
  showPtsFractions,
  toggleShrink,
  printMode
}: ShowDisplayArchetypeMemberProps ) => {
  const {
    name,
    subName,
    sections,
    standingTag,
    pts,
    ptsTag,
    stats, 
    abilityTraits,
    fightTraits,
    shootTraits,
    statTraits,
    traitConditions,
    injuries,
    factionCampaignUpgrades,
    gear,
    factionSpecialRules,
    upgrades,
    tacticOptions
  } = useBloodOfStarsMembers({displayArchetypeMember, archetypeDisplay, showPtsFractions});

  const unitDescriptor = {
    key: displayArchetypeMember?.key,
    title: `${name}${subName ? ` (${subName})`: ``}`,
    description: displayArchetypeMember?.description
  }

  if( standingTag?.val) {
    switch(standingTag.val) { 
      case StandingType.team:
      case StandingType.tactic:
        return <ShowDisplayArchetypeMemberTactic
          {...{
            name,
            subName,
            sections,
            standingTag,
            pts,
            ptsTag,
            stats, 
            abilityTraits,
            fightTraits,
            shootTraits,
            statTraits,
            traitConditions,
            injuries,
            factionCampaignUpgrades,
            upgrades,
            displayArchetypeMember,
            archetypeDisplay,
            className,
            onClickDescriptor,
            tacticOptions,
            toggleShrink,
            printMode
          }}
        />
      default:
        // do nothing
    }
  }

  return (<div className={classNames(styles.archetypeMemberGroup, className)}>
    <div className={styles.baseWrapper}>
      <div className={styles.nameWrapper}>
        <div className={classNames(styles.nameBox, printMode && styles.lineStyle)}>
          { unitDescriptor.description ? (
             <AddOnClickDescriptor tag={unitDescriptor} onClickDescriptor={onClickDescriptor} >
              {name}{subName ? ` (${subName})`: ``}
             </AddOnClickDescriptor>)
          : (<>{name}{subName ? ` (${subName})`: ``}</>)
          }
          {!printMode && <div className={styles.expandSpace}><div onClick={toggleShrink} className={styles.expandButton}>-</div></div>}
        </div>
        <div className={styles.ptsBox}>
          <AddOnClickDescriptor tag={ptsTag} onClickDescriptor={onClickDescriptor} >
          <div className={classNames(styles.header, styles.ptsHeader)}>
            Pts
          </div>
          <div className={styles.ptsText}>
            {pts}
          </div>
          </AddOnClickDescriptor>
        </div>
      </div>
      <div className={styles.statsBox}>
        {stats.map((s) => {
          return (<div key={getTagKey(s)} className={styles.statBox}>
            <AddOnClickDescriptor tag={s}  onClickDescriptor={onClickDescriptor} >
            <div className={styles.header}>
              {s.label ? s.label.substring(0,1) : ``}
            </div>
            <div className={styles.statVal}>
              {s.val}
            </div>
            </AddOnClickDescriptor>
          </div>)
        })}
      </div>
    </div>
    <div className={styles.traitsOuterBox}>
    <DivCutCornerBorder
      className={styles.traitsBoxWrapper}
      backgroundClassName={styles.backgroundTraitsBoxWrapper}
      maskChildren={false}
    >
      <div className={styles.traitsBox}>
        {statTraits && statTraits.length > 0 && (
          <div  className={styles.statTraitsWrapper}>
            Ct: {statTraits.map((st,key) => {
              return (
               <AddOnClickDescriptor key={getTagKey(st)} tag={st}  onClickDescriptor={onClickDescriptor} >
                  {key > 0 ? `, ` : ``}
                  <div className={styles.traitEntry}>{st.label}</div>
                </AddOnClickDescriptor>
              )

            })}
          </div>
        )}
        {abilityTraits && abilityTraits.length > 0 && (
          <div className={styles.skillTraitsWrapper}>
          At: {abilityTraits.map((st,key) => {
              return (
                <AddOnClickDescriptor key={getTagKey(st)} tag={st}  onClickDescriptor={onClickDescriptor} >
                  {key > 0 ? `, ` : ``}
                  <div className={styles.traitEntry}>{st.label}</div>
                </AddOnClickDescriptor>
              )
            })}
          </div>
        )}
        {fightTraits && fightTraits.length > 0 && (
          <div className={styles.skillTraitsWrapper}>
          Ft: {fightTraits.map((st,key) => {
              return (
                <AddOnClickDescriptor key={getTagKey(st)} tag={st}  onClickDescriptor={onClickDescriptor} >
                  {key > 0 ? `, ` : ``}
                  <div className={styles.traitEntry}>{st.label}</div>
                </AddOnClickDescriptor>
              )
            })}
          </div>
        )}
        {shootTraits && shootTraits.length > 0 && (
          <div className={styles.skillTraitsWrapper}>
          St: {shootTraits.map((st,key) => {
              return (
                <AddOnClickDescriptor key={getTagKey(st)} tag={st}  onClickDescriptor={onClickDescriptor} >
                  {key > 0 ? `, ` : ``}
                  <div className={styles.traitEntry}>{st.label}</div>
                </AddOnClickDescriptor>
              )
            })}
          </div>
        )}
        {traitConditions && traitConditions.length > 0 && <div className={styles.skillTraitsWrapper}>
          {traitConditions.map((st,key) => {
            return (
              <AddOnClickDescriptor key={getTagKey(st)} tag={st}  onClickDescriptor={onClickDescriptor}  >
                {key > 0 ? `, ` : ``}
                <DisplayTrait className={styles.traitEntry} tag={st} />
              </AddOnClickDescriptor>
            )
          })}
        </div>}
        {(
          (factionCampaignUpgrades && factionCampaignUpgrades.length > 0) ||
          (factionSpecialRules && factionSpecialRules.length > 0) ||
          (gear && gear.length > 0) ||
          (injuries && injuries.length > 0) ||
          (upgrades && upgrades.length > 0)
        ) &&
          <div 
            className={classNames(styles.skillTraitsWrapper)}
          >
            <div className={styles.upgradesLine}></div>
            <div>
              {factionSpecialRules && factionSpecialRules.length > 0 && <div className={styles.skillTraitsWrapper}>
                {factionSpecialRules.map((st,key) => {
                  return (
                    <AddOnClickDescriptor key={getTagKey(st)} tag={st}  onClickDescriptor={onClickDescriptor} >
                      {key > 0 ? `, ` : ``}
                      <div className={styles.traitEntry}>{st.label}</div>
                    </AddOnClickDescriptor>
                  )
                })}
              </div>}
              {gear && gear.length > 0 && <div className={styles.skillTraitsWrapper}>
                {gear.map((st,key) => {
                  return (
                    <AddOnClickDescriptor key={getTagKey(st)} tag={st}  onClickDescriptor={onClickDescriptor} >
                      {key > 0 ? `, ` : ``}
                      <div className={styles.traitEntry}>{st.label}</div>
                    </AddOnClickDescriptor>
                  )
                })}
              </div>}
            </div>
            {
              ((factionCampaignUpgrades && factionCampaignUpgrades.length > 0) ||
                (injuries && injuries.length > 0) ||
                (upgrades && upgrades.length > 0 )
              )&& (<div className={styles.traitsHeader}>
             Campaign Traits
            </div>)}
            <div>
              {factionCampaignUpgrades && factionCampaignUpgrades.length > 0 && <div className={styles.skillTraitsWrapper}>
              {factionCampaignUpgrades.map((st,key) => {
                  return (
                    <AddOnClickDescriptor key={getTagKey(st)} tag={st}  onClickDescriptor={onClickDescriptor} >
                      {key > 0 ? `, ` : ``}
                      <div className={styles.traitEntry}>{st.label}</div>
                    </AddOnClickDescriptor>
                  )
                })}
              </div>}

              {injuries && injuries.length > 0 && <div className={styles.skillTraitsWrapper}>
              {injuries.map((st,key) => {
                  return (
                    <AddOnClickDescriptor key={getTagKey(st)} tag={st}  onClickDescriptor={onClickDescriptor} >
                      {key > 0 ? `, ` : ``}
                      <div className={styles.traitEntry}>{st.label}</div>
                    </AddOnClickDescriptor>
                  )
                })}
              </div>}
              {upgrades && upgrades.length > 0 && <div className={styles.skillTraitsWrapper}>
                {upgrades.map((st,key) => {
                  return (
                    <AddOnClickDescriptor key={getTagKey(st)} tag={st}  onClickDescriptor={onClickDescriptor} >
                      {key > 0 ? `, ` : ``}
                      <div className={styles.traitEntry}>{st.label}</div>
                    </AddOnClickDescriptor>
                  )
                })}
              </div>}
            </div>
          </div>
        }
      </div>
    </DivCutCornerBorder>
    </div>
  </div>);
};

interface reduceType {
  stats: DisplayTagType[];
  abilityTraits: DisplayTagType[];
  fightTraits: DisplayTagType[];
  shootTraits: DisplayTagType[];
  statTraits: DisplayTagType[];
  traitConditions: DisplayTagType[];
  injuries: DisplayTagType[];
  gear: DisplayTagType[];
  factionSpecialRules: DisplayTagType[];
  factionCampaignUpgrades: DisplayTagType[];
  upgrades: DisplayTagType[];
  tacticOptions: DisplayTagType[];
  remainingTagsMap?: Map<string, TagType>;
}

interface useBloodOfStarsMemberDataProps {
  displayArchetypeMember?: CastMemberType;
  archetypeDisplay?: ArchetypeDisplayType;
  showPtsFractions?: boolean;
}

const removeTagsFromMap = (tags: TagType[], tagMap?: Map<string, TagType>) => {

  if(!tagMap) {
    return tagMap;
  }

  return tags.reduce((ntm: Map<string, TagType>, t: TagType) => {
    if (ntm.get(getTagKey(t))) {
      ntm.delete(getTagKey(t));
    }

    return ntm;

  }, new Map(tagMap));

}

const useBloodOfStarsMembers = ({displayArchetypeMember, archetypeDisplay, showPtsFractions}: useBloodOfStarsMemberDataProps) => {
  const displayMemberTagMaps  = displayArchetypeMember?.tagsMap;
  const tagOptionsMap = displayArchetypeMember?.tagOptionsMap;
  const sections = archetypeDisplay?.data?.sections; 
  const name =  displayArchetypeMember?.title || displayArchetypeMember?.name || "";
  const subName = displayArchetypeMember?.title &&  displayArchetypeMember?.name ? displayArchetypeMember?.name : "";
  const memberTagsMap = displayMemberTagMaps;
  const ptsTag = displayArchetypeMember?.tagsMap?.get(StatTags.points);
  const displayPtsTag: TagType | undefined = ptsTag && { ...ptsTag };

  if (displayPtsTag) {
    displayPtsTag.val = showPtsFractions ? displayPtsTag.val : parseInt(`${displayPtsTag?.val}` || `0`, 10);
  } 

  const standingTag = displayArchetypeMember?.tagsMap?.get(StatTags.standing); 
  const pts = ptsTag && !Array.isArray(ptsTag.val) ? `${showPtsFractions ? ptsTag?.val : parseInt(`${ptsTag?.val}` || `0`, 10)}` : "0"; 
  const tags = sections?.reduce((result: reduceType, section) => {
    const curTags = getTags(section.tagKeys, result.remainingTagsMap);
    result.remainingTagsMap = removeTagsFromMap(curTags, result.remainingTagsMap);
    const curOptionTags = getTags(section.tagKeys, tagOptionsMap);
    if (section.key === SectionTagKey.statline) {
      result.stats = curTags?.map((ct) => {
        const baseLabel = ct.title || ct.key; 
        return {
          ...ct,
          label: baseLabel 
          //? baseLabel.substring(0,1) : ``
        }
      });
      result.remainingTagsMap = removeTagsFromMap(result.stats, result.remainingTagsMap);
    }
    else if (section.key === SectionTagKey.traits) {
      result.statTraits = curTags?.map((ct) => {
        return {
          ...ct,
          label: getTagDisplayName(ct)
        }
      });
    }
    else if (section.key === SectionTagKey.abilityTraits) {
      result.abilityTraits = curTags?.map((ct) => {
        return {
          ...ct,
          label: getTagDisplayName(ct)
        }
      });
    }
    else if (section.key === SectionTagKey.fightTraits) {
      result.fightTraits = curTags?.map((ct) => {
        return {
          ...ct,
          label: getTagDisplayName(ct)
        }
      });
    }
    else if (section.key === SectionTagKey.shootTraits) {
      result.shootTraits = curTags?.map((ct) => {
        return {
          ...ct,
          label: getTagDisplayName(ct)
        }
      });
    }
    else if (section.key === SectionTagKey.traitConditions) {
      result.traitConditions = curTags?.map((ct) => {
        return {
          ...ct,
          label: getTagDisplayName(ct)
        }
      });
    }
    else if (section.key === SectionTagKey.injuries) {
      result.injuries = curOptionTags?.map((ct) => {
        return {
          ...ct,
          label: getTagDisplayName(ct)
        }
      });
    }
    else if (section.key === SectionTagKey.gear) {
      result.gear = curOptionTags?.map((ct) => {
        return {
          ...ct,
          label: getTagDisplayName(ct)
        }
      });
    }
    else if (section.key === SectionTagKey.factionSpecialRules) {
      result.factionSpecialRules = curOptionTags?.map((ct) => {
        return {
          ...ct,
          label: getTagDisplayName(ct)
        }
      });
    }
    else if (section.key === SectionTagKey.factionCampaignUpgrades) {
      result.factionCampaignUpgrades = curOptionTags?.map((ct) => {
        return {
          ...ct,
          label: getTagDisplayName(ct)
        }
      });
    }
    else if (section.key === SectionTagKey.upgrades) {
      result.upgrades = curOptionTags?.map((ct) => {
        return {
          ...ct,
          label: getTagDisplayName(ct)
        }
      });
    }
    else if (section.key === SectionTagKey.tacticOptions) {
      result.tacticOptions = curOptionTags?.map((ct) => {
        return {
          ...ct,
          label: getTagDisplayName(ct)
        }
      });
    }

    return result;
  },{
    stats:[],
    abilityTraits: [],
    fightTraits: [],
    shootTraits: [],
    statTraits:[],
    traitConditions:[],
    injuries: [],
    gear: [],
    factionSpecialRules: [],
    factionCampaignUpgrades: [],
    upgrades: [],
    tacticOptions: [],
    remainingTagsMap: new Map(memberTagsMap)
  });

  return {
    name, 
    subName, 
    pts,
    ptsTag: displayPtsTag || ptsTag,
    standingTag,
    sections,
    stats : tags?.stats || [],
    abilityTraits: tags?.abilityTraits || [],
    fightTraits: tags?.fightTraits || [],
    shootTraits: tags?.shootTraits || [],
    statTraits : tags?.statTraits || [],
    traitConditions : tags?.traitConditions || [],
    injuries: tags?.injuries || [],
    gear: tags?.gear || [],
    factionSpecialRules: tags?.factionSpecialRules || [],
    factionCampaignUpgrades: tags?.factionCampaignUpgrades || [],
    upgrades: tags?.upgrades || [],
    tacticOptions: tags?.tacticOptions || []
  };
}


export const ShowArchetypeMember = ({ 
  archetypeMember,
  archetypeDisplay,
  tagModifiers,
  tagDescriptionsMap,
  className,
  onClickDescriptor,
  isShrunk,
  toggleShrink,
}: ShowArchetypeProps ) => {
  const {
    displayArchetypeMember,
  } = useDisplayArchetypeMember({ archetypeMember, tagModifiers, tagDescriptionsMap});
  const [searchParams] = useSearchParams();
  const showPtsFractions = searchParams.get('showPtsFractions') ? true : false;
  return (<ShowDisplayArchetypeMember
    {...{ displayArchetypeMember, archetypeDisplay, onClickDescriptor, className, showPtsFractions, isShrunk, toggleShrink, }}
  />);
 }

 export const ShowArchetypeMemberTactic = ({ 
  archetypeMember,
  archetypeDisplay,
  tagModifiers,
  tagDescriptionsMap,
  className,
  onClickDescriptor
}: ShowArchetypeProps ) => {
  const {
    displayArchetypeMember,
  } = useDisplayArchetypeMember({ archetypeMember, tagModifiers, tagDescriptionsMap});
  const [searchParams] = useSearchParams();
  const showPtsFractions = searchParams.get('showPtsFractions') ? true : false;


  return (<ShowDisplayArchetypeMember
    {...{ displayArchetypeMember, archetypeDisplay, onClickDescriptor, className, showPtsFractions}}
  />);
 }

