import React, { ReactNode } from 'react';

import Header from "./Header";
import ContentWrapper from "./ContentWrapper";
import styles from "./styles.module.scss";

interface LayoutPresentationProps {
  children?: ReactNode;
}

const LayoutPresentation = ({children}: LayoutPresentationProps) => {
  return (<div className={styles.bodyWrapper}>
    <Header/>
    <ContentWrapper>
      {children}
    </ContentWrapper>
  </div>);
};

export default LayoutPresentation;
