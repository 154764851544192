import React, { useCallback } from "react";
import Layout from "Layout";
import { Button } from "components/Buttons/Button";
import { TextField, TextFieldType } from "components/Forms/TextField";
import { ShowTag } from "components/Tags/ShowTag";
import styles from "./styles.module.scss";
import { FormType } from "components/Forms/Form";
import { useTagModifierForm } from "pages/TagModifiersGroups/useTagModifiersGroupForm";
import { Form } from 'components/Forms/Form';
import JSONEditor from "components/JsonEditor";
import { useNavigate } from "react-router-dom";
import { TagModifiersGroupType } from "actions/tag.types";
import { routeLinks } from "App/routing";


export const NewTagModifier = () => {
  const navigate = useNavigate();

  const submitCallback = useCallback((tagModifiersGroups?: TagModifiersGroupType) => {
    navigate(`/${routeLinks.tagModifiersGroups}/${tagModifiersGroups?.id}`);
  }, [navigate]);

  const {
    tagDescriptionsMap,
    errors,
    onSubmit,
    register,
    submitting,
    submitError,
    setValue,
    formData
  } = useTagModifierForm({ formType: FormType.Create, callback: submitCallback });

  return (
    <Layout>
      <div >
        <h1>
          New Tag Modifiers Group
        </h1>
        <div>
          <Form
            onSubmit={onSubmit}
          >
            <Button type="submit">Save</Button>
            {!submitting && submitError && (<div>{submitError}</div>)} 
            <TextField 
              type={TextFieldType.text}
              placeholder="key"
              label="Name"
              labelClassName={styles.label}
              className={styles.entry}
              error={errors?.name}
              {...register("name",{
                validate:{
                  validLength: (val?: string) => {
                    return val && val?.length > 0;
                  }
                }
              })}
            />
            <TextField 
              type={TextFieldType.text}
              placeholder="Description"
              label="Description"
              labelClassName={styles.label}
              className={styles.entry}
              error={errors?.description}
              {...register("description",{
                validate:{
                  validLength: (val?: string) => {
                    return (val && val?.length > 0) || "Field Must not be empty.";
                  }
                }
              })}
            />
            <TextField 
              type={TextFieldType.number}
              labelClassName={styles.label}
              className={styles.entry}
              placeholder="Display"
              label="Display"
              error={errors?.display}
              {...register("display",{
              })}
            />
            <TextField
              type={TextFieldType.textArea}
              label="Data" 
              fieldClassName={styles.dataText}
              labelClassName={styles.label}
              className={styles.hidden}
              error={errors?.data}
              {...register("data",{
                validate:{
                  validLength: (val?: string) => {
                    return val && val?.length > 0;
                  }
                }
              })}
            />
            <JSONEditor
              value={formData}
              className={styles.dataBlock}
              label="Data"
              labelClassName={styles.label}
              onChange={(changed, oldChanged) => {
                if(changed !== oldChanged && (typeof changed === "string" || typeof changed === 'undefined')) {
                  setValue('data', changed || '');
                }
              }}
            />
          </Form>
        </div>
        <div>
          {Array.from(tagDescriptionsMap.values())?.map((tag) => {
            return (<ShowTag tag={tag} key={tag.id}
            />);
          })}
        </div>
      </div>
    </Layout>
  );
}
