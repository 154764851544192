import {useCallback} from "react";
import Layout from "Layout";
import classNames from "classnames";
import { useParams } from "react-router-dom";

import { Button } from "components/Buttons/Button";
import { TextField, TextFieldType } from "components/Forms/TextField";
import styles from "./styles.module.scss";

import { Form } from 'components/Forms/Form';
import { JSONEditor } from "components/JsonEditor"
import { useArchetypeDisplayForm } from "pages/ArchetypeDisplays/useArchetypeDisplayForm";
import { routeLinks } from "App/routing";
import { useNavigate } from "react-router-dom";

import { ArchetypeDisplayType } from "actions/cast.types";

type CastPageParams = {
  archetypeDisplayId: string;
};

export const EditArchetypeDisplay = () => {
  const { archetypeDisplayId } = useParams<CastPageParams>();
  const navigate = useNavigate();
  const submitCallback = useCallback((archetypeDisplay?: ArchetypeDisplayType) => {
    navigate(`/${routeLinks.archetypeDisplays}/${archetypeDisplay?.id || archetypeDisplayId}`);
  }, [navigate, archetypeDisplayId]);

  const {
    name,
    errors,
    onSubmit,
    register,
    submitting,
    submitError,
    setValue,
    formGroups,
    formSections,
    loaded
  } = useArchetypeDisplayForm({archetypeDisplayId, callback: submitCallback});

  return (
    <Layout>
      <div >
        { !loaded ? <div>loading...</div> : 
          <>
            <h1>
              { `${archetypeDisplayId} : ${name}`}
            </h1>
            <div>
              <Form
                onSubmit={onSubmit}
              >
                <Button type="submit">Save</Button>
                {!submitting && submitError && (<div>{submitError}</div>)}
                <TextField 
                  type={TextFieldType.text}
                  placeholder="key"
                  label="Name"
                  labelClassName={styles.label}
                  className={styles.entry}
                  error={errors?.name && { ...errors.name, ...{message:"Name must not be empty"}}}
                  {...register("name",{
                    validate:{
                      validLength: (val) => {
                        return (val && val?.length > 0) || "Cannot be blank";
                      }
                    }
                  })}
                />
                <TextField 
                  type={TextFieldType.text}
                  placeholder="Description"
                  label="Description"
                  labelClassName={styles.label}
                  className={classNames(styles.entry, errors?.description && styles.textError)}
                  error={errors?.description && { ...errors.description, ...{message:"Description must not be empty"}}}
                  {...register("description",{
                    validate:{
                      validLength: (val) => {
                        return (val && val?.length > 0 )|| "Cannot be blank";
                      }
                    }
                  })}
                />
                <TextField 
                  type={TextFieldType.number}
                  labelClassName={styles.label}
                  className={styles.entry}
                  placeholder="Display"
                  label="Display"
                  error={errors?.display}
                  {...register("display",{
                  })}
                />
              <TextField
                  type={TextFieldType.textArea}
                  label="Sections" 
                  fieldClassName={styles.dataText}
                  labelClassName={styles.label}
                  className={styles.hidden}
                  error={errors?.sections}
                  {...register("sections",{
                    validate:{
                      validLength: (val) => {
                        return val && val?.length > 0;
                      }
                    }
                  })}
                />
                <JSONEditor
                  value={formSections}
                  className={styles.dataBlock}
                  label="Sections"
                  labelClassName={styles.label}
                  onChange={(changed, oldChanged) => {
                    if(changed !== oldChanged && (typeof changed === "string" || typeof changed === 'undefined')) {
                      setValue('sections', changed || '');
                    }
                  }}
                />
                <TextField
                  type={TextFieldType.textArea}
                  label="Groups" 
                  fieldClassName={styles.dataText}
                  labelClassName={styles.label}
                  className={styles.hidden}
                  error={errors?.groups}
                  {...register("groups",{
                    validate:{
                      validLength: (val) => {
                        return val && val?.length > 0;
                      }
                    }
                  })}
                />
                <JSONEditor
                  value={formGroups}
                  className={styles.dataBlock}
                  label="Groups"
                  labelClassName={styles.label}
                  onChange={(changed, oldChanged) => {
                    if(changed !== oldChanged && (typeof changed === "string" || typeof changed === 'undefined')) {
                      setValue('groups', changed || '');
                    }
                  }}
                />
              </Form>
            </div>
          </>
        }
      </div>
    </Layout>
  );
}
